
import { HelpWrapper, FlexBox } from './styles';
import { ReactElement } from 'react';
type props = {
  title: String
  callCenter: String
  email: String
  img: string
};
export default function Help(props: props): ReactElement {
  return (
      <HelpWrapper>
        { props.title }
        <FlexBox>
          <div>
            <p>
              <a href={`https://wa.me/${props.callCenter}`} target="_blank">
                Atendimento via WhatsApp
              </a>
            </p>
            <p>
              { props.email }
            </p>
          </div>
            <img src={ props.img } />
        </FlexBox>
      </HelpWrapper>
  );
}
