import { useMemo } from 'react';
import { useTotalValues } from '../../hooks/totalValues';
import { MoneyFormat } from '../MoneyFormat';
import { LabelTotal, TotalWrapper } from './styles';

export function Total() {
  const { totalValues } = useTotalValues();

  const {
    price,
    amountDiscountApplied,
    amountWithDiscountApplied,
    voucher,
    percentDiscount
  } = totalValues;

  const subtotal = useMemo(() => {
    if (
      percentDiscount > 0 &&
      amountDiscountApplied &&
      amountWithDiscountApplied !== undefined &&
      voucher
    ) {
      return (
        <>
          <LabelTotal color="grey">Total</LabelTotal>
          <LabelTotal color="grey">
            <MoneyFormat>{price}</MoneyFormat>
          </LabelTotal>
          <LabelTotal color="#008000b3">Desconto</LabelTotal>
          <LabelTotal color="#008000b3">
            <MoneyFormat>{amountDiscountApplied}</MoneyFormat>
          </LabelTotal>
        </>
      );
    }

    return null;
  }, [voucher, percentDiscount]);

  const total = useMemo(() => {
    let totalText = 'Total';
    let value = price;
    if (amountWithDiscountApplied !== undefined) {
      totalText = 'Subtotal';
      value = amountWithDiscountApplied;
    }

    return (
      <>
        <LabelTotal>{totalText}</LabelTotal>
        <LabelTotal>
          <MoneyFormat>{value}</MoneyFormat>
        </LabelTotal>
      </>
    );
  }, [totalValues]);

  return (
    <TotalWrapper>
      {subtotal}
      {total}
    </TotalWrapper>
  );
}

export default Total;
