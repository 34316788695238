import { ReactElement, useState } from 'react';
import { ArrowBack, IconCheck } from '../../../assets/icons';
import { Ul, StyledSideBar, Header, CircleIcon, Dot } from './styles';
type props = {
  quests: Object []
  actual: number
};
export default function SideBar(props: props): ReactElement {
  const [open, setOpen] = useState(true);
  return (
    <StyledSideBar open={open}>
      <Header open={open}>
        <h2>Questionários</h2>
        <button onClick={() => setOpen(!open)}><ArrowBack /></button>
      </Header>
      <Ul open={open}>
        { props.quests?.map((quest: any, index: number) => {
          return (
            <li key={ index }>
              <CircleIcon colorBase={props.actual > index ? '#15B193' : '#F0F2F4'}>
              {props.actual > index ? <IconCheck /> : <Dot />}
              </CircleIcon>
              <span className={props.actual > index ? 'green' : ''}>{ quest.text }</span>
            </li>
          );
        })}
      </Ul>
    </StyledSideBar>
  );
}
