import { ReactElement } from 'react';
import Button from '../Button';
import { StyledCard, Content, ButtonWrapper } from './styles';
import { Link } from 'react-router-dom';
type props = {
  children?: ReactElement
  cardBody: string | ReactElement
  cardBodyText?: string | ReactElement
  img?: string
  bodyColor?: string
  bodyFontWeight?: string
  secondCollumn?: boolean
  userName?: string
  mode?: string
  borderColor?: string
  buttonText?: string
  bodyFontSize?: number
  imgHeight?: number
  isRightImg?: boolean
  isLink?: string
  onClick?: Function | any
};
export default function Card(props: props): ReactElement {
  const isDarkMode = props.mode === 'dark' ? 1 : 0;
  return (
    <StyledCard
      isDarkMode={isDarkMode}
      isRightImg={props.isRightImg}
      borderColor={props.borderColor}
      imgHeight={props.imgHeight}
    >
      {props.img && <img src={props.img}/>}
      <div className={props.secondCollumn ? 'flex' : ''}>
        <Content
          isRightImg={props.isRightImg}
          color={props.bodyColor}
          fontSize={props.bodyFontSize}
          fontWeight={props.bodyFontWeight}
        >
            <span>
              {props.cardBody}
            </span>
            <br/>
            <strong>
              {props.userName}
            </strong>
            <br/>
            <span>
              {props.cardBodyText}
            </span>
        </Content>
        {props.buttonText &&
          <ButtonWrapper>
            {props.isLink
              ? (
                <Link className="link" to={props.isLink}>
                  {props.buttonText}
                </Link>
                )
              : (
                <Button onClick={() => props.onClick(true)} border={isDarkMode ? 'solid 1px white' : 'none' }>
                  {props.buttonText}
                </Button>
                )
            }

          </ButtonWrapper>
        }
      </div>
    </StyledCard>
  );
}
