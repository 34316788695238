import { apiCatalogLambda, apiCatalogo, apiProductsMocks } from './api';
import { Catalog, ProductsMock, Services } from '../interfaces';
import { ServiceProductLambda } from '../interfaces/catalogLambda.interface';

async function getCatalogoService(): Promise<Services> {
  const { data } = await apiCatalogo.get<Services>(
    `${process.env.REACT_APP_CATALOGO_SERVICE}/services/${process.env.REACT_APP_PREDICTA_SERVICE_ID}`
  );

  return data;
}

async function getCatalogoLambda(
  idCatalogo: string
): Promise<ServiceProductLambda> {
  const { data } = await apiCatalogLambda.get<ServiceProductLambda>(
    `/${idCatalogo}`
  );

  return data;
}

async function getCatalogoMock(idService: string = '2'): Promise<ProductsMock> {
  const { data } = await apiProductsMocks.post<ProductsMock>('/products/', {
    service_id: idService
  });

  return data;
}

export {
  getCatalogoService as getCatalogo,
  getCatalogoLambda,
  getCatalogoMock
};
