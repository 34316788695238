import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      height: 40,
      width: '100%',
      '& > div': {
        height: 40,
        position: 'relative'
      },
      '& label': {
        position: 'absolute',
        top: -8,
        color: 'rgba(153, 153, 153, 0.73)'
      },
      '& .MuiFormLabel-filled': {
        color: '#004f92'
      },
      '& .MuiFormLabel-filled + div fieldset': {
        borderColor: '#004f92'
      },
      '& .MuiInputLabel-shrink': {
        top: 0
      },
      '& .MuiSelect-select': {
        padding: 10,
        paddingBottom: 10,
        paddingLeft: 14,
        marginTop: 2,
        color: '#004f92'
      },
      '& div input': {
        height: 34,
        padding: 10,
        paddingLeft: 14
      },
      '& fieldset': {
        height: 46,
        borderRadius: 5
      },
      '& p': {
        fontSize: 10,
        marginLeft: 5,
        padding: 0,
        color: '#f44336'
      }
    }
  })
);
