import { Title } from './styles';
import { useTotalValues } from '../../hooks/totalValues';
import { usePaymentServices } from '../../hooks/usePaymentServices';
import { useVoucher } from '../../contexts/Voucher/context';
import FormVoucher from '../Voucher';
import { useAuth } from '../../contexts/Auth/context';
import { useState } from 'react';
import { useInstallments } from '../../hooks/installments';
import { useDispatch } from 'react-redux';
import { SET_FULL_DISCOUNT_VOUCHER } from '../../store/redux';

type VoucherInputProps = {
  remove: () => void;
};

export function VoucherInput({ remove }: VoucherInputProps) {
  const dispatch = useDispatch();
  const {
    voucher,
    setVoucher,
    invalidVoucher,
    successVoucher,
    setInvalidVoucher,
    setSuccessVoucher,
    applying,
    setApplying
  } = useVoucher();

  const { setTotalValues } = useTotalValues();
  const { applyVoucher } = usePaymentServices();
  const { handleInstallments, installments } = useInstallments();

  const { productId, serviceId, user } = useAuth();
  const [message, setMessage] = useState<string>('');

  const handleApplyDiscount = async () => {
    setApplying();

    if (!voucher) {
      setInvalidVoucher();
      setMessage('O cupom não pode ser vazio');
      return;
    }

    try {
      const data = await applyVoucher(voucher, productId, user.document);

      if (data.percentDiscount === 100) {
        dispatch({ type: SET_FULL_DISCOUNT_VOUCHER, fullDiscountVoucher: true });
      }

      handleInstallments({
        ...installments,
        price: data.amountWithDiscountApplied
      });

      setTotalValues({
        ...data,
        price: data.amount,
        voucher
      });

      setSuccessVoucher();
    } catch (error: any) {
      setVoucher('');
      setInvalidVoucher();

      if (error?.status === 400 && error?.data?.error) {
        setMessage(error?.data?.error);
      } else {
        setMessage('Cupom informado inválido');
      }
    }
  };

  return (
    <>
      <Title>Cupom de desconto</Title>
      <FormVoucher
        isError={invalidVoucher}
        isSuccess={voucher !== '' && successVoucher}
        voucher={voucher}
        message={message}
        applying={applying}
        onChange={(e) => setVoucher(e.target.value)}
        submit={handleApplyDiscount}
        remove={() => {
          dispatch({ type: SET_FULL_DISCOUNT_VOUCHER, fullDiscountVoucher: false });
          setVoucher('');
          remove();
        }}
      />
    </>
  );
}
