import styled from 'styled-components';

import { Container } from '../../styles/GlobalStyle';

export const FooterSite = styled.footer`
    background: rgb(0,79,146);
    color: #fff;
    font-size: 22px;
    padding-bottom:10px;
    
    @media (min-widht: 1025px) { 
      zoom: 80%;
    }
`;
export const FooterMessage = styled.div`
  background: #1170b7;
  padding: 15px 0;

  p {
    color: #fff;
    margin: 0;
    font-size: 20px;
    text-align: center;
  }
  @media (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;
export const FooterContainer = styled(Container)`
    .flex{
      display: flex;
      font-weight: 500;
    }
    .align-center{
      align-items: center;
    }
    .ml-10{
      margin-left: 10px;
    }
    .social{
      margin-bottom: 0px;
    }
    @media (min-width: 769px){
        padding-top: 3rem;
        padding-left: 6rem;
        padding-right: 6rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    @media(max-width: 768px){
      justify-content: center;
      text-align: center;

      .flex{
        text-align: center;
        justify-content: center;
      }
    }
`;

export const TitleH3 = styled.h3`
    margin-bottom: 10px;
    font-size: 21px;
    font-weight: 600;

    @media(max-width: 768px){
      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }
`;
export const SocialLinksWrapper = styled.div`
    color: #fff;
`;

export const UlSocial = styled.ul`
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    @media (max-width: 768px) {
      justify-content: center;
      flex-wrap: wrap;
    }
`;
export const LiSocial = styled.li`
    padding: 8px;
    svg{
        font-size: 30px;
    }
`;

export const DivCopyright = styled.div`
    font-size: .75rem;
    margin-top: 20px;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;
    width: 100%;
`;
