import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Cpf, Phone, DateM } from '../../utils/masks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { register } from '../../utils/services';
import Header from './Header';
import { Content, FormWrapper, FormHeader, Alert } from './styles';
import { ArrowBack, IconCheck } from '../../assets/icons';
import Load from '../../ui/SimpleLoad';
import TagManager from 'react-gtm-module';
import Input from './Input';

export default function Register(): ReactElement {
  document.body.classList.remove('modal-open');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [passport, setPassport] = useState('');
  const [cpf, setCpf] = useState('');
  const [date, setDate] = useState('');
  const [sex, setSex] = useState('M');
  const [nationality, setNationality] = useState('2');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [terms, setTerms] = useState(false);
  const [data, setData] = useState(false);

  const [loading, setLoading] = useState(false);
  const [erro, setErro] = useState('');
  const [erros, setErros] = useState<Array<{ path: string, message: string }>>();
  const [message, setMessage] = useState('');

  // Scroll to error
  const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetBottom);
  const myRefError = useRef(null);
  const myRefMessage = useRef(null);
  const executeScroll = (ref: any) => scrollToRef(ref);

  useEffect(() => {
    if (erro !== '') {
      executeScroll(myRefError);
    }
  }, [erro]);

  const submitHandle = async (): Promise<void> => {
    setErros([]);
    setErro('');
    if (password !== confirmPassword) {
      setErros([{ path: 'password', message: 'As senhas não conferem' }]);
    } else if (!terms) {
      setErro('Você precisa concordar com os Termos de Serviço');
    } else if (!data) {
      setErro('Você precisa concordar com os Termos do contrato de prestação de serviços.');
    } else {
      setLoading(true);
      let parsedDate = '';
      if (date) {
        const parts = date.split('/');
        parsedDate = format(
          new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0])), 'yyyy-MM-dd'
        );
      }
      const data = {
        name: name,
        lastName: lastName,
        email: email,
        phone: phone,
        document: nationality === '2' ? cpf.replace(/[^\d]+/g, '') : passport,
        documentType: nationality === '2' ? 'CPF' : 'PASSAPORTE',
        date: parsedDate,
        sex: sex,
        password: password
      };
      const submitResponse = await register(data);
      if ('erro' in submitResponse) {
        setErro('');
        if (submitResponse?.erro) {
          const hasPassErr = submitResponse?.erro?.match(/senha/i);
          const hasDocErr = submitResponse?.erro?.match(/document/i);
          if (hasDocErr || hasPassErr) {
            const err = { path: hasDocErr ? 'document' : 'password', message: submitResponse.erro };
            if (submitResponse.erros) {
              submitResponse.erros.put(err);
            } else {
              submitResponse.erros = [err];
            }
          } else {
            setErro(submitResponse.erro);
          }
        }
        setErros(submitResponse.erros);
      } else {
        setMessage('Seu cadastro foi criado com sucesso.');
        executeScroll(myRefMessage);
      }
      setLoading(false);
    }
  };
  return (
    <>
      <Header />
      <Content>
        <FormWrapper ref={myRefMessage}>
          {loading
            ? (
              <Load top="300px" isFixed={true}></Load>
            )
            : message && (
              <Alert>
                <p>{message}</p>
                <p>Para ativa-lo clique no link que enviamos para seu e-mail</p>
                <p>Caso já tenha confirmado seu email, <Link to="/" className="link">clique aqui</Link> para fazer o login</p>
              </Alert>
            )}
          <>
            <FormHeader>
              <Link to="/">
                <ArrowBack />
              </Link>
              <h1>Cadastro</h1>
            </FormHeader>
            <p>
              Cadastre-se com seus dados para utilizar os serviços disponíveis no <br />
              Einstein Conecta.
            </p>

            {erro !== '' && (<p ref={myRefError} className="error">{erro}</p>)}
            <Input
              label='Nome'
              type="text"
              onChange={(e) => setName(e.target.value)}
              value={name}
              autoComplete="off"
              errMsg={erros?.find(e => e.path === 'firstName')?.message}
            />
            <Input
              label='Sobrenome'
              type="text"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              autoComplete="off"
              errMsg={erros?.find(e => e.path === 'lastName')?.message}
            />

            <div className="form-group">
              <select className="form-control" placeholder="Nacionalidade" onChange={(e) => setNationality(e.target.value)} value={nationality} autoComplete="off" required>
                <option value="2">Brasileiro</option>
                <option value="3">Estrangeiro</option>
              </select>
              <label className="form-label">Nacionalidade</label>
            </div>

            <Input
              label={nationality === '2' ? 'CPF' : 'Passaporte'}
              type="text"
              onChange={(e) => nationality === '2' ? setCpf(Cpf(e.target.value)) : setPassport(e.target.value)}
              value={nationality === '2' ? cpf : passport}
              autoComplete="off"
              errMsg={erros?.find(e => e.path === 'documentType' || e.path === 'document')?.message}
            />
            <Input
              label='Data de Nascimento'
              type="text"
              onChange={(e) => setDate(DateM(e.target.value))}
              value={date}
              autoComplete="off"
              errMsg={erros?.find(e => e.path === 'birthDate')?.message}
            />
            <div className="form-group">
              <select
                className="form-control"
                placeholder="sexo" onChange={(e) => {
                setSex(e.target.value);
                }} value={sex} required>
                <option value="M">Masculino</option>
                <option value="F">Feminino</option>
              </select>
              <label className="form-label">Sexo</label>
            </div>
            <Input
              label='Telefone'
              placeholder='DDD + Telefone'
              type="text"
              onChange={(e) => setPhone(Phone(e.target.value))}
              value={phone}
              autoComplete="off"
              errMsg={erros?.find(e => e.path === 'phone')?.message}
            />
            <Input
              label='E-mail'
              type="text"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              autoComplete="off"
              errMsg={erros?.find(e => e.path === 'email')?.message}
            />
            <Input
              label='Senha'
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              autoComplete="new-password"
              errMsg={erros?.find(e => e.path === 'password')?.message}
            />
            <Input
              label='Confirme sua senha'
              placeholder='Senha'
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              autoComplete="new-password"
              errMsg={erros?.find(e => e.path === 'password')?.message}
            />
            <div className="verify-pass">
              <p>
                <IconCheck /> Pelo menos 8 caracteres
              </p>
              <p>
                <IconCheck /> Contém letra maiúscula (A-Z)
              </p>
              <p>
                <IconCheck /> Contém letra minúscula (a-z)
              </p>
              <p>
                <IconCheck /> Contém número (0-9)
              </p>
              <p>
                <IconCheck /> Contém caractere especial (@#$&*)
              </p>
            </div>
            <label className="flex check-box">
              <input checked={data} onChange={(e) => setData(e.target.checked)} type="checkbox" />
              <div>
                Eu concordo que o Hospital Israelita Albert Einstein armazene e processe <br /> meus dados pessoais. <Link className="link" to="/Termos/termos.pdf" target="_blank">Termos de Serviço</Link>.
              </div>
            </label>

            <label className="flex check-box">
              <input checked={terms} onChange={(e) => setTerms(e.target.checked)} type="checkbox" />
              <div>
                Termos do contrato de <Link className="link" to="/Termos/contrato.pdf" target="_blank">prestação de serviços</Link>.
              </div>
            </label>

            <label className="flex check-box">
              <input type="checkbox" />
              <div>
                Envio de comunicações relevantes para sua saúde e para a prestação de
                <br /> serviços nas unidades Einstein, em diferentes canais (físicos ou digitais),
                <br />apurados com base no histórico de utilização dos serviços, demografia,
                <br /> residência e preferências pessoais informadas.
              </div>
            </label>

            <button onClick={async () => {
              submitHandle();
              const tagManagerArgs = {
                gtmId: 'GTM-TDPD3K8',
                dataLayer: {
                  event: 'Einstein-Predicta',
                  categoria: 'Entrar',
                  acao: 'step-2.1',
                  rotulo: 'Cadastrar - se'
                }
              };

              TagManager.initialize(tagManagerArgs);
            }}>Cadastrar</button>
          </>
        </FormWrapper>
      </Content>
    </>
  );
}
