import { ReactElement, useEffect, useState } from 'react';

import { ScheduleWrapper, ButtonNewSchedule, ScheduleTitle, ScheduleInfos, Load, ToolTip } from './styles';

import { IconsNewSchedule } from '../../assets/icons';
import CalendarSchedule from '../CalendarSchedule/index';
import CardSchedule from './CardSchedule';
import { useDispatch, useSelector } from 'react-redux';

import { OPEN_MODAL, ScheduleRootState, UserInfo } from '../../store/redux';
import axios from 'axios';
import { addHours } from 'date-fns';

const baseUrl = process.env.REACT_APP_BASE_API;
const token = `Bearer ${String(localStorage.getItem('api_token'))}`;
const tokenPatient = `Bearer ${String(localStorage.getItem('api_patientToken'))}`;
const config = {
  headers: {
    'x-access-token': token,
    'x-access-patient-token': tokenPatient
  }
};

const bodySchedule = {
  schedulingId: '',
  unityId: '',
  locationId: '',
  resourceId: '',
  serviceId: '',
  medicalRecord: '', // user.prontuario
  finalDate: null,
  registerQuantity: null,
  lastRegister: null,
  schedulingType: null,
  futureScheduling: true,
  statusList: ['P']
};

export default function Schedule(): ReactElement {
  const dispatch = useDispatch();
  const user = useSelector<ScheduleRootState, UserInfo>(scheduleReducer => scheduleReducer.user);
  const userDataLoaded = useSelector<ScheduleRootState, boolean>(scheduleReducer => scheduleReducer.userDataLoaded);
  const [scheduleList, setScheduleList] = useState<any[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [dateSelected, setDateSelected] = useState<Date>(new Date());
  const [postSchedule, setPostSchedule] = useState(false);
  const daySelected = useSelector<ScheduleRootState, string | Date | undefined>(scheduleReducer => scheduleReducer.newSchedule?.day);

  const [width, setWindowWidth] = useState(0);
  const showDesktopComponent: boolean = width > 1024;

  const planInfinityOver: boolean = user.step === 7;

  const reload = useSelector<ScheduleRootState, boolean>(scheduleReducer => scheduleReducer.reload);

  const getSchedules = async (): Promise<any> => {
    bodySchedule.medicalRecord = user.prontuario;

    const scheduleResponse = await axios.post<any>(
      `${baseUrl}/schedule/getAppointments`,
      bodySchedule,
      config
    )
      .then(response => {
        setIsLoading(true);
        setScheduleList(response.data.response);
        setPostSchedule(localStorage.getItem('postSchedule') === 'true');
        setIsLoading(false);
        return response;
      }).catch(error => {
        console.log(error);
      });

    return scheduleResponse;
  };

  useEffect(() => {
    if (!userDataLoaded) {
      return;
    }

    getSchedules();
  }, [userDataLoaded, reload]);

  // Responsive
  const updateDimensions = () => {
    const width = window.outerWidth;
    setWindowWidth(width);
  };

  useEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <ScheduleWrapper>
      { user.prontuario === null || !user?.eligibility
      ? <>{showDesktopComponent && <CalendarSchedule dateSelected={scheduleList ? addHours(new Date(scheduleList[0]?.appointmentDate ?? new Date()), 3) : new Date()} setSelectedDate={setDateSelected} /> } </>
      : (<>
        {isLoading
          ? (
            <Load/>
            )
          : (
                <>
                  {showDesktopComponent && <CalendarSchedule dateSelected={scheduleList ? addHours(new Date(scheduleList[0]?.appointmentDate ?? new Date()), 3) : new Date()} setSelectedDate={setDateSelected} /> }
                  {user?.plan !== 1 && showDesktopComponent &&
                  <ButtonNewSchedule disabled={user?.plan === 1 || (user.step >= 3 && user.stepStatus === 'Realizada' && !postSchedule)} onClick={() => dispatch({ type: OPEN_MODAL })}>
                    <IconsNewSchedule /> Novo Agendamento
                    {user.step >= 3 && user.stepStatus === 'Realizada' && !postSchedule && !planInfinityOver && <ToolTip> Disponível após a emissão do laudo anual. </ToolTip>}
                  </ButtonNewSchedule>
                  }

                  <ScheduleInfos>
                    <ScheduleTitle>{ scheduleList?.length !== 0 ? 'Meus agendamentos' : 'Você não possui agendamentos.' }</ScheduleTitle>
                      {scheduleList?.map((schedule: any, index: number) => {
                        return (
                          <CardSchedule key={index} schedule={schedule} />
                        );
                      })}
                  </ScheduleInfos>
                </>
          )
        } </>)
      }
    </ScheduleWrapper>
  );
}
