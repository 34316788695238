interface InputProps {
  label?: string;
  type?: string;
  placeholder?: string;
  onChange?: (e: any) => void;
  value?: any;
  autoComplete?: string;
  errMsg?: string;
}

export default function Input({
  label,
  type,
  placeholder,
  onChange,
  value,
  autoComplete,
  errMsg
}: InputProps) {
  return (
    <div>
      <div className='form-group'>
        <input
          className={errMsg ? 'form-control error-alert' : 'form-control'}
          type={type}
          placeholder={placeholder ?? label}
          onChange={onChange}
          value={value}
          autoComplete={autoComplete}
          required />
        <label className='form-label'>{label}</label>
      </div>
      <div>
        {!!errMsg &&
          (<p className="error-list">{errMsg}</p>)
        }
      </div>
    </div>
  );
}
