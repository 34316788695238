import styled from 'styled-components';
type buttonProps = {
  bkcolor: string
  color: string
};
export const QuizContent = styled.div`
    padding: 4rem;
    display: grid;
    grid-template: 
    "title title"
    "quiz sidebar"
    "quiz sidebar";
    column-gap: 2rem;
    grid-template-columns: auto 355px;
    .link{
      color: #009DFF;
      font-size: .85rem;
      font-weight: 600;
    }
    .underline{
      text-decoration: underline;
    }
    .baseline{
      align-items: flex-end;
    }

    @media(max-width: 720px){
      padding: 2rem;
      display: flex;
      flex-direction: column;
    }
    @media(max-width: 1024px){
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    @media(max-width: 300px){
      padding: 1.2rem;
    }
`;
export const Title = styled.div`
  grid-area: title;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  h1{
    font-size: 22px;
    font-weight: 600;
  }
  svg{
    margin-right: .8rem;
    transform: rotate(-90deg);
  }

  path{
    stroke: #000;
  }
`;
export const SideBarArea = styled.div`
  grid-area: sidebar;

`;
export const QuizArea = styled.div`
  position: relative;
  min-height: 530px;
  grid-area: quiz;
  padding: 40px;
  padding-top: 20px;
  background: #fff;
  border-radius: 10px;

  >div{ 
    display: flex;
    flex-direction: row;
    align-items: center;
    input[type=radio]{
      margin: 10px 10px;
      width: 20px;
      height: 20px;
    }
  }
  
  .product {
    font-size: .85rem;
    color: #707070;
    line-height: 1.5rem;
  }

  h2{
    font-size: 16px;
    max-width: 380px;
    margin-bottom: 20px;
  }

  @media(max-width: 720px){
    margin-bottom: 20px;
  }
  @media(max-width: 1024px){
    width: 100%;
    margin-bottom: 30px;
  }
`;
export const Separator = styled.div`
  border-bottom: 1px solid #DCE1E5;
  height: 30px;
  width: 100%;
`;
export const ButtonsWrapper = styled.div`
  display:flex;
  position: relative;
  top: 10px;
  margin-left: auto;
  margin-top: auto;
  max-width: 270px;
  min-width: 70px;
  button:last-child{
    margin-left: 10px;
  }
`;

export const Button = styled.button<buttonProps>`
  width: 130px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background : ${props => props.bkcolor};
  color: ${props => props.color};
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #009DFF;
  border-radius: 30px;

  &:disabled, &[type=disabled]{
    background-color: #8D9196;
    border: #8D9196;
  }
`;

export const StyledOption = styled.div`
  display: 'flex';
  align-items: center;
  margin-bottom: 1rem;

  .form-control {
    padding-left: 50px;
  }
  label{
    font-size: 14px;
    color: #707070;
  }
  input{
    margin-right: 10px;
    height: 20px;
    width: 20px;
  }
  textarea:focus{
    outline: none;  
    box-shadow: none;
  }
  textarea{
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
    border-radius: 5px;
    width: 524px;
    height: 130px;
    padding: 10px;
    margin-top: 10px;
    border: solid 1px #a6a6a6;
  }
  @media(max-width: 720px){
    textarea{
      width: 200px;
    }
  }
  @media(max-width: 280px){
    textarea{
      width: 100%;
    }
  }
`;
