import { unitsPredicta } from './units';
const units = unitsPredicta.unidades;

let unitsPredictaByUF: any = [];

// Cria o objeto com UF e o objeto unidades vazio
Object.keys(units).forEach((key: any) => {
    unitsPredictaByUF.push({ uf: units[key].uf, units: [] });
});

// Remove UFs duplicadas
unitsPredictaByUF = unitsPredictaByUF.filter(function (this: typeof unitsPredictaByUF, a: any) {
    return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
}, Object.create(null));

// Insere as unidades no seu respectivo estado
Object.keys(units).forEach((key: any) => {
    const index = unitsPredictaByUF.findIndex((index: any) => index.uf === units[key].uf);
    // Desconsidera a unidade ambulatorial do ibirapuera
    if (units[key].id !== 72) {
        unitsPredictaByUF[index].units.push('Unidade ' + units[key].name);
    }
});

// Ordena as unidades
Object.keys(unitsPredictaByUF).forEach((key: any) => {
    unitsPredictaByUF[key].units.sort();
});

export default unitsPredictaByUF;
