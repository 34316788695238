import styled from 'styled-components';
type props = {
  colorBase?: string
  open?: boolean
};
export const StyledSideBar = styled.div<props>`
  padding: 30px;
  padding-top: 10px;
  padding-bottom: ${props => props.open ? '20px' : '0px'};
  background: #fff;
  border-radius: 10px;

  @media(max-width: 720px){
    padding: 10px;
    margin-bottom: 70px;
  }
  @media(max-width: 1024px){
    margin-bottom: 50px;
  }
  @media(max-width: 300px){
    padding: 5px;
    margin-bottom: 75px;
  }
`;

export const Header = styled.div<props>`
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${props => props.open ? '15px' : '0px'};
  border-bottom: ${props => props.open ? '1px solid #7070701f' : 'none'};
  h2{
    font-size: 14px;
  }
  button{
    svg{
      ${props => props.open && 'transform: rotate(180deg)'};
      transition: all .3s;
    }
    
  }
`;

export const Ul = styled.ul<props>`
  display: ${props => props.open ? 'block' : 'none'};
  transition: all .3s;
  li{
    padding: 5px;
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight:600;
    border-radius: 10px;
    min-width: 250px;
    line-height: 1.1rem;
    color: #707070;
  }
  .green{
    color: #15B193;
    font-weight: 700;
  }
  @media(max-width: 1024px){
    li{
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  @media(max-width: 300px){
    li{
      font-size: .8rem;
      width: 90%;
    }
  }
`;

export const CircleIcon = styled.div<props>`
  min-width: 20px;
  width: 20px;
  height: 20px;
  margin: 5px;
  border-radius: 20px;
  background-color: ${props => props.colorBase ? props.colorBase : '#F0F2F4'};
  color: #fff;
  margin-right: 15px;
  text-transform: none;
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
    height: 9px;
  }
`;

export const Dot = styled.div`
  min-width: 7px;
  width: 7px;
  height: 7px;
  border-radius: 7px;
  background-color: #fff;
`;
