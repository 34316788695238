import styled from 'styled-components';
type userInfoImageProps = {
  size?: number | string
  margin?: number | string
  isOpen?: boolean
};
export const UserInfoWrapper = styled.div`

    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    height: 4rem;
    min-width: 17.5rem;

    .user-info--level {
      font-size: .75rem;
      color: #000;
      opacity: 60%;
      font-weight: 600;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .user-info--manage-btn{
      font-size: .75rem;
      display: block;
      color: black;
      padding: .5rem 1.4rem;
      border: 1px solid #C9CDDB;
      border-radius: 30px;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .user-info--logoff{
      font-size: 14px;
      color: #000;
      display: flex;
      justify-content: center;
      width: 100%;
      border-top: 1px solid #C9CDDB;
      padding-top: 1rem;
    }
`;

export const Notifications = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2rem;
`;

export const UserInfoName = styled.span`

    font-size: .875rem;
    font-weight: 600;
    margin: 0 1rem;
`;

export const UserInfoImage = styled.img<userInfoImageProps>`

    display: block;
    border-radius: 50%;
    overflow: hidden;

    margin: ${props => props.margin && `${props.margin};`}
    width: ${props => props.size && `${props.size}rem;`}
    height: ${props => props.size && `${props.size}rem;`}
`;

export const UserInfoCard = styled.div`
    position: absolute;
    width: 100%;
    top: 100%;
    left: 3%;
    right: 0;
    background: #fff;
    border: 1px solid #C9CDDB;
    border-radius: .5rem;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const Toggle = styled.button<userInfoImageProps>`
  border: solid #8D96A0;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: ${props => props.isOpen ? 'rotate(-135deg)' : 'rotate(45deg)'};
  transition: all .3s;
`;
