import styled from 'styled-components';
type props = {
  open?: boolean
  plan?: number
};
export const ModalBkg = styled.div<props>`
    ${props => !props.open ? 'display: none' : 'display:flex'};    
    background: rgba(0,0,0, 0.6);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    bottom:0;
    top: 50px;
    z-index: 1;
    align-items:center;
    justify-content:center;
    overflow-y: hidden;

    @media(max-width: 768px){
      top: 40px;
    }
`;
export const Modal = styled.div`
    position: relative;
    width:65%;
    background: white;
    padding: 20px;
    border-radius: 10px;
    height: 650px;
    overflow-y: auto;
    @media(max-width: 1024px){
      width: 90%;
      height: 80vh;
    }
`;
export const Header = styled.div`
    display:flex;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0,0,0, 0.1);
    button{
      height: 20px;
      margin-left:auto;
      svg{
        color: #707070;
        height: 20px;
      }
    }
    @media(max-width: 360px){
      margin-left: -55px;
    }
`;
export const Body = styled.div<props>`
  display:flex;
  padding-top: ${props => props.plan === 1 ? '40px' : '40px'};
  @media(max-width: 1024px){
    padding-top: 20px;
    flex-direction: column;
  }
`;
export const Price = styled.div`
  margin: 0 auto;
  display:flex;
  flex-direction: column;
  width: 100%;
  max-width: 350px;
  padding-top: 60px;
  h3{
    font-size: 28px;
    margin: 5px 0 20px 0;
    font-weight: 100;
    label{
      b{
        color: #000;
      }
    }
  }
  h4{
    font-size: 24px;
    font-weight: 400;
    color: #707070;
    text-decoration: line-through;
    text-decoration-color: #1E7DDD;
  }
  >h2{
    color: #000;
    font-size: 18px !important;
    margin: 0 auto 15px auto !important;
  }
  small{
    font-size: 15px;
    color: #707070;
    font-weight: 600;
  }
  @media(max-width: 1024px){
      padding-top: 0;
  }
`;
export const List = styled.div<props>`
  margin: 0 auto 20px auto;
  margin-left: 20px;
  max-width: 60%;
  li{
    display:flex;
    align-items: center;
    line-height: 1.5rem;
    font-size: 1.12rem !important;
    font-weight: 500 !important;
    color: #000000 !important;
    margin-bottom: 25px;
    text-align: left;
    p{
      margin-top: -15px;
    }
    svg{
      margin-right: 10px;
      height: 22px;
    }
    .blue-check{
      margin-right: 10px;
      min-width: 23px;
      width: 23px;
      height: 23px;
      border-radius: 23px;
      background: rgb(30, 125, 221, 0.197);
      display: flex;
      align-items: center;
      justify-content: center;
      svg{
        width: 14px;
        height: 12px;
        margin: 0;
        path{
          stroke: rgb(30, 125, 221);
        }
      }
    }
  }
  @media(max-width: 1024px){
    max-width: 90%;
    margin: 0 auto;
    ul{
      margin-top: 20px;
      padding-right: 10px;
      li{
        p{
          line-height: 25px;
        }
      }
    }
  }
`;
export const ButtonWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  display:flex;
  justify-content: center;
  margin-bottom: 20px;
  .btn{
    width: 315px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.12rem;
    font-weight: 600;
    border-radius: 20px;
    background-color: #00B193;
    color: #fff;
  }
`;
export const ProductText = styled.span`
    margin-left: 50px;
    display: flex;
    align-items: center;
    color: #707070;
    font-weight: 600;
    font-size: 30px;
    b{
      font-size: 32px;
      color: #F4F7FA;
      text-align: center;
      line-height: 59px;
      background: red;
      display: block;
      border-radius: .5rem;
      width: 59px;
      height: 59px;
      margin-right: .5rem;
      background-color: #00B193;
    }
`;
