import { AuthProvider } from './auth';
import { LoadingProvider } from './loading';
import { WarningProvider } from './warning';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <LoadingProvider>
      <WarningProvider>{children}</WarningProvider>
    </LoadingProvider>
  </AuthProvider>
);

export default AppProvider;
