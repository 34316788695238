 interface IAuthUser {
  authToken: string
  patientToken: string
 }

 async function getAuthUser({ authToken, patientToken }: IAuthUser) {
  const fetchAsync = () => fetch(`${process.env.REACT_APP_BASE_API_AUTH}/users`, {
    headers: {
      'x-access-token': `Bearer ${authToken}`,
      'x-access-patient-token': `Bearer ${patientToken}`
    }
  }).then((res) => res.json());
  return fetchAsync();
}

export { getAuthUser };
