import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import {
  HeaderSite,
  HeaderContainer,
  GradientLine,
  Separator
} from './styles';
import {
  // LogoEinstein,
  IconEinsteinConecta
} from '../../../assets/icons';

import LogoEinstein from '../../../assets/logo.png';

export default function Header(): ReactElement {
  return (
    <HeaderSite>
      <HeaderContainer>
      <Link to="/">
        <img src={LogoEinstein} width={'200px'} />
      </Link>
      </HeaderContainer>
      <GradientLine />
    </HeaderSite>
  );
}
