import { ReactComponentElement, useMemo, useState } from 'react';
import VoucherContext, { VoucherInterface } from './context';

interface Props {
  children: ReactComponentElement<any> | Array<ReactComponentElement<any>>;
}

export default function VoucherProvider({ children }: Props) {
  const [voucher, setVoucher] = useState<string>();
  const [invalidVoucher, setInvalidVoucher] = useState<boolean>(false);
  const [successVoucher, setSuccessVoucher] = useState<boolean>(false);
  const [applying, setApplying] = useState<boolean>(false);

  const context = useMemo(
    (): VoucherInterface => ({
      voucher,
      invalidVoucher,
      successVoucher,
      applying,
      setVoucher: (value?: string) => {
        setVoucher(value ?? '');
        if (value) {
          setSuccessVoucher(false);
          setInvalidVoucher(false);
          setApplying(false);
        }
      },
      setInvalidVoucher: () => {
        setInvalidVoucher(true);
        setSuccessVoucher(false);
        setApplying(false);
      },
      setSuccessVoucher: () => {
        setInvalidVoucher(false);
        setSuccessVoucher(true);
        setApplying(false);
      },
      setApplying: () => {
        setSuccessVoucher(false);
        setInvalidVoucher(false);
        setApplying(true);
      }
    }),
    [voucher, invalidVoucher, successVoucher, applying]
  );

  return (
    <VoucherContext.Provider value={context}>
      {children}
    </VoucherContext.Provider>
  );
}
