import { ReactElement, useState } from 'react';
import SideBar from '../../components/SideBar';
import { Link } from 'react-router-dom';
import { ArrowBack } from '../../assets/icons';
import { ButtonUploadFile } from '../../components/ButtonUploadFile';
import { uploadPatientDocs, uploadPatientProfileImg } from '../../utils/services';
import {
  UserDataContent,
  SideBarArea,
  UserDataArea,
  Header,
  Separator,
  CardOption
} from './styles';
interface IListDocuments {
  id: number
  title: string
  lblRemove?: string
  lblUpload: string
  fileNameToSave: string
  documentType: string
  showLoad: boolean
}

export default function UserUpload(): ReactElement {
  const handleDocsUpload = (e: any | undefined, id: number): void => {
    const formData = new FormData();
    const file = e.target.files[0];
    const selectDocs = listDocs[id - 1];
    formData.append(id !== 4 ? 'files' : 'file', file, selectDocs.fileNameToSave);
    formData.append(id !== 4 ? 'selectedDocument' : 'documentType', selectDocs.documentType);
    selectDocs.showLoad = true;
    listDocs[id - 1] = selectDocs;
    setListDocs([...listDocs]);
    if (id !== 4) {
      uploadPatientDocs(formData)
        .then(() => { listDocs[id - 1].lblRemove = 'Reenviar'; setListDocs([...listDocs]); })
        .finally(() => { selectDocs.showLoad = false; setListDocs([...listDocs]); });
    } else {
      uploadPatientProfileImg(formData)
        .then(() => { listDocs[id - 1].lblRemove = 'Reenviar'; setListDocs([...listDocs]); })
        .finally(() => { selectDocs.showLoad = false; setListDocs([...listDocs]); });
    }
  };

  const [listDocs, setListDocs] = useState<IListDocuments[]>([
    {
      id: 1,
      title: 'Frente do documento',
      fileNameToSave: 'ID_FRONT.jpg',
      documentType: 'ID_FRONT',
      lblUpload: 'Fazer Upload',
      showLoad: false
    },
    {
      id: 2,
      title: 'Verso do documento',
      fileNameToSave: 'ID_BACK.jpg',
      documentType: 'ID_BACK',
      lblUpload: 'Fazer Upload',
      showLoad: false
    },
    {
      id: 3,
      title: 'Selfie com o documento',
      fileNameToSave: 'SELFIE.jpg',
      documentType: 'SELFIE',
      lblUpload: 'Fazer Upload',
      showLoad: false
    },
    {
      id: 4,
      title: 'Foto de perfil',
      fileNameToSave: 'PROFILE_PIC.jpg',
      documentType: 'PROFILE_PIC',
      lblUpload: 'Fazer Upload',
      showLoad: false
    }
  ]);

  return (
    <UserDataContent>
      <SideBarArea>
        <SideBar />
      </SideBarArea>
      <UserDataArea>
        <Header>
          <div className="flex arrow">
            <Link to="seguranca"><ArrowBack /></Link>
            <h1>Upload de documentos</h1>
          </div>
          <p className="headnote">
            Para começar a utilizar o Einstein Conecta faça upload de um documento recente com foto (identidade, CNH ou passaporte), uma selfie sua segurando o documento e uma foto de perfil.
          </p>
        </Header>
        {
          listDocs.map((m, index) => {
            return (
              <>
                <Separator />
                <CardOption>
                  <div>
                    <h2>{m.title}</h2>
                  </div>
                  <div>
                    {/* <button className="btn-outline">Fazer upload</button> */
                        <ButtonUploadFile id={m.id} showload={listDocs[index].showLoad} labelRemove={m.lblRemove} labelUpload={m.lblUpload} handleFileUploadSelected={(e) => handleDocsUpload(e, m.id)} ></ButtonUploadFile>
                    }
                  </div>
                </CardOption>
              </>
            );
          })
        }
        <Separator />
      </UserDataArea>
    </UserDataContent>
  );
}
