import React, { ReactElement } from 'react';
import {
  CircleWhatsapp
} from './styles';
import { IconWhatsApp } from '../../assets/icons';

type props = {
  phone: string
  mobileBottom?: string
};

export default function ButtonWhatsapp(props: props): ReactElement {
  return (
    <CircleWhatsapp href={`https://wa.me/${props.phone}`} mobileBottom={props.mobileBottom} target="_blank"><IconWhatsApp /></CircleWhatsapp>
  );
}
