import { useRef, useEffect } from 'react';
import { useField } from '@unform/core';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select, { SelectProps } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { useLoading } from '../../hooks/loading';

import { useStyles } from './styles';

interface SelectTypes extends SelectProps {
  label: string
  name: string
  valueList: string[]
}

const SelectInput = ({ valueList, label, name, ...rest }: SelectTypes) => {
  const classes = useStyles();

  const { isLoading } = useLoading();

  const selectRef = useRef<HTMLSelectElement>(null);
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current?.querySelector('input'),
      getValue: ref => ref.value,
      setValue: (ref, value) => {
        ref.value = value;
      }
    });
  }, [fieldName, registerField]);

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        label={label}
        ref={selectRef}
        error={!!error}
        defaultValue=""
        disabled={isLoading}
        {...rest}
      >
        {valueList.map((value, index) => {
          return (
            <MenuItem key={index} value={value.toLowerCase()}>
              {value}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default SelectInput;
