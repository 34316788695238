import React, { ReactElement } from 'react';
import userImage from '../../assets/user-image.png';

import { UserInfoImage } from './styles';
type props = {
  size?: number
  margin?: string
  src?: string
};
export default function UserInfo(props: props): ReactElement {
  return (
      <UserInfoImage src={props.src ?? userImage } size={props.size} margin={props.margin} />
  );
}
