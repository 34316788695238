import { createContext, useContext } from 'react';

export interface VoucherInterface {
  voucher?: string
  applying: boolean
  setApplying: () => void
  setVoucher: (voucher: string) => void
  invalidVoucher: boolean
  setInvalidVoucher: () => void
  successVoucher: boolean
  setSuccessVoucher: () => void
}

const VoucherContext = createContext<VoucherInterface>({
  voucher: undefined,
  applying: false,
  setVoucher: (voucher: string) => {},
  invalidVoucher: false,
  setInvalidVoucher: () => {},
  setApplying: () => {},
  successVoucher: false,
  setSuccessVoucher: () => {}
});

export function useVoucher(): VoucherInterface {
  return useContext(VoucherContext);
}

export default VoucherContext;
