/* eslint-disable */
import styled from 'styled-components';
type props = {
  img?: string
  isDarkMode?: number
  bodyColor?: string
  fontWeight?: string
  secondCollumn?: string
  userName?: string
  borderColor?: string
  buttonText?: string
  fontSize?: number
  imgHeight?: number
  isRightImg?: boolean
};
export const StyledCard = styled.div<props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 15px;
  border: 2px solid #40BDA6;
  font-size: 12px;
  padding: .75rem;
  width: 100%;
  color: black;
  background: #fff;
  height: 100%;
  @media(min-width: 1024px){
    max-height: 200px;
  }
  @media(min-width: 769px) and (max-width: 1024px){
    min-height: 200px;
  }
  img{
    margin-bottom: .6rem;
  }
  @media(max-width: 1024px){
    display: flex;
    align-items:center;
    flex-direction: column;
    width: 98%;
    margin: 0 auto;
    div{
      display: flex;
      justify-content: center;
    }
    img{
      margin-bottom: 3.1vh;
    }
  }
  @media(max-width: 340px){
    img{
      margin-bottom: 1vh;
    }
  }
  @media(min-width: 601px) and (max-width: 768px){
    display: flex;
    align-items: center;
    justify-content: center;
    height: 170px;
  }
  @media(max-width: 414px){
    height: 220px;
  }
`;

export const ButtonWrapper = styled.div`
    text-align: center;
    bottom: 0;
    margin-top: 1rem;
    .link{
      border-radius: 25px;
      margin-right: auto;
      padding: 8px 20px 8px 20px;
      width: auto;
      cursor: pointer;
      border: none;
      color: white;
      font-size: 12px;
      background: #40BDA6;
      flex-wrap: nowrap;
      justify-content: center;
    }
    @media (max-width: 1024px){
      .link{
        padding: 5px 15px 5px 15px;
      }
    }
`;
