import styled from 'styled-components';
type props = {
  pointerWidth?: number
  progressWidth?: number
};
export const Container = styled.div`
   width: 100%;
   padding-top:40px;
`;

export const Bar = styled.div`
   background: rgba(112,112,122, 0.16);
   width: 100%;
   height: auto;
   border-radius:12px;
`;
export const Progress = styled.div<props>`
   background: #40BDA6;
   width: ${props => props.progressWidth}%;
   height: 13px;
   border-radius:12px;
   max-width: 100%;
   transition: all 3s;
`;
export const Pointer = styled.div<props>`
   background: #40BDA6;
   color: white;
   height: 25px;
   width: 25px;
   border-radius: 50%;
   padding: 3px;
   text-align: center;
   font-size: 14px;
   font-weight:bold;
   margin-bottom: 5px;
   margin-left: ${props => props.pointerWidth ? props.pointerWidth > 100 ? '98' : props.pointerWidth : props.pointerWidth}%;
   transition: all 3s;

   &:after{
    content: "";
    margin: auto;
    display: block;
    left: 0;
    right: 0;
    width: 6px;
    height: 6px;
    transform: rotate(45deg);
    background-color: #40BDA6;
    z-index: -1;
   }
`;
