import { VideoResponsive } from './styles';

type props = {
  videoUrl: string
  videoTitle: string
};

const YoutubeEmbed = (props: props) => (
  <VideoResponsive>
    <iframe
      src={props.videoUrl}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={props.videoTitle}
    />
  </VideoResponsive>
);

export default YoutubeEmbed;
