import { ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IconUserData, IconDependents, IconCreditCard, IconSecurity } from '../../assets/icons';
import { Ul } from './styles';

export default function SideBar(): ReactElement {
  const location = useLocation();
  return (
    <Ul>
      <li className={location.pathname === '/meus-dados' ? 'actual' : ''}>
        <Link to='/meus-dados'>
          <IconUserData /> Meus dados
        </Link>
      </li>
      {/* <li className={location.pathname === '/dependentes' ? 'actual' : ''}>
        <Link to='/dependentes'>
          <IconDependents /> Dependentes
        </Link>
      </li>
      <li
      className={location.pathname === '/meus-planos' ? 'actual' : ''}
      >
        <Link to='/meus-planos'>
          <IconCreditCard /> Meus planos
        </Link>
      </li> */}
      <li
        className={
          location.pathname === '/seguranca' || location.pathname === '/prontuario' || location.pathname === '/autenticacao' || location.pathname === '/upload-documentos' ? 'actual' : ''
        }
        >
        <Link to='/seguranca'>
          <IconSecurity/> Segurança e privacidade
        </Link>
      </li>
    </Ul>
  );
}
