import styled from 'styled-components';

export const VideoResponsive = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10% 0;
    
    iframe {
        height: 40rem;
        width: 60%;

        @media screen and (max-width: 1600px) {
            max-height: 30rem;
        }
        @media screen and (max-width: 1300px) {
            width: 70%;
        }
        @media (max-width: 1000px) {
            width: 80%;
            max-height: 30rem;
        }
        @media (max-width: 700px) {
            width: 90%;
            max-height: 20rem;
        }
    }
`;
