import { ReactElement } from 'react';
import SideBar from '../../components/SideBar';
import { Link } from 'react-router-dom';
import { ArrowBack } from '../../assets/icons';
import {
  UserDataContent,
  SideBarArea,
  UserDataArea,
  Header,
  Separator,
  CardOption
} from './styles';
export default function UserMedicalRecord(): ReactElement {
  const active = false;
  return (
    <UserDataContent>
      <SideBarArea>
        <SideBar />
      </SideBarArea>
      <UserDataArea>
        <Header>
          <div className="flex arrow">
            <Link to="seguranca"><ArrowBack /></Link>
            <h1>Exibir meu prontuario completo</h1>
            <span className={active ? 'success' : 'danger'}>
              { active ? 'Ativo' : 'Inativo' }
            </span>
          </div>
          { active && (
            <p className="headnote">
              Para exibir todos os exames e atendimentos que você fez no Einstein é necessário confirmar o e-mail ou telefone existentes no seu prontuário. Por onde você quer receber seu código de segurança?
            </p>
          )}
        </Header>
        <Separator />
        { active
          ? (
            <>
              <p className="footnote">
                Todas suas passagens no Einstein estão sendo exibidas em seu histórico.
              </p>
              <button className="btn-outline red">Desativar</button>
            </>
            )
          : (
            <>
            <CardOption>
              <div>
                <h2>E-mail</h2>
                <p>Enviaremos um e-mail para paciente•••••••@eins•••br</p>
              </div>
              <div>
                <button className="btn-outline">Enviar e-mail</button>
              </div>
            </CardOption>
            <Separator />
            <CardOption>
              <div>
                <h2>Celular</h2>
                <p>Enviaremos um SMS para (11) ••••• - 4354</p>
              </div>
              <div>
                <button className="btn-outline">Enviar SMS</button>
              </div>
            </CardOption>
            <Separator />
            <p className="footnote">
              <Link to="/meus-dados" className="link">Clique aqui</Link> se não tiver mais acesso ao e-mail ou dispositivo.
            </p>
            <Separator />
            </>
            )
        }
      </UserDataArea>
    </UserDataContent>
  );
}
