import styled from 'styled-components';

export const BannerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    justify-content: unset;
    align-items: center;
  }

  .BackgroundDNA{
    position: absolute;
    left: 0;
    width: 100%;
    padding: 0;
    padding-top: 30px;

    bottom: 90px;
    z-index: 10;

    @media (min-width: 1920px) { /* 67% zoom */
      margin-top: 25px;
      height: 200px;
    }

    @media (min-width: 1601px) and (max-width: 1919px) { /* 80% zoom */
      margin-top: 25px;
      height: 200px;
    }

    @media (min-width: 1301px) and (max-width: 1600px) { /* 80% zoom */
      padding-top: 10px;
    }

    @media (min-width: 1025px) and (max-width: 1300px) {
      padding-top: 30px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      padding-top: 60px;
    }

    @media (max-width: 767px) {
      padding-top: 30px;
      top: 490px;
    }
  }
`;

export const PrincipalContainer = styled.div`
  width: 100%;
  margin-left: auto;
  height: 515px;
  background-color: #004792;
  outline: 85px solid #3ad6ca;
  
  div {
    margin: 100px 50px 0px 100px;
    text-align: left;

    @media (max-width: 1228px) {
      margin-top: 100px;
      margin-left: 50px;
    }

    @media (max-width: 768px) {
      margin-top: 150px;
      text-align: center;
    }
  }
  a{
    display: block;
    text-align: center;
    border-radius: 50px;
    font-size: 1.5rem;
    font-weight: 600;
    background-color: #37ceff;
    color: #000729;
    bolder-weight: 2px;
    margin-top: 30px;

    z-index: 20;
    position: relative;

    width: 35%;
    padding: 25px;

    @media (max-width: 1500px) and (min-width: 1229px) { /* 80% zoom */
      width: 70%;
      padding: 10px;
    }

    @media (max-width: 1228px) {
      font-size: 1rem;
      width: 100%;
    }

    @media (max-width: 1092px) {
      font-size: 1.0rem;
      padding: 15px;
    }

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 768px) {
    height: 600px;
    width: 100%;
  }
`;
export const Header = styled.div`
  margin: 0 !important;
  font-family: Montserrat, sans-serif !important;
  color: #fff !important;

  hr {
    border: 0;
    border-top: 0.8px solid #3ad6c9;
    margin-top: 15px;
    margin-bottom: 30px;
  }

  h1{
    font-size: 4rem;
    line-height: 1;
    .second{
      font-weight: 500;
    }

    @media (max-width: 1228px) {
      font-size: 2rem;
      margin-bottom: 30px;
    }
    @media (max-width: 768px) {
      font-size: 3.5rem;
    }
  }
  h2{
    font-size: 2.5rem;
    color: #fff !important;

    @media (max-width: 1900px) and (min-width: 1500px){
      font-size: 1.2rem !important;
      margin-bottom: 30px !important;
    }
    @media (max-width: 1228px) {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }
    @media (max-width: 768px) {
      font-size: 2.3rem;
    }
    
  }
  h3{
    font-size: 1.5rem;
    font-weight: unset;

    @media (max-width: 854px) and (min-width: 852px){
      font-size: 0.8rem !important;
      margin-bottom: 30px !important;
    }
    @media (max-width: 1228px) {
      font-size: 1.1rem;
      margin-bottom: 50px;
    }
    @media (max-width: 768px) {
      font-size: 1.4rem;
      margin-bottom: 0px;
    }
  }
`;
export const BannerImage = styled.div`
  width: 50%;

  @media (min-width: 1920px) { /* 67% zoom */
    width: 40%;
  }

  @media (max-width: 1025px) { 
    width: unset;
  }
  
  img {
    height: 600px;
    width: auto;
    display: block;
    object-position: top;
    object-fit: contain;
  }

  @media (max-width: 768px) {
    /* display: none; */
    width: 100%;

    img {
      height: auto;
      width: 100%;
    }
  }
`;
