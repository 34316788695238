import styled from 'styled-components';
type props = {
  size?: number | string
  margin?: number | string
};
export const UserInfoImage = styled.img<props>`

    display: block;
    border-radius: 50%;
    overflow: hidden;

    margin: ${props => props.margin && `${props.margin};`}
    width: ${props => props.size && `${props.size}px;`}
    height: ${props => props.size && `${props.size}px;`}
`;
