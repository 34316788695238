import styled from 'styled-components';

export const UserDataContent = styled.div`
    padding: 4rem;
    padding-left: 2rem;
    padding-right: 8rem;

    @media(min-width: 1025px){
      display: grid;
      grid-template: 
      "sidebar userData"
      "sidebar userData";
      column-gap: 2rem;
      grid-template-columns: 250px auto;
    }

    @media(max-width: 1024px){
      display: flex;
      flex-direction: column;
      padding: 1rem;
      padding-bottom: 6rem;
    }
`;
export const SideBarArea = styled.div`
  grid-area: sidebar;
`;
export const UserDataArea = styled.div`
  grid-area: userData;
  min-height: 260px;
  padding: 2.5rem;
  background: #fff;
  border-radius: 10px;
  h1{
    font-size: 1.25rem;
  }
  .link{
    color: #009DFF;
    font-size: .85rem;
    font-weight: 600;
  }
  @media(max-width: 778px){
    padding: 1.5rem;
  }
`;

export const Separator = styled.div`
  border-bottom: 1px solid #DCE1E5;
  height: 2.5rem;
  width: 100%;
  @media(max-width: 778px){
    height: 1.5rem;
  }
`;

export const ProfilePic = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CardOption = styled.div`
  padding-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2{
    font-size: .85rem;
    line-height: 1.5rem;
  }
  p{
    font-size: .75rem;
    color: #707070;
    font-weight: 600;
  }
  div{
    padding-left: 10px;
    padding-right: 10px;
  }
  .danger{
    color: #F53748;
  }
  .success{
    color: #5cb85c;
  }
`;
export const Rules = styled.div`
  display: flex;
  font-size: .75rem;
  color: #666666;
  margin-top: 10px;
  div{
    margin-right: 20px;
  }
  svg{
    margin-right: 5px;
  }
`;
