import styled from 'styled-components';

export const UserDataContent = styled.div`
    padding: 4rem;
    padding-left: 2rem;
    padding-right: 8rem;
    .link{
      color: #009DFF;
      font-size: .875rem;
      font-weight: 600;
    }
    .underline{
      text-decoration: underline;
    }
    .baseline{
      align-items: flex-end;
    }
    @media(min-width: 1025px){
      display: grid;
      grid-template: 
      "sidebar userData"
      "sidebar userData";
      column-gap: 2rem;
      grid-template-columns: 250px auto;
    }
    @media(max-width: 1024px){
      display: flex;
      flex-direction: column;
      padding: 1rem;
      padding-bottom: 6rem;
    }
`;
export const SideBarArea = styled.div`
  grid-area: sidebar;
`;
export const UserDataArea = styled.div`
  min-height: 560px;
  grid-area: userData;
  padding: 2.5rem;
  background: #fff;
  border-radius: 10px;
  h1{
    font-size: 1.25rem;
  }
  .load{
    display: flex;
    align-items: center;
    height: 100%;
  }
  @media(max-width: 778px){
    padding: 1.5rem;
  }
`;

export const Separator = styled.div`
  border-bottom: 1px solid #DCE1E5;
  height: 2.5rem;
  width: 100%;
  @media(max-width: 778px){
    height: 1.5rem;
  }
`;

export const Profile = styled.div`
  padding: 1.87rem;
  @media(max-width: 778px){
    padding: 0;
    padding-top: 1.87rem;
  }
`;

export const ProfilePic = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ProfileForm = styled.div`
  .error{
    text-align: left;
    color: #F53748;
    margin-bottom: .6rem;
    font-size: 1rem;
  }  

  .error-list{
    text-align: left;
    color: #F53748;
    font-size: 0.8rem;
    margin-bottom: .5rem;
  }  

  input:focus, select:focus{
    outline: none;  
    box-shadow: none;
  }
  input, select{
    width: 100%;
    height: 40px;
    padding: 10px;
    border-radius: 4px;
    margin-top: 15px;
    border: solid 1px #999999ba;
    ::placeholder {
      color: transparent;
    }
  }
  input:read-only{
  background-color: #EAEAEA;
  cursor: unset;
  }
  
  .form-group{
    transition: all .3s;
    width: 100%;
    max-width: 400px;
    height: 60px;
  }
  .form-label{
    color: #999999ba;
    font-size: 14px;
    padding-right: 4px;
    padding-left: 4px;
    width: fit-content;
    display:block;
    margin-left: 10px;
    transform: translateY(-30px);
    transition: all .3s;
    background: #fff;
    position: relative;
    z-index: 1;
    transform-origin: 0 0;
  }
  .form-control{
    transition: all .5s;
    position: relative;
    z-index: 2;
    background: transparent;
  }
  .form-control:focus{
    border: solid 1px #004F92 !important;
    color: #004F92;
  }
  .form-control:focus + .form-label,
  .form-control:not(:placeholder-shown) + .form-label
  {
    transform: translateY(-50px) scale(.9);
    color: #004F92;
    font-weight: 500;
    z-index: 3;
  }
  .form-control:focus,
  .form-control:not(:placeholder-shown)
  {
    border: solid 1px #004F92 !important;
    color: #004F92;
  }
  .btn{
    margin-top: 1.25rem;
    margin-left: auto;
    border-radius: 30px;
    width: 100%;
    max-width: 300px;
    height: 2.8rem;
    padding: .6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 600;
    background-color: #009DFF;
    color: #fff;
  }
  @media(min-width: 440px){
    .flex{
      display: flex;
      justify-content: center;
      div:first-child{
        margin-right: .6rem;
      }
    }
  }
`;

export const Ul = styled.ul`
  list-style: none;
  display:flex;
`;
