import styled from 'styled-components';

export const CalendarWrapper = styled.div`    
    background-color: #fff;
    border-radius: .5rem;
    margin: 0 auto 1rem;
    padding: 2rem;
    max-width: 100%;

    .react-calendar{
        margin: 1rem auto;
        font-size: .75rem;
        text-align: center;
    }

    .react-calendar abbr {
        text-decoration: none;
    }

    .react-calendar__navigation{
        padding: .5rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 0 auto;
    }

    .react-calendar__navigation__label__labelText{
        color: #2196F3;
        font-size: 1.125rem;
        font-weight: 600;
    }

    .react-calendar__navigation__arrow {
        font-size: 2.5rem;
        color: #2196F3;
        line-height: 2.5rem;
        width: 40px;
        margin: 0 -5px 0 -5px;
    }

    .react-calendar__navigation__label__labelText{
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 2.5rem;
    }

    .react-calendar__tile{
        padding: .5rem;
    }

    .react-calendar__tile:not(:disabled){        
        cursor: pointer;
    }

    .react-calendar__tile > *{        
        opacity:.6;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        display: block;
        line-height: 1.5rem;
        vertical-align: middle;
        text-align: center;
        margin: auto;
    }

    .react-calendar__tile.after > *{
        background-color: red;
        color: #fff;
    }

    .react-calendar__tile.selected > *{
        background-color: #40BDA6;
        color: #fff;
        border-radius: 50%;
    }

    .react-calendar__month-view__weekdays__weekday{
        font-weight: 600;
        padding: .5rem;
    }
`;
