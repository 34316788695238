import styled from 'styled-components';

export const Ul = styled.ul`
  li{
    padding: 15px;
    display: flex;
    align-items: center;
    font-size: .93rem;
    font-weight:600;
    border-radius: 10px;
    svg{
      margin-right: 10px;
      max-height: 18px;
    }
  }
  .actual{
    background: rgba(0, 157, 255, 0.08);
    color: #009DFF;
    #icon-user-data, #icon-credit, #icon-security{
      path{
        fill: #009DFF;
      }
    }
    #icon-dependents, #icon_upload{
      path{
        stroke: #009DFF;
      }
    }
  }
  @media(max-width: 1024px){
    display: flex;
    margin-bottom: 2rem;
    justify-content: center;
  }
  @media(max-width: 778px){
    flex-wrap: wrap;
    justify-content: start;
    li{
      width: 50%
    }
  }
  @media(max-width: 500px){
    li{
      width: 100%
    }
  }
`;
