import styled from 'styled-components';

type props = {
  fullDiscountVoucher?: boolean;
};

export const ContainerCardFields = styled.div<props>`
  display: ${(props) => (props.fullDiscountVoucher ? 'none' : 'block')};
`;

export const Container = styled.div<props>`
  margin-top: ${(props) => (props.fullDiscountVoucher ? '0px' : '1.25rem')};
  display: flex;
  justify-content: center;

  form {
    width: 100%;
  }

  & .fieldset-container {
    border: 0;
  }

  h1 {
    margin: 2rem 0 1rem 0;
  }

  .card-grid-container {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    gap: 1.5rem;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .terms a {
    color: #4aabfe !important;
  }

  @media (min-width: 440px) {
    .card-grid-container {
      margin-top: 1.5rem;
      flex-direction: row;
      gap: 1.5rem;
    }
  }
`;

export const Button = styled.button`
  height: 50px;
  width: 100%;
  color: #fff;
  background-color: #004f92;
  margin-top: 25px;
  border-radius: 25px;
  border: 0;
  background-color: ${(props) => (props.disabled ? '#ccc' : '#004f92')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  span {
    font-weight: 500;
  }
`;
