/* eslint-disable */

import axios from 'axios';
import { AuthResponse } from '../../../../interfaces';
import { moveToLogin } from '../../../../utils/moveToLogin';

const baseUrl = process.env.REACT_APP_BASE_API;
const baseUrlAuth = process.env.REACT_APP_BASE_API_AUTH;
const baseUrlMCAuth = process.env.REACT_APP_MINHACONTA_LOGIN;

export const searchDocument = async (document: string): Promise<any> => {
  const ret = await axios
    .post(`${baseUrlAuth}/users/find-username`, {
      username: document
    })
    .then((response) => {
      return response.status;
    })
    .catch((error) => {
      return error;
    });
  return ret;
};

  const hasExipered = (token: string) => {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      if (payload.exp * 1000 > Date.now()) {
        return false
      }
      return true
    } catch (_) {
      return true;
    }
  }

export function login(): AuthResponse {

  const token = localStorage.getItem('api_token') || ''
  const patientToken = localStorage.getItem('api_patientToken') || ''

  if (!(token && patientToken) || hasExipered(token) ) {
    moveToLogin(false, 'contratar')
  }

  return {
    token,
    patientToken,
  }

}

export const checkEligibility = async (cpf: string): Promise<any> => {
  const predToken = String(localStorage.getItem('api_token'));
  const patientToken = String(localStorage.getItem('api_patientToken'));
  let document = '';
  if (cpf.length <= 14) {
    document = cpf.replace(/[^\d]+/g, '');
  }
  const ret = await axios
    .get(`${baseUrl}/user/eligibility?document=${document}`, {
      headers: {
        'x-access-token': `Bearer ${predToken}`,
        'x-access-patient-token': `Bearer ${patientToken}`
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.message;
    });
  return ret;
};

export const register = async (data: any): Promise<any> => {
  const ret: any = await axios
    .post(
      `${baseUrlAuth}/users`,
      {
        firstName: data.name,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        document: data.cpf,
        documentType: data.documentType,
        birthDate: data.date,
        sex: data.sex,
        password: data.password
      },
      { headers: { origin: 'predicta' } }
    )
    .then((response) => {
      // console.log(response);
      localStorage.setItem('api_tokenBuy', response.data.tokenBuy);
      return response;
    })
    .catch((error) => {
      const listErros: string[] = [];
      if (error.response.data.errors) {
        error.response.data.errors.map((erro: any, index: any): any => {
          return (listErros[index] = erro.message);
        });
      }
      const ObErro = {
        erro: error.response.data.message,
        erros: listErros
      };
      return ObErro;
    });
  return ret;
};

export const create = async (data: any): Promise<any> => {
  const token = `Bearer ${String(localStorage.getItem('api_token'))}`;
  const tokenPatient = `Bearer ${String(
    localStorage.getItem('api_patientToken')
  )}`;

  const ret: any = await axios
    .post(
      `${baseUrl}/user/create`,
      {
        name: data.name,
        lastName: data.lastName,
        birthDate: data.date,
        sex: data.sex,
        nationality: data.nationality,
        phone: data.phone,
        document: data.cpf,
        planId: data.product
      },
      {
        headers: {
          'x-access-token': `Bearer ${token}`,
          'x-access-patient-token': `Bearer ${tokenPatient}`
        }
      }
    )
    .then((response) => response.data.response)
    .catch((error) => ({ error: error.response.data.response }));
  return ret;
};
