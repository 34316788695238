import {
  forwardPaymentApiError,
  factoryAxiosPaymentApiError,
  factoryUnknownPaymentApiError,
  factoryPaymentApiErrorWhenBodyIsErrorResponse
} from '../../errors/paymentService';
import { IThisArgAxiosService } from '../../interfaces/IThisArgAxiosService';

type ApplyiedVoucher = {
  amount: number;
  percentDiscount: number;
  amountDiscountApplied: number;
  amountWithDiscountApplied: number;
};

const PAYMENT_API_KEY = process.env.REACT_APP_PAYMENT_API_KEY;

export async function applyVoucher(
  this: IThisArgAxiosService,
  voucher: string,
  productId: string | number,
  userDocument: string
): Promise<ApplyiedVoucher> {
  const data = {
    voucher,
    productId,
    userDocument
  };

  const res = await this.axios.post('/v2.0/voucher', data, {
    headers: {
      'x-api-key': PAYMENT_API_KEY
    }
  });

  if (res.status === 200) {
    return res.data.data;
  } else {
    const paymentApiError = res;

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw paymentApiError;
  }
}
