import styled, { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    :root{
        font-size: 16px;
        --primary-color: #40BDA6;
        --secondary-color: #2196F3;

        --border-primary: #ABAEB7;
        --border-card: #C9CDDB;
        
        --background-site: #F0F2F4;
        --background-primary: #EFF8FF;
        --background-secondary: rgba(0, 157, 255, 0.25);

        --text-primary: #15B193;
        --text-secondary: #004F92;
        --label-secondary: blue;
        --label-primary: rgba(21,177,147, 0.16);

        @media(max-width: 768px){
          font-size: 12px;
        }
      }

    *{
        margin:0;
        padding: 0;
        box-sizing: border-box;
    }

    body, 
    html{
      font-family: 'Montserrat', sans-serif;
      scroll-behavior: smooth;
    }

    body {
      background-color: #F0F2F4;
    }

    button{
      outline: 0;
      border: 0;       
      background-color: transparent;
      font-family: inherit;
      cursor: pointer;
    }
    textarea:focus{
      outline: none;  
      box-shadow: none;
    }

    :where(
        a, 
        a:visited, 
        a:focus, 
        a:hover
    ){
        text-decoration: none;
        color: inherit;
    }

    ul{
        list-style: none;
    }

  input{
    outline:none;
    box-shadow: none;
    border: solid 1px #999999ba;
  }

  input:focus{
    outline: none;  
    box-shadow: none;
  }

  .modal-open{
    /* overflow-y: hidden; */
  }
  .modal-close{
    /* overflow-y: auto; */
  }
  .display-none{
    display: none;
  }
`;
export const Container = styled.div`
    width: 95%;
    margin: 0 auto;
`;

export const Content = styled.div`
    min-height: 100vh;
    width: 100%;
    padding-top: 4rem;

    @media(min-width:992px){
      padding-left: 4.5rem;
    }
`;
