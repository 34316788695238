/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ReactElement, useEffect, useState } from 'react';
import {
  ContentHome,
  Nurse,
  Result,
  Single,
  History,
  Services,
  ServicesTitle,
  ServicesWrapper,
  Sidebar,
  ContentLayout,
  NextArrowDashboard,
  PrevArrowDashboard,
  YellowAlert
} from './styles';
import Slider from '../../ui/Slider';
import Card from '../../ui/Card';
import MiniCard from '../../ui/MiniCard';
import Historic from '../../components/Historic';
import HelpCenter from '../../ui/Help';
import ServiceCard from '../../ui/ServiceCard';
import Schedule from '../../components/Schedule';

import { IconShoppingBag, IconConecta } from '../../assets/icons';
import NurseImg from '../../assets/nurse.svg';
import ResultsImg from '../../assets/results.svg';
import QuizImage from '../../assets/quiz.png';
import QuizImg from '../../assets/quizImg.png';
import SingleAppointmentImg from '../../assets/singleAppointment.png';
import HelpImage from '../../assets/help.svg';
import ConectaImage from '../../assets/headerConecta.svg';
import RehabImage from '../../assets/headerRehab.svg';
import PsicoImage from '../../assets/headerPsico.svg';
import yellowAlert from '../../assets/Site/Alerta_icon.svg';
import { useDispatch, useSelector } from 'react-redux';

import {
  NEW_SINGLE_APPOINTMENT,
  OPEN_MODAL,
  ScheduleRootState,
  SET_LOAD,
  UserInfo,
  USER_PRODUCT,
  IS_USER_DATA_STALE
} from '../../store/redux';
import { GetListDependents } from '../../utils/services';
import ContactNurse from '../../components/ContactNurse';

export default function Dashboard(): ReactElement {
  const dispatch = useDispatch();
  const user: UserInfo = useSelector<ScheduleRootState, UserInfo>(
    (scheduleReducer) => scheduleReducer.user
  );
  const isUserDataStale = useSelector<ScheduleRootState, boolean>(
    (scheduleReducer) => scheduleReducer.isUserDataStale
  );
  const [contactNurse, setContactNurse] = useState(false);

  const [width, setWindowWidth] = useState(0);
  const showDesktopComponent: boolean = width > 1024;

  // Responsive
  const updateDimensions = () => {
    const width = window.outerWidth;
    setWindowWidth(width);
  };

  useEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 0,
    nextArrow: <NextArrowDashboard className="next-arrow" />,
    prevArrow: <PrevArrowDashboard className="prev-arrow" />,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const localStorageToRedux = () => {
    // dispatch({ type: SET_LOAD, load: true });
    const localUser = JSON.parse(localStorage.getItem('user') ?? '[]');
    if (localUser.length > 0) {
      // dispatch({ type: SET_LOAD, load: false });
      dispatch({
        type: USER_PRODUCT,
        name: localUser.firstName,
        param: localUser.planDescription,
        lastName: localUser.lastName,
        prontuario: localUser.medicalRecord,
        state: {
          user: {
            eligibility: localUser?.eligibility,
            phone: localUser.phone,
            email: localUser.email,
            birthDate: localUser.birthDate,
            sex: localUser.sex,
            document: localUser.document,
            nationality: localUser.nationality,
            // imgThumb: localUser.profile.image ?? '',
            questionnaire: localUser.sendQuestionnaire,
            singleSchedule: localUser.singleSchedule,
            plan: localUser.planId,
            step: localUser.step,
            stepStatus: localUser.stepStatus,
            stepDescription: localUser.stepStatusDescription
          }
        }
      });
    }
  };

  useEffect(() => {
    // TODO: CONFIRMA EQUIPE FRONT ONDE INSERIR CARREGAMENTO DE DEPENDENTS
    dispatch(GetListDependents());

    dispatch({
      type: IS_USER_DATA_STALE,
      state: {
        isUserDataStale: true
      }
    });
  }, []);

  useEffect(() => {
    localStorageToRedux();

    dispatch({
      type: IS_USER_DATA_STALE,
      state: {
        isUserDataStale: false
      }
    });
  }, [isUserDataStale]);

  return (
    // !user?.eligibility && user?.prontuario === null
    //   ? <>
    //     {/* página sem eligibilidade INÍCIO */}
    //     <YellowAlert>
    //       <img src={yellowAlert} />
    //       <span><b>Você não possui eligibilidade ao produto. Tente novamente mais tarde.</b></span>
    //     </YellowAlert>
    //     <ContentHome>
    //       <ContentLayout medicalRecord={user.prontuario === null ? 'noMedicalRecord' : 'hasMedicalRecord'}>
    //         <Nurse>
    //           <Card mode="dark" img={NurseImg}
    //             cardBody="Bem-vindo, "
    //             userName={`${user.firstName} ${user.lastName}`}
    //             cardBodyText="Estamos finalizando o seu cadastro. Tente novamente mais tarde."
    //           />
    //         </Nurse>

    //         {/* <Services>
    //           <ServicesTitle>
    //             <IconShoppingBag/>
    //               <h3>
    //                 Serviços Einstein
    //               </h3>
    //             <div></div>
    //           </ServicesTitle>
    //           <ServicesWrapper>
    //             <Slider settings={settings}>
    //               <ServiceCard
    //                 colorBase="#1170B7;"
    //                 colorStart="#41B7A0"
    //                 colorEnd="#4980C1"
    //                 icon={<IconConecta/>}
    //                 imgHeader={ConectaImage}
    //                 title="Conecta"
    //                 description="O Einstein Conecta é um serviço de orientações médicas no qual você pode falar
    //                 a qualquer hora do dia e de qualquer lugar do mundo com um médico do Einstein."
    //                 />
    //               <ServiceCard
    //                 colorBase="#E39573;"
    //                 colorStart="#E17E53"
    //                 colorEnd="#BF3C02"
    //                 icon={<IconConecta/>}
    //                 imgHeader={RehabImage}
    //                 title="Telereabilitação"
    //                 description="O Telereabilitação é um serviço de orientações médicas no qual você pode falar
    //                 a qualquer hora do dia e de qualquer lugar do mundo com um médico do Einstein."
    //               />
    //               <ServiceCard
    //                 colorBase="#B19CCC;"
    //                 colorStart="#D9CDE8"
    //                 colorEnd="#694E88"
    //                 icon={<IconConecta/>}
    //                 imgHeader={PsicoImage}
    //                 title="Telepsicologia"
    //                 description="O Telepsicologia é um serviço de orientações médicas no qual você pode falar
    //                 a qualquer hora do dia e de qualquer lugar do mundo com um médico do Einstein."
    //               />
    //             </Slider>
    //           </ServicesWrapper>
    //         </Services> */}
    //       </ContentLayout>
    //       <Sidebar>
    //         {showDesktopComponent ? <><Schedule /></> : ''}

    //         <HelpCenter
    //           title="Central de Ajuda"
    //           callCenter="551136202550"
    //           email="einsteinconecta@einstein.br"
    //           img={HelpImage}
    //         />
    //       </Sidebar>
    //     </ContentHome>
    //     {/* página sem eligibilidade FIM */}
    //   </>
    //   :
    <>
      <ContentHome>
        {showDesktopComponent ? (
          <>
            <ContactNurse open={contactNurse} setOpen={setContactNurse} />
            <ContentLayout plan={user.plan === 1 ? 'one' : 'infinity'}>
              <Nurse>
                <Card
                  mode="dark"
                  img={NurseImg}
                  cardBody="Bem-vindo, "
                  userName={`${user.firstName} ${user.lastName}`}
                  buttonText="Falar com o Enfermeiro"
                  onClick={setContactNurse}
                />
              </Nurse>
              <Result>
                {user.plan === 1
                  ? (
                    !user.questionnaire
                      ? (
                        <MiniCard
                          img={QuizImg}
                          buttonText="Responder"
                          isLink="/questionario"
                        >
                          <p>Responda ao questionário para completar seu perfil</p>
                        </MiniCard>
                      )
                      : (
                        <MiniCard
                          img={QuizImg}
                          buttonText="Ver Resultados"
                          isLink="/historic"
                        >
                          <p>
                            Veja o resultado <br /> dos seus exames
                          </p>
                        </MiniCard>
                      )
                  )
                  : !user.questionnaire
                    ? (
                      <MiniCard
                        img={QuizImg}
                        buttonText="Responder"
                        isLink="/questionario"
                      >
                        <p>Responda ao questionário para completar seu perfil</p>
                      </MiniCard>
                    )
                    : (
                      <Card
                        mode="light"
                        img={ResultsImg}
                        cardBody={
                          <span>
                            Por aqui você pode acompanhar o <br /> resultado de seus
                            exames.
                          </span>
                        }
                        cardBodyText=""
                        buttonText="Ver Histórico"
                        isRightImg
                        isLink="/historic"
                      />
                    )}
              </Result>
              {/* É exibida a opção de consulta avulsa caso o plano do usuário seja do tipo One */}
              {/* <Single>
                {user.plan === 1 &&
                  <MiniCard
                    img={SingleAppointmentImg}
                    buttonText="Consulta Avulsa"
                    onClick={() => user.singleSchedule ? dispatch({ type: OPEN_MODAL }) : dispatch({ type: NEW_SINGLE_APPOINTMENT })}
                  ><p>Marque uma consulta <br /> com um geneticista</p>
                  </MiniCard>
                }
              </Single> */}
              <History>
                <Historic reverse />
              </History>
              {/* <Services>
                  <ServicesTitle>
                    <IconShoppingBag />
                    <h3>
                      Serviços Einstein
                    </h3>
                    <div></div>
                  </ServicesTitle>
                  <ServicesWrapper>
                    <Slider settings={settings}>
                      <ServiceCard
                        colorBase="#1170B7;"
                        colorStart="#41B7A0"
                        colorEnd="#4980C1"
                        icon={<IconConecta />}
                        imgHeader={ConectaImage}
                        title="Conecta"
                        description="O Einstein Conecta é um serviço de orientações médicas no qual você pode falar
                a qualquer hora do dia e de qualquer lugar do mundo com um médico do Einstein."
                      />
                      <ServiceCard
                        colorBase="#E39573;"
                        colorStart="#E17E53"
                        colorEnd="#BF3C02"
                        icon={<IconConecta />}
                        imgHeader={RehabImage}
                        title="Telereabilitação"
                        description="O Telereabilitação é um serviço de orientações médicas no qual você pode falar
                a qualquer hora do dia e de qualquer lugar do mundo com um médico do Einstein."
                      />
                      <ServiceCard
                        colorBase="#B19CCC;"
                        colorStart="#D9CDE8"
                        colorEnd="#694E88"
                        icon={<IconConecta />}
                        imgHeader={PsicoImage}
                        title="Telepsicologia"
                        description="O Telepsicologia é um serviço de orientações médicas no qual você pode falar
                a qualquer hora do dia e de qualquer lugar do mundo com um médico do Einstein."
                      />
                    </Slider>
                  </ServicesWrapper>
                </Services> */}
            </ContentLayout>
            <Sidebar>
              <Schedule />
              <HelpCenter
                title="Central de Ajuda"
                callCenter="551136202550"
                email="einsteinconecta@einstein.br"
                img={HelpImage}
              />
            </Sidebar>
          </>
        ) : (
          <>
            <ContactNurse open={contactNurse} setOpen={setContactNurse} />
            <ContentLayout plan={user.plan === 1 ? 'one' : 'infinity'}>
              <ServicesWrapper>
                <Slider settings={settings}>
                  <Nurse>
                    <Card
                      mode="dark"
                      img={NurseImg}
                      cardBody="Bem-vindo, "
                      userName={`${user.firstName} ${user.lastName} `}
                      cardBodyText=""
                      buttonText="Falar com o Enfermeiro"
                      onClick={setContactNurse}
                      imgHeight={70}
                    />
                  </Nurse>
                  <Result>
                    {user.plan === 1
                      ? (
                        !user.questionnaire
                          ? (
                            <MiniCard
                              img={QuizImg}
                              buttonText="Responder"
                              isLink="/questionario"
                            >
                              <p>
                                Responda ao questionário para completar seu perfil
                              </p>
                            </MiniCard>
                          )
                          : (
                            <MiniCard
                              img={QuizImg}
                              buttonText="Ver Resultados"
                              isLink="/historic"
                            >
                              <p>
                                Veja o resultado <br /> dos seus exames
                              </p>
                            </MiniCard>
                          )
                      )
                      : !user.questionnaire
                        ? (
                          <MiniCard
                            img={QuizImg}
                            buttonText="Responder"
                            isLink="/questionario"
                          >
                            <p style={{ fontSize: '13.5px' }}>
                              Responda ao questionário para completar seu perfil
                            </p>
                          </MiniCard>
                        )
                        : (
                          <Card
                            mode="light"
                            img={ResultsImg}
                            cardBody={
                              <span>
                                {' '}
                                Acompanhe o resultado dos seus <b>exames</b>.
                              </span>
                            }
                            buttonText="Ver Histórico"
                            imgHeight={70}
                            isLink="/historic"
                          />
                        )}
                  </Result>
                  {/* {user.plan === 1
                    ? <Single>
                      <MiniCard
                        img={SingleAppointmentImg}
                        buttonText="Consulta Avulsa"
                        onClick={() => dispatch({ type: NEW_SINGLE_APPOINTMENT })}
                      ><p>Marque uma consulta <br /> com um geneticista</p>
                      </MiniCard>
                    </Single>
                    : <HelpCenter
                      title="Central de Ajuda"
                      callCenter="551136202550"
                      email="einsteinconecta@einstein.br"
                      img={HelpImage}
                    />
                  } */}
                </Slider>
              </ServicesWrapper>
              <History>
                <Historic reverse />
              </History>
              <Schedule />
              {/* <Services>
                  <ServicesTitle>
                    <IconShoppingBag />
                    <h3>
                      Serviços Einstein
                    </h3>
                    <div></div>
                  </ServicesTitle>
                  <ServicesWrapper>
                    <Slider settings={settings}>
                      <ServiceCard
                        colorBase="#1170B7;"
                        colorStart="#41B7A0"
                        colorEnd="#4980C1"
                        icon={<IconConecta />}
                        imgHeader={ConectaImage}
                        title="Conecta"
                        description="O Einstein Conecta é um serviço de orientações médicas no qual você pode falar
              a qualquer hora do dia e de qualquer lugar do mundo com um médico do Einstein."
                      />
                      <ServiceCard
                        colorBase="#E39573;"
                        colorStart="#E17E53"
                        colorEnd="#BF3C02"
                        icon={<IconConecta />}
                        imgHeader={RehabImage}
                        title="Telereabilitação"
                        description="O Telereabilitação é um serviço de orientações médicas no qual você pode falar
              a qualquer hora do dia e de qualquer lugar do mundo com um médico do Einstein."
                      />
                      <ServiceCard
                        colorBase="#B19CCC;"
                        colorStart="#D9CDE8"
                        colorEnd="#694E88"
                        icon={<IconConecta />}
                        imgHeader={PsicoImage}
                        title="Telepsicologia"
                        description="O Telepsicologia é um serviço de orientações médicas no qual você pode falar
              a qualquer hora do dia e de qualquer lugar do mundo com um médico do Einstein."
                      />
                    </Slider>
                  </ServicesWrapper>
                </Services> */}
            </ContentLayout>
          </>
        )}
      </ContentHome>
    </>
  );
}
