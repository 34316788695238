/* eslint-disable @typescript-eslint/member-delimiter-style */
import CryptoJS from 'crypto-js/aes';
import { ReactComponentElement, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import AppCookies from '../../utils/cookies';
import AuthContext, { AuthInterface, AuthUser } from './context';

interface Props {
  children: ReactComponentElement<any> | Array<ReactComponentElement<any>>;
}

export default function AuthProvider({ children }: Props) {
  const [cookies, setCookie] = useCookies<any>([]);

  const [authToken, setAuthToken] = useState<string>('');
  const [paymentTokenBuy, setPaymentTokenBuy] = useState<string>('');
  const [authPatientToken, setAuthPatientToken] = useState<string>('');
  const [sourcePlatform, setSourcePlatform] = useState<string>('');
  const [productId, setProductId] = useState<string | number>('');
  const [serviceId, setServiceId] = useState<string | number>('');
  const [platformToken, setPlatformToken] = useState<string>('');
  const [successPaymentToken, setSuccessPaymentToken] = useState<string>('');
  const [client, setClient] = useState<string>('');
  const [holderDocumentToken, setHolderDocumentToken] = useState<string>('');

  const [user, setUser] = useState<AuthUser>({} as AuthUser);

  const context = useMemo(
    (): AuthInterface => ({
      user,
      successPaymentToken:
        cookies['@EinsteinMP:payment_token'] ?? successPaymentToken,
      productId: productId ?? cookies['@product_id'],
      serviceId: cookies['@service_id'] ?? serviceId,
      client: cookies['@client'] ?? client,
      holderDocumentToken:
        cookies['@holder_document_token'] ?? holderDocumentToken,
      platformToken: cookies['@platform_token'] ?? platformToken,
      sourcePlatform: cookies['@source_platform'] ?? sourcePlatform,
      authToken: AppCookies.get('api_token') ?? authToken,
      paymentTokenBuy:
        AppCookies.get('api_token') ?? paymentTokenBuy,
      authPatientToken:
        AppCookies.get('api_patientToken') ?? authPatientToken,
      setAuthToken: (token: string) => {
        AppCookies.set('api_token', token);
        setAuthToken(token);
      },
      setPaymentTokenBuy: (token: string) => {
        AppCookies.set('api_token', token);
        setPaymentTokenBuy(token);
      },
      setAuthPatientToken: (token: string) => {
        AppCookies.set('api_patientToken', token);
        setAuthPatientToken(token);
      },
      setUser,
      setSourcePlatform: (platform: string) => {
        setCookie('@source_platform', platform, { path: '/' });
        setSourcePlatform(platform);
      },
      setProductId: (id: string | number) => {
        setCookie('@product_id', id, { path: '/' });
        setProductId(id);
      },
      setServiceId(id: string | number) {
        setCookie('@service_id', id, { path: '/' });
        setServiceId(id);
      },
      setSuccessPaymentToken: (token: string) => {
        setSuccessPaymentToken(token);
        setCookie('@EinsteinMP:payment_token', token, { path: '/' });
      },
      setClient: (value: string) => {
        setClient(value);
        setCookie('@client', value, { path: '/' });
      },
      setHolderDocumentToken: (value: string) => {
        const documentEncrypted = CryptoJS.encrypt(
          JSON.stringify(value),
          process.env.COOKIE_SECRET ? process.env.COOKIE_SECRET : ''
        ).toString();

        const cookieExpiresDate = new Date();
        cookieExpiresDate.setHours(cookieExpiresDate.getHours() + 5);
        setHolderDocumentToken(documentEncrypted);
        setCookie('@holder_document_token', documentEncrypted, {
          expires: cookieExpiresDate,
          path: '/'
        });
      },
      setPlatformToken: (value: string) => {
        setPlatformToken(value);
        setCookie('@platform_token', value, { path: '/' });
      }
    }),
    [
      authToken,
      paymentTokenBuy,
      authPatientToken,
      user,
      sourcePlatform,
      productId,
      serviceId,
      platformToken,
      successPaymentToken,
      client,
      holderDocumentToken
    ]
  );

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
}
