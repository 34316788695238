/* eslint-disable */

export const moveToLogin = (logoff = false, meta = '{}') => {
  const callback = `${window.location.protocol}//${window.location.host}`;
  const params = new URLSearchParams({
    callback,
    appOrigin: 'predicta-web',
    meta
  })

  if (logoff) {
    params.set('logout', '1')
  }

  window.location = `${process.env.REACT_APP_MINHA_CONTA_SSO}?${params}` as unknown as Location;
}
