import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: .85rem;
    font-weight: 600;
    border-radius: 10px;
    padding: 25px;
    background: transparent;
    border: dashed 1px #707071;
    text-align: center;
    p{
      margin-top: 10px;
      margin-bottom: 20px;
    }
`;

export const ButtonWrapper = styled.div`
    display:flex;
`;

export const ToolTip = styled.span`
    cursor: pointer;
    background-color: #36a38f;
    font-size: .875rem;
    text-shadow: -1px 0 gray, 0 1px gray, 1px 0 gray, 0 -1px gray;
    padding: .5rem 1.25rem;
    text-align: center;
    color: #fff;
    font-weight: 600;
    border-radius: 2rem;
    justify-content: center;
    width: 100%;
    max-width: 315px;
    position: absolute;
    opacity:0;
    margin: 0px 0px 0px 0px;
    svg{
        margin-right: .5rem;
    }
    &:hover{
      opacity:0.8;
    }
    @media(max-width: 500px) {
      max-width: 120px;
    }
`;
