import React, { ReactElement, useState, useRef } from 'react';
import { AccordionSection, Button, Content, Text, BtnCollapse } from './styles';
type props = {
  key: number
  title: string
  children: ReactElement
  btnColor?: string
};
export default function Accordion(props: props): ReactElement {
  const [active, setActive] = useState(false);

  const [height, setHeight] = useState('0');

  const content = useRef(document.createElement('div'));

  function toggleAcordion(): void {
    setActive(!active);
    setHeight(active ? '0' : `${content.current.scrollHeight}`);
  }
  return (
    <AccordionSection className='accordion'>
      <Button active={active} onClick={toggleAcordion}>
        <p>{props.title}</p>
        <BtnCollapse active={active} btnColor={props.btnColor} />
      </Button>
      <Content ref={content} maxHeight={height}>
        <Text>
          {props.children}
        </Text>
      </Content>
    </AccordionSection>
  );
}
