import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 1280px;
  height: fit-content;
  margin-top: 70px;
  padding: 0px 50px;

  @media (max-width: 800px) {
    padding: 20px;
    margin-top: 70px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 30px;
`;

export const Image = styled.img`
  max-width: 400px;
  margin: 24px auto;
  height: auto;

  @media (max-width: 800px) {
    max-width: 300px;
  }
`;

export const Button = styled.button`
  height: 50px;
  width: 100%;
  color: #fff;
  font-weight: 600;
  background-color: #004f92;
  margin-top: 30px;
  border-radius: 25px;
  border: 0;
  align-items: center;
`;
