import React, { createContext, useContext, useState, useCallback } from 'react';

import Loading from '../Layout/Loading';

interface LoadingContextData {
  isLoading: boolean
  startLoading: () => void
  endLoading: () => void
}

const LoadingContext = createContext<LoadingContextData>(
  {} as LoadingContextData
);

const LoadingProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const startLoading = useCallback(() => {
    setIsLoading(true);
  }, []);

  const endLoading = useCallback(() => {
    setIsLoading(false);
  }, []);

  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        startLoading,
        endLoading
      }}
    >
      {children}
      {isLoading && <Loading />}
    </LoadingContext.Provider>
  );
};

function useLoading(): LoadingContextData {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error('useAuth must be used within an LoadingProvider');
  }

  return context;
}

export { LoadingProvider, useLoading };
