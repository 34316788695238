import styled from 'styled-components';
import { Container } from '../../styles/GlobalStyle';

export const HeaderSite = styled.header`
    position: fixed;
    width: 100%;
    background-color: white;
    z-index: 30;
    top: 0;
    @media (min-widht: 1025px) { 
      zoom: 80%;
    }
`;

export const HeaderContainer = styled(Container)`
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    .label{
      font-weight: 600;
      color: #004F92;
      font-size: 17px;
      border-left: solid 1px #004f9269;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 1rem;
      margin-left: 1rem;
    }
`;

export const MenuDesktop = styled.nav`
    display: flex;
    margin-left: 6rem;
    margin-right: 1.5rem;

    .menu-desktop--item{
        font-size: .85rem;
        color: #707070;
        padding-right: 1rem;
        padding-left: 1rem;

        &:hover{
            color: #004F92;
        }
    }
`;
export const BtnWrapper = styled.div`
    margin-left: auto;
    display:flex;
    align-items:center;

    .btn{
      border-radius: 20px;
      width: 170px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 600;
    }
    .orange{
      background-color: #E0832C;
      color: #fff;
    }
    .outline{
      border: solid 1px #1F4E8D;
      color: #004F92;
      margin-left: 1rem;
    }
`;

export const GradientLine = styled.div`
    background-image: linear-gradient(to right, #F1AD51 0%, #DE656B 30%, #4595C4 60% , #3AD5CA 100%);
    height: 8px;
    @media(max-width: 778px){
      height: 4px;
    }
`;
