import styled, { css } from 'styled-components';
import ArrowBack from '../../assets/ArrowBack.svg';
import ArrowForward from '../../assets/ArrowForward.svg';

// Verificar se isto é aplicável em classe
interface ContentLayoutProps {
  plan?: 'infinity' | 'one'
  medicalRecord?: 'noMedicalRecord' | 'hasMedicalRecord'
}
export const YellowAlert = styled.div`
    display: flex;
    align-items: center;
    grid-area: alert;
    width: 96%;
    height: fit-content;
    padding: 10px;
    margin: 1% 2% 0% 2%;
    border-radius: 15px;
    color: #FFB324;
    background-color: #FFB3241A;
    border: 2px solid #FFB324;
    img{
      width: 30px;
      height: 30px;
    }
`;
export const ContentHome = styled.div`
    min-height: 90vh;
    padding: 2rem;
    background: #F0F2f4;
    display: flex;
    width: 100%;
    margin: 0 auto;

    @media(max-width: 1050px){
      flex-direction: column;
      overflow-y: auto;
    }
`;

export const Sidebar = styled.div`
    width: 100%;

    @media(min-width: 992px){      
      max-width: 345px;
      grid-area: sidebar;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    @media(min-width: 601px) and (max-width: 991px){
      display: flex;
      align-items: flex-start;
    }
`;

export const ContentLayout = styled.div<ContentLayoutProps>` 
    gap: 15px;
    align-items: stretch; 
    width: 100%;

   @media(min-width: 1025px){       
    display: grid;

      ${props =>
        props.plan === 'one'
          ? css`
            grid-template: 
              "nurse results single"
              "historic historic historic"
              "services services services";
            `
          : props.plan === 'infinity' &&
            css`
            grid-template: 
              "nurse results results"
              "historic historic historic"
              "services services services";
            `}
      ${props =>
        props.medicalRecord === 'noMedicalRecord'
          ? css`
            grid-template: 
              "nurse nurse nurse"
              "alert alert alert"
              "services services services";
            `
          : props.medicalRecord === 'hasMedicalRecord' &&
            css`
            grid-template: 
              "nurse results results"
              "historic historic historic"
              "services services services";
            `}
   }
   grid-template-columns: 1fr 0.9fr;
   grid-template-rows: 1fr 0fr 0fr;   

   @media(min-widt: 992px){
     
    // width: calc(100% - 345px); 
   }
`;

export const Nurse = styled.div`
    grid-area: nurse;
    display: flex;
    justify-content: center;
    width: 100%;
    @media(min-width: 1024px){
      max-height: 200px;
    }
`;

export const Single = styled.div`
    grid-area: single;
    @media(min-width: 1024px){
      max-height: 200px;
    }
`;

export const Result = styled.div`
    grid-area: results;
`;

export const History = styled.div`
    grid-area: historic;
    display: flex;
    justify-content: center;
`;

export const Help = styled.div`

    grid-area: help;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Services = styled.div`
    grid-area: services;   
    align-items: center;
    justify-content: center;
`;

export const ServicesTitle = styled.div`
   display: flex;
   margin-top:40px;
   color: black;
   h3{
     font-weight: normal;
     font-size: 22px;
     width: 40%;
     opacity: 0.69;
     @media(max-width: 1200px){
        width: 50%;
      }
      @media(max-width: 1024px){
        width: 70%;
      }
      @media(max-width: 1020px){
        font-size: 20px;
        width: 100%;
      }
    }  
   
   svg{
      margin-right: 10px;
      width: 40px;
   }
   div{
     border-bottom: solid 2px #707070;
     opacity: 0.23;
     height: 20px;
     width: -webkit-fill-available;
     @media(max-width: 680px){
      width: auto;
    }
   }
`;
export const ServicesWrapper = styled.div`
   padding: 20px;
   margin-bottom: 10px;
  //  @media(max-width: 1250px){
  //   width: 50vh;
  // }
`;
export const NextArrow = styled.div`
    height: 36px;
    width: 36px;
    line-height: 1;
    opacity: 1;
    color: black;
    margin:-10px;
    background-color: #61626321;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 0px 0px 6px;
    &:before{
      content: url('${ArrowForward}');
      font-size: 30px;
      font-weight: bold;
      line-height: 1;
      color: black;
      display: block;
      position:absolute;
    }
    &:hover{
      background-color: #61626329;
    }
    @media(max-width: 1024px){
      margin-right: 14px;
    }
`;
export const PrevArrow = styled.div`
    height: 36px;
    width: 36px;
    line-height: 1;
    opacity: 1;
    color: black;
    margin:-10px;
    background-color: #61626321;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 0px 0px 4px;
    &:before{
      content: url('${ArrowBack}');
      font-size: 30px;
      font-weight: bold;
      line-height: 1;
      color: black;
      display: block;
      position:absolute;
    }
    &:hover{
      background-color: #61626329;
    }
    grid-area: services; 

    @media(max-width: 1024px){
      margin-left: 14px;
}   
`;

export const NextArrowDashboard = styled.div`
    height: 36px;
    width: 36px;
    line-height: 1;
    opacity: 1;
    color: black;
    margin:-10px;
    background-color: #61626321;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 0px 0px 6px;
    &:before{
      content: url('${ArrowForward}');
      font-size: 30px;
      font-weight: bold;
      line-height: 1;
      color: black;
      display: block;
      position:absolute;
    }
    &:hover{
      background-color: #61626329;
    }

`;
export const PrevArrowDashboard = styled.div`
    height: 36px;
    width: 36px;
    line-height: 1;
    opacity: 1;
    color: black;
    margin:-10px;
    background-color: #61626321;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 0px 0px 4px;
    &:before{
      content: url('${ArrowBack}');
      font-size: 30px;
      font-weight: bold;
      line-height: 1;
      color: black;
      display: block;
      position:absolute;
    }
    &:hover{
      background-color: #61626329;
    }
    grid-area: services; 

`;
