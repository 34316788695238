import styled from 'styled-components';

export const SecurityCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  width: 100%;
  max-width: 400px;
  border: solid 1px #CDF2E1;
  box-shadow: 0px 0px 6px #cdf2e1;
  border-radius: 10px;
  padding: 40px;
  svg{
    height: 100px;
    width: 80px;
    opacity: 1;
  }
  h3{
    font-size: 1.12rem;
    font-weight: bold;
    margin-bottom: 30px;
  }
  p{
    font-size: 1rem;
    color: #4E4E4E;
    line-height: 1.9rem;
    text-align: justify;
    font-weight: 400;
    margin: 30px auto;
  }
  button{
    width: 230px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    font-size: 1.12rem;
    font-weight: 600;
    border-radius: 20px;
    background-color: #00B193;
    color: #fff;
  }

  @media(min-width: 1024px){
    max-width: 380px;
    width: 50%; 
    margin-right: 10px;
    margin-top: 80px;
    
    p{
      min-height: 335px;
    }
  }
`;
