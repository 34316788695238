/* eslint-disable @typescript-eslint/member-delimiter-style */
import { createContext, useContext, useState, useCallback } from 'react';

interface Installments {
  product_id: string | number;
  price: number;
  hasInstallments: boolean;
  installmentsMax: number;
  installmentsNotFees: number;
  installmentsPercentFees: number;
}

interface InstallmentsContextData {
  installments: Installments;
  handleInstallments: (data: Installments) => void;
}

const InstallmentsContext = createContext<InstallmentsContextData>(
  {} as InstallmentsContextData
);

const InstallmentsProvider: React.FC = ({ children }) => {
  const [installments, setInstallments] = useState<Installments>({
    product_id: 0,
    price: 0,
    hasInstallments: false,
    installmentsMax: 0,
    installmentsNotFees: 0,
    installmentsPercentFees: 0
  });

  const handleInstallments = useCallback((data: Installments) => {
    setInstallments(data);
  }, []);

  return (
    <InstallmentsContext.Provider
      value={{
        installments,
        handleInstallments
      }}
    >
      {children}
    </InstallmentsContext.Provider>
  );
};

function useInstallments(): InstallmentsContextData {
  const context = useContext(InstallmentsContext);

  if (!context) {
    throw new Error('useAuth must be used within an LoadingProvider');
  }

  return context;
}

export { InstallmentsProvider, useInstallments };
