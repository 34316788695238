import styled from 'styled-components';

export const UserDataContent = styled.div`
    padding: 4rem;
    padding-left: 2rem;
    padding-right: 8rem;

    .flex{
      display: flex;
      align-items: baseline;
      div:first-child{
        margin-right: 10px;
      }
    }

    @media(min-width: 1025px){
      display: grid;
      grid-template: 
      "sidebar userData"
      "sidebar userData";
      column-gap: 2rem;
      grid-template-columns: 250px auto;
    }

    @media(max-width: 1024px){
      display: flex;
      flex-direction: column;
      padding: 1rem;
      padding-bottom: 6rem;
    }
`;

export const Header = styled.div`
  .danger{
    color: #F53748;
    font-size: .85rem;
    font-weight: 700;
    margin-left: 10px;
  }  
  .success{
    color: #5cb85c;
    font-size: .85rem;
    font-weight: 700;
    margin-left: 10px;
  }

  .arrow{
    svg{
      margin-right: .8rem;
      transform: rotate(-90deg);
    }

    path{
      stroke: #000;
    }
  }

  .space-between{
    justify-content: space-between;
  }
`;

export const SideBarArea = styled.div`
  grid-area: sidebar;
`;
export const UserDataArea = styled.div`
  grid-area: userData;
  min-height: 560px;
  padding: 2.5rem;
  background: #fff;
  border-radius: 10px;

  h1{
    font-size: 1.25rem;
  }

  h2{
    font-size: 1rem;
    font-weight: 600;
    color: #000;
  }

  .footnote{
    font-size: .75rem;
    color: #707070;
    margin-top: 30px;
    font-weight: 600;
  }

  .headnote{
    font-size: .75rem;
    color: #707070;
    margin-top: 10px;
    font-weight: 600;
  }

  .link{
    color: #009DFF;
    text-decoration: underline;
    font-weight: 600;
  }

  .btn-outline{
    height: 35px;
    width: 120px;
    border: solid 1px #009DFF; 
    border-radius: 30px;
    color: #009DFF;
    font-weight: 600;
    font-size: .75rem;
  }

  .red{
    border: solid 1px #F53748;
    color: #F53748;
    margin-top: 30px;
    height: 40px;
    width: 260px;
    font-size: .85rem;
  }

  @media(max-width: 778px){
    padding: 1.5rem;
    .btn-outline{
      width: 80px;
    }
  }
`;

export const Separator = styled.div`
  border-bottom: 1px solid #DCE1E5;
  height: 2.5rem;
  width: 100%;
  @media(max-width: 778px){
    height: 1.5rem;
  }
`;

export const CardOption = styled.div`
  padding-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2{
    font-size: .85rem;
    line-height: 1.5rem;
  }
  p{
    font-size: .75rem;
    color: #707070;
    font-weight: 600;
  }
  div{
    padding-left: 10px;
    padding-right: 10px;
  }
`;
