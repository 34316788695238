import { ReactElement } from 'react';
import SideBar from '../../components/SideBar';
import { Link } from 'react-router-dom';
import { ArrowBack } from '../../assets/icons';
import {
  UserDataContent,
  SideBarArea,
  UserDataArea,
  Header,
  Separator,
  CardOption
} from './styles';
export default function UserAuth(): ReactElement {
  return (
    <UserDataContent>
      <SideBarArea>
        <SideBar />
      </SideBarArea>
      <UserDataArea>
        <Header>
          <div className="flex arrow">
            <Link to="seguranca"><ArrowBack /></Link>
            <h1>Autenticação em dois fatores</h1>
          </div>
          <p className="headnote">
            Ao ativar a autenticação em dois fatores, você precisará inserir um código de segurança que será enviado via SMS ou e-mail sempre que for fazer login.
          </p>
        </Header>
        <Separator />
          <CardOption>
            <div>
              <h2>E-mail</h2>
              <p>Enviaremos um e-mail para paciente•••••••@eins•••br</p>
            </div>
            <div>
              <div className="switch">
                <input type="checkbox" id="email" />
                <label htmlFor="email"></label>
              </div>
            </div>
          </CardOption>
          <Separator />
          <CardOption>
            <div>
              <h2>Celular</h2>
              <p>Enviaremos um SMS para (11) ••••• - 4354</p>
            </div>
            <div>
              <div className="switch">
                <input type="checkbox" id="phone" />
                <label htmlFor="phone"></label>
              </div>
            </div>
          </CardOption>
          <Separator />
          <p className="footnote">
            <Link to="/meus-dados" className="link">Clique aqui</Link> se não tiver mais acesso ao e-mail ou dispositivo.
          </p>
          <Separator />
      </UserDataArea>
    </UserDataContent>
  );
}
