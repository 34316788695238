import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  text-align: center;
  padding-top: 82px;


  .backgroundsection {
    background-color: #f8f8f8;
    height: 471px;
  }

  .main-banner {
    /* pointer-events: none; */
    /* width: 100%; */
    display: inline-block;
  }

  .content {
    margin-top: 64px;
    display: inline-block;
  }
`;
