import styled from 'styled-components';
import { UserInfoProps } from './UserIcon';
export const Container = styled.img<UserInfoProps>`
  display: block;
  border-radius: 50%;
  overflow: hidden;
  margin: ${(props) => props.margin && `${props.margin};`}
  width: ${(props) => props.size && `${props.size}px;`}
  height: ${(props) => props.size && `${props.size}px;`}
`;
