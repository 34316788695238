import styled from 'styled-components';
export const Content = styled.div`
  p{
    font-size: 1rem;
    color: #464646;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
  }
  h1{
    font-size: 1.5rem;
    font-weight: 600;
  }

  @media(min-width: 778px){
    padding-top: 11.25rem;
    padding-bottom: 11.25rem;
    background-color: #F0F2F4;
    display: flex;
    justify-content: center;
    align-items: center;
    p{
      margin-bottom: 1.25rem;
    }
  }

`;

export const FormWrapper = styled.div`
  min-height: 100vh;
  background: #fff;
  border-radius: 0.6rem;
  padding: 4rem;

  .error{
    color: #F53748;
    margin-bottom: 0.6rem;
    margin-top: 0.6rem;
    max-width: 538px;
  }
  .error-list{
    color: #F53748;
    font-size:13px;
    margin-bottom: 0.3rem;
    margin-top: 0.3rem;
    max-width: 538px;
  }
  .error-alert{
    border: solid 2px #F53748 !important;
  }
  .form-group{
    transition: all .3s;
    width: 100%;
    height: 60px;
  }
  .form-label{
    color: #999999ba;
    font-size: 14px;
    padding-right: 4px;
    padding-left: 4px;
    width: fit-content;
    display:block;
    margin-left: 0.6rem;
    transform: translateY(-30px);
    transition: all .3s;
    background: #fff;
    position: relative;
    z-index: 1;
  }
  .form-control{
    transition: all .5s;
    position: relative;
    z-index: 2;
    background: transparent;
  }
  .form-control:focus{
    border: solid 2px #004F92 !important;
    color: #004F92;
  }
  .form-control:focus + .form-label,
  .form-control:not(:placeholder-shown) + .form-label
  {
    transform: translateY(-50px) scale(.9);
    color: #004F92;
    font-weight: 500;
    z-index: 3;
  }
  .form-control:focus,
  .form-control:not(:placeholder-shown)
  {
    border: solid 2px #004F92;
    color: #004F92;
  }
  input{
    width: 100%;
    height: 40px;
    padding: 0.6rem;
    border-radius: 5px;
    margin-top: 15px;
    border: solid 1px #999999ba;
    ::placeholder {
      color: transparent;
    }
  }
  input[type="checkbox"]{
    width: 18px;
    height: 18px;
    padding: 0;
    margin: 0;
    margin-right: 0.6rem;
    border-radius: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
  }
  input[type="checkbox"]:checked{
    background: #004D8E;
    border:#004D8E;
  }
  input[type=checkbox]:checked:before {
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'>
    <path id='check' d='M14.491,6,7.279,13.213,4,9.934' fill='none' stroke='white' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'/>
  </svg>");
  }
  .check-box{
    font-size: 14px;
    line-height: 1.5em;
    color: #666666;
    margin-bottom: 15px;
  }
  select{
    width: 100%;
    height: 40px;
    padding: 0.6rem;
    border-radius: 5px;
    margin-top: 15px;
    border: solid 1px #999999ba;
    font-size: 14px;
    font-weight: 500;
    color: #999999ba;
  }
  select:focus {
    outline: none;  
    box-shadow: none;
  }
  input:focus {
    outline: none;  
    box-shadow: none;
  }
  .flex{
    display: flex;
  }
  .link{
    color: #005A9C;
    text-decoration: underline;
  }
  button{
    font-size: 1.12rem;
    font-weight: 500;
    border-radius: 20px;
    padding: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px white;
    color: white;
    background: #004F92;
    color: white;
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
    margin-top: 50px;
  }
  .verify-pass{
    p{
      font-size: .75rem;
      svg{
        path{
          stroke: #3EB49D;
        }
      }
    }
  }
  @media(max-width: 778px){
    padding: 15px;
    padding-top: 100px;
    .verify-pass{
      border-bottom: 1px solid #d2d2d2;
      padding-bottom: 15px;
      margin-bottom: 15px;
    }
    .check-box{
      div{
        width: calc(100% - 30px);
      }
    }
  }
`;

export const FormHeader = styled.div`
  display: flex;
  align-items: center;
  svg{
    transform: rotate(-90deg);
    margin-right: 5px;
    path{
      stroke: #000;
    }
  }
`;
export const Alert = styled.div`
  align-items: center;
  border-radius: 5px;
  padding: 20px;
  padding-left: 0;
  p{
    color: #464646;
    font-weight: 600;
  }
`;
