import styled from 'styled-components';

type StyledLabelProps = {
  color?: string | number
};

export const StyledLabel = styled.span<StyledLabelProps>`
    font-size: 14px;
    font-weight: bold;
    border-radius: 10px;
    padding: 5px 25px;
    ${props => props.color
        ? `background: ${props.color};
        color: ${props.color};`
        : `background:rgba(21,177,147, 0.16);
        color: #15B193;`
    }
   
    
`;
