import { ReactElement } from 'react';
import Button from '../Button';
import { StyledCard, ButtonWrapper } from './styles';
import { Link } from 'react-router-dom';
type props = {
  children?: ReactElement
  img?: string
  buttonText?: string
  isLink?: string
  onClick?: Function | any
};
export default function Card(props: props): ReactElement {
  return (
    <StyledCard>
      <div>
        {props.img && <img src={props.img}/>}
      </div>
        {props.children}
        {props.buttonText &&
          <ButtonWrapper>
            {props.isLink
              ? (
                <Link className="link" to={props.isLink}>
                  {props.buttonText}
                </Link>
                )
              : (
                <Button onClick={props.onClick} >
                  {props.buttonText}
                </Button>
                )
            }
          </ButtonWrapper>
        }
    </StyledCard>
  );
}
