import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_PAYMENT_SERVER_API
});

export const qasApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_AUTH
});

export const peApi = axios.create({
  baseURL: process.env.REACT_APP_PAYMENT_AUTH_SERVICE
});

export const cepApi = axios.create({
  baseURL: process.env.REACT_APP_PAYMENT_CEP
});

export const predictaPaymentApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_API
});
