import { ReactElement } from 'react';

import { Body, ButtonWrapper, Header, List, Modal, ModalBkg, Price } from './styles';

import ClearIcon from '@material-ui/icons/Clear';
import { Link } from 'react-router-dom';

type props = {
  open: boolean
  setOpen: Function | any
  plan: number
  oldValue?: string
  newValue: string
  parcel?: string
  parcelValue?: string
  children?: ReactElement
};

export default function ModalPlan(props: props): ReactElement {
  // eslint-disable-next-line no-lone-blocks
  {
    props.open
      ? document.body.classList.add('modal-open')
      : document.body.classList.remove('modal-open');
  }
  return (
    <ModalBkg open={props.open} onClick={() => props.setOpen(false)}>
      <Modal onClick={e => { e.stopPropagation(); }}>
        <Header>
          {/* <ProductText><b>PR</b> Predicta</ProductText> */}
          <button onClick={() => props.setOpen(false)}>
            <ClearIcon />
          </button>
        </Header>
        <Body plan={props.plan}>
          <Price>
            <h3>
              <label className="green">Predicta <br />{props.plan === 1 ? 'One' : 'Infinity'}</label>
            </h3>

            {props.parcel && (
              <h3>Em Até <b>{props.parcel}x <br />de R${props.parcelValue}</b> <br />sem juros</h3>
            )}
            {props.oldValue && (
              <h4 className="old-value">R$ {props.oldValue}</h4>
            )}
            <h2>Total: R$ {props.newValue} </h2>

            {/* <small>Mensais</small> */}
          </Price>
          <List plan={props.plan}>
            <ul>
              {props.children}
            </ul>
          </List>
        </Body>
        <ButtonWrapper>
          <Link className="btn"
            to={{
              pathname: 'contratar',
              state: props.plan
            }}
          >Contratar agora
          </Link>
        </ButtonWrapper>
      </Modal>
    </ModalBkg>
  );
}
