/* eslint-disable */
import styled from 'styled-components';
type props = {
  img?: string
  isDarkMode?: number
  bodyColor?: string
  fontWeight?: string
  secondCollumn?: string
  userName?: string
  borderColor?: string
  buttonText?: string
  fontSize?: number
  imgHeight?: number
  isRightImg?: boolean
};
export const StyledCard = styled.div<props>`
  display:flex;
  align-items: center;
  border-radius: 15px;
  padding: 20px;
  width: 100%;
  //margin: 0 auto;
  border: solid 2px ${props => props.borderColor ? props.borderColor : '#40BDA6'};
  color: black;
  background: white;
  height: 100%;
  
  @media(min-width: 1024px){
    max-height: 200px;
  }
  .flex{
    display: flex;
    align-items: center;
  }
  img{
    height: ${props => props.imgHeight ? props.imgHeight : '125'}px;
  }
  @media(max-width: 1250px){
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    width:100%;
  }

  @media(min-width: 601px) and (max-width: 768px){
    display: flex;
    align-items: center;
    justify-content: center;
    height: 170px;
    width: 100%;
  }
  @media(max-width: 414px){
    height: 220px;
  }
  
  ${props => props.isRightImg &&
    `flex-direction: row-reverse;
    // padding: 20px 20px 0 20px;
    img{
      height: 150px;
    }
    `
  }
  
  ${props => props.isDarkMode && 
    `color: white;
    background: #40BDA6;`
  }
`;

export const Content = styled.div<props>`
    margin-left: 10px;
    font-size: ${props => props.fontSize ? `${props.fontSize}px` : props.isRightImg ? '12px' : '18px'};
    text-align: left;
    ${props => props.color && `color: ${props.color};`}
    @media(max-width: 1250px){
      text-align:center;
    }
    span{
      font-weight: ${props => props.fontWeight ? props.fontWeight : props.isRightImg ? 'bolder' : 'lighter'};
    }

`;

export const ButtonWrapper = styled.div`
    text-align: center;
    bottom: 0;
    margin: 10px;
    margin-top: 20px;
    .link{
      border-radius: 25px;
      margin-right: auto;
      padding: 8px 20px 8px 20px;
      width: auto;
      cursor: pointer;
      border: none;
      color: white;
      font-size: 0.75rem;
      background: #40BDA6;
      flex-wrap: nowrap;
      display: flex;
      justify-content: center;
    }
`;

export const SecondCollumn = styled.div`
    margin-left: auto;
    display:flex;
    align-items: center;
    font-size: 0.8rem;
    font-weight: bold;
`;
