/* eslint-disable @typescript-eslint/member-delimiter-style */
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Content } from './styles/GlobalStyle';

import Header from './components/Header';
import SideMenu from './components/SideMenu';
import ModalSchedule from './components/ModalSchedule';
import Auth from './components/Auth';
import Load from './ui/Load';
import { ScheduleRootState } from './store/redux';

type Props = {
  children?: JSX.Element;
  title?: string;
};

const Layout = ({ children }: Props): ReactElement => {
  const scheduleReducer = useSelector<ScheduleRootState, boolean>(
    (scheduleReducer) => scheduleReducer.modalVisible
  );
  const load = useSelector<ScheduleRootState, boolean>(
    (scheduleReducer) => scheduleReducer.load
  );
  // const load = true;
  // eslint-disable-next-line no-lone-blocks
  {
    load
      ? document.body.classList.add('modal-open')
      : document.body.classList.remove('modal-open');
  }
  return (
    <Auth>
      {load && <Load noLabel />}
      <Content>
        <SideMenu />
        <div className={load ? 'display-none' : ''}>
          <Header />
          {children}
          {scheduleReducer && <ModalSchedule show={scheduleReducer} />}
        </div>
      </Content>
    </Auth>
  );
};
export default Layout;
