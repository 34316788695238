import { ReactElement, useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { SideMenuWrapper, SideMenuNav, BtnMenu } from './styles';

export default function SideMenu(): ReactElement {
  const location = useLocation();
  const useStateLocation = {
    dashboard: false,
    schedule: false,
    historic: false,
    contact: false
  };

  switch (location.pathname) {
    case '/dashboard':
      useStateLocation.dashboard = true;
      useStateLocation.schedule = false;
      useStateLocation.historic = false;
      useStateLocation.contact = false;
      break;
    case '/schedule':
      useStateLocation.schedule = true;
      useStateLocation.dashboard = false;
      useStateLocation.historic = false;
      useStateLocation.contact = false;
      break;
    case '/historic':
      useStateLocation.historic = true;
      useStateLocation.dashboard = false;
      useStateLocation.schedule = false;
      useStateLocation.contact = false;
      break;
    case '/contact':
      useStateLocation.contact = true;
      useStateLocation.dashboard = false;
      useStateLocation.schedule = false;
      useStateLocation.historic = false;
      break;
    default:
      useStateLocation.dashboard = true;
      useStateLocation.schedule = false;
      useStateLocation.historic = false;
      useStateLocation.contact = false;
      break;
  }

  const [isOpen, setIsOpen] = useState(false);
  const [iconHome, setIconHome] = useState(useStateLocation.dashboard);
  const [iconSchedule, setIconSchedule] = useState(useStateLocation.schedule);
  const [iconHistoric, setIconHistoric] = useState(useStateLocation.historic);
  const [iconContact, setIconContact] = useState(useStateLocation.contact);

  const toggle = (): void => {
    setIsOpen(!isOpen);
  };

  function toggleIcon(nameIcone: string) {
    switch (nameIcone) {
      case 'dashboard':
        setIconHome(true);
        setIconSchedule(false);
        setIconHistoric(false);
        setIconContact(false);
        break;
      case 'schedule':
        setIconSchedule(true);
        setIconHome(false);
        setIconHistoric(false);
        setIconContact(false);
        break;
      case 'historic':
        setIconHistoric(true);
        setIconSchedule(false);
        setIconHome(false);
        setIconContact(false);
        break;
      case 'contact':
        setIconContact(true);
        setIconHistoric(false);
        setIconSchedule(false);
        setIconHome(false);
        break;
      default:
    }
  }

  const [width, setWindowWidth] = useState(0);
  const showDesktopComponent: boolean = width > 1024;

  // Responsive
  const updateDimensions = () => {
    const width = window.outerWidth;
    setWindowWidth(width);
  };

  useEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <SideMenuWrapper onMouseEnter={toggle} onMouseLeave={toggle}>
      {showDesktopComponent &&
        <BtnMenu>
          {!isOpen
            ? (
              <img src='https://einstein-ui-conecta-lib.s3.amazonaws.com/icons/hamburgerIcon.svg' />
            )
            : null}
        </BtnMenu>
      }

      <SideMenuNav>
        <NavLink
          id='nav-home'
          to="/dashboard" exact
          className='side-menu-item'
          activeClassName='side-menu-item--active'
          onClick={() => toggleIcon('dashboard')}
        >
          <div className='svgContainer'>
            {iconHome
              ? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22.131" viewBox="0 0 20 22.131">
                <path id="Home" d="M6.307,18.771V15.7a1.39,1.39,0,0,1,1.349-1.419H10.39A1.39,1.39,0,0,1,11.748,15.7h0v3.076A1.2,1.2,0,0,0,12.887,20H14.71A3.367,3.367,0,0,0,18,16.562h0V7.838a2.487,2.487,0,0,0-.911-1.9L10.855.685a2.89,2.89,0,0,0-3.737,0L.911,5.943A2.467,2.467,0,0,0,0,7.847v8.714A3.367,3.367,0,0,0,3.29,20H5.113a1.2,1.2,0,0,0,1.176-1.229h0" transform="translate(1 1.131)" fill="#2196f3" stroke="#2096f3" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
              </svg>
              : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22.131" viewBox="0 0 20 22.131">
                <g id="Icon_Meu_Painel" data-name="Icon Meu Painel" transform="translate(1 1.131)">
                  <g id="Home">
                    <path id="Home" data-name="Home" d="M6.307,18.771V15.7a1.39,1.39,0,0,1,1.349-1.419H10.39A1.39,1.39,0,0,1,11.748,15.7h0v3.076A1.2,1.2,0,0,0,12.887,20H14.71A3.367,3.367,0,0,0,18,16.562h0V7.838a2.487,2.487,0,0,0-.911-1.9L10.855.685a2.89,2.89,0,0,0-3.737,0L.911,5.943A2.467,2.467,0,0,0,0,7.847v8.714A3.367,3.367,0,0,0,3.29,20H5.113a1.2,1.2,0,0,0,1.176-1.229h0" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                  </g>
                </g>
              </svg>
            }
          </div>
          <span>Meu Painel</span>
        </NavLink>
        <NavLink
          to="/schedule"
          className='side-menu-item'
          activeClassName='side-menu-item--active'
          onClick={() => toggleIcon('schedule')}
        >
          <div className='svgContainer'>
            {iconSchedule
              ? <svg id="iconShedule" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                <g id="Grupo_161874" data-name="Grupo 161874" transform="translate(-602 -534)">
                  <g id="Agenda" transform="translate(602 534)">
                    <g id="Icon_-_Agenda" data-name="Icon - Agenda">
                      <g id="Calendar" transform="translate(6 5)">
                        <path id="Path" d="M13.238,0H4.771C1.834,0,0,1.636,0,4.643v9.05c0,3.054,1.834,4.728,4.771,4.728h8.458c2.946,0,4.771-1.645,4.771-4.652V4.643C18.009,1.636,16.184,0,13.238,0Z" transform="translate(0 1.579)" fill="#2196f3" stroke="#2196f3" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        <path id="Line_200" d="M0,.473H17.824" transform="translate(0.093 6.931)" fill="none" stroke="#fff" strokeLinecap="square" strokeLinejoin="round" strokeWidth="1.5" />
                        <path id="Line_201" d="M.459.473H.468" transform="translate(12.984 10.837)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        <path id="Line_202" d="M.459.473H.468" transform="translate(8.546 10.837)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        <path id="Line_203" d="M.459.473H.468" transform="translate(4.099 10.837)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        <path id="Line_204" d="M.459.473H.468" transform="translate(12.984 14.723)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        <path id="Line_205" d="M.459.473H.468" transform="translate(8.546 14.723)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        <path id="Line_206" d="M.459.473H.468" transform="translate(4.099 14.723)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        <path id="Line_207" d="M.463,0V3.291" transform="translate(12.581 0)" fill="#2196f3" stroke="#2096f3" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        <path id="Line_208" d="M.463,0V3.291" transform="translate(4.502 0)" fill="#2196f3" stroke="#2096f3" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                      </g>
                      <rect id="Retângulo_4149" data-name="Retângulo 4149" width="30" height="30" fill="none" />
                    </g>
                  </g>
                </g>
              </svg>
              : <svg id="Icon_Agendamentos" data-name="Icon Agendamentos" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                <g id="calendar" transform="translate(5 4)">
                  <g id="Grupo_161876" data-name="Grupo 161876" transform="translate(-543 -586)">
                    <g id="Agenda" transform="translate(538 582)">
                      <g id="Icon_-_Agenda" data-name="Icon - Agenda">
                        <rect id="Retângulo_4149" data-name="Retângulo 4149" width="30" height="30" fill="none" />
                      </g>
                    </g>
                  </g>
                  <g id="Calendar-2" data-name="Calendar" transform="translate(2 1)">
                    <path id="Line_200" d="M0,.473H17.824" transform="translate(0.093 6.931)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
                    <path id="Line_201" d="M.459.473H.468" transform="translate(12.984 10.837)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
                    <path id="Line_202" d="M.459.473H.468" transform="translate(8.546 10.837)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
                    <path id="Line_203" d="M.459.473H.468" transform="translate(4.099 10.837)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
                    <path id="Line_204" d="M.459.473H.468" transform="translate(12.984 14.723)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
                    <path id="Line_205" d="M.459.473H.468" transform="translate(8.546 14.723)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
                    <path id="Line_206" d="M.459.473H.468" transform="translate(4.099 14.723)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
                    <path id="Line_207" d="M.463,0V3.291" transform="translate(12.581 0)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
                    <path id="Line_208" d="M.463,0V3.291" transform="translate(4.502 0)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
                    <path id="Path" d="M13.238,0H4.771C1.834,0,0,1.636,0,4.643v9.05c0,3.054,1.834,4.728,4.771,4.728h8.458c2.946,0,4.771-1.645,4.771-4.652V4.643C18.009,1.636,16.184,0,13.238,0Z" transform="translate(0 1.579)" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
                  </g>
                </g>
              </svg>
            }

          </div>
          <span>Agendamentos</span>
        </NavLink>
        <NavLink
          to="/historic"
          className='side-menu-item'
          activeClassName='side-menu-item--active'
          onClick={() => toggleIcon('historic')}
        >
          <div className='svgContainer'>

            {iconHistoric
              ? <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g id="clock">
                  <g id="Elipse_1486" data-name="Elipse 1486" transform="translate(2 2)" fill="#2096f3" stroke="#707070" strokeWidth="1">
                    <circle cx="10" cy="10" r="10" stroke="none" />
                    <circle cx="10" cy="10" r="9.5" fill="none" />
                  </g>
                  <rect id="Retângulo_2916" data-name="Retângulo 2916" width="24" height="24" transform="translate(24 24) rotate(180)" fill="#2096f3" opacity="0" />
                  <path id="Caminho_5043" data-name="Caminho 5043" d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" fill="#2096f3" />
                  <path id="Caminho_5044" data-name="Caminho 5044" d="M16,11H13V8a1,1,0,0,0-2,0v4a1,1,0,0,0,1,1h4a1,1,0,0,0,0-2Z" fill="#eff8ff" />
                </g>
              </svg>
              : <svg id="Icon_Histórico" data-name="Icon Histórico" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g id="clock" transform="translate(0 -0.181)">
                  <rect id="Retângulo_2916" data-name="Retângulo 2916" width="24" height="24" transform="translate(24 24.181) rotate(180)" fill="#707070" opacity="0" />
                  <path id="Caminho_5043" data-name="Caminho 5043" d="M12.292,2A10.292,10.292,0,1,0,22.583,12.292,10.292,10.292,0,0,0,12.292,2Zm0,18.525a8.233,8.233,0,1,1,8.233-8.233A8.233,8.233,0,0,1,12.292,20.525Z" transform="translate(-0.402 0.047)" fill="#707070" />
                  <path id="Caminho_5044" data-name="Caminho 5044" d="M16,11H13V8a1,1,0,0,0-2,0v4a1,1,0,0,0,1,1h4a1,1,0,0,0,0-2Z" transform="translate(-0.048 0.286)" fill="#707070" />
                </g>
              </svg>
            }

          </div>
          <span>Histórico</span>
        </NavLink>
        <NavLink
          to="/contact"
          className='side-menu-item'
          activeClassName='side-menu-item--active'
          onClick={() => toggleIcon('contact')}
        >
          <div className='svgContainer'>
            {iconContact
              ? <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g id="Grupo_11922" data-name="Grupo 11922">
                  <circle id="Elipse_1508" data-name="Elipse 1508" cx="9" cy="9" r="9" transform="translate(3 3)" fill="#2096f3" />
                  <g id="Polígono_3" data-name="Polígono 3" transform="matrix(0.951, -0.309, 0.309, 0.951, 1.375, 16.245)" fill="#2096f3">
                    <path d="M 5.116899967193604 4.5 L 0.8830999732017517 4.5 L 3 0.971833348274231 L 5.116899967193604 4.5 Z" stroke="none" />
                    <path d="M 3 1.943650007247925 L 1.766190052032471 4 L 4.233809947967529 4 L 3 1.943650007247925 M 3 0 L 6 5 L 0 5 L 3 0 Z" stroke="none" fill="#2096f3" />
                  </g>
                </g>
                <g id="message-circle">
                  <circle id="Elipse_897" data-name="Elipse 897" cx="1" cy="1" r="1" transform="translate(11 11)" fill="#2096f3" />
                  <circle id="Elipse_898" data-name="Elipse 898" cx="1" cy="1" r="1" transform="translate(15 11)" fill="#2096f3" />
                  <circle id="Elipse_899" data-name="Elipse 899" cx="1" cy="1" r="1" transform="translate(7 11)" fill="#2096f3" />
                  <path id="Caminho_5040" data-name="Caminho 5040" d="M19.07,4.93a10,10,0,0,0-16.28,11,1.06,1.06,0,0,1,.09.64L2,20.8A.994.994,0,0,0,3,22h.2l4.28-.86a1.26,1.26,0,0,1,.64.09,10,10,0,0,0,11-16.28Zm.83,8.36a8,8,0,0,1-11,6.08,3.26,3.26,0,0,0-1.25-.26,3.43,3.43,0,0,0-.56.05l-2.82.57.57-2.82a3.09,3.09,0,0,0-.21-1.81A8,8,0,1,1,19.9,13.29Z" fill="#2096f3" />
                  <rect id="Retângulo_2914" data-name="Retângulo 2914" width="24" height="24" fill="#2096f3" opacity="0" />
                  <g id="Elipse_1509" data-name="Elipse 1509" transform="translate(7 11)" fill="#fff" stroke="#eff8ff" strokeWidth="1">
                    <circle cx="1" cy="1" r="1" stroke="none" />
                    <circle cx="1" cy="1" r="0.5" fill="none" />
                  </g>
                  <g id="Elipse_1510" data-name="Elipse 1510" transform="translate(11 11)" fill="#fff" stroke="#eff8ff" strokeWidth="1">
                    <circle cx="1" cy="1" r="1" stroke="none" />
                    <circle cx="1" cy="1" r="0.5" fill="none" />
                  </g>
                  <g id="Elipse_1511" data-name="Elipse 1511" transform="translate(15 11)" fill="#fff" stroke="#eff8ff" strokeWidth="1">
                    <circle cx="1" cy="1" r="1" stroke="none" />
                    <circle cx="1" cy="1" r="0.5" fill="none" />
                  </g>
                </g>
              </svg>
              : <svg id="Icon_Contato" data-name="Icon Contato" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g id="message-circle">
                  <circle id="Elipse_897" data-name="Elipse 897" cx="1" cy="1" r="1" transform="translate(11 11)" fill="#707070" />
                  <circle id="Elipse_898" data-name="Elipse 898" cx="1" cy="1" r="1" transform="translate(15 11)" fill="#707070" />
                  <circle id="Elipse_899" data-name="Elipse 899" cx="1" cy="1" r="1" transform="translate(7 11)" fill="#707070" />
                  <path id="Caminho_5040" data-name="Caminho 5040" d="M19.07,4.93a10,10,0,0,0-16.28,11,1.06,1.06,0,0,1,.09.64L2,20.8A.994.994,0,0,0,3,22h.2l4.28-.86a1.26,1.26,0,0,1,.64.09,10,10,0,0,0,11-16.28Zm.83,8.36a8,8,0,0,1-11,6.08,3.26,3.26,0,0,0-1.25-.26,3.43,3.43,0,0,0-.56.05l-2.82.57.57-2.82a3.09,3.09,0,0,0-.21-1.81A8,8,0,1,1,19.9,13.29Z" fill="#707070" />
                  <rect id="Retângulo_2914" data-name="Retângulo 2914" width="24" height="24" rx="7" fill="#707070" opacity="0" />
                </g>
              </svg>
            }

          </div>
          <span>Contato</span>
        </NavLink>
      </SideMenuNav>
    </SideMenuWrapper>
  );
}
