import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: 40,
      width: '100%',
      '& div': {
        height: 40,
        position: 'relative'
      },
      '& label': {
        position: 'absolute',
        top: -8,
        color: 'rgba(153, 153, 153, 0.73)'
      },
      '& .MuiFormLabel-filled': {
        top: 0,
        color: '#004f92'
      },
      '& .MuiFormLabel-filled + div fieldset': {
        borderColor: '#004f92'
      },
      '& .MuiFormLabel-filled + div input': {
        color: '#004f92',
        padding: 0,
        paddingLeft: 14
      },
      '& .MuiInputLabel-shrink': {
        top: 0,
        color: '#004f92'
      },
      '& .MuiInputLabel-shrink + div fieldset': {
        borderColor: '#004f92'
      },
      '& .MuiInputLabel-shrink + div input': {
        color: '#004f92',
        padding: 0,
        paddingLeft: 14
      },
      '& .Mui-focused': {
        top: 0
      },
      '& .Mui-disabled': {
        color: 'rgb(133, 133, 133)'
      },
      '& .Mui-disabled + div input': {
        color: 'rgb(133, 133, 133)'
      },
      '& div input': {
        height: 34,
        padding: 10,
        paddingLeft: 14,
        marginBottom: 14
      },
      '& fieldset': {
        height: 46,
        borderRadius: 5
      },
      '& p': {
        margin: 0,
        padding: 0,
        paddingLeft: 5,
        fontSize: 10
      }
    }
  })
);
