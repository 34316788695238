import React, { ReactElement } from 'react';
import { StyledLabel } from './styles';
type props = {
  children: string
  color?: string | undefined
};
export default function Label(props: props): ReactElement {
  return (
    <StyledLabel color={props.color}>
        {props.children}
    </StyledLabel>
  );
}
