import React, { ReactElement, useEffect, useState } from 'react';

import {
  Container,
  BackSection,
  ProductDetails,
  TypesFaq,
  Steps,
  PlansSection,
  SliderContainer,
  MedicCard,
  ModalMedic,
  VideoResponsive
} from './styles';

import {
  AccordionWrapper,
  CompromiseSection,
  FaqSection,
  PlansWrapper
} from '../Home/styles';

import ButtonWhatsapp from '../../ui/ButtonWhatsapp';
import Footer from '../../ui/Footer';
import { IconCheck, LogoEinstein } from '../../assets/icons';

import FactImg from '../../assets/Site/FACT.png';

import JciImg from '../../assets/Site/jci.png';

import WestchesterImg from '../../assets/Site/Westchester.png';
import Header from '../../ui/Header';
import HeaderMobile from '../../ui/HeaderMobile';

import Accordion from '../../ui/Accordion';

import { useDispatch, useSelector } from 'react-redux';
import { ScheduleRootState, Item, FIRST_ITEMS } from '../../store/redux';
import Plan from '../../components/Plan';

import { MainBanner } from '../../components/MainBanner';

import Slider from 'react-slick';
import CardsModal from '../../components/CardsModal';
// import Banner from '../../ui/Banner';
import BannerPlans from '../../ui/BannerPlans';
import BannerImg from '../../assets/Site/banner-predicta-one.jpg';
import PredictaPlanImg from '../../assets/Site/predicta-one.jpg';

export default function LandingOne(): ReactElement {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();

  const [allFaq, setAllFaq] = useState(false);
  const [width, setWindowWidth] = useState(0);
  const showDesktopComponent: boolean = width > 768;

  // Responsive
  const updateDimensions = () => {
    const width = window.outerWidth;
    setWindowWidth(width);
  };

  useEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const accordionItems = useSelector<ScheduleRootState, Item[] | undefined>(
    (scheduleReducer) => scheduleReducer.filtered
  );

  function allItems(): void {
    setAllFaq(!allFaq);
    dispatch({ type: FIRST_ITEMS, number: allFaq ? 5 : 0 });
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    centerMode: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          infinite: true,
          autoplay: true,
          autoplaySpeed: 3000,
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      {showDesktopComponent ? <Header /> : <HeaderMobile />}
      <Container>

        <BannerPlans
          image={BannerImg}
          productName="PREDICTA"
          productSecondName="ONE"
          title="Saúde e longividade em seu código genético."
          textButton="Saiba mais"
          linkButton="#contratar"
        />

        <ProductDetails id="productdetails">
          <div>
            <h2>Predicta One</h2>
            <ul>
              <li>
                É simples, rápido e fácil! O Predicta One foi desenvolvido por
                especialistas do Hospital Israelita Albert Einstein para que você
                conheça algumas das informações contidas nos seus genes. Nosso
                compromisso é com sua saúde, por isso nosso foco são as doenças
                genéticas e hereditárias.
              </li>
              <li>
                O exame irá proporcionar que você saiba se tem risco aumentado de
                desenvolver alguma doença genética. Com essa informação, você
                pode, juntamente com seu médico, adotar um check-up  preventivo
                personalizado.
              </li>
              <li>
                <b>Importante:</b> O teste não exige solicitação médica e
                recomendamos fortemente que o resultado seja avaliado por um
                especialista de sua preferência.
              </li>
              <li>
                Temos uma equipe especializada para tirar suas dúvidas, clique no
                ícone do Whatsapp e escolha a opção Predicta.
              </li>
            </ul>
          </div>
          <div>
            <img src={PredictaPlanImg} />
          </div>
          {/* <a href="/contratar">
            Valor <b>R$15.500,00</b>
          </a> */}
        </ProductDetails>

        <TypesFaq>
          <FaqSection id="types">
            <h3>
              Confira abaixo a lista de doenças, de origem genética, que podem
              ter seus riscos aumentados detectados pelo Predicta One:{' '}
            </h3>

            <AccordionWrapper className="holder">
              <Accordion btnColor="#00B193" title={'Tipos de câncer'} key={1}>
                <div>
                  <div className="main">
                    <div className="title">
                      <div>Genes</div>
                      <div>Tipos de câncer</div>
                    </div>
                    <div className="row">
                      <div className="row-section">
                        APC, ATM, AXIN2, BAP1, BARD1, BMPR1A, BRCA1, BRCA2,
                        BRIP1, CDC73, CDH1, CDK4, CDKN2A, CHEK2, DICER1, EPCAM,
                        FH, FLCN, GREM1, HOXB13, KIT, MAX, MEN1, MET, MITF,
                        MLH1, MSH2, MSH3, MSH6, MUTYH, NBN, NF1, NF2, NTHL1,
                        PALB2, PDGFRA, PMS2, POLD1, POLE, PRKAR1A, PTCH1, PTEN,
                        RAD51C, RAD51D, RB1, RET, SDHA, SDHAF2, SDHB, SDHC,
                        SDHD, SMAD4, SMARCA4, SMARCB1, STK11, TMEM127, TP53,
                        TSC1, TSC2, VHL, WT1.
                      </div>
                      <div className="row-section">
                        • Cerebral <br />
                        • Endométrio/Útero <br />
                        • Hematológico
                        <br />
                        • Mama
                        <br />
                        • Melanoma
                        <br />
                        • Ovário
                        <br />
                        • Pâncreas
                        <br />
                        • Próstata
                        <br />
                        • Queratoacantoma múltiplo tipo Ferguson-Smith
                        <br />
                        • Sarcomas
                        <br />
                        • Tireoide
                        <br />
                        • Tumores de Pele
                        <br />
                        • Tumores Endocrinológico
                        <br />
                        • Tumores Gastrointestinais
                        <br />• Tumores Renais
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion>

              <Accordion
                btnColor="#00B193"
                title={'Doenças cardiovasculares'}
                key={2}
              >
                <div className="main">
                  <div className="title">
                    <div>Genes</div>
                    <div>Doenças cardiovasculares</div>
                  </div>

                  <div className="row">
                    <div className="row-section">
                      ACTA2, ACTC1, ACTN2, ACVRL1, APOB, BAG3, BMPR2, CACNA1C,
                      CACNB2, CALM1, CALM2 e CALM3, CASQ2, CAV1, CAV3, COL3A1,
                      CRYAB, CSRP3, DES, DMD, DSC2, DSG2, DSP, EMD, ENG, F2, F5,
                      F9, FBN1, FHL1, FLNC, GDF2, GLA, GPD1L, HCN4, JUP, KCNE1 e
                      KCNE2, KCNH2, KCNJ2, KCNQ1, LAMP2, LDLR, LDLRAP1, LMNA,
                      MYBPC3, MYH11, MYH7, MYL2 e MYL3, MYLK, NKX2-5, PCSK9,
                      PKP2, PLN, PRKAG2, PRKG1, PROC, PROS1, RBM20, RYR2, SCN5A,
                      SERPINC1, SGCD, SMAD3, SMAD4, TCAP, TGFB2, TGFB3, TGFBR1,
                      TGFBR2, TMEM43, TNNC1, TNNI3, TNNT2, TPM1, TRDN, TTN, TTR, VCL.
                    </div>
                    <div className="row-section">
                      • Aortopatias
                      <br />
                      • Arritmias
                      <br />
                      • Cardiopatia congênita
                      <br />
                      • Doença de Depósito Lisossomal
                      <br />
                      • Doença Neuromuscular
                      <br />
                      • Formas genéticas de pressão alta e colesterol alto
                      <br />
                      • Hipertensão Arterial Pulmonar
                      <br />
                      • Hipobetalipoproteinemia Familiar
                      <br />
                      • Miocardiopatias
                      <br />
                      • Telangiectasia Hemorrágica Hereditária
                    </div>
                  </div>
                </div>
              </Accordion>

              <Accordion btnColor="#00B193" title={'Outras doenças'} key={3}>
                <div className="main">
                  <div className="title">
                    <div>Genes</div>
                    <div>Outras doenças</div>
                  </div>

                  <div className="row">
                    <div className="row-section">ATP7B, BTD, CACNA1S, GAA, HAMP, HFE, HJV, OTC, RPE65, RYR1, SERPINA1, SLC40A1, TFR2.</div>
                    <div className="row-section">
                      • Deficiência de Alfa-1-Antitripsina
                      <br />
                      • Deficiência de Ornitina Transcarbamilase
                      <br />
                      • Doença de Wilson
                      <br />
                      • Hemocromatose Hereditária
                      <br />
                      • Paralisia Periódica Hipocalêmica
                      <br />
                      • Susceptibilidade à Hipertermia Maligna
                      <br />
                      • Trombofilia hereditária
                    </div>
                  </div>
                </div>
              </Accordion>
            </AccordionWrapper>
          </FaqSection>
        </TypesFaq>

        <Steps>
          {/* <h3>Etapas</h3> */}
          <div className="titles">
            Veja como é fácil realizar o Predicta One:
          </div>
          <div className="timeline">
            <div className="left">
              <div className="card">
                <div className="image">
                  <img
                    src="https://einstein-ui-conecta-lib.s3.amazonaws.com/icons/predicta/home/credit-card.png"
                    alt="Card"
                  />
                </div>
                <h4>Pagamento</h4>
                <p>Pague em até 6 vezes sem juros.</p>
              </div>

              <div className="card">
                <div className="image">
                  <img
                    src="https://einstein-ui-conecta-lib.s3.amazonaws.com/icons/predicta/home/schedule.png"
                    alt="Card"
                  />
                </div>
                <h4>Receba o Kit</h4>
                <p>Receba o KIT em sua casa e colete o exame de modo fácil</p>
              </div>

              <div className="card">
                <div className="image">
                  <img
                    src="https://einstein-ui-conecta-lib.s3.amazonaws.com/icons/predicta/home/dna.png"
                    alt="Card"
                  />
                </div>
                <h4>Envio do material coletado</h4>
                <p>Envie o material coletado para nosso laboratório</p>
              </div>

              <div className="card">
                <div className="image">
                  <img
                    src="https://einstein-ui-conecta-lib.s3.amazonaws.com/icons/predicta/home/reporting.png"
                    alt="Card"
                  />
                </div>
                <h4>Resultado de exame</h4>
                <p>Aguarde a liberação de seu resultado</p>
              </div>
            </div>
          </div>
        </Steps>

        <VideoResponsive>
          <iframe
            src="https://einstein-ui-conecta-lib.s3.amazonaws.com/video/HIAE_Exame+Predicta_FINAL.mp4"
            frameBorder="1"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Einstein Genômica"
          />
        </VideoResponsive>

        <PlansSection id="contratar">
          <PlansWrapper>
            <Plan
              plan={1}
              newValue="2.754,00"
              parcel="6"
              parcelValue="459,00"
              modalChildren={
                <>
                  <li>
                    <p>
                      É simples, rápido e fácil! O Predicta One foi desenvolvido
                      por especialistas do Hospital Israelita Albert Einstein
                      para que você conheça algumas das informações contidas nos
                      seus genes. Nosso compromisso é com sua saúde, por isso
                      nosso foco são as doenças genéticas e hereditárias.
                    </p>
                  </li>
                  <li>
                    <p>
                      O exame irá proporcionar que você saiba se tem risco
                      aumentado de desenvolver alguma doença genética. Com essa
                      informação, você pode, juntamente com seu médico, adotar
                      um check-up personalizado, com exames voltados para a
                      detecção precoce.
                    </p>
                  </li>
                  <li>
                    <p>
                      Para realizar o Predicta One, basta adquirir o exame
                      através do nosso site. Você receberá o kit completo para
                      colher sua saliva e enviar de volta para o nosso
                      laboratório. O resultado será disponibilizado através do
                      nosso site, com liberação de acesso através de login e
                      senha. O teste não exige solicitação médica, mas
                      recomendamos fortemente que o resultado seja avaliado por
                      um especialista de sua preferência.
                    </p>
                  </li>
                  <li>
                    Confira abaixo a lista de doenças que podem ter seus riscos
                    aumentados detectados pelo Predicta One:
                  </li>
                  <li>
                    <b>Tipos de Câncer:</b>
                  </li>
                  <li>
                    <p>
                      Cerebral
                      <br />
                      Endométrio/Útero
                      <br />
                      Hematológico
                      <br />
                      Mama
                      <br />
                      Melanoma
                      <br />
                      Ovário
                      <br />
                      Pâncreas
                      <br />
                      Próstata
                      <br />
                      Sarcomas
                      <br />
                      Tireoide
                      <br />
                      Tumores de Pele
                      <br />
                      Tumores Endocrinológico
                      <br />
                      Tumores Gastrointestinais
                      <br />
                      Tumores Renais
                      <br />
                    </p>
                  </li>
                  <li>
                    <b>Doenças cardíacas:</b>
                  </li>
                  <li>
                    <p>
                      Aortopatias
                      <br />
                      Arritmias
                      <br />
                      Cardiopatia congênita
                      <br />
                      Doença de Depósito Lisossomal
                      <br />
                      Doença Neuromuscular
                      <br />
                      Epiteliomas Escamosos Múltiplos Auto-Remitentes
                      <br />
                      Formas genéticas de pressão alta e colesterol alto
                      <br />
                      Hipertensão Arterial Pulmonar
                      <br />
                      Hipobetalipoproteinemia Familiar
                      <br />
                      Miocardiopatias
                      <br />
                      Telangiectasia Hemorrágica Hereditária
                      <br />
                      Trombofilia hereditária
                      <br />
                    </p>
                  </li>
                  <li>
                    <b>Outras doenças:</b>
                  </li>
                  <li>
                    <p>
                      Deficiência de Alfa-1-Antitripsina
                      <br />
                      Deficiência de Ornitina Transcarbamilase
                      <br />
                      Doença de Wilson
                      <br />
                      Hemocromatose Hereditária
                      <br />
                      Paralisia Periódica Hipocalêmica
                      <br />
                      Susceptibilidade à Hipertermia Maligna
                      <br />
                    </p>
                  </li>
                </>
              }
            >
              <>
                <li>
                  <div className="blue-check">
                    <div>
                      <IconCheck />
                    </div>
                  </div>
                  <div>
                    Receba seu kit de coleta de saliva em qualquer lugar do
                    Brasil
                  </div>
                </li>
                <li>
                  <div className="blue-check">
                    <div>
                      <IconCheck />
                    </div>
                  </div>
                  <div>Serão avaliadas alterações em 153 genes</div>
                </li>
                {/* <li>
                  <div className="blue-check">
                    <div>
                      <IconCheck />
                    </div>
                  </div>
                  <div>Avalia o risco de formação de coágulos sanguíneos</div>
                </li> */}
                <li>
                  <div className="blue-check">
                    <div>
                      <IconCheck />
                    </div>
                  </div>
                  <div>
                    O teste avalia risco aumentado de origem genética para 15
                    tipos de câncer, 12 doenças cardiovasculares e outras 7
                    doenças genéticas
                  </div>
                </li>
                <li>
                  <div className="blue-check">
                    <div>
                      <IconCheck />
                    </div>
                  </div>
                  <div>
                    Laudo fornecido sem burocracia para ser avaliado pelo médico
                    de sua preferência
                  </div>
                </li>
              </>
            </Plan>
          </PlansWrapper>
        </PlansSection>
        <FaqSection id="ajuda">
          <h2>Ficou alguma dúvida?</h2>
          <p>Confira aqui algumas perguntas frequentes: </p>
          <AccordionWrapper>
            {accordionItems !== undefined && accordionItems?.length !== 0
              ? (
                accordionItems.map((item, index) => {
                  return (
                    <Accordion
                      btnColor="#00B193"
                      key={index}
                      title={String(item.title)}
                    >
                      <div dangerouslySetInnerHTML={{ __html: item.content }} />
                    </Accordion>
                  );
                })
              )
              : (
                <div className="span">Nenhum resultado encontrado...</div>
              )}
          </AccordionWrapper>
          <button id="btn-009" onClick={allItems} className="btn">
            {' '}
            {allFaq ? 'Ver menos' : 'Todas as dúvidas'}
          </button>
        </FaqSection>

        <CompromiseSection id="compromise">
          <LogoEinstein />
          <h4>COMPROMISSO E EXCELÊNCIA!</h4>
          {/* <p>
            Nossos processos seguem severamente a LGPD genética
            <br />
            para proteger e guardar os dados do seu DNA!
          </p> */}
          <div>
            <img src={FactImg} />
            <img src={JciImg} />
            <img src={WestchesterImg} />
          </div>
        </CompromiseSection>

        <ButtonWhatsapp phone="551136202550" />
        <Footer />
      </Container>
    </>
  );
}
