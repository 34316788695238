import React, { ReactElement } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SlickSlider from 'react-slick';

type props = {
  settings: Object
  children: ReactElement | JSX.Element[]
};
export default function Slider(props: props): ReactElement {
  return (
    <SlickSlider {...props.settings}>
      {/* {props.children} */}
    </SlickSlider>
  );
}
