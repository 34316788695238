import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(1),
      maxWidth: '100%',
      width: '100%'
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  })
);
