import styled from 'styled-components';
import Background from '../../assets/Site/newBanner.jpeg';
import BackgroundMobile from '../../assets/Site/newBannerMobile.jpeg';

export const Content = styled.div`
  background-color: #fff;
  .green {
    color: #00b193;
    font-weight: 600;
  }
  .greenLabel {
    color: #007A8C;
    font-weight: 600;
  }
  .blue {
    color: #1860CC;
    font-weight: 600;
  }
  p {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    line-height: 28px;
  }
  h2 {
    font-size: 2.5rem;
    color: #112c40;
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  @media (min-width: 1025px) {
    flex-direction: column;
    .green {
      display: block;
    }
  }
  padding-top: 80px;
  @media (max-width: 768px) {
    padding-top: 50px;
  }
`;

export const Banner = styled.section`
  width: 100%;
  background-size: cover;
  // height: 540px;
  background-image: url(${Background});
  label {
    font-weight: 600;
    color: #004f92;
    font-size: 20px;
  }
  p {
    font-size: 1.12rem;
    max-width: 310px;
    width: 100%;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  a {
    border-radius: 50px;
    max-width: 370px;
    height: 80px;
    width: 100%;
    margin: 30% 0 0 5%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    font-weight: 600;
    background-color: #37ceff;
    color: #000729;
    bolder-weight: 2px;
  }
  @media (min-width: 1200px) and (max-width: 1600px) {
    a {
      margin: 33% 0 0 5%;
    }
  }
  @media (min-width: 1000px) and (max-width: 1200px) {
    a {
      margin: 45% 0 0 5%;
    }
  }
  @media only screen and (min-width: 600px) and (max-width: 1100px) {
    a {
      margin: 58% 0 0 5%;
      max-width: 350px;
      height: 50px;
    }
  }
  div{
    width: 100%;
  }

  @media (max-width: 768px) {
    padding-top: 2vw;
    padding-bottom: 2vw;
    img {
      position: absolute;
      bottom: 0;
      max-width: 100%;
      right: -25vw;
      width: 75vw;
    }
  }

  @media (max-width: 1024px) {
    & > div {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  @media (min-width: 769px) {
    height: 120vh;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 480px;
      margin-bottom: -60px;
    }
  }
`;

export const BannerMobile = styled.section`
  width: 100%;
  background-size: cover;
  background-image: url(${BackgroundMobile});
  padding-top: 10vw;
  padding-bottom: 10vw;
  margin-top: 52px;
  @media (max-width: 340px) {
    height: 120vh;
  }
  @media (min-width: 341px) and (max-width: 800px) {
    height: 80%;
  }
  a {
    border-radius: 50px;
    height: 60px;
    margin: 55vh 2vh 0 0;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    font-weight: 600;
    background-color: #37ceff;
    color: #000729;
    bolder-weight: 2px;
    @media (min-width: 768px) {
      margin: 65vh 4vh -5vh 4vh;
    }
  }
`;

export const About = styled.section`
  padding-top: 50px;
  display: flex;
  justify-content: center;
  justify-content: center;

  div {
    max-width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  img {
    width: 547px;
    height: 490px;
    margin-right: 20px;
    object-fit: contain;
  }

  .mb-50 {
    margin-bottom: 50px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 20px;    
    line-height: 28px;

  }
  @media (max-width: 768px) {
    display: block;
  }

  @media (max-width: 1024px) {
    img {
      height: 350px;
      max-width: 547px;
      width: 80%;
      margin: 1rem auto;
      display: block;
      object-fit: contain;
    }
  }

  @media (min-width: 1024px) {
    & > div {
      max-width: 557px;
      margin-left: 40px;
    }
  }
`;

export const ForWho = styled.section`
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    max-width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  img {
    width: 547px;
    height: 490px;
    object-fit: contain;
  }

  .mb-50 {
    margin-bottom: 50px;
  }

  p {
    font-size: 1.12rem;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  @media (max-width: 768px) {
    display: block;
  }

  @media (max-width: 1024px) {
    img {
      height: 350px;
      max-width: 547px;
      width: 80%;
      margin: 1rem auto;
      display: block;
      object-fit: contain;
    }
  }

  @media (min-width: 1024px) {
    & > div {
      width: 557px;
      margin-left: 40px;
    }
  }
`;

export const CardsSection = styled.section`
  padding-top: 50px;
  text-align: center;
  img {
    width: 500px;
    margin-right: 20px;
  }
  h2 {
    margin-bottom: 30px;
  }

  p {
    font-size: 1.12rem;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  @media (max-width: 1024px) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const PlansSection = styled.section`
  margin-top: 100px;
  text-align: center;
  h2 {
    margin-bottom: 50px;
  }
  .text-blue {
    color: #009ddc;
    text-decoration: underline;
  }
`;
export const PlansWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;

  > div {
    p:first-child {
      margin-bottom: 7px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (min-width: 769px) {
    align-items: stretch;
  }
`;

export const SecuritySection = styled.section`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  margin-bottom: 20px;
  h2 {
    color: #000000;
  }
`;
export const FaqSection = styled.section`
  padding-top: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  h2 {
    color: #000000;
  }

  h3 {
    font-size: 2rem;
    color: #112c40;
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: 10px;
    color: black;
    padding: 0 7%;
  }

  .btn {
    border-radius: 20px;
    width: 250px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.12rem;
    font-weight: 600;
    border: solid 1px #00b193;
    color: #00b193;
    margin-top: 3rem;
  }
`;
export const AccordionWrapper = styled.div`
  margin-top: 40px;
  padding: 0 2rem;
  max-width: 800px;
  width: 100%;

  .two {
    display: flex;
    flex-direction: row;
    .main {
      width: 50%;
    }
  }
`;

export const CompromiseSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
  padding-bottom: 150px;
  text-align: center;
  h4 {
    font-size: 16px;
    color: #025a9c;
    margin-top: 10px;
    margin-bottom: 50px;
    font-weight: bold;
  }
  p {
    color: #025a9c;
    font-size: 24px;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 500px;
    margin: 3rem auto 1rem;

    img {
      display: block;
      max-width: 100%;
      margin: 0 1rem 1rem;
    }
  }

  @media (max-width: 768px) {
  }
`;
