import axios, { AxiosResponse } from 'axios';
import {
  DependentInfo,
  DEPENDENTS_LIST,
  DEPENDENT_ADD,
  ScheduleInfo,
  UserInfo
} from '../store/redux';
// import { useDispatch } from 'react-redux';
// import mockApi from '../pages/UserDependents/mock.json';
import { Dispatch } from 'react';
import { IPersonPatientPortal } from '../interfaces/person.interface';
export interface ScheduleProps {
  medicalRecord?: string;
  unityId?: string;
  DataSlot?: string;
  modality?: string;
  stepId?: string;
}
export interface IHeader {
  headers: {
    Authorization?: string;
    'x-access-token'?: string;
    'x-access-patient-token'?: string;
  };
}
const IdConvenio = process.env.REACT_APP_ID_CONVENIO;
const InsuranceId = process.env.REACT_APP_INSURANCE_ID;

const baseUrlAuth = process.env.REACT_APP_BASE_API_AUTH;
const baseUrlMCAuth = process.env.REACT_APP_MINHACONTA_LOGIN;
const baseUrl = process.env.REACT_APP_BASE_API;
const baseUrManagement = process.env.REACT_APP_MC_MANAGEMENT_URL;

const getAuthorizationManagement = (): IHeader => {
  const tokenPatient = `Bearer ${String(
    localStorage.getItem('api_patientToken')
  )}`;
  return {
    headers: {
      Authorization: tokenPatient
    }
  };
};

const getConfig = (): IHeader => {
  const token = `Bearer ${String(localStorage.getItem('api_token'))}`;
  const tokenPatient = `Bearer ${String(
    localStorage.getItem('api_patientToken')
  )}`;
  return {
    headers: {
      Authorization: tokenPatient,
      'x-access-token': token,
      'x-access-patient-token': tokenPatient
    }
  };
};

const parseSex = (sex: string) => {
  if (sex === 'f') return 'Feminino';
  if (sex === 'm') return 'Masculino';
  return '';
};
// const updateUserRedux = (ret: any) => {
//   const dispatch = useDispatch();
//   dispatch({
//     type: USER_PRODUCT,
//     name: ret.firstName,
//     param: ret.planDescription,
//     lastName: ret.lastName,
//     prontuario: ret.medicalRecord,
//     state: {
//       user: {
//         phone: ret.phone,
//         email: ret.email,
//         birthDate: ret.birthDate,
//         sex: ret.sex,
//         document: ret.document,
//         nationality: '' // TODO REFAZER ENDPOINT NÃO ESTA RETORNANDO INFORMAÇÃO
//       }
//     }
//   });
// };
// const updateUserDependentsRedux = (ret: any): any => {
//   return { type: DEPENDENTS_LIST, dependependents: ret };
// };
const queryBuilder = (args: ScheduleProps): string => {
  let queryString = '?';
  const size = Object.entries(args).length;
  Object.entries(args).forEach(([key, value], index) => {
    if (value !== '' && value !== undefined && value !== 'undefined') {
      if (index < size) {
        queryString = queryString + `${key}=${String(value)}&`;
      } else {
        queryString = queryString + `${key}=${String(value)}`;
      }
    }
  });
  return queryString;
};

export const getHours = async (props: ScheduleProps): Promise<any> => {
  const queryString = queryBuilder(props);
  return await axios
    .get<any>(`${baseUrl}/schedule/slots/hours${queryString}`, getConfig())
    .then((response: AxiosResponse) => {
      return response.data.response;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
};

export const getDays = async (props: ScheduleProps): Promise<any> => {
  const queryString = queryBuilder(props);
  const availableDays = await axios
    .get<any>(`${baseUrl}/schedule/slots/days${queryString}`, getConfig())
    .then((response) => {
      return response.data.response;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });

  return availableDays;
};

export const createSchedule = async (data: ScheduleInfo): Promise<any> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '[]');
  const bodySchedule = {
    appointmentData: data.appointmentData,
    teleconsultationData: {
      appointment: `${data.day} ${data.time}`,
      birth: user.birthDate,
      document: user.document,
      phone: user.phone,
      documentType: user.documentType,
      idConvenio: IdConvenio,
      insuranceId: InsuranceId,
      name: user.name,
      email: user.email,
      sex: user.sex
    },
    modality: data.modality,
    registered: `${data?.day} - ${data?.time}`,
    stepId: null,
    medicalRecord: user.prontuario
  };

  if (data.rescheduleId) {
    await deleteSchedule(data.rescheduleId, user.step, user.document);
    bodySchedule.stepId = user.step;
  } else {
    bodySchedule.stepId = user.step + 1;
  }

  return await axios.post<any>(
    `${baseUrl}/schedule/create`,
    bodySchedule,
    getConfig()
  );
};

export const deleteSchedule = async (
  idSchedule: string,
  stepId: number,
  document: string
): Promise<string> => {
  return await axios
    .delete<any>(
      `${baseUrl}/schedule/cancel?schedulingId=${idSchedule}&stepId=${stepId}&document=${document}`,
      getConfig()
    )
    .then(async (response: AxiosResponse) => {
      // console.log(response);
      await getUserData();
      return 'validou';
    })
    .catch((error) => {
      console.log(error);
      return 'error';
    });
};

export const getClinics = async (document: string): Promise<any> => {
  const query = `?document=${document}`;
  return await axios
    .get<any>(`${baseUrl}/schedule/linked-units${query}`, getConfig())
    .then((response) => {
      return response.data.response.unidades;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
};

export const getTokenAuth = async (
  cpf: string,
  password: string
): Promise<any> => {
  let userDocument = '';
  if (cpf.length >= 14) {
    userDocument = cpf.replace(/[^\d]+/g, '');
  } else {
    userDocument = cpf;
  }

  const ret = await axios
    .post(`${baseUrlMCAuth}/mc/auth/login`, {
      document: userDocument,
      password: password,
      clientId: 'predicta-web'
    })
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error) => {
      const listErros: string[] = [];
      if (error.response.data.errors) {
        error.response.data.errors.map((erro: any, index: any): any => {
          return (listErros[index] = erro.message);
        });
      }
      const ObErro = {
        erro: error.response.data.message,
        erros: listErros
      };
      return ObErro;
    });
  return ret;
};

export const getUserData = async (): Promise<any> => {
  const personRequest = await axios
    .get(
      `${baseUrManagement}/patient-portal/v1/person`,
      getAuthorizationManagement()
    )
    .then((response) => {
      console.log({ response });
      return response?.data || {};
    })
    .catch((error) => {
      console.log(error);
      return error?.error || error?.code;
    });

  const person: IPersonPatientPortal = personRequest || {};
  const fullName = person.name.split(' ');
  const firstName = fullName.shift();
  const lastName = fullName.join(' ');
  const [firstMedicalRecord] = person?.medicalRecords || [];
  const medicalRecord = firstMedicalRecord ? firstMedicalRecord.value : '';

  const ret: any = await axios
    .get(`${baseUrl}/user/detailed`, getConfig())
    .then((response) => {
      return response.data.response;
    })
    .catch((error) => {
      console.log(error);
      const ObErro = {
        erro: 'Não foi possível fazer o login, tente novamente mais tarde.'
      };
      return ObErro;
    });

  if (person) {
    const dataUser = {
      document: person.cpf || person.foreignDocument,
      documentType: person.cpf ? 'CPF' : 'PASSAPORTE',
      name: person.name,
      firstName: firstName,
      lastName: lastName,
      email: person.email,
      product: ret.planId,
      plan: ret.planDescription,
      prontuario: medicalRecord || ret.medicalRecord,
      step: ret.stepStatusId ?? 0,
      stepStatus: ret.stepStatus,
      stepDescription: ret.stepStatusDescription,
      birthDate: person.birthDate,
      sex: person.biologicalSex,
      phone: person.phone,
      // imgThumb: ret.profile.image ?? '',
      questionnaire: ret.sendQuestionnaire,
      singleSchedule: ret.singleSchedule
    };
    localStorage.setItem('user', JSON.stringify(dataUser));
  }
  return ret;
};

export const resetPassword = async (cpf: string): Promise<any> => {
  const ret = await axios
    .post<any>(`${baseUrlAuth}/auths/recover-password`, {
      username: cpf,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      }
    })
    .then((response: AxiosResponse) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
  return ret;
};

export const getSteps = async (cpf: string): Promise<any> => {
  const ret = await axios
    .get<any>(`${baseUrl}/schedule/steps?document=${cpf}`, getConfig())
    .then((response: AxiosResponse) => {
      if (
        response.data.response.data.slice(-1)[0] !== undefined &&
        response.data.response.data.slice(-1)[0] !== 'undefined'
      ) {
        localStorage.setItem(
          'lastStep',
          JSON.stringify(response.data.response.data.slice(-1)[0])
        );
        console.log('response getSteps', response.data.response.data);
      }
      return response.data.response;
    })
    .catch((error) => {
      console.log(error);
    });
  return ret;
};

export const sendMail = async (data: any): Promise<any> => {
  const ret = await axios
    .post<any>(
      `${baseUrl}/user/contact`,
      {
        nome: data.nome,
        mail: data.mail,
        telefone: data.telefone,
        cpf: data.cpf,
        mensagem: data.mensagem
      },
      getConfig()
    )
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return ret;
};

export const sendNurseMail = async (data: any): Promise<any> => {
  const ret = await axios
    .post<any>(
      `${baseUrl}/user/contactnurse`,
      {
        nome: data.nome,
        mail: data.mail,
        telefone: data.telefone,
        cpf: data.cpf,
        mensagem: data.mensagem
      },
      getConfig()
    )
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return ret;
};

export const editPassword = async (data: any): Promise<any> => {
  const ret = await axios
    .put<any>(
      `${baseUrlAuth}/users/change-password`,
      {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': getConfig().headers['x-access-token']
        }
      }
    )
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error) => {
      const listErros: string[] = [];
      if (error.response.data.errors) {
        error.response.data.errors.map((erro: any, index: any): any => {
          return (listErros[index] = erro.message);
        });
      }
      const ObErro = {
        erro: error.response.data.message ?? error.response.data,
        erros: listErros,
        status: error.response.status ?? 400
      };
      return ObErro;
    });
  return ret;
};

export const register = async (data: any): Promise<any> => {
  const ret: any = await axios
    .post(
      `${baseUrlAuth}/users`,
      {
        firstName: data.name,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        document: data.document,
        documentType: data.documentType,
        birthDate: data.date,
        sex: data.sex,
        password: data.password
      },
      { headers: { origin: 'predicta' } }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const ObErro = {
        erro: error.response.data.message,
        erros: error.response.data.errors
      };
      return ObErro;
    });
  return ret;
};

export const uploadPatientDocs = async (uploadData: FormData): Promise<any> => {
  return await axios
    .post<FormData>(
      `${baseUrlAuth}/uploads/signups/documents`,
      uploadData,
      getConfig()
    )
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error) => error);
};

export const uploadPatientProfileImg = async (
  uploadData: FormData
): Promise<any> => {
  return await axios
    .post<FormData>(
      `${baseUrManagement}/patient-portal/v1/person/image-profile`,
      uploadData,
      getAuthorizationManagement()
    )
    .then((response: AxiosResponse) => {
      getUserData();
      return response.data;
    })
    .catch((error) => error);
};

export const getPatientProfileImg = async (): Promise<any> => {
  return await axios
    .get(`${baseUrManagement}/patient-portal/v1/person/image-profile`,
      getAuthorizationManagement()
    )
    .then((response: AxiosResponse) => {
      console.log({ response });
      return response.data;
    })
    .catch((error) => error);
};

export const updateUserDetail = async (user: UserInfo): Promise<any> => {
  return await axios
    .put<UserInfo>(`${baseUrlAuth}/users`, user, getConfig())
    .then((response: AxiosResponse) => {
      // updateUserRedux(response.data);
      getUserData();
      return response.data;
    })
    .catch((error) => {
      const listErros: string[] = [];
      if (error.response.data.errors) {
        error.response.data.errors.map((erro: any, index: any): any => {
          return (listErros[index] = erro.message);
        });
      }
      const ObErro = {
        erro: error.response.data.message,
        erros: listErros
      };
      return ObErro;
    });
};

export const addDependent =
  (dependentAdd: DependentInfo) => (dispatch: Dispatch<any>) => {
    dispatch({
      type: DEPENDENT_ADD,
      state: { currentDependent: dependentAdd }
    });
    // const result = axios.post<DependentInfo>(`${baseUrlPatient}/patient/dependents`, dependentAdd, getConfig())
    //   .then(response => dependentAdd)
    //   .catch(() => { });
  };
export const updateDependent = () => async (dispatch: any, getState: any) => {
  // TODO falta endpoint para atualizaçao dependente.
};
export const GetListDependents = () => async (dispatch: any, getState: any) => {
  const parseListSex = (itens: DependentInfo[]) => {
    const result = itens.map((elem, index) => ({
      ...elem,
      sex: parseSex(elem.sex)
    }));
    return result;
  };

  const result = await axios
    .get<DependentInfo[]>(`${baseUrlAuth}/dependentes`, getConfig())
    .then((response) => parseListSex([] as DependentInfo[]))
    .catch(() => {});
  dispatch({
    type: DEPENDENTS_LIST,
    state: { dependents: result }
  });
};

export const searchCep = async (cep: string): Promise<any> => {
  return await axios
    .get<any>(`https://viacep.com.br/ws/${cep}/json/`)
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const sendQuestionnaire = async (
  user: any,
  answersList: any
): Promise<any> => {
  const body = {
    document: user.document,
    questions: answersList
  };
  const ret = await axios
    .post<any>(`${baseUrl}/user/questionanswer/create`, body, getConfig())
    .then((response: AxiosResponse) => {
      return response;
    })
    .catch((error) => {
      return error.response.data;
    });
  return ret;
};
