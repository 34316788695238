import styled from 'styled-components';

export const BtnMenu = styled.button`
    width: 4.5rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const SideMenuNav = styled.nav`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    transition: padding .3s ease;

    .side-menu-item {
        font-size: .875rem;
        font-weight: 500;
        width: 52px;
        height: 52px;
        margin-bottom: .25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: .5rem;

        span{
            opacity: 0;
            transition: none;
            font-size: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        svg {
            width: 40px;
            flex-shrink: 0;

            :is(path, circle, rect) {
                transition: all .3s ease; 
            }
        }

        &:hover{            
            background-color: #EFF8FF;
        }

        &--active {
            background-color: #EFF8FF;
            color: #2196F3;;

            svg :is(path, circle, rect) {
                //fill: #2196F3;
            }
        }

    }

    @media(min-width: 1025px){
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 4.5rem .25rem 0;
        margin-bottom: 46vh;
        .side-menu-item {
            width: 4rem;
            height: 3.5rem;
    
            svg {
                width: 40px;
            }
    }
  }
    @media(max-width: 1024px){        
        width: 100%;
        justify-content: space-around;

        .side-menu-item {
            margin: auto;
        }
    }

`;

export const SideMenuWrapper = styled.div`
    background-color: #fff;
    display: flex;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 64px;
    z-index: 10;
    transition: width .3s ease; 

    @media(max-width: 1025px){
        justify-content: center;
    }

    @media(min-width: 1025px){
        grid-area: menu;
        flex-direction: column;
        align-items: flex-start;

    justify-content: flex-start;
        left: 0;
        top: 0;
        width: 4.5rem;
        height: 100vh;
        z-index: 10;   
        transition: width .3s ease;  

        &:hover{
            width: 240px;
    
            ${SideMenuNav}{
                padding-left: 1rem;
                padding-right: 1rem;
            }
    
            .side-menu-item{
                width: 100%;
                justify-content: flex-start;
                padding-left: .75rem;
                padding-right: .75rem;
                transition: padding .3s ease;
    
                svg{
                    margin-right: 1rem;
                }
    
                span{
                    opacity: 1;
                    font-size: .875rem;
                }
            }
        }
    }
`;
