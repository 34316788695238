import { useHistory } from 'react-router-dom';
import About from '../../../assets/Site/about.png';
import Header from '../Header';
import { Button, Container, Content, Image, Title } from './styles';

export function Success() {
  const history = useHistory();
  const planId = localStorage.getItem('selectedProduct');
  const predictaOne = process.env.REACT_APP_PREDICTA_PRODUCT_ONE_ID;
  const orderIsPredictaOne = predictaOne === planId;
  return (
    <>
      <Header step={'userData'} handleStep={() => console.log('a')}/>
      <Container>
        <Content>
          <Title> Seu pedido foi realizado com sucesso!</Title>
          { orderIsPredictaOne
            ? <h4>Após o recebimento do e-mail de confirmação de pagamento, você receberá o seu kit de coleta em até 5 dias úteis, no endereço informado no ato da compra. </h4>
            : <h4>Após o recebimento do e-mail de confirmação de pagamento, você poderá efetuar o agendamento da sua consulta pré-teste em nosso site. </h4>
          }

          <Image src={About}/>
          <h5>* Em caso de dúvida entre em contato nos canais de atendimento disponíveis em nosso site. </h5>
          <Button onClick={() => history.push('/')}>
            Voltar à página inicial
          </Button>
        </Content>
      </Container>
    </>
  );
};
