import { ReactElement } from 'react';
import Load from '../../../ui/SimpleLoad';
import { IconCheck } from '../../../assets/icons';

import {
  PasswordForm,
  Rules
} from './styles';

type props = {
  loading: boolean
  show: boolean
  message: string
  erro: string
  erros: []
  inputErros: {
    oldPassword: boolean
    newPassword: boolean
    confirmPassword: boolean
  }
  oldPassword: string
  newPassword: string
  confirmPassword: string
  setOldPassword: Function
  setNewPassword: Function
  setConfirmPassword: Function
  changePassword: Function
};
export default function ResetPassword(props: props): ReactElement {
  return (
    <PasswordForm show={props.show}>
      {props.loading
        ? (
          <Load />
          )
        : (
        <>
          {props.message && (
            <p className="success">{props.message}</p>
          )}
          {props.erro && (
            <p className="error">{props.erro}</p>
          )}
          {props.erros?.map((erro: string, index: number): ReactElement => {
            return (
                <p className="error-list" key={index}>{ erro }</p>
            );
          })}
          <div className="form-group">
          <input className={ props.inputErros.oldPassword && props.oldPassword === '' ? 'form-control required' : 'form-control'} type="password" placeholder="Senha atual"
          value={props.oldPassword} onChange={(e) => props.setOldPassword(e.target.value)}
          />
          <label className="form-label">Senha atual</label>
          </div>

          <div className="form-group">
          <input className={ props.inputErros.newPassword && props.newPassword === '' ? 'form-control required' : 'form-control'} type="password" placeholder="Nova senha"
          value={props.newPassword} onChange={(e) => props.setNewPassword(e.target.value)}
          />
          <label className="form-label">Nova senha</label>
          </div>

          <div className="form-group">
          <input className={ props.inputErros.confirmPassword && props.confirmPassword === '' ? 'form-control required' : 'form-control'} type="password" placeholder="Confirmar nova senha"
          value={props.confirmPassword} onChange={(e) => props.setConfirmPassword(e.target.value)}
          />
          <label className="form-label">Confirmar nova senha</label>
          </div>

          <Rules>
          <div>
            <p>
              <IconCheck /> Pelo menos 8 caracteres
            </p>
            <p>
              <IconCheck /> Contém letra maiúscula (A-Z)
            </p>
            <p>
              <IconCheck /> Contém letra minúscula (a-z)
            </p>
          </div>
          <div>
            <p>
              <IconCheck /> Contém número (0-9)
            </p>
            <p>
              <IconCheck /> Contém caractere especial (@#$&*)
            </p>
          </div>
          </Rules>

          <button onClick={ async () => props.changePassword() }>Confirmar</button>
        </>
          )
      }
    </PasswordForm>
  );
}
