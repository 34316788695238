import { useEffect, useRef } from 'react';
import TextField, { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import { useField } from '@unform/core';

import { useLoading } from '../../Payment/hooks/loading';

import { useStyles } from './styles';

interface InputProps extends OutlinedTextFieldProps {
  name: string
}

const Input = ({ name, ...rest }: InputProps) => {
  const classes = useStyles();

  const { isLoading } = useLoading();

  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (error) {
      inputRef.current?.focus();
    }
  }, [error]);

  return (
    <TextField
      inputRef={inputRef}
      className={classes.root}
      error={!!error}
      helperText={error}
      disabled={isLoading}
      {...rest}
    />
  );
};

export default Input;
