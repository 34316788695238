import React, { ReactElement, useEffect, useState } from 'react';
import { Container } from './styles';

type props = {
  message: string
};

export default function Toast(props: props): ReactElement {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count < 10) {
      setTimeout((e) => {
        setCount(count + 0.25);
      }, 100);
    }
  }, [count]);

  return (
    <Container progress={count}>
      <p>{props.message}</p>
      <div>
        <div className="progressBar" />
      </div>
    </Container>
  );
}
