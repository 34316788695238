import { ReactElement } from 'react';

import { ModalBkg, Modal, ProductText, Header, Body, List } from './styles';

import ClearIcon from '@material-ui/icons/Clear';

type props = {
  open: boolean
  setOpen: Function | any
  title: string | ReactElement
  children?: ReactElement
};

export default function ModalPredicta(props: props): ReactElement {
  // eslint-disable-next-line no-lone-blocks
  {
    props.open
      ? document.body.classList.add('modal-open')
      : document.body.classList.remove('modal-open');
  }
  return (
    <ModalBkg open={props.open} onClick={() => props.setOpen(false)}>
      <Modal onClick={e => { e.stopPropagation(); }}>
        <Header>
          {/* <ProductText><b>PR</b> Predicta</ProductText> */}
          <button onClick={() => props.setOpen(false)}>
            <ClearIcon />
          </button>
        </Header>
        <Body>
          <h2>
            {props.title}
          </h2>
          {props.children}
        </Body>
      </Modal>
    </ModalBkg>
  );
}
