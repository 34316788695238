import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9;
  border-bottom: 1px solid #ABAEB7;
  padding: 0 1.5rem 0 5.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F0F2F4;
  svg{
    width: 32px;
  }

  @media only screen and (max-width: 1024px) {
    padding: 0 1rem 0 1rem;
  }
`;

export const UserInfoWrapper = styled.div`

`;
