import File from '../../../assets/payment-icons/check.svg';
import { Close } from '@material-ui/icons';
import { useVoucher } from '../../../contexts/Voucher/context';
import {
  Image, InputButton, Loading, StyleProps, ButtonError
} from './style';

export interface Props extends StyleProps {
    value: string
    submit?: () => Promise<void>
}

export default function Button({ value, action, submit }: Props) {
  const { setVoucher } = useVoucher();
  return (
    <>
      { action === 'error' && (<ButtonError type="button" action={action} onClick={() => setVoucher('')}> <Close fontSize='medium'/> </ButtonError>)}
      { action === 'success' && (<Image src={File} alt="success icon" />)}
      { action === 'submit' && (<InputButton type="button" value={value} action={action} onClick={submit} />)}
      { action === 'loading' && (<Loading />)}
    </>
  );
}
