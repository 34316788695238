import React, { ReactElement } from 'react';
import Logo from '../../assets/Site/footerLogo.png';
import {
  PhoneIcon,
  IconChat,
  IconFacebook,
  IconTwitter,
  IconInstagram,
  IconYoutube,
  IconLinkedin,
  IconSpotify
} from '../../assets/icons';
import {
  FooterSite,
  FooterMessage,
  FooterContainer,
  TitleH3,
  UlSocial,
  LiSocial,
  SocialLinksWrapper,
  DivCopyright
} from './styles';

export default function Footer(): ReactElement {
  return (
    <FooterSite>
     <FooterMessage>
        <p>
          “Somos o Hospital Israelita Albert Einstein e cuidar de você é a nossa
          certeza.”
        </p>
      </FooterMessage>
      <FooterContainer>
        <div>
          <TitleH3>Central de Atendimento</TitleH3>
          <TitleH3 className="flex"><PhoneIcon/>+55 11 2151-1233</TitleH3>
          <TitleH3 className="flex"><PhoneIcon/>+55 11 3620-2550</TitleH3>
        </div>
        <div>
            <TitleH3>Fale Conosco</TitleH3>
            <TitleH3 className="flex align-center">
              <IconChat />
              <div className="ml-10">
                <a target="_blank" href="https://www.einstein.br/atendimento" rel="noreferrer">Queremos ouvir você</a>
              </div>
            </TitleH3>
        </div>
        <SocialLinksWrapper>
          <TitleH3 className="social">Nossos Canais</TitleH3>
          <UlSocial>
              <LiSocial><a target="_blank" href="https://www.facebook.com/HospitalAlbertEinstein"><IconFacebook/></a></LiSocial>
              <LiSocial><a target="_blank" href="https://www.twitter.com/hosp_einstein"><IconTwitter/></a></LiSocial>
              <LiSocial><a target="_blank" href="https://instagram.com/hosp_einstein"><IconInstagram/></a></LiSocial>
              <LiSocial><a target="_blank" href="https://www.youtube.com/user/HospitalEinstein"><IconYoutube/></a></LiSocial>
              <LiSocial><a target="_blank" href="https://www.linkedin.com/company/hospital-albert-einstein"><IconLinkedin/></a></LiSocial>
              <LiSocial><a target="_blank" href="https://open.spotify.com/user/hospital_albert_einstein"><IconSpotify/></a></LiSocial>
              <LiSocial><a target="_blank" href="https://vidasaudavel.einstein.br/"><img src={ Logo } /></a></LiSocial>
          </UlSocial>
        </SocialLinksWrapper>
        <DivCopyright>
          <div><a target="_blank" href="https://www.einstein.br/sobre-einstein/politicas-site" rel="noreferrer">Termos de uso e Politica de Privacidade</a></div>
          <div>Copyright © 2021 Hospital Israelita Albert Einstein. Todos os direitos reservados</div>
        </DivCopyright>
      </FooterContainer>
    </FooterSite>
  );
}
