import Select, { SelectProps } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import { useLoading } from '../../hooks/loading';

import { useStyles } from './styles';

type SelectTypes = SelectProps & {
  valueList: Array<{
    id: string;
    name: string;
    price: string;
  }>;
};

const Dropdown = ({ valueList, ...props }: SelectTypes) => {
  const classes = useStyles();

  const { isLoading } = useLoading();

  return (
    <div>
      <FormControl
        variant="outlined"
        size="small"
        className={classes.formControl}
      >
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          disabled={isLoading}
          {...props}
        >
          {valueList.map((val, idx) => (
            <MenuItem key={idx} value={val.id}>
              {`${val.name} - R$${val.price}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default Dropdown;
