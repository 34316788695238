import { ReactElement } from 'react';

import { BannerContainer, PrincipalContainer, Header, BannerImage } from './styles';
import dna_background from '../../assets/Site/dna-background.png';

type props = {
  id: string
  productName: string
  productSecondName?: string
  title?: string
  hr?: boolean
  color?: string
  image: string
  description: string
  textButton: string
  linkButton: string
};

export default function Banner(props: props): ReactElement {
  return (
    <BannerContainer id={props.id}>
      <PrincipalContainer>
        <div>
          <Header>
            <h1>
              {props.productName}
              {props.productSecondName ? <><br /><span className='second'>{props.productSecondName}</span><hr></hr></> : ''}
            </h1>

            <h2>
              {props.title}
            </h2>
            <h3>
              {props.description}
            </h3>
          </Header>
          <a href={props.linkButton}>
            {props.textButton}
          </a>
        </div>
      </PrincipalContainer>

      <BannerImage>
        <img src={props.image} />
      </BannerImage>

      <img className='BackgroundDNA' src={dna_background} />
    </BannerContainer>
  );
}
