import React, { ChangeEvent } from 'react';
import { Load } from '../../ui/SimpleLoad/styles';
import { ButtonUploadStyled, MiniLoad } from './style';

export interface IButtonUploadFile {
  labelUpload: string
  labelRemove?: string
  showload: boolean
  id?: number
  handleFileUploadSelected: (e: React.ChangeEvent<HTMLInputElement>) => void
}
export const ButtonUploadFile: React.FC<IButtonUploadFile> = ({ showload, labelUpload, labelRemove, handleFileUploadSelected }): JSX.Element => {
  return (
    <>
      { showload
        ? <MiniLoad> <Load /> </MiniLoad>
        : <ButtonUploadStyled>
          <label>
             <input type="file" className="hide" onChange={(e) => handleFileUploadSelected(e)} />
            <div>{labelRemove ?? labelUpload}</div>
          </label>
        </ButtonUploadStyled>
      }
    </>
  );
}
  ;
