import { ReactElement, useEffect, useState } from 'react';

import { ModalBkg, Contact, TextArea, ContactWrapper, CircleIcon, FormWrapper } from './styles';
import { Cpf, Phone } from '../../utils/masks';
import ClearIcon from '@material-ui/icons/Clear';
import ButtonLocal from '../../ui/Button';
import Load from '../../ui/SimpleLoad';

import { ScheduleRootState, UserInfo } from '../../store/redux';
import { useSelector } from 'react-redux';

import { IconPhone } from '../../assets/icons';

import { sendNurseMail } from '../../utils/services';

type props = {
  open: boolean
  setOpen: Function | any
};
export default function ContactNurse(props: props): ReactElement {
  // eslint-disable-next-line no-lone-blocks
  { props.open
    ? document.body.classList.add('modal-open')
    : document.body.classList.remove('modal-open');
  }
  const userInfo: UserInfo = (useSelector<ScheduleRootState, UserInfo>(scheduleReducer => scheduleReducer.user));
  const [erro, setErro] = useState('');
  const [erros, setErros] = useState<any>({});
  const [responseMessage, setResponseMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    setName(`${userInfo.firstName} ${userInfo.lastName}`);
    setPhone(userInfo?.phone ?? '');
    setEmail(userInfo?.email ?? '');
    setCpf(Cpf(userInfo?.document ?? ''));
  }, [userInfo]);

  async function handleSend(): Promise<any> {
    const listErros = {
      name: false,
      phone: false,
      email: false,
      cpf: false,
      message: false
    };
    if (!name || !phone || !email || !cpf || !message) {
      setErro('Preencha os campos em vermelho:');
      if (!name) {
        listErros.name = true;
      }
      if (!phone) {
        listErros.phone = true;
      }
      if (!email) {
        listErros.email = true;
      }
      if (!cpf) {
        listErros.cpf = true;
      }
      if (!message) {
        listErros.message = true;
      }
      setErros(listErros);
    } else if (cpf.length < 12) {
      listErros.cpf = true;
      setErro('CPF inválido');
    } else {
      setLoading(true);
      const data = {
        nome: name,
        mail: email,
        telefone: phone.replace(/[^\d]+/g, ''),
        cpf: cpf.replace(/[^\d]+/g, ''),
        mensagem: message
      };
      const sendMailResponse = await sendNurseMail(data);
      if (sendMailResponse.statusCode !== 400) {
        setResponseMessage('Sua mensagem foi enviada com sucesso');
        setName('');
        setPhone('');
        setEmail('');
        setCpf('');
        setMessage('');
      } else {
        setErro(sendMailResponse.response);
      }
      setLoading(false);
    }
  }
  return (
    <ModalBkg open={props.open} onClick={ () => props.setOpen(false)}>
      <Contact onClick={e => { e.stopPropagation(); }}>
        <div className="flex">
          <button onClick={ () => props.setOpen(false)}>
            <ClearIcon/>
          </button>
        </div>
        <FormWrapper>
          {/* <ContactWrapper>
            <CircleIcon><IconPhone/></CircleIcon>(11) 2151-7690
          </ContactWrapper> */}
          {loading
            ? (
              <Load left="45%" />
              )
            : (
              <>
                {erro && (
                  <p className="error">{erro}</p>
                )}
                {responseMessage && (
                  <p className="success">{responseMessage}</p>
                )}
                <br />
                <div className="form-group">
                  <input
                    className={ erros.name && name === '' ? 'form-control required' : 'form-control'}
                    value={name} onChange={(e) => setName(e.target.value)} placeholder="Nome" />
                  <label className="form-label">Nome</label>
                </div>
                <div className="form-group">
                  <input
                  className={ erros.phone && phone === '' ? 'form-control required' : 'form-control'}
                  value={phone} onChange={(e) => setPhone(Phone(e.target.value))} placeholder="DDD + Telefone" />
                  <label className="form-label">DDD + Telefone</label>
                </div>
                <div className="form-group">
                  <input
                  className={ erros.email && email === '' ? 'form-control required' : 'form-control'}
                  value={email} onChange={(e) => setEmail(e.target.value)} placeholder="E-mail" />
                  <label className="form-label">E-mail</label>
                </div>
                <div className="form-group">
                  <input
                  className={ erros.cpf && cpf === '' ? 'form-control required' : 'form-control'}
                  value={cpf} onChange={(e) => setCpf(Cpf(e.target.value))} placeholder="CPF" />
                  <label className="form-label">CPF</label>
                </div>
                <TextArea
                className={ erros.message && message === '' ? 'required' : ''}
                placeholder="Mensagem" value={message} onChange={(e) => setMessage(e.target.value)} />
                <ButtonLocal
                  onClick={ async () => handleSend() }
                  bkColor="white"
                  border="solid 1px #009DFF"
                  color="#009DFF" width={300}
                  height={50} fontWeight="700"
                  fontSize="14px"
                >
                  Enviar
                </ButtonLocal>
              </>
              )}
        </FormWrapper>
      </Contact>
    </ModalBkg>
  );
}
