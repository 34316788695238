import styled from 'styled-components';
type props = {
  active?: boolean
  maxHeight?: string
  btnColor?: string
};
export const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;
export const Button = styled.button<props>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1.5rem;
  align-items: center;
  border-bottom: solid 1px #E6E6E6;
  transition: background-color 0.6s ease;
  svg{
    margin-left:auto;
    color: #009DFF;
  }
  p{
    font-weight: ${props => props.active ? '600' : '500'};
    font-size: 16px;
    text-align:left;
  }
`;

export const BtnCollapse = styled.div<props>`
    width: 30px;
    height: 30px;
    margin-left:auto;
    position: relative;

    &:before, &:after{
        content: '';
        height: 2px;
        width: 16px;
        position: absolute;
        background: ${props => props.btnColor ? props.btnColor : '#009DFF'};
        border-radius: 5px;
        transition: all .5s ease-out;
        margin: auto;
        left: 0;
        right: 0;  
        top: 0;
        bottom: 0;
    }

    ${props => props.active
        ? `
          &:before, &:after{ 
            transform: rotate(0);
          }

          &:after{
              transform: translateY(4px);
          }

          &:before{
              transform: translateY(4px);
          } 
        `
        : `   
          &:before, &:after{  
            transform: translateY(0px);
          }

          &:before{
            transform: rotate(90deg);
          }

          &:after{
              transform: rotate(0deg);
          } 
        `
    }
`;

export const Content = styled.div<props>`
   background-color: white;
   overflow: hidden;
   max-height: ${props => props.maxHeight}px;
   transition: max-height 0.6s ease;
`;
export const Text = styled.div`
   font-weight: 400;
   /* font-size: .85rem; */
   font-size: 1rem;
   padding: 18px;
   p{
     margin-bottom: 10px;
   }
`;
