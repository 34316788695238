import { ReactElement } from 'react';
import { StyledButton } from './styles';

type props = {
  children?: string
  onClick?: Function | any
  isDarkMode?: number
  bkColor?: string
  color?: string
  border?: string
  fontWeight?: string
  fontSize?: string
  width?: number
  height?: number
  icon?: boolean
  noMargin?: boolean
  disabled?: boolean
};
export default function ButtonUi(props: props): ReactElement {
  return (
    <StyledButton onClick={props.onClick}
      icon={props.icon}
      isDarkMode={props.isDarkMode}
      bkColor={props.bkColor}
      color={props.color}
      fontWeight={props.fontWeight}
      fontSize={props.fontSize}
      width={props.width}
      border={props.border}
      height={props.height}
      noMargin={props.noMargin}
      disabled={props.disabled}
    >
      {props.children ? props.children : 'Clique Aqui' }
    </StyledButton>
  );
}
