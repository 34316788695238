import React, { useContext, useCallback, createContext, useState } from 'react';

interface IWarningInfo {
  code?: string;
  signal: 'error' | 'warning';
  description?: string;
  title?: string;
}

interface WarningContextData {
  message: IWarningInfo;
  showWarning(content: IWarningInfo): void;
  removeWarning(): void;
}

const WarningContext = createContext<WarningContextData>(
  {} as WarningContextData
);

const WarningProvider: React.FC = ({ children }) => {
  const [message, setMessage] = useState<IWarningInfo>({} as IWarningInfo);

  const showWarning = useCallback((content: IWarningInfo) => {
    setMessage(content);
  }, []);

  const removeWarning = useCallback(() => {
    setMessage({} as IWarningInfo);
  }, []);

  return (
    <WarningContext.Provider
      value={{
        message,
        showWarning,
        removeWarning
      }}
    >
      {children}
    </WarningContext.Provider>
  );
};

function useWarning(): WarningContextData {
  const context = useContext(WarningContext);

  if (!context) {
    throw new Error('useWarning must be used within a WarningProvider');
  }

  return context;
}

export { WarningProvider, useWarning };
