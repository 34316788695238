/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/default-param-last */
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { format } from 'date-fns';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const RESET_NEW_SCHEDULE = 'RESET_NEW_SCHEDULE';

export const NEW_SINGLE_APPOINTMENT = 'NEW_SINGLE_APPOINTMENT';
export const NEW_SINGLE_APPOINTMENT_SUCCESS = 'NEW_SINGLE_APPOINTMENT_SUCCESS';
export const NEW_SCHEDULE = 'NEW_SCHEDULE';
export const NEW_SAMPLE_COLLECTION = 'NEW_SAMPLE_COLLECTION';
export const NEW_STATE_COLLECTION = 'NEW_STATE_COLLECTION';

export const NEW_ONLINE_SCHEDULE = 'NEW_ONLINE_SCHEDULE';
export const NEW_PRESENTIAL_SCHEDULE = 'NEW_PRESENTIAL_SCHEDULE';

export const NEW_SCHEDULE_SET_CLINIC = 'NEW_SCHEDULE_SET_CLINIC';
export const NEW_PRESENTIAL_SCHEDULE_DAY = 'NEW_PRESENTIAL_SCHEDULE_DAY';
export const NEW_SCHEDULE_SET_DAY = 'NEW_SCHEDULE_SET_DAY';
export const NEW_SCHEDULE_STEP_DAY = 'NEW_SCHEDULE_STEP_DAY';
export const NEW_SCHEDULE_SET_TIME = 'NEW_SCHEDULE_SET_TIME';
export const NEW_SCHEDULE_STEP_TIME = 'NEW_SCHEDULE_STEP_TIME';
export const CONFIRMATION_STEP = 'CONFIRMATION_STEP';
export const CONFIRMATION_STEP_INFO = 'CONFIRMATION_STEP_INFO';
export const SCHEDULE_SEND = 'SCHEDULE_SEND';
export const SCHEDULE_ERROR = 'SCHEDULE_ERROR';
export const RESCHEDULE = 'RESCHEDULE';

export const FILTER = 'FILTER';
export const FIRST_ITEMS = 'FIRST_ITEMS';
export const LOGIN_PURCHASE = 'LOGIN_PURCHASE';
export const PURCHASE_PRONTUARIO = 'PURCHASE_PRONTUARIO';

export const USER_PRODUCT = 'USER_PRODUCT';
export const IS_USER_DATA_STALE = 'IS_USER_DATA_STALE';
export const USER_DATA_LOADED = 'USER_DATA_LOADED';

export const IS_REGISTERED = 'IS_REGISTERED';

export const SET_LOAD = 'SET_LOAD';
export const SET_RELOAD = 'SET_RELOAD';
export const SET_FULL_DISCOUNT_VOUCHER = 'SET_FULL_DISCOUNT_VOUCHER';

export const DEPENDENTS_LIST = 'DEPENDENTS_LIST';
export const DEPENDENT_UPDATE = 'DEPENDENT_UPDATE';
export const DEPENDENT_ADD = 'DEPENDENT_ADD';

export const ADD_ANSWER = 'ADD_ANSWER';

const IdConvenio = process.env.REACT_APP_ID_CONVENIO;
const InsuranceId = process.env.REACT_APP_INSURANCE_ID;
export interface Item {
  title: String
  content: string
}

export interface Purchase {
  cpf?: string
  registered?: number
  token?: string
  refreshToken?: string
  name?: string
  lastName?: string
  prontuario?: string
  error?: string
  errors?: []
}

export interface ScheduleRootState {
  user: UserInfo
  isUserDataStale: boolean
  userDataLoaded: boolean
  dependents: DependentInfo[]
  currentDependent?: DependentInfo
  modalVisible: boolean
  newSchedule?: ScheduleInfo
  step: string
  items: Item[]
  filtered?: Item[]
  purchase?: Purchase
  load: boolean
  reload: boolean
  answers?: Answer[] | undefined
  fullDiscountVoucher: boolean;
}

export interface ScheduleRootAction {
  state: ScheduleRootState
  day: Date
  time: number | any
  search: string
  param: string
  number?: number
  error?: string
  type: string
  errors?: []
  name: string
  lastName: string
  prontuario: string
  local: string
  idAgenda: string
  idLocal: string
  idUnidade: string
  resourceId: string
  serviceId: string
  load: boolean
  fullDiscountVoucher: boolean
  rescheduleId?: string
  newAnswer?: Answer[]
  isUserDataStale?: boolean
}

export interface UserInfo {
  eligibility: boolean
  firstName: string
  lastName: string
  owner: boolean
  plan: number | null
  birthDate?: string
  phone?: string
  prontuario: string
  email?: string
  // imgThumb: string
  questionnaire?: string
  singleSchedule?: boolean
  sex?: string
  nationality?: string
  document: string
  products: string | string[]
  step: number
  stepStatus: string
  stepDescription: string
}

export interface ScheduleInfo {
  selectedClinic?: number | string
  modality?: string
  rescheduleId?: string
  appointmentData?: {
    scheduleId?: string
    IdPaciente?: string
    locationId?: string
    unityId?: string
    resourceId?: string
    serviceId?: string
    medicalRecord?: string
    // IdConvenio?: string
    // IdEscritorio?: string
    // IdPlano?: string
    document?: string
  }
  teleconsultationData?: {
    appointment?: string
    birth?: string
    document?: string
    documentType?: string
    idConvenio?: string
    insuranceId?: string
    name?: string
    email?: string
    sex?: string
    phone?: string
  }
  day?: Date | string
  time?: number | string
  scheduleId?: string

}

export interface DependentInfo {
  _id: string
  name: string
  lastName: string
  email: string
  phone: string
  sex: string
  birthDate: string
  image: string
  isHolder: boolean
  nationality: string
}

export interface Answer {
  questionid: number
  questionAnswer: String
};

const user = JSON.parse(localStorage.getItem('user') ?? '[]');

export const initialState: ScheduleRootState = {
  // TIRAR - inicio
  user: {
    eligibility: false,
    firstName: user.firstName,
    lastName: user.lastName,
    prontuario: user.prontuario,
    owner: true,
    plan: user.plan,
    // imgThumb: user.imgThumb ?? '',
    products: '',
    document: user.document,
    questionnaire: '', // user.questionnaire
    singleSchedule: false, // user.singleSchedule
    step: 0,
    stepStatus: '',
    stepDescription: ''
  },
  isUserDataStale: false,
  userDataLoaded: false,
  dependents: [],
  modalVisible: false,
  newSchedule: {
    appointmentData: {
      IdPaciente: '',
      medicalRecord: user.prontuario,
      // IdConvenio: '',
      // IdEscritorio: '',
      // IdPlano: '',
      document: user.document
    },
    teleconsultationData: {
      appointment: '',
      birth: user.birthDate,
      document: user.document,
      documentType: user.documentType,
      idConvenio: IdConvenio,
      insuranceId: InsuranceId,
      name: user.name,
      email: user.email,
      sex: user.sex,
      phone: user.phone
    }
  },
  // TIRAR - fim
  step: 'choose-appointment-type',
  items: [
    {
      title: 'Uma solicitação médica é necessária para a realização do Predicta One e Infinity?',
      content: 'Não, a compra pode ser feita diretamente pelo site, sem necessidade de prescrição médica.'
    },
    {
      title: 'Quem pode fazer o Predicta One e Infinity?',
      content: 'Pacientes saudáveis que querem utilizar a medicina preventiva ao seu favor. Este exame é contra-indicado para pacientes que realizaram transplante de medula óssea e menores de 18 anos.'
    },
    {
      title: 'Descobri uma alteração genética e não tenho nenhuma doença relacionada, como fazer?',
      content: 'Pacientes que descobrem ser portadores de alterações genéticas têm a oportunidade de fazer um acompanhamento individualizado, com um planejamento de hábitos de vida específicos para diminuir sua chance de desenvolvimento de doenças relacionadas ou detecção precoce.'
    },
    {
      title: 'É possível eu ter alguma alteração genética e não desenvolver a doença?',
      content: 'Sim, algumas pessoas podem apresentar certas alterações genéticas em determinados genes que estão associados a diversas condições genéticas e, não necessariamente, isso significa que essas pessoas certamente irão desenvolver essas doenças. Por isso é muito importante que os resultados do Predicta sejam discutidos em consulta com médico geneticista.'
    },

    {
      title: 'Preciso consultar um médico após realizar o Predicta One?',
      content: 'Recomendamos fortemente que os resultados sejam analisados por um médico de sua preferência. Temos uma equipe médica especializada para que você agende uma consulta, clique no ícone do Whatsapp e escolha a opção Predicta.'
    },
    {
      title: 'Conhecer minhas informações genéticas possibilita um melhor planejamento para ter filhos?',
      content: 'Sim. Com essa informação e acompanhamento em serviços especializados, é possível estimar a chance de cada filho herdar a(s) alteração(ões) genética(s) identificada(s) pelo Predicta e conhecer o significado desse cenário para cada filho.'
    },
    {
      title: 'Meu DNA muda durante a vida?',
      content: 'Não. O DNA que nasceu com você irá acompanhá-lo durante toda a vida, o que muda é o funcionamento dos seus genes (expressão gênica) que dependerá da interação com o meio ambiente.'
    },
    {
      title: 'É necessário ficar em jejum para fazer um teste genético?',
      content: 'Não. Como o exame avalia genes, estar ou não em jejum não afeta o resultado.'
    },
    {
      title: 'Qual o prazo para receber o resultado do meu teste?',
      content: 'Nossos prazos tem início a partir do recebimento da amostra ou da coleta de sangue em nosso laboratório. Veja os prazos: <br /> <br /> Predicta Infinity - 60 dias a partir do dia em que você coletou sua amostra de sangue em nosso laboratório.<br /><br />  Predicta One - 30 dias a partir do dia em que nosso laboratório receber a amostra que você enviou por correio.'
    }

  ],
  purchase: {
    cpf: '',
    registered: 0,
    token: '',
    refreshToken: '',
    name: '',
    lastName: '',
    prontuario: '',
    error: ''
  },
  fullDiscountVoucher: false,
  load: false,
  reload: false,
  answers: []
};

const scheduleReducer = (state: ScheduleRootState = initialState, action: ScheduleRootAction): ScheduleRootState => {
  // console.log(state);
  switch (action.type) {
    case OPEN_MODAL: // Abre modal
      return {
        ...state,
        modalVisible: true
      };

    case CLOSE_MODAL: // Fecha modal
      return {
        ...state,
        modalVisible: false,
        step: 'choose-appointment-type',
        newSchedule: {
          ...state.newSchedule,
          rescheduleId: action.rescheduleId
        }
      };

    case NEW_SINGLE_APPOINTMENT: // Abre modal - tela para consulta avulsa
      return {
        ...state,
        step: 'schedule-single-appointment',
        modalVisible: true
      };

    case NEW_SINGLE_APPOINTMENT_SUCCESS: // Abre modal - tela para consulta avulsa
      return {
        ...state,
        step: 'schedule-single-appointment-success',
        modalVisible: true
      };

    case RESET_NEW_SCHEDULE: // Reinicia os steps de novo agendamento
      return {
        ...state,
        step: 'choose-appointment-type',
        newSchedule: {
          ...state.newSchedule,
          rescheduleId: action.rescheduleId
        }
      };

    case RESCHEDULE: // Reinicia os steps de novo agendamento
      return {
        ...state,
        newSchedule: {
          ...state.newSchedule,
          rescheduleId: action.rescheduleId
        }
      };

    case NEW_SCHEDULE: // Inicia novo agendamento online
      return {
        ...state,
        step: 'choose-online-or-presential'
      };

    case NEW_SAMPLE_COLLECTION: // Inicia novo agendamento online
      return {
        ...state,
        step: 'choose-clinic',
        newSchedule: {
          ...state.newSchedule,
          day: ''
        }
      };

    case NEW_STATE_COLLECTION: // Inicia novo agendamento online
      return {
        ...state,
        step: 'choose-state'
      };

    case NEW_ONLINE_SCHEDULE: // Inicia novo agendamento online
      return {
        ...state,
        step: 'schedule-choose-day',
        newSchedule: {
          ...state.newSchedule,
          modality: 'online'
        }
      };

    case NEW_PRESENTIAL_SCHEDULE: // Inicia novo agendamento presencial
      return {
        ...state,
        step: 'choose-clinic',
        newSchedule: {
          ...state.newSchedule,
          modality: 'presential'
        }
      };

    case NEW_PRESENTIAL_SCHEDULE_DAY: // Inicia novo agendamento presencial
      return {
        ...state,
        step: 'schedule-choose-day',
        newSchedule: {
          ...state.newSchedule,
          appointmentData: {
            ...state.newSchedule?.appointmentData,
            locationId: String(action.local)
          }
        }
      };

    case NEW_SCHEDULE_SET_CLINIC: // Seleciona o dia do agendamento - DIA
      return {
        ...state,
        newSchedule: {
          ...state.newSchedule,
          appointmentData: {
            ...state.newSchedule?.appointmentData,
            unityId: action.idLocal
          }
        }
      };

    case NEW_SCHEDULE_SET_DAY: // Seleciona o dia do agendamento - DIA
      return {
        ...state,
        newSchedule: {
          ...state.newSchedule,
          day: format(action.day, 'yyyy-MM-dd')
        }
      };

    case NEW_SCHEDULE_STEP_TIME: // Seleciona o dia do agendamento PRESENTIAL - HORA
      return {
        ...state,
        step: 'schedule-choose-time'
      };

    case NEW_SCHEDULE_SET_TIME: // Seleciona o dia do agendamento - DIA
      return {
        ...state,
        newSchedule: {
          ...state.newSchedule,
          time: action.time,
          appointmentData: {
            ...state.newSchedule?.appointmentData,
            scheduleId: action.idAgenda,
            locationId: action.idLocal?.toString(),
            resourceId: action.resourceId?.toString(),
            serviceId: action.serviceId?.toString()
          },
          teleconsultationData: {
            appointment: `${state.newSchedule?.day} - ${action.time}`,
            ...state.newSchedule?.teleconsultationData
          }
        }
      };

    case CONFIRMATION_STEP: // Seleciona o dia do agendamento PRESENTIAL - HORA
      return {
        ...state,
        step: 'confirm-schedule'
      };

    case CONFIRMATION_STEP_INFO: // Seleciona o dia do agendamento
      return {
        ...state,
        step: 'confirm-schedule-info'
      };
    case SCHEDULE_SEND: // Seleciona o dia do agendamento PRESENTIAL - HORA
      return {
        ...state,
        step: 'schedule-done'
      };

    case SCHEDULE_ERROR: // Seleciona o dia do agendamento PRESENTIAL - HORA
      return {
        ...state,
        step: 'schedule-error'
      };

    case FILTER: // Filtra os itens
      if (action.search === '') {
        const filtered = state.items;
        return {
          ...state,
          filtered
        };
      } else {
        const filtered = state.items.filter(
          // eslint-disable-next-line array-callback-return
          (item: Item) => {
            if (item.title.toLowerCase().includes(action.search.toLowerCase()) || item.content.toLowerCase().includes(action.search.toLowerCase())) {
              return item;
            }
          });
        return {
          ...state,
          filtered
        };
      }
    case FIRST_ITEMS: // Retorna os x primeiros itens
      if (action.number === 0) {
        const filtered = state.items;
        return {
          ...state,
          filtered
        };
      } else {
        // eslint-disable-next-line no-case-declarations
        const filtered = state.items.slice(0, action.number);
        return {
          ...state,
          filtered
        };
      }

    case IS_REGISTERED: // define cpf e registro
      return {
        ...state,
        purchase: {
          cpf: action.search,
          registered: action.number,
          error: ''
        }
      };

    case LOGIN_PURCHASE: // define token de login
      return {
        ...state,
        purchase: {
          cpf: state.purchase?.cpf,
          registered: state.purchase?.registered,
          token: action.search,
          refreshToken: action.param,
          error: action.error,
          errors: action.errors
        }
      };
    case PURCHASE_PRONTUARIO: // define Prontuário e demais dados do usuário
      return {
        ...state,
        purchase: {
          cpf: action.param ? action.param : state.purchase?.cpf,
          registered: state.purchase?.registered,
          token: state.purchase?.token,
          refreshToken: state.purchase?.refreshToken,
          name: action.name,
          lastName: action.lastName,
          prontuario: action.prontuario
        }
      };
    case USER_PRODUCT: // define produto
      return {
        ...state,
        user: {
          eligibility: action.state.user?.eligibility,
          firstName: action.name,
          owner: true,
          // imgThumb: action.state.user.imgThumb ?? '',
          questionnaire: action.state.user.questionnaire,
          singleSchedule: action.state.user.singleSchedule,
          plan: action.state.user.plan,
          products: action.param,
          lastName: action.lastName,
          prontuario: action.prontuario,
          sex: action.state.user.sex,
          email: action.state.user.email,
          phone: action.state.user.phone,
          birthDate: action.state.user.birthDate,
          nationality: action.state.user.nationality,
          document: action.state.user.document,
          step: action.state.user.step,
          stepStatus: action.state.user.stepStatus,
          stepDescription: action.state.user.stepDescription
        }
      };
    case IS_USER_DATA_STALE:
      return {
        ...state,
        isUserDataStale: action.isUserDataStale ?? false
      };
    case USER_DATA_LOADED:
      return {
        ...state,
        userDataLoaded: true
      };
    case SET_LOAD: // controle do load da dash
      return {
        ...state,
        load: action.load
      };
    case SET_RELOAD: // controle do reload dos componentes
      return {
        ...state,
        reload: action.load
      };
    case SET_FULL_DISCOUNT_VOUCHER: // controle de exibição dos componentes na tela de pagamento
      return {
        ...state,
        fullDiscountVoucher: action.fullDiscountVoucher
      };
    case DEPENDENTS_LIST: // controle do load da dash
      return {
        ...state,
        dependents: action.state.dependents && action.state.dependents.length > 0 ? action.state.dependents : state.dependents
      };
    case DEPENDENT_ADD: // controle do load da dash
      if (action.state.currentDependent) { state.dependents.push(action.state.currentDependent); }
      return {
        ...state
      };

    case ADD_ANSWER: // adiciona uma resposta ao objeto
      return {
        ...state,
        answers: action.newAnswer
      };

    default:
      // eslint-disable-next-line no-case-declarations
      const filtered = state.items;
      return {
        ...state,
        filtered
      };
  }
};

export const store = createStore(scheduleReducer, applyMiddleware(thunk));
