import styled from 'styled-components';

interface StyleProps {
  isError: boolean
  isSuccess: boolean
}

const GetColor = (props: StyleProps) => {
  if (props.isError) return '#707070';
  if (props.isSuccess) return '#004F92';
  return '#707070';
};

export const InputText = styled.input`
    border: none;
    width: 100%;
    padding: 0.6rem;
    font-size: 1rem;
    border-radius: 0.3rem;
    color: ${(props: StyleProps) => GetColor(props)};
    :focus{
      outline: none;
    }
`;
