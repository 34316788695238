import styled from 'styled-components';
type props = {
  show: boolean
};
export const PasswordForm = styled.div<props>`
  height: ${props => props.show ? '450px' : '0px'};
  padding-left: 10px;
  transition: height 0.6s ease;
  overflow: hidden;

  .success{
    color: #5cb85c;
    font-size: .85rem;
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 600;
  }

  .error{
    color: #F53748;
    font-size: .75rem;
    margin-top: 10px;
  } 

  .error-list{
    color: #F53748;
    font-size: .75rem;
    margin-bottom: 6px;
    margin-top: 6px;
  }  

  .flex{
    display: flex;
    div:first-child{
      margin-right: 10px;
    }
  }
  input:focus{
    outline: none;  
    box-shadow: none;
  }
  input{
    width: 60%;
    height: 40px;
    padding: 10px;
    border-radius: 4px;
    margin-top: 15px;
    border: solid 1px #999999ba;
    ::placeholder {
      color: transparent;
    }
  }
  .required{
    border: solid 1px #F53748;
  }
  .form-group{
    transition: all .3s;
    width: 100%;
    height: 60px;
  }
  .form-label{
    color: #999999ba;
    font-size: 14px;
    padding-right: 4px;
    padding-left: 4px;
    width: fit-content;
    display:block;
    margin-left: 10px;
    transform: translateY(-30px);
    transition: all .3s;
    background: #fff;
    position: relative;
    z-index: 1;
    transform-origin: 0 0;
  }
  .form-control{
    transition: all .5s;
    position: relative;
    z-index: 2;
    background: transparent;
  }
  .form-control:focus{
    color: #707070;
  }
  .form-control:focus + .form-label,
  .form-control:not(:placeholder-shown) + .form-label
  {
    transform: translateY(-46px) scale(.7);
    font-weight: 500;
    z-index: 3;
    color: #707070;

  }
  .form-control:focus,
  .form-control:not(:placeholder-shown)
  {
    color: #707070;
  }
  button{
    margin-top: 20px;
    border-radius: 30px;
    width: 100%;
    max-width: 260px;
    height: 2.5rem;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .85rem;
    font-weight: 600;
    background-color: #009DFF;
    color: #fff;
  }
  @media(max-width: 778px){
    input{
      width: 100%;
    }
  }
`;
export const Rules = styled.div`
  display: flex;
  font-size: .75rem;
  color: #666666;
  margin-top: 10px;
  div{
    margin-right: 1.25rem;
  }
  svg{
    margin-right: 5px;
    width: .8rem;
    path{
      stroke: #3EB49D;
    }
  }
`;
