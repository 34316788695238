import { ReactElement, useState } from 'react';
import ModalPlan from '../../ui/ModalPlan';
import SitePlan from '../../ui/Plan';
type props = {
  plan: number
  oldValue?: string
  newValue: string
  parcel?: string
  parcelValue?: string
  children?: ReactElement
  modalChildren?: ReactElement
  isMain?: boolean
};
export default function Plan(props: props): ReactElement {
  const [open, setOpen] = useState(false);
  return (
    <>
      <ModalPlan children={props.modalChildren} open={open} setOpen={setOpen} plan={props.plan} oldValue={props.oldValue} newValue={props.newValue} parcel={props.parcel} parcelValue={props.parcelValue} />
      <SitePlan setOpen={setOpen} plan={props.plan} oldValue={props.oldValue} newValue={props.newValue} parcel={props.parcel} parcelValue={props.parcelValue} isMain={props.isMain}>
        {props.children}
      </SitePlan>
    </>
  );
}
