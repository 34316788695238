import { ReactElement, useEffect, useState } from 'react';

import { CalendarWrapper } from './styles';
import Calendar, { CalendarTileProperties } from 'react-calendar';
import { parseISO, isSameDay, addHours } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { NEW_SCHEDULE_SET_DAY, ScheduleRootState } from '../../store/redux';

type CalendarProps = {
  functional?: boolean
  days?: string[]
  setSelectedDate: (date: Date) => void
  dateSelected: Date
};

export default function CalendarSchedule({ days, functional, setSelectedDate, dateSelected }: CalendarProps): ReactElement {
  const dispatch = useDispatch();
  // const [dateSelected, setDateSelected] = useState<Date>(new Date());
  const daySelected = useSelector<ScheduleRootState, string | Date | undefined>(scheduleReducer => scheduleReducer.newSchedule?.day);

  const tileClass = ({ date }: CalendarTileProperties): string => {
    let classes = '';
    const dateOverwrite = addHours(new Date(String(daySelected)), 3);
    const compareDate = date;

    if (functional && isSameDay(dateOverwrite, compareDate)) {
      classes += ' selected';
    } else if (!functional && isSameDay(dateSelected, compareDate)) {
      classes += ' selected';
    }

    return classes;
  };

  const tileDisabled = ({ date }: CalendarTileProperties): any => {
    return !days?.find(dDate => isSameDay(parseISO(dDate), date));
  };

  const handleDate = (value: Date): void => {
    setSelectedDate(value);
    dispatch({ type: NEW_SCHEDULE_SET_DAY, day: value });
  };

  return (
    <CalendarWrapper>
      <Calendar
        onChange={functional ? handleDate : setSelectedDate}
        value={dateSelected}
        tileClassName={tileClass}
        tileDisabled={days ? tileDisabled : () => { }}
        minDate={new Date()}
        prev2Label={null}
        next2Label={null}
        view={'month'}
      />
    </CalendarWrapper>
  );
}
