import React, { ReactElement } from 'react';
import Routes from './router/routes';

import { Provider } from 'react-redux';
import { store } from './store/redux';
import VoucherProvider from './contexts/Voucher/Provider';
import AuthProvider from './contexts/Auth/Provider';
import { TotalValuesProvider } from './hooks/totalValues';
import { InstallmentsProvider } from './hooks/installments';
import { AcceptCookies } from './components/AcceptCookies';
import { LoginProcessor } from './components/LoginProcessor';

function App(): ReactElement {
  return (
    <Provider store={store}>
      <AuthProvider>
        <LoginProcessor>
          <VoucherProvider>
            <InstallmentsProvider>
              <TotalValuesProvider>
                <Routes />
                <AcceptCookies />
              </TotalValuesProvider>
            </InstallmentsProvider>
          </VoucherProvider>
        </LoginProcessor>
      </AuthProvider>
    </Provider>
  );
}

export default App;
