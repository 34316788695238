import visa from '../assets/payment-icons/visa.svg';
import mastercard from '../assets/payment-icons/mastercard.svg';
import amex from '../assets/payment-icons/amex.svg';
import diners from '../assets/payment-icons/diners.svg';
import elo from '../assets/payment-icons/elo.svg';

export default [
  {
    id: 1,
    icon: visa,
    label: 'VISA'
  },
  {
    id: 2,
    icon: mastercard,
    label: 'MASTER'
  },
  {
    id: 3,
    icon: amex,
    label: 'AMERICAN_EXPRESS'
  },
  {
    id: 33,
    icon: diners,
    label: 'DINERS'
  },
  {
    id: 41,
    icon: elo,
    label: 'ELO'
  }
];
