/* eslint-disable import/no-extraneous-dependencies */
import styled, { css } from 'styled-components';
import { Properties } from 'csstype';

export const TotalWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  margin-top: 2rem;
  padding: 0.6rem 1rem;
  border-radius: 0.3rem;
  background-color: #f2f5f4;
  justify-content: space-between;
`;

export const LabelTotal = styled.div<{ color?: Properties['color'] }>`
  color: #464646;
  font-weight: 600;
  font-size: 1.1rem;
  letter-spacing: -0.18px;
  margin: 5px 0;

  ${({ color }) => css`
    color: ${color};
  `}
`;
