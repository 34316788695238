import { ReactElement } from 'react';

import { BannerContainer, PrincipalContainer, Header, BannerImage } from './styles';
import dna_background from '../../assets/Site/dna-background.png';

type props = {
  productName: string
  productSecondName: string
  title: string
  // description: string
  image: string
  textButton: string
  linkButton: string
};

export default function BannerPlans(props: props): ReactElement {
  return (
    <BannerContainer>
      <PrincipalContainer>
        <div>
          <Header>
            <h1>
              {props.productName}
              {props.productSecondName ? <><br /><span className='second'>{props.productSecondName}</span></> : ''}
            </h1>

            <hr></hr>

            <h3>
              {props.title}
            </h3>
          </Header>
          <a href={props.linkButton}>
            {props.textButton}
          </a>
        </div>
      </PrincipalContainer>

      <BannerImage>
        <img src={props.image} />
      </BannerImage>

      <img className='BackgroundDNA' src={dna_background} />
    </BannerContainer>
  );
}
