import React, { ReactElement, useState } from 'react';
import ModalPredicta from '../../ui/ModalPredicta';

import {
  SecurityCard
} from './styles';

type props = {
  icon: ReactElement
  title: string | ReactElement
  description: string
  buttonTxt: string
  modalChildren?: ReactElement
  id: string
};
export default function SecCard(props: props): ReactElement {
  const [open, setOpen] = useState(false);
  return (
    <>
      <ModalPredicta children={props.modalChildren} open={open} setOpen={setOpen} title={props.title} />

      <SecurityCard>
        <h3>{props.title}</h3>
        {props.icon}
        <p>
          {props.description}
        </p>
        <button id={props.id} onClick={() => {
          setOpen(true);
        }}>{props.buttonTxt}</button>
      </SecurityCard>
    </>
  );
}
