const ID_PREDICTA_ONE = process.env.REACT_APP_PREDICTA_PRODUCT_ONE_ID as string;
const ID_PREDICTA_INFINITY = process.env
  .REACT_APP_PREDICTA_PRODUCT_INFINITY_ID as string;
const ID_GENETIC_CONSULTATION = process.env
  .REACT_APP_PREDICTA_GENETIC_CONSULTATION as string;

export const diferentialsPlans = [
  {
    idPlan: ID_PREDICTA_ONE,
    diferentials: [
      {
        description:
          'Receba seu kit de coleta de saliva em qualquer lugar do Brasil',
        index: 0
      },
      {
        description: 'Serão avaliadas alterações em 147 genes',
        index: 1
      },
      {
        description:
          ' O teste avalia risco aumentado de origem genética para 15 tipos de câncer, 12 doenças ardiovasculares utras 7 doenças genéticas',
        index: 2
      },
      {
        description:
          'Laudo fornecido sem burocracia para ser avaliado pelo médico de sua preferência',
        index: 3
      }
    ]
  },
  {
    idPlan: ID_PREDICTA_INFINITY,
    diferentials: [
      {
        description:
          'A sua amostra de sangue deverá ser coletada em nossos laboratórios do Hospital Israelita Albert Einstein ',
        index: 0
      },
      {
        description:
          'Sequenciamento do genoma completo com avaliação de risco para o desenvolvimento de condições genéticas em 563 genes',
        index: 1
      },
      {
        description:
          'Consulta com médico geneticista pré e pós-teste, com avaliação do histórico clinico do paciente e esclarecimento quanto ao resultado do teste',
        index: 2
      },
      {
        description:
          'O resultado do teste é atualizado 1 vez ao ano durante 4 anos, juntamente com a orientação de um médico geneticista em consulta',
        index: 3
      },
      {
        description:
          'Análise de alterações genéticas para verificar quais os medicamentos são mais efetivos, ou contra-indicados, para o seu organismo. São analisadas substâncias voltadas para doenças cardiovasculares, hematológicas, gástricas, infecciosas, neurológicas, oncológicas e psiquiátricas.',
        index: 5
      }
    ]
  },
  {
    idPlan: ID_GENETIC_CONSULTATION,
    diferentials: [
      {
        description:
          'Os médicos geneticistas são profissionais altamente especializados que trabalham com indivíduos e famílias buscando compreender o impacto dos genes na saúde humana.',
        index: 0
      },
      {
        description:
          'O aconselhamento genético pode te ajudar a compreender riscos de doenças hereditárias, investigar histórico clínico familiar e auxiliar na escolha de testes a serem realizados.',
        index: 1
      },
      {
        description:
          'Agende sua consulta com um de nossos médicos geneticista',
        index: 2
      }
    ]
  }
];
