import React, { useState, useRef } from 'react';
import { AccordionSection, Button, Content, Text, BtnCollapse } from './styles';

export interface AccordionProps {
  index: number
  title: String
  children: any
}

export const Accordion: React.FC<AccordionProps> = ({
  title = 'teste',
  children = 'testeChildren'
}) => {
  const [active, setActive] = useState(false);

  const [height, setHeight] = useState('0');

  const content = useRef(document.createElement('div'));

  function toggleAcordion(): void {
    setActive(!active);
    setHeight(active ? '0' : `${content.current.scrollHeight}`);
  }
  return (
    <AccordionSection>
      <Button active={active} onClick={toggleAcordion}>
        <p>{title}</p>
        <BtnCollapse active={active} />
      </Button>
      <Content ref={content} maxHeight={height}>
        <Text>{children}</Text>
      </Content>
    </AccordionSection>
  );
};
