import styled from 'styled-components';
type props = {
  open?: boolean
  plan?: number
};
export const ModalBkg = styled.div<props>`
    ${props => !props.open ? 'display: none' : 'display:flex'};    
    background: rgba(0,0,0, 0.6);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    bottom:0;
    top: 40px;
    z-index: 29;
    align-items:center;
    justify-content:center;
    // overflow-y: hidden;
    padding: 0 50px;

    @media(max-width: 768px){
      top: 40px;
      padding: 0;
    }
`;
export const Modal = styled.div`
  position: relative;
  width:65%;
  background: white;
  padding: 20px 50px;

  border-radius: 10px;
  height: 600px;
  overflow-y: auto;
  @media(max-width: 1024px){
    width: 90%;
    height: 80vh;
    padding: 20px 15px;
  }
  text-align: center;
    margin-bottom: 20px;
    
    h2 {
      color: #000000;
    }

    > div {
      p:first-child {
        margin-bottom: 7px;
      }
    }
    background-color: #fff;
    .green {
      color: #00b193;
      font-weight: 600;
    }
    p {
      font-size: 1.12rem !important;
      font-weight: 500 !important;
      color: #000000 !important;
    }
    h2 {
      font-size: 2.5rem;
      color: #112c40;
      font-weight: 300;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    @media (min-width: 1025px) {
      zoom: 80%;

      .green {
        display: block;
      }
    }
`;
export const Header = styled.div`
  display:flex;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0,0,0, 0.1);
  button{
    height: 20px;
    margin-left:auto;
    svg{
      color: #707070;
      height: 20px;
    }
  }
  @media(max-width: 360px){
    margin-left: -55px;
  }
`;
export const Body = styled.div<props>`
  display:flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 50px;

  h2{
    font-size: 32px !important;
    margin-bottom: 20px !important;
  }

  @media(max-width: 1024px){
    padding-top: 20px;
  }
`;

export const List = styled.div<props>`
  margin: 0 auto;
  li{
    display:flex;
    align-items: center;
    font-size: 16px;
    line-height: 1.5rem;
    color: #707070;
    font-weight: 600;
    text-align: justify;
    margin: 0 auto;
    width: 100%;
    
    >p{
      margin: 15px 0;
      width: 100%;
    }
    
    svg{
      margin-right: 10px;
      height: 22px;
    }
    .blue-check{
      margin-right: 10px;
      min-width: 23px;
      width: 23px;
      height: 23px;
      border-radius: 23px;
      background: rgb(30, 125, 221, 0.197);
      display: flex;
      align-items: center;
      justify-content: center;
      svg{
        width: 14px;
        height: 12px;
        margin: 0;
        path{
          stroke: rgb(30, 125, 221);
        }
      }
    }
  }
  @media(max-width: 1024px){
    max-width: 90%;
    margin: 0 auto;
    ul{
      margin-top: 20px;
      padding-right: 10px;
      li{
        p{
          line-height: 25px;
        }
      }
    }
  }
`;

export const ListUnits = styled.div`
  text-align: left;

  h3{
    margin-top: 20px;
    margin-bottom: 10px;
  }

  ul{
    margin-top: 0;
  }

  ul li p {
    line-height: 0.2;
  }
`;

export const ButtonWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  display:flex;
  justify-content: center;
  margin-bottom: 20px;
  .btn{
    width: 315px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.12rem;
    font-weight: 600;
    border-radius: 20px;
    background-color: #00B193;
    color: #fff;
  }
`;
export const ProductText = styled.span`
    margin-left: 50px;
    display: flex;
    align-items: center;
    color: #707070;
    font-weight: 600;
    font-size: 30px;
    b{
      font-size: 32px;
      color: #F4F7FA;
      text-align: center;
      line-height: 59px;
      background: red;
      display: block;
      border-radius: .5rem;
      width: 59px;
      height: 59px;
      margin-right: .5rem;
      background-color: #00B193;
    }
`;
