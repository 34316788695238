import styled from 'styled-components';

export const MiniLoad = styled.div`
div{
    height:25px;
    width:25px;
    left:0px;
    top:0px;
  }
  div: after{
    height:30px;
    width:30px;
   
  }
`;
export const ButtonUploadStyled = styled.div`
  height: 35px;
  width: 7.5rem;
  cursor: pointer;
  border: solid 1px #009DFF; 
  border-radius: 30px;
  color: #009DFF;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: .75rem;
  white-space: nowrap;
  .hide{
    display: none
  }
  div{
    height:100%;
    cursor: pointer;
    display: flex;
    align-items: center;
  justify-content: center;
  }
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;          /* Likely future */      
  
`;
