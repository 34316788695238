/* eslint-disable */
import React, { useEffect } from "react"
import AppCookies from "../../utils/cookies";

export const LoginProcessor: React.FC = ({ children }) => {

  useEffect(() => {


    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const patientToken = params.get("patientToken");

    if (!(token && patientToken)) {
      return;
    }

    AppCookies.set('api_token', token);
    localStorage.setItem('api_token', token);
    AppCookies.set('api_patientToken', patientToken);
    localStorage.setItem('api_patientToken', patientToken);



    if (params.get('meta') === 'contratar') {
      window.location.assign('/contratar');
      return;
    }

    window.location.assign('/dashboard');



  }, [])

  return <>{children}</>
}
