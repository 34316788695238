import styled from 'styled-components';
type props = {
  open: boolean
};

type inputProps = {
  width?: string | number
};
export const ScheduleContent = styled.div`
    @media(min-width: 1024px){
      grid-template-columns: auto 450px;
    }
    min-height: 90vh;
    padding: 2rem;
    display: grid;
    grid-template: 
    "title nav"
    "main main";
    gap: 2rem;
    grid-template-rows: 50px auto;
`;

export const ScheduleContentMain = styled.section`
    grid-area: main;
`;

export const ScheduleContenSidebar = styled.section`
    grid-area: sidebar;
`;

export const Title = styled.div`
    grid-area: title;
    display: flex;
    justify-content: flex-start;
    h2{
      font-size: 22px;
      font-weight: 600;
    }
`;

export const Calendar = styled.div`
    display: flex;
    justify-content: center;
    padding-right: 30px;
    width: 400px;
`;

export const History = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding-right: 2rem;
`;

export const Button = styled.button`
    color:#009DFF;
    border:solid 1px #009DFF;
    padding:8px 20px 8px 20px;
    display: flex;
    align-items:center;
    border-radius: 25px;
    svg{
      margin-right: 5px;
    }
`;

export const ScheduleWrapper = styled.div`
    width: 100%;
    padding-right: 2rem;
    position:relative;
    margin-top: 30px;
`;
export const HistoricWrapper = styled.div`
    width: 100%;
    padding-right: 2rem;
    position:relative;
    margin-top: 30px;
`;

export const ButtonsWrapper = styled.div`
    grid-area: nav;
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;
    height: 44px;

    .buttonSlide--path{
        background-color: #fff;
        border: 2px solid #fff;
        color: #707070;
        border-radius: 2.5rem;
        position: relative;
        display: flex;
    }

    .buttonSlide--label,
    .buttonSlide--toggle{  
        width: 120px;     
        height: 40px; 
        padding: .75rem 1.25rem;
        font-size: .75rem;
        display: block;
        border-radius: 2.5rem;
        cursor: pointer;
    }

    .buttonSlide--toggle{
        position: absolute;  
        background-color: #009DFF;
        color: #fff;
        transition: all .3s ease;
        font-family: 'Montserrat',sans-serif;
        left: 0;
        right: 0;
        margin: auto;
    }

    .buttonSlide--toggle.left{
        transform: translateX(-50%)
    }

    .buttonSlide--toggle.right{
        transform: translateX(50%)
    }

`;

export const Load = styled.div`
  position: relative;
  left: 50%;
  top: 100px;
  width: 50px;
  height: 50px;
  border: 4px solid #80808042;
  border-radius: 50%;
  box-sizing: border-box;
  &:after {
    content: "";
    position: absolute;
    top: -4px;
    left: -4px;
    display: block;
    width: 50px;
    height: 50px;
    border: 4px solid transparent;
    border-top: 4px solid #004F92;
    border-radius: 50%;
    box-sizing: border-box;
    animation: spin 0.55s infinite ease-in-out;
  }
  }
 
  @keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
  }
`;

export const FilterWrapper = styled.div<props>`
    ${props => !props.open && 'display: none;'}    
    background: white;
    border-radius: 10px;
    padding: 30px 20px 20px 20px;
    margin-top:10px;
    box-shadow: 0px 6px 20px rgba(0,0,0, 0.2);
    position:absolute;
    z-index: 1;
    color: #707070;
    .form-control{
      margin-right:50px;
    }
`;
export const FormWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: .85rem;
    margin-bottom: 10px;
`;

export const ButtonsWrapperModal = styled.div`
    display: flex;
    bottom:0;
    padding-top: 15px;
    justify-content: flex-end;
    margin-right: auto;
    border-top: solid 1px #DCE1E5;
    
    .link{
      color: #009DFF;
    }
    .outline{
      color: #009DFF;
      border: solid 1px  #009DFF;
    }
    .primary{
      color: white;
      background: #009DFF;
    }
`;
export const ButtonModal = styled.button`
  border-radius: 5px;
  padding:10px;
  margin-right:10px;
  min-width: 95px;
`;
export const Label = styled.label`
  color: #000;
  font-size: 14px;
  font-weight: 600;
`;

export const Input = styled.input<inputProps>`
  border: solid 1px #707070;
  border-radius: 4px;
  height: 38px;
  width: ${props => props.width ? `${props.width}px` : 'auto'};
  padding-left:10px;
  margin-bottom: 10px;
  margin-top: 5px;
`;
export const CheckList = styled.div`
  .container{
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 15px;
    margin-top: 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #e6e6e6;
  }

  .container:hover input ~ .checkmark {
    background-color: #dedede;
  }

  .container input:checked ~ .checkmark {
    background-color: #009DFF;
    border-radius: 3px;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
