import styled from 'styled-components';

export const HelpContent = styled.div`
    @media(min-width: 1024px){
      min-height: 90vh;
      padding: 2rem;
      display: grid;
      grid-template: 
      "title title"
      "main sidebar";
      column-gap: 1.8rem;
      row-gap: 0.5rem;
      grid-template-rows: 50px auto;
      grid-template-columns: auto 350px;
    }
    .error{
      color: #F53748;
      font-size: .75rem;
      margin-bottom: 10px;
      margin-top: 10px;
    } 
    .error-list{
      color: #F53748;
      font-size:10px;
      margin-bottom: 6px;
      margin-top: 6px;
    } 
    .success{
      color: #5cb85c;
      font-size: 14px;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .required{
      border: solid 1px #F53748;
    }
    input:focus{
      outline: none;  
      box-shadow: none;
    }
    textarea:focus{
      outline: none;  
      box-shadow: none;
    }
    input {
      border: solid 1px #999999ba;
      border-radius: 8px;
      height: 50px;
      width: 100%;
      font-size: 14px;
      padding-left:10px;
      ::placeholder {
        color: #999999;
      }
    }
    @media(max-width: 1024px){
      min-height: 100vh;
      padding: 1rem;
    }
`;

export const HelpContentMain = styled.section`
    grid-area: main;
    background: white;
    padding: 2.5rem 1.5rem 1.5rem 1.5rem;
    border-radius: 10px;
`;
export const TextWrapper = styled.div`
  display:flex;
  justify-content: center;
  margin-bottom: 40px;
`;

export const Text = styled.div`
  @media(max-width: 1024px){
    width: 90%;
    h1{
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    p{
      font-size: 11px;
      line-height: 1.5rem;
    }
    text-align: center;  
  }
  @media(min-width: 1024px){
    width: 60%;
    h1{
      font-size: 40px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    p{
      font-size: 16px;
      line-height: 1.5rem;
    }
    text-align: center;
  }

`;

export const Title = styled.div`
    grid-area: title;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 12px;
    margin-left: 12px;
    h2{
      font-size: 22px;
      font-weight: 600;
    }
`;
export const HelpContenSidebar = styled.section`
    @media(max-width: 1024px){
      margin-bottom: 50px;
      margin-top: 10px;
    }
    grid-area: sidebar;
    background: white;
    padding: 1.4rem;
    border-radius: 10px;
    color: #707070;
    font-weight: 600;
    line-height: 1.5rem;
    p{
      margin-bottom: 20px;
    }
    
    .form-group{
      transition: all .3s;
      width: 100%;
      height: 60px;
      margin-bottom: 5px;
      margin-top: 5px;
    }
    .textarea{
      height: auto;
    }
    input {
      ::placeholder {
        color: transparent;
      }
    }
    .form-label{
      color: #999999ba;
      font-size: 13px;
      font-weight: 300;
      padding-right: 4px;
      padding-left: 4px;
      width: fit-content;
      display:block;
      margin-left: 10px;
      transform: translateY(-38px);
      transition: all .3s;
      background: #fff;
      position: relative;
      z-index: 1;
      transform-origin: 0 0;
    }
    .form-control{
      transition: all .5s;
      position: relative;
      z-index: 2;
      background: transparent;
    }
    .form-control:focus{
      color: #707070;
    }
    .form-control:focus + .form-label,
    .form-control:not(:placeholder-shown) + .form-label
    {
      transform: translateY(-60px) scale(.8);
      font-weight: 500;
      z-index: 3;
      color: #707070;
  
    }
    .form-control:focus,
    .form-control:not(:placeholder-shown)
    {
      color: #707070;
    }
`;
export const ContactWrapper = styled.div`
  display:flex;
  align-items:center;
  margin-bottom: 20px;
  font-size: .85rem;
`;
export const AccordionWrapper = styled.div`
  margin-top: 80px;
  border-top: solid 1px #B2B2B2;
  padding: 0 2rem;
  .span{
    color: #707070;
    margin-top: 10px;
  }
`;
export const CircleIcon = styled.div`
  min-width: 32px;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background: #009DFF;
  color: #fff;
  margin-right: 10px;
  text-transform: none;
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
    position: absolute;
  }

`;

export const TextArea = styled.textarea`
  border: solid 1px #999999ba;
  border-radius: 5px;
  height: 130px;
  width: 100%;
  font-size: 14px;
  padding-left:10px;
  padding-top:15px;
  margin-bottom: 10px;
  margin-top: 5px;
  color: #707070;
  ::placeholder {
    color: #999999ba;
    font-size: 14px;
    font-weight: 300;
  }
`;
