import React, { ReactElement, ReactNode } from 'react';
import Message from '../Message';
import { Form, StyleProps } from './style';

interface Props extends StyleProps {
  children: ReactNode | ReactNode[];
  isNotEmpty: boolean | undefined;
  message?: string;
}

export default function Wrapper({
  children,
  isError,
  isSuccess,
  isNotEmpty,
  message
}: Props) {
  return (
    <Form isError={isError} isSuccess={isSuccess}>
      {isNotEmpty && (
        <Message isError={isError} isSuccess={isSuccess} position="top" />
      )}
      {children}
      {isError && (
        <Message
          isError={isError}
          isSuccess={isSuccess}
          message={message}
          position="bottom"
        />
      )}
    </Form>
  );
}
