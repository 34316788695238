/* eslint-disable */
import { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ScheduleRootState, UserInfo } from '../../store/redux';
import userImagePng from '../../assets/user-image.png';
import { IconLogOff } from '../../assets/icons';

import {
  UserInfoWrapper,
  UserInfoImage,
  UserInfoName,
  UserInfoCard,
  Toggle
} from './styles';
import { moveToLogin } from '../../utils/moveToLogin';
import { getPatientProfileImg } from '../../utils/services';

export default function UserInfos(): ReactElement {
  const user: UserInfo = useSelector<ScheduleRootState, UserInfo>(
    (scheduleReducer) => scheduleReducer.user
  );
  const [isOpen, setIsOpen] = useState(false);
  const [userImage, setUserImage] = useState(null);
  const toggleUserCard = (): void => {
    setIsOpen(!isOpen);
  };
  function logoff(): void {
    localStorage.clear();
    moveToLogin(true);
  }

  const getProfileImage = async () => {
    const { url } = await getPatientProfileImg();
    setUserImage(url ?? null);
  };

  useEffect(() => {
    getProfileImage();
  }, [user]);

  return (
    <UserInfoWrapper>
      {/* <Notifications>
                <IconNotification />
            </Notifications> */}
      <UserInfoName>{`${user.firstName} ${user.lastName}`}</UserInfoName>
      <UserInfoImage
        src={userImage ?? userImagePng}
        size={3}
        margin={'0 1rem 0 0'}
        alt={user.firstName}
      />

      <Toggle onClick={toggleUserCard} isOpen={isOpen}></Toggle>

      {isOpen && (
        <UserInfoCard>
          <UserInfoImage
            src={userImage ?? userImagePng}
            size={4}
            margin={'0 0 1rem 0'}
            alt={user.firstName}
          />
          <UserInfoName>{`${user.firstName} ${user.lastName}`}</UserInfoName>
          {/* {user.owner && <span className="user-info--level">Principal</span>} */}
          <span className="user-info--level">Principal</span>
          <Link to="/meus-dados" className="user-info--manage-btn">
            Gerenciar Conta
          </Link>
          <Link to="/" className="user-info--logoff" onClick={() => logoff()}>
            <IconLogOff /> Fazer Logoff
          </Link>
        </UserInfoCard>
      )}
    </UserInfoWrapper>
  );
}
