/* eslint-disable @typescript-eslint/member-delimiter-style */
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import creditCardType from 'credit-card-type';
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import InputAdornment from '@material-ui/core/InputAdornment';

import { api, cepApi, peApi, predictaPaymentApi } from '../Payment/utils/api';

import SelectInput from '../Payment/Layout/Select';
import Input from './Input';

import {
  BirthDateMask,
  CardNumberMask,
  DocumentCPFMask,
  ExpirationDateMask,
  PhoneNumberMask,
  ZipCodeMask
} from '../Payment/masks';

import {
  formSchema,
  formSchemaOne,
  formSchemaSingle
} from '../Payment/schemas';

import getValidationErrors from '../Payment/utils/getValidationErrors';
import paymentIcons from '../Payment/utils/icons-payment';
import isCardNumberValid from '../Payment/utils/validateCreditCard';

import { useInstallments } from '../../../hooks/installments';
import { useAuth } from '../Payment/hooks/auth';
import { useLoading } from '../Payment/hooks/loading';
import { useWarning } from '../Payment/hooks/warning';

import { useDispatch, useSelector } from 'react-redux';
import { CONFIG } from '../../../config/env';
import { Rules } from '../../../enums';
import { CatalogProduct, Installments, Service } from '../../../interfaces';
import {
  NEW_SINGLE_APPOINTMENT_SUCCESS,
  ScheduleRootState,
  UserInfo
} from '../../../store/redux';
import AppCookies from '../../../utils/cookies';
import { BeneficiaryDto, ICreatePaymentOrder } from '../Payment';
import { Button, Container } from '../Payment/styles';

interface IAddress {
  zipCode: string;
  street: string;
  number: string;
  complement?: string;
  city: string;
  state: string;
}

interface IAddressBeneficiary {
  zipCode: string;
  street: string;
  number: string;
  complement?: string;
  city: string;
  state: string;
}

interface ICreditCard {
  cardNumber: string;
  expirationDate: string;
  securityCode: string;
}

interface IFormInput {
  installments: string;
  firstName: string;
  lastName: string;
  gender: string;
  birthDate: string;
  phoneNumber: string;
  nationality: string;
  document: string;
  rg: string;
  email: string;
  address: IAddress;
  creditCard: ICreditCard;
  documentB: string;
  firstNameB: string;
  lastNameB: string;
  genderB: string;
  birthDateB: string;
  phoneNumberB: string;
  nationalityB: string;
  rgB: string;
  emailB: string;
  addressBeneficiary: IAddressBeneficiary;
}

interface IBeneficiaryData {
  documentOwner: string;
  paymentId: string;
  planId: number;
  document: string;
  name: string;
  lastName: string;
  birthDate: string | undefined;
  sex: string | undefined;
  phone: string | undefined;
  nationality: string | undefined;
  documentType: string;
  email: string | undefined;
  zipCode: string | null;
  address: string | null;
  state: string | null;
  city: string | null;
  addressNumber: string | null;
  complement: string | null;
}

interface IPayment {
  nit: string;
  storeToken: string;
  merchantId: string;
  paymentId: string;
  token: string;
}

interface IEsitefResponse {
  code: any;
  message: any;
  payment: {
    authorizer_code: any;
    authorizer_message: any;
    status: any;
    nit: any;
    order_id: any;
    customer_receipt: any;
    authorizer_id: any;
  };
}

type IEsitefCreditCard = Omit<ICreditCard, 'expirationDate'> & {
  expirationMonth: string;
  expirationYear: string;
};

interface ITouched {
  [key: string]: boolean;
}

interface IErrors {
  [key: string]: string;
}

type props = {
  product: number;
};

const PAYMENT_API_KEY = process.env.REACT_APP_PAYMENT_API_KEY;

const PaymentForm = (props: props) => {
  const formRef = useRef<FormHandles>(null);
  const userInfo: UserInfo = useSelector<ScheduleRootState, UserInfo>(
    (scheduleReducer) => scheduleReducer.user
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const { service_id, access_token, source_platform, token, patient_token } =
    useAuth();
  const { product: product_id } = props;
  const { isLoading, startLoading, endLoading } = useLoading();
  const { showWarning } = useWarning();
  const { handleInstallments } = useInstallments();

  const [service, setService] = useState<Service>();
  const [products, setProducts] = useState<CatalogProduct[]>([]);
  const [value, setValue] = useState('');

  useEffect(() => {
    products.forEach((product) => {
      if (String(product.id) === String(value)) {
        const info = {} as Installments;

        product.rules.forEach((rule) => {
          const actualRule = rule.rule;
          if (
            actualRule.rule === Rules.INSTALLMENTS_ID &&
            actualRule.value === true
          ) {
            info.product_id = Number(product.id);
            info.hasInstallments = Boolean(rule.value);
          } else if (actualRule.rule === Rules.INSTALLMENTS_MAX_RULE_ID) {
            info.installmentsMax = Number(rule.value);
          } else if (actualRule.rule === Rules.INSTALLMENTS_NOT_FEES_ID) {
            info.installmentsNotFees = Number(rule.value);
          } else if (actualRule.rule === Rules.INSTALLMENTS_PERCENT_FEES_ID) {
            info.installmentsPercentFees = 1 + Number(rule.value) / 100;
          }
        });

        handleInstallments({
          product_id: product.id,
          price: Number(product.price),
          installmentsMax: info.installmentsMax ?? 0,
          installmentsNotFees: info.installmentsNotFees ?? 0,
          installmentsPercentFees: info.installmentsPercentFees ?? 0,
          hasInstallments: info.hasInstallments ?? false
        });
      }
    });
  }, [products, value, handleInstallments]);

  useEffect(() => {
    peApi
      .post('products', { service_id: CONFIG.PAYMENT_SERVICE_ID })
      .then((response) => {
        setService(response.data);
        setProducts(response.data.service_products);
        setValue(String(3));
      });
  }, []);

  const { installments } = useInstallments();

  const [cardType, setCardType] = useState('');
  const [creditCard, setCreditCard] = useState<IEsitefCreditCard>(
    {} as IEsitefCreditCard
  );
  const [chosedNationality, setChosedNationality] = useState('');
  const [installmentsList, setInstallmentsList] = useState<string[]>([]);

  const [touched, setTouched] = useState<ITouched>({} as ITouched);
  const [shrinked, setShrinked] = useState<ITouched>({} as ITouched);
  const [shrinkedB, setShrinkedB] = useState<ITouched>({} as ITouched);
  const [inputErrors, setInputErrors] = useState<IErrors>({} as IErrors);

  const [submissionAttempts, setSubmissionAttempts] = useState(1);

  const cleanValues = useMemo(() => /[^0-9]+/g, []);

  useEffect(() => {
    const installmentsValues = [];

    if (installments?.hasInstallments) {
      for (let i = 1; i <= installments.installmentsMax; i += 1) {
        if (i <= installments.installmentsNotFees) {
          const installmentWithoutFees = Number(installments.price / i).toFixed(
            2
          );

          const amountWithoutFees = Number(installments.price).toFixed(2);

          installmentsValues.push(
            `${i} x R$${installmentWithoutFees} = R$${amountWithoutFees}`
          );
        } else {
          const installmentWithFees = Number(
            (installments.price * installments.installmentsPercentFees) / i
          ).toFixed(2);

          const amountWithFees = Number(
            installments.installmentsPercentFees * installments.price
          ).toFixed(2);

          installmentsValues.push(
            `${i} x R$${installmentWithFees} = R$${amountWithFees} (com juros)`
          );
        }
      }
    }

    setInstallmentsList(installmentsValues);
  }, [installments]);

  function formatPhone(phoneNumber: string) {
    const cleanNumber = phoneNumber.replace(/\D/g, '');

    const formatedNumber = cleanNumber.replace(
      /^(\d{2})(\d{5})(\d{4})$/,
      '($1) $2-$3'
    );

    return formatedNumber;
  }

  const handleSubmit = useCallback(
    async (data: IFormInput) => {
      setSubmissionAttempts((prev) => prev + 1);
      let catalogo_product_id = '';

      try {
        const access_token = localStorage.getItem('api_tokenBuy');
        formRef.current?.setErrors({});
        setInputErrors({});

        if (product_id === 1) {
          // predicta one
          catalogo_product_id = process.env
            .REACT_APP_PREDICTA_PRODUCT_ONE_ID as string;

          await formSchemaOne.validate(data, { abortEarly: false });
        } else if (product_id === 2) {
          // predicta infinity
          catalogo_product_id = process.env
            .REACT_APP_PREDICTA_PRODUCT_INFINITY_ID as string;

          await formSchema.validate(data, { abortEarly: false });
        } else if (product_id === 3) {
          // predicta consulta avulsa
          catalogo_product_id = process.env
            .REACT_APP_PREDICTA_AVULSA_ID as string;

          await formSchemaSingle.validate(data, { abortEarly: false });
        }

        const lastDigits = data.creditCard.cardNumber
          .replace(cleanValues, '')
          .slice(-4);
        const phoneNumber = data.phoneNumber.replace(cleanValues, '');

        let document = '';

        if (data.nationality === 'brasileiro') {
          document = data.document.replace(cleanValues, '');
        } else if (data.nationality === 'estrangeiro') {
          document = data.document.replace(/[^0-9a-z]+/gi, '');
        }

        const zipCode = data.address.zipCode.replace(cleanValues, '');

        const day = data.birthDate.split('/')[0];
        const month = data.birthDate.split('/')[1];
        const year = data.birthDate.split('/')[2];

        let idNumber = data.rg;

        if (chosedNationality === 'estrangeiro') {
          idNumber = '';
        }

        const birthDate = `${year}-${`0${month}`.slice(-2)}-${`0${day}`.slice(
          -2
        )}`;

        const serviceId = process.env.REACT_APP_PREDICTA_SERVICE_ID as string;

        const [expirationMonth, expirationYear] =
          data.creditCard.expirationDate.split('/');

        const installmentsNumber = installmentsList.findIndex(
          (installment) => installment.toLowerCase() === data.installments
        );

        const documentType =
          data.nationality.toUpperCase() === 'ESTRANGEIRO'
            ? 'PASSAPORTE'
            : 'CPF';

        const beneficiaries: BeneficiaryDto[] = [];

        beneficiaries.push({
          name: `${data.firstName} ${data.lastName}`,
          birthday: data.birthDate.split('/').reverse().join('-'),
          gender: data.gender === 'masculino' ? 'M' : 'F',
          nationality:
            data.nationality === 'brasileiro' ? 'BRASILEIRO' : 'ESTRANGEIRO',
          document: data.document,
          email: data.email,
          ddi: '55',
          phone: data.phoneNumber.replace(cleanValues, ''),
          isOwner: true
        });

        const paymentOrder: ICreatePaymentOrder = {
          buyer: {
            document: data.document,
            name: `${data.firstName} ${data.lastName}`,
            email: data.email,
            gender: data.gender === 'masculino' ? 'M' : 'F',
            nationality: data.nationality.toUpperCase(),
            birthday: data.birthDate.split('/').reverse().join('-'),
            ddi: '55',
            phone: data.phoneNumber.replace(cleanValues, '')
          },
          beneficiaries,
          productId: product_id.toString(),
          billingAddress: {
            street: data.address.street,
            number: data.address.number,
            state: data.address.state,
            cep: zipCode,
            city: data.address.city,
            complement: data.address.complement ?? ''
          },
          card: {
            number: data.creditCard.cardNumber.replace(/ /g, ''),
            securityCode: data.creditCard.securityCode,
            expiration: data.creditCard.expirationDate.replace(/\//, ''),
            flag: cardType
          },
          installments: installmentsNumber + 1,
          installmentType: 'LOJA_SEM_JUROS',
          totalDependents: 1
        };

        if (!paymentOrder.productId) {
          showWarning({
            title: 'O Produto não foi escolhido!',
            description:
              'Por favor, escolha um produto para que o pagamento seja efetuado.',
            signal: 'warning'
          });
          window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
          startLoading();

          try {
            const response = await api.post(
              '/v2.0/process-payment',
              paymentOrder,
              {
                headers: {
                  'x-api-key': PAYMENT_API_KEY,
                  authorization: `Bearer ${token}`,
                  'x-access-token': `Bearer ${access_token}`,
                  'x-access-patient-token': `Bearer ${patient_token}`
                }
              }
            );

            const beneficiaryData: IBeneficiaryData = {
              documentOwner:
                userInfo.nationality === 'brasileiro'
                  ? data.document.replace(cleanValues, '')
                  : '',
              paymentId: response.data.id,
              planId: product_id,
              document:
                userInfo.nationality === 'brasileiro'
                  ? userInfo.document.replace(cleanValues, '')
                  : userInfo.document,
              name: userInfo.firstName,
              lastName: userInfo.lastName,
              birthDate: userInfo.birthDate
                ? userInfo.birthDate.split('/').reverse().join('-')
                : undefined,
              sex: userInfo.sex
                ? userInfo.sex.charAt(0).toUpperCase()
                : undefined,
              phone: userInfo.phone
                ? formatPhone(userInfo.phone.replace(cleanValues, ''))
                : undefined,
              nationality: userInfo.nationality,
              documentType:
                userInfo.nationality === 'brasileiro' ? 'cpf' : 'passaporte',
              email: userInfo.email,
              zipCode: null,
              address: null,
              state: null,
              city: null,
              addressNumber: null,
              complement: null
            };

            await predictaPaymentApi.post('/payment/insert', beneficiaryData, {
              headers: {
                'x-access-token': `Bearer ${token}`,
                'x-access-patient-token': `Bearer ${AppCookies.get(
                  'api_patientToken'
                )}`
              }
            });

            endLoading();
            dispatch({ type: NEW_SINGLE_APPOINTMENT_SUCCESS });
          } catch (err: any) {
            showWarning({
              signal: 'error',
              title: 'Falha ao realizar Pagamento',
              description: err.response.data.error
            });
            endLoading();
          }
        }
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          setInputErrors(errors);
        } else {
          showWarning({ code: '3959', signal: 'error' });
          if (submissionAttempts === 3) {
            history.push('/attempts-exceeded');
          }
        }

        endLoading();
      }
    },
    [
      cardType,
      cleanValues,
      access_token,
      product_id,
      service_id,
      endLoading,
      startLoading,
      chosedNationality,
      showWarning,
      submissionAttempts,
      history,
      source_platform,
      token,
      installmentsList,
      patient_token
    ]
  );

  const handleCreditCard = useCallback(
    (event: ChangeEvent<{ value: string }>, name: string) => {
      const { value } = event.target;
      const cleanNumber = value.replace(cleanValues, '');

      if (name === 'expirationDate') {
        const [expirationMonth, expirationYear] = value.split('/');

        setCreditCard((prev) => ({ ...prev, expirationMonth }));
        setCreditCard((prev) => ({ ...prev, expirationYear }));
      }

      if (name === 'securityCode') {
        setCreditCard((prev) => ({ ...prev, securityCode: value }));
      }

      if (name === 'cardNumber') {
        if (isCardNumberValid(Number(cleanNumber))) {
          const cardBrand = creditCardType(cleanNumber);
          setCreditCard((prev) => ({ ...prev, cardNumber: cleanNumber }));
          if (cardBrand.length !== 0) {
            if (cardBrand[0].type === 'visa') {
              setCardType('VISA');
            } else if (cardBrand[0].type === 'mastercard') {
              setCardType('MASTER');
            } else {
              showWarning({
                title: 'Bandeira não aceita',
                description:
                  'Por favor, utilize um cartão de crédito MASTERCARD ou VISA.',
                signal: 'warning'
              });
            }
          }

          if (!cleanNumber) {
            setCardType('');
          }
        }
      }
    },
    [cleanValues]
  );

  const handleCEP = useCallback(
    async (event: ChangeEvent<{ value: unknown }>, type: string) => {
      const value = event.target.value as string;
      const cep = value.replace(cleanValues, '');
      const isOwner = type === 'owner';

      if (cep.length === 8) {
        try {
          const response = await cepApi.get(`/${cep}/json/`);

          const { localidade, logradouro, uf, erro } = response.data;

          if (!erro) {
            isOwner
              ? formRef.current?.setData({
                  address: {
                    zipCode: cep,
                    street: logradouro,
                    state: uf,
                    city: localidade
                  }
                })
              : formRef.current?.setData({
                  addressBeneficiary: {
                    zipCode: cep,
                    street: logradouro,
                    state: uf,
                    city: localidade
                  }
                });

            isOwner
              ? setShrinked({
                  ...shrinked,
                  street: true,
                  state: true,
                  city: true
                })
              : setShrinkedB({
                  ...shrinkedB,
                  street: true,
                  state: true,
                  city: true
                });
          } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });

            showWarning({
              code: '3569',
              title: 'Não foi possível encontrar seu CEP!',
              description: `Por favor, verifique se seu CEP está correto e digite
              novamente`,
              signal: 'warning'
            });
          }
        } catch {
          window.scrollTo({ top: 0, behavior: 'smooth' });

          showWarning({
            code: '3663',
            title: 'Houve um problema para encontrar seu CEP!',
            description: `Por favor, verifique se seu CEP está correto e digite
            novamente`,
            signal: 'warning'
          });
        }
      }
    },
    []
  );

  const handleNationalityDocument = useCallback(
    async (event: ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string;
      setChosedNationality(value);
    },
    []
  );

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <fieldset className="fieldset-container">
          <Scope path="creditCard">
            <Input
              label="Número do cartão *"
              name="cardNumber"
              InputProps={{
                inputComponent: CardNumberMask as any,
                endAdornment: paymentIcons.map((icon) => {
                  if (cardType === icon.label) {
                    return (
                      <InputAdornment key={icon.id} position="end">
                        <img
                          style={{
                            marginBottom: `${
                              inputErrors['creditCard.cardNumber'] ? 0 : '5px'
                            }`
                          }}
                          src={icon.icon}
                          width={45}
                          alt={icon.label}
                        />
                      </InputAdornment>
                    );
                  }
                  return null;
                })
              }}
              onChange={(event: any) => handleCreditCard(event, 'cardNumber')}
              variant="outlined"
            />
            <div className="card-grid-container">
              <Input
                label="Data de expiração *"
                name="expirationDate"
                InputProps={{
                  inputComponent: ExpirationDateMask as any
                }}
                onChange={(event: any) =>
                  handleCreditCard(event, 'expirationDate')
                }
                variant="outlined"
              />
              <Input
                label="Código de segurança *"
                name="securityCode"
                onChange={(event: any) =>
                  handleCreditCard(event, 'securityCode')
                }
                variant="outlined"
              />
            </div>
          </Scope>
        </fieldset>
        <br />
        <br />
        <fieldset className="fieldset-container">
          {installments?.hasInstallments && (
            <>
              <h3>Parcelamento</h3>
              <br />
              <SelectInput
                label="Parcelas *"
                name="installments"
                valueList={installmentsList}
              />
            </>
          )}
        </fieldset>
        <br />
        <br />
        <fieldset className="fieldset-container">
          <h3>Dados do titular do cartão</h3>
          <br />
          <div className="grid-container">
            <Input label="Nome *" name="firstName" variant="outlined" />
            <Input label="Sobrenome *" name="lastName" variant="outlined" />
            <Input
              label="Data de nascimento *"
              name="birthDate"
              InputProps={{
                inputComponent: BirthDateMask as any
              }}
              variant="outlined"
            />
            <SelectInput
              label="Sexo"
              name="gender"
              valueList={['Masculino', 'Feminino']}
            />
            <Input
              label="DDD + Telefone *"
              name="phoneNumber"
              InputProps={{
                inputComponent: PhoneNumberMask as any
              }}
              variant="outlined"
            />
            <SelectInput
              label="Nacionalidade"
              name="nationality"
              valueList={['Brasileiro', 'Estrangeiro']}
              onChange={handleNationalityDocument}
            />
            {chosedNationality === 'brasileiro' && (
              <>
                <Input
                  label="CPF *"
                  name="document"
                  InputProps={{
                    inputComponent: DocumentCPFMask as any
                  }}
                  variant="outlined"
                />
                <Input label="RG *" name="rg" variant="outlined" />
              </>
            )}
            {chosedNationality === 'estrangeiro' && (
              <Input label="Passaporte *" name="document" variant="outlined" />
            )}
          </div>
          <Input label="E-mail *" name="email" variant="outlined" />
        </fieldset>
        <br />
        <br />
        <fieldset className="fieldset-container">
          <h3>Endereço de cobrança</h3>
          <br />
          <Scope path="address">
            <div className="grid-container">
              <Input
                label="CEP *"
                name="zipCode"
                InputProps={{
                  inputComponent: ZipCodeMask as any
                }}
                onChange={(event: any) => handleCEP(event, 'owner')}
                variant="outlined"
              />
            </div>
            <Input
              label="Endereço *"
              name="street"
              InputLabelProps={{
                shrink: shrinked.street || touched.street,
                focused: touched.street
              }}
              onFocus={() => setTouched({ ...touched, street: true })}
              onBlur={() => setTouched({ ...touched, street: false })}
              onChange={(event: any) =>
                event.target.value === ''
                  ? setShrinked({ ...shrinked, street: false })
                  : setShrinked({ ...shrinked, street: true })
              }
              style={{ marginBottom: 24 }}
              variant="outlined"
            />
            <div className="grid-container">
              <Input label="Número" name="number" variant="outlined" />
              <Input
                label="Complemento (opcional)"
                name="complement"
                variant="outlined"
              />
              <Input
                label="Estado *"
                name="state"
                InputLabelProps={{
                  shrink: shrinked.state || touched.state,
                  focused: touched.state
                }}
                onFocus={() => setTouched({ ...touched, state: true })}
                onBlur={() => setTouched({ ...touched, state: false })}
                onChange={(event: any) =>
                  event.target.value === ''
                    ? setShrinked({ ...shrinked, state: false })
                    : setShrinked({ ...shrinked, state: true })
                }
                variant="outlined"
              />
              <Input
                label="Cidade *"
                name="city"
                InputLabelProps={{
                  shrink: shrinked.city || touched.city,
                  focused: touched.city
                }}
                onFocus={() => setTouched({ ...touched, city: true })}
                onBlur={() => setTouched({ ...touched, city: false })}
                onChange={(event: any) =>
                  event.target.value === ''
                    ? setShrinked({ ...shrinked, city: false })
                    : setShrinked({ ...shrinked, city: true })
                }
                variant="outlined"
              />
            </div>
          </Scope>
        </fieldset>
        <input
          type="hidden"
          className="esitef-cardnumber"
          defaultValue={creditCard.cardNumber}
        />
        <input
          type="hidden"
          className="esitef-cardexpirymonth"
          defaultValue={creditCard.expirationMonth}
        />
        <input
          type="hidden"
          className="esitef-cardexpiryyear"
          defaultValue={creditCard.expirationYear}
        />
        <input
          type="hidden"
          className="esitef-cardsecuritycode"
          defaultValue={creditCard.securityCode}
        />
        <Button type="submit" disabled={isLoading}>
          <span style={{ fontSize: '18px' }}>Confirmar compra</span>
        </Button>
      </Form>
    </Container>
  );
};

export default PaymentForm;
