import React, { ReactElement, useState, useEffect } from 'react';
import { Accordion } from '../../components/Accordion/Accordion';
import { Cpf, Phone } from '../../utils/masks';
import {
  Title,
  HelpContent,
  HelpContenSidebar,
  HelpContentMain,
  TextWrapper,
  Text,
  ContactWrapper,
  CircleIcon,
  TextArea,
  AccordionWrapper
} from './styles';

import { useSelector, useDispatch } from 'react-redux';

import { sendMail } from '../../utils/services';

import {
  ScheduleRootState,
  Item,
  FILTER,
  SET_LOAD,
  UserInfo
} from '../../store/redux';

import { IconMail, IconPhone } from '../../assets/icons';

import ButtonLocal from '../../ui/Button';

import Load from '../../ui/SimpleLoad';
import ButtonWhatsapp from '../../ui/ButtonWhatsapp';

// import Accordion from '../../../components/Accordion';

export default function Contact(): ReactElement {
  const dispatch = useDispatch();
  const accordionItems = useSelector<ScheduleRootState, Item[] | undefined>(scheduleReducer => scheduleReducer.filtered);
  const userInfo: UserInfo = (useSelector<ScheduleRootState, UserInfo>(scheduleReducer => scheduleReducer.user));

  const [search, setSearch] = useState('');

  const [loading, setLoading] = useState(false);
  const [erro, setErro] = useState('');
  const [erros, setErros] = useState<any>({});
  const [responseMessage, setResponseMessage] = useState('');

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [message, setMessage] = useState('');

  dispatch({ type: SET_LOAD, load: false });
  useEffect(() => {
    setName(`${userInfo.firstName} ${userInfo.lastName}`);
    setPhone(userInfo?.phone ?? '');
    setEmail(userInfo?.email ?? '');
    setCpf(Cpf(userInfo?.document ?? ''));
  }, [userInfo]);

  useEffect(() => {
    const debounceSearch = setTimeout(
      () => {
        dispatch({ type: FILTER, search: search });
      },
      500
    );
    return () => {
      clearTimeout(debounceSearch);
    };
  }, [search]);

  async function handleSend(): Promise<any> {
    const listErros = {
      name: false,
      phone: false,
      email: false,
      cpf: false,
      message: false
    };
    if (!name || !phone || !email || !cpf || !message) {
      setErro('Preencha os campos em vermelho:');
      if (!name) {
        listErros.name = true;
      }
      if (!phone) {
        listErros.phone = true;
      }
      if (!email) {
        listErros.email = true;
      }
      if (!cpf) {
        listErros.cpf = true;
      }
      if (!message) {
        listErros.message = true;
      }
      setErros(listErros);
    } else if (cpf.length < 12) {
      listErros.cpf = true;
      setErro('CPF inválido');
    } else {
      setLoading(true);
      const data = {
        nome: name,
        mail: email,
        telefone: phone.replace(/[^\d]+/g, ''),
        cpf: cpf.replace(/[^\d]+/g, ''),
        mensagem: message
      };
      const sendMailResponse = await sendMail(data);
      if (sendMailResponse.statusCode !== 400) {
        setResponseMessage('Sua mensagem foi enviada com sucesso');
        setName('');
        setPhone('');
        setEmail('');
        setCpf('');
        setMessage('');
      } else {
        setErro(sendMailResponse.response);
      }
      setLoading(false);
    }
  }
  return (
    <HelpContent>
      <Title>
        <h2>Contato e ajuda</h2>
      </Title>
      <HelpContentMain>
        <TextWrapper>
          <Text>
            <h1>Têm alguma dúvida?</h1>
            <p>
              Se não encontrar o que procura no <strong>Dúvidas Frequentes,</strong> utilize o <strong>Chat</strong> e fale com a gente!
            </p>
          </Text>
        </TextWrapper>
        <input placeholder="Buscar" value={search} onChange={(e) => setSearch(e.target.value)} />
        <AccordionWrapper>
          {
          accordionItems !== undefined && accordionItems?.length !== 0
            ? accordionItems.map((item, index) => {
              return (
                <Accordion key={index} index={index}
                  title={item.title}
                >
                  <div dangerouslySetInnerHTML={{ __html: item.content }} />
                </Accordion>
              );
            })
            : <div className="span">Nenhum resultado encontrado...</div>
          }
        </AccordionWrapper>
      </HelpContentMain>

      <HelpContenSidebar>
        <p>
          Contate nosso time
        </p>

        {loading
          ? (
            <Load />
            )
          : (
            <>
            {erro && (
              <p className="error">{erro}</p>
            )}
            {responseMessage && (
              <p className="success">{responseMessage}</p>
            )}
            <div className="form-group">
              <input className={ erros.name && name === '' ? 'form-control required' : 'form-control'} value={name} onChange={(e) => setName(e.target.value)} placeholder="Nome" />
              <label className="form-label">Nome</label>
            </div>
            <div className="form-group">
              <input className={ erros.phone && phone === '' ? 'form-control required' : 'form-control'} value={phone} onChange={(e) => setPhone(Phone(e.target.value))} placeholder="DDD + Telefone" />
              <label className="form-label">DDD + Telefone</label>
            </div>
            <div className="form-group">
              <input className={ erros.email && email === '' ? 'form-control required' : 'form-control'} value={email} onChange={(e) => setEmail(e.target.value)} placeholder="E-mail" />
              <label className="form-label">E-mail</label>
            </div>
            <div className="form-group">
              <input className={ erros.cpf && cpf === '' ? 'form-control required' : 'form-control'} value={cpf} onChange={(e) => setCpf(Cpf(e.target.value))} placeholder="CPF" />
              <label className="form-label">CPF</label>
            </div>
            <TextArea className={ erros.message && message === '' ? 'required' : ''} placeholder="Mensagem" value={message} onChange={(e) => setMessage(e.target.value)} />
           <ButtonLocal
              onClick={ async () => handleSend() }
              bkColor="white"
              border="solid 1px #009DFF"
              color="#009DFF" width={300}
              height={50} fontWeight="700"
              fontSize="14px"
            >
              Enviar
            </ButtonLocal>
            {/* <Button label="Enviar" color="#009DFF" backgroundColor="white"></Button> */}
            {/* width: 300px;
            justify-content: center; */}
            </>
            )
        }
      </HelpContenSidebar>
      <ButtonWhatsapp phone="551136202550" mobileBottom="75" />
    </HelpContent>
  );
}
