import React, { ReactElement } from 'react';
import { ButtonWrapper, Container, ToolTip } from './styles';
import Button from '../Button';
import noScheduleImg from '../../assets/noSchedule.png';

import { OPEN_MODAL, UserInfo, ScheduleRootState } from '../../store/redux';
import { useDispatch, useSelector } from 'react-redux';
type props = {
  text?: string
  noButton?: boolean
};
export default function NoSchedule(props: props): ReactElement {
  const dispatch = useDispatch();
  const user = useSelector<ScheduleRootState, UserInfo>(scheduleReducer => scheduleReducer.user);

  const planInfinityOver: boolean = user.step === 7;

  const postSchedule = JSON.parse(localStorage.getItem('postSchedule') ?? '');
  return (
    <Container>
      <img src={noScheduleImg} />
      <p>
        { props.text ? props.text : 'Vamos agendar a sua consulta!' }
      </p>
      {!props.noButton &&
      <ButtonWrapper>
      <Button
        bkColor='#009DFF'
        height={50}
        width={315}
        fontSize='16px'
        noMargin
        disabled={user?.plan === 1 || (user.step >= 3 && user.stepStatus === 'Realizada' && !postSchedule)}
        onClick={() => dispatch({ type: OPEN_MODAL })}
      >
          Agendar
        </Button>
        {user.step >= 3 && user.stepStatus === 'Realizada' && !postSchedule && !planInfinityOver && <ToolTip> Disponível após a emissão do laudo anual. </ToolTip>}
        </ButtonWrapper>

      }
    </Container>
  );
}
