import { ReactElement, useState } from 'react';
import SideBar from '../../components/SideBar';
import ResetPassword from './ResetPassword';
import { Link } from 'react-router-dom';

import {
  UserDataContent,
  SideBarArea,
  UserDataArea,
  Separator,
  CardOption
} from './styles';
import axios from 'axios';

export default function UserSecurity(): ReactElement {
  const [passwordForm, setPasswordForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [erro, setErro] = useState('');
  const [erros, setErros] = useState<any>([]);

  const [inputErros, setInputErros] = useState<any>([]);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  function getMcLoginConfig() {
    const tokenPatient = `Bearer ${String(
      localStorage.getItem('api_patientToken')
    )}`;
    return {
      headers: {
        authorization: tokenPatient
      }
    };
  }

  async function changePassword(): Promise<any> {
    setMessage('');
    setErro('');
    setErros([]);

    const listErros = {
      oldPassword: false,
      newPassword: false,
      confirmPassword: false
    };

    if (!oldPassword || !newPassword || !confirmPassword) {
      setErro('Preencha os campos em vermelho:');
      setErros([]);
      if (!oldPassword) {
        listErros.oldPassword = true;
      }
      if (!newPassword) {
        listErros.newPassword = true;
      }
      if (!confirmPassword) {
        listErros.confirmPassword = true;
      }
      setInputErros(listErros);
    } else if (newPassword !== confirmPassword) {
      setErro('As senhas não conferem');
      setErros([]);
    } else {
      setLoading(true);

      try {
        const payload = {
          appOrigin: 'predicta-web',
          password: newPassword,
          actualPassword: oldPassword
        };

        const baseUrlLogin = process.env.REACT_APP_MC_LOGIN_URL || '';
        const url = `${baseUrlLogin}/reset-password`;
        const config = getMcLoginConfig();

        const { data } = await axios.put(url, payload, config);

        if (data?.statusCode === 202) {
          setErro('');
          setErros([]);
          setOldPassword('');
          setNewPassword('');
          setConfirmPassword('');
          setMessage('Senha alterada com sucesso');
        } else {
          console.log(data?.statusCode);
          throw new Error('Senha errada');
        }

        setLoading(false);
      } catch (error: any) {
        let message = 'Não foi possível atualizar sua senha';

        console.log(error?.response);

        if (error?.response?.status === 403) {
          message = 'Sua senha atual está incorreta';
        }

        setErro(message);
        setErros(['']);
        console.log(
          'UNKNOWN_ERROR_RESET_PASS',
          error?.response?.statusCode || 500
        );
      }

      setLoading(false);
    }
  }
  return (
    <UserDataContent>
      <SideBarArea>
        <SideBar />
      </SideBarArea>
      <UserDataArea>
        <h1>Segurança e Privacidade</h1>
        <Separator />
        <CardOption>
          <div>
            <h2>Senha</h2>
            <p>Altere sua senha de acesso ao Einstein Conecta</p>
          </div>
          <div>
            <button
              onClick={() => setPasswordForm(!passwordForm)}
              className="link"
            >
              {passwordForm ? 'Fechar' : 'Alterar senha'}
            </button>
          </div>
        </CardOption>
        <ResetPassword
          loading={loading}
          show={passwordForm}
          message={message}
          erro={erro}
          erros={erros}
          inputErros={inputErros}
          oldPassword={oldPassword}
          newPassword={newPassword}
          confirmPassword={confirmPassword}
          setOldPassword={setOldPassword}
          setNewPassword={setNewPassword}
          setConfirmPassword={setConfirmPassword}
          changePassword={changePassword}
        />
        <Separator />
        {/* <CardOption>
          <div>
            <h2>Exibir meu prontuário completo - <span className="danger">Inativo</span></h2>
            <p>Exiba todos seus exames e atendimentos do Einstein em seu histórico</p>
          </div>
          <div>
            <Link to="/prontuario" className="link">Editar</Link>
          </div>
        </CardOption>
        <Separator />
        <CardOption>
          <div>
            <h2>Autenticação em dois fatores - <span className="success">Ativo</span></h2>
            <p>Proteja sua conta utilizando seu e-mail ou celular para fazer login</p>
          </div>
          <div>
          <Link to="/autenticacao" className="link">Editar</Link>
          </div>
        </CardOption>
        <Separator /> */}
        <CardOption>
          <div>
            <h2>Upload de documentos</h2>
            <p>
              Faça upload de uma foto de seu documento para validar sua conta
            </p>
          </div>
          <div>
            <Link to="/upload-documentos" className="link">
              Ver mais
            </Link>
          </div>
        </CardOption>
        <Separator />
      </UserDataArea>
    </UserDataContent>
  );
}
