import React, { ReactElement } from 'react';
import { Container, Description, Image } from './styles';

type props = {
  image: string
  name?: string
  description: string
};

export default function DescriptionCard(props: props): ReactElement {
  return (
    <Container>
      <Image>
        <img src={props.image} alt={props.name} />
        <h3>{props.name}</h3>
      </Image>

      <Description>
        <p dangerouslySetInnerHTML={{ __html: props.description }} />
      </Description>
    </Container>
  );
}
