import styled from 'styled-components';

export const ScheduleContent = styled.div`
    @media(min-width: 1024px){
        display: grid;
    }
    min-height: 90vh;
    padding: 2rem;
    grid-template: 
    "title nav"
    "main sidebar";
    gap: 2rem;
    grid-template-rows: 50px auto;
    grid-template-columns: auto 345px;
`;

export const ScheduleContentMain = styled.section`
    @media(max-width: 1024px){
        padding-top: 2rem;
    }
    grid-area: main;
`;

export const ScheduleContenSidebar = styled.section`
    grid-area: sidebar;
`;

export const Title = styled.div`
    grid-area: title;
    display: flex;
    justify-content: flex-start;
    h2{
      font-size: 22px;
      font-weight: 600;
    }
`;

export const Calendar = styled.div`
    display: flex;
    justify-content: center;
    padding-right: 30px;
    width: 400px;
`;

export const History = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding-right: 2rem;
`;

export const ScheduleWrapper = styled.div`
    width: 100%;
    padding-right: 2rem;
`;

export const ButtonsWrapper = styled.div`
    grid-area: nav;
    display: flex;
    justify-content: space-between;
    height: 44px;

    .buttonSlide--path{
        background-color: #fff;
        border: 2px solid #fff;
        color: #707070;
        border-radius: 2.5rem;
        position: relative;
        display: flex;
    }

    .buttonSlide--label,
    .buttonSlide--toggle{  
        width: 120px;     
        height: 40px; 
        padding: .75rem 1.25rem;
        font-size: .75rem;
        display: block;
        border-radius: 2.5rem;
        cursor: pointer;
    }

    .buttonSlide--toggle{
        position: absolute;  
        background-color: #009DFF;
        color: #fff;
        transition: all .3s ease;
        font-family: 'Montserrat',sans-serif;
        left: 0;
        right: 0;
        margin: auto;
    }

    .buttonSlide--toggle.left{
        transform: translateX(-50%)
    }

    .buttonSlide--toggle.right{
        transform: translateX(50%)
    }

`;

export const ButtonNewSchedule = styled.button`
    background-color: #009DFF;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: relative;

    :before, :after{
        content: '';
        width: 20px;
        height: 1px; 
        background-color: #fff;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
    }

    :after{
        transform: rotate(90deg);
    }
`;

export const Load = styled.div`
  position: relative;
  left: 50%;
  top: 100px;
  width: 50px;
  height: 50px;
  border: 4px solid #80808042;
  border-radius: 50%;
  box-sizing: border-box;
  &:after {
    content: "";
    position: absolute;
    top: -4px;
    left: -4px;
    display: block;
    width: 50px;
    height: 50px;
    border: 4px solid transparent;
    border-top: 4px solid #004F92;
    border-radius: 50%;
    box-sizing: border-box;
    animation: spin 0.55s infinite ease-in-out;
  }
 
  @keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
  }
`;
