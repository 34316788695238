import styled from 'styled-components';

export const HelpWrapper = styled.div`
  background: rgba(0, 157, 255, 0.25);
  padding: 25px 10px 0px 20px;
  border-radius: 8px;
  color: #004F92;
  text-align:center;
  font-size: 15px;
  font-weight: bold;
  width: 95%;
  margin: 0 auto 60px auto;
  p{
    margin-bottom: 8px;
    flex-wrap: wrap;
    word-wrap: break-word;
    width: 100%;
  }

  @media(max-width: 320px){
    height: 220px;
  }
  
  @media(min-width: 321px) and (max-width: 414px){
    height: 220px;
  }
`;

export const FlexBox = styled.div`
  display:flex;
  flex-direction: column;
  margin-top: 40px;
  a{
    text-decoration: underline;
  }

  img{
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 40%;
    margin: 0 30%;
    @media(max-width: 1025px){
      max-width: 20%;
      margin: 0 40%;
    }
  }

  
  @media(max-width: 414px){
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    img{
      margin: -7px 10px 5px 0px;
      width: 78px;
    }
  }
`;
