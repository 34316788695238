import { IThisArgAxiosService } from '../../interfaces/IThisArgAxiosService';
import { serviceList, ServiceListBinded } from './serviceList';

export type ThisArg = {
  thirArgInjection: IThisArgAxiosService
};

export function createServices(this: ThisArg): ServiceListBinded {
  return Object
    .keys(serviceList)
    .reduce<ServiceListBinded>(
      (servicesToBind, key) => ({
        ...servicesToBind,
        [key as keyof ServiceListBinded]: serviceList[key as keyof ServiceListBinded].bind(this.thirArgInjection)
      }),
    // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
    {} as ServiceListBinded
    );
}
