import React, { ReactElement, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { format, parseISO, isEqual } from 'date-fns';

import { ScheduleRootState, UserInfo } from '../../store/redux';

// import HistoricCard from '../../components/Historic';
import CalendarSchedule from '../../components/CalendarSchedule';
import ScheduleCard from '../../components/ScheduleCard';
// import Card from '../../ui/Card';
import NoSchedule from '../../ui/NoSchedule';

// import predictaIcon from '../../assets/predicta.svg';
import {
  Title,
  // ButtonsWrapper,
  ScheduleContent,
  ScheduleContenSidebar,
  ScheduleContentMain,
  // ButtonNewSchedule,
  ScheduleWrapper,
  Load
} from './styles';
import { ptBR } from 'date-fns/locale';

const token = `Bearer ${String(localStorage.getItem('api_token'))}`;
const patientToken = `Bearer ${String(localStorage.getItem('api_patientToken'))}`;

const config = {
  headers: {
    'x-access-token': token,
    'x-access-patient-token': patientToken
  }
};

const bodySchedule = {
  schedulingId: '',
  unityId: '',
  locationId: '',
  resourceId: '',
  serviceId: '',
  medicalRecord: '', // user.prontuario
  registerQuantity: null,
  lastRegister: null,
  schedulingType: null,
  futureScheduling: true,
  statusList: ['P']
};

export default function Schedule(): ReactElement {
  // const dispatch = useDispatch();
  // const [buttonSlide, setButtonSlide] = useState(true);
  const [scheduleList, setScheduleList] = useState<any>();

  const [dateSelected, setDateSelected] = useState<Date>(new Date());

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const user = useSelector<ScheduleRootState, UserInfo>(scheduleReducer => scheduleReducer.user);
  const reload = useSelector<ScheduleRootState, boolean>(scheduleReducer => scheduleReducer.reload);
  const userDataLoaded = useSelector<ScheduleRootState, boolean>(scheduleReducer => scheduleReducer.userDataLoaded);

  const getSchedules = async (): Promise<any> => {
    bodySchedule.medicalRecord = user.prontuario;

    const baseUrl = process.env.REACT_APP_BASE_API;
    const scheduleResponse = await axios.post<any>(
      `${baseUrl}/schedule/getAppointments`,
      bodySchedule,
      config
    )
      .then(response => {
        return setScheduleList(response.data.response);
      }).catch(error => {
        console.log(error);
      });

    setIsLoading(false);
    return scheduleResponse;
  };

  useEffect(() => {
    if (!userDataLoaded) {
      return;
    }

    getSchedules();
  }, [userDataLoaded, reload]);

  // dispatch({ type: SET_LOAD, load: false });
  const today = format(new Date(), 'yyyy-MM-dd');

  const [width, setWindowWidth] = useState(0);
  const showDesktopComponent: boolean = width > 1024;

  // Responsive
  const updateDimensions = () => {
    const width = window.outerWidth;
    setWindowWidth(width);
  };

  useEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <ScheduleContent>
      <Title>
        <h2>
          Meus Agendamentos
          {/* {buttonSlide ? 'Meus Agendamentos' : 'Meus Programas'} */}
        </h2>
      </Title>
      {/* <ButtonsWrapper> */}
        {/* <div onClick={() => setButtonSlide(!buttonSlide)} className="buttonSlide--path">
          <span className="buttonSlide--label">Agendados</span>
          <span className="buttonSlide--label">Programas</span>
          <button className={`${buttonSlide ? 'left' : 'right'} buttonSlide--toggle`}>{buttonSlide ? 'Agendados' : 'Programas'}</button>
        </div> */}

        {/* <ButtonNewSchedule onClick={() => dispatch({ type: OPEN_MODAL })} /> */}
      {/* </ButtonsWrapper> */}
      <ScheduleContentMain>

      {
          // buttonSlide
          //   ? (
              <ScheduleWrapper>
                {!isLoading
                  ? (
                      scheduleList?.length === 0 || scheduleList === undefined || scheduleList === 'undefined'
                        ? (
                          <NoSchedule noButton={user.plan === 1} />
                          )
                        : (
                            scheduleList?.map((schedule: any) => {
                              const fullScheduleDate = format(parseISO(schedule.appointmentDate), 'cccccc, dd/MM/yyyy', { locale: ptBR });
                              const halfScheduleDate = format(parseISO(schedule.appointmentDate), 'cccccc, dd/MM', { locale: ptBR });
                              return (
                                <ScheduleCard isToday={isEqual(parseISO(schedule.appointmentDate), parseISO(today))} schedule date={fullScheduleDate.charAt(0).toUpperCase() + fullScheduleDate.slice(1)} separator>
                                  <div className="date-schedule">
                                    <h1>{halfScheduleDate.charAt(0).toUpperCase() + halfScheduleDate.slice(1)} | {schedule.appointmentHour}</h1>
                                    <h3>{ user.products }</h3>
                                  </div>
                                  <div className="doctor-schedule">
                                    <h2>{schedule.doctor.split(' ').slice(0, 2).join(' ')}</h2>
                                    <h3>{schedule.serviceDescription.split(' ').slice(0, 4).join(' ')}</h3>
                                  </div>
                                  {/* <div className="product-schedule">
                                    <span><b className="predicta">PR</b> Predicta</span>
                                  </div> */}

                                </ScheduleCard>
                              );
                            })
                          )
                    )
                  : (
                    <Load />
                    )}

                </ScheduleWrapper>
            //   )
            // : (
            //   <HistoricCard buttonSchedule buttonReschedule />
            //   )
        }

      </ScheduleContentMain>

      <ScheduleContenSidebar>
          {/* {buttonSlide
            ? ( */}
            {showDesktopComponent &&
              <CalendarSchedule dateSelected={dateSelected} setSelectedDate={setDateSelected}/>
            }
              {/* )
            : (
              <Card
                mode="light"
                cardBody={ user.product === 2 ? 'Infinity' : 'One' }
                bodyColor="#7A819A"
                bodyFontSize={12}
                bodyFontWeight="bold"
                secondCollumn={true}
                img={predictaIcon}
                imgHeight={30}
                borderColor="#009DFF"
              />
              ) } */}

      </ScheduleContenSidebar>

    </ScheduleContent>
  );
}
