/* eslint-disable */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';

import {
  PURCHASE_PRONTUARIO,
  USER_PRODUCT,
  SET_LOAD,
  IS_USER_DATA_STALE,
  USER_DATA_LOADED
} from '../../store/redux';
import Load from '../../ui/SimpleLoad';
import { IPersonPatientPortal } from '../../interfaces/person.interface';

const token = `Bearer ${String(localStorage.getItem('api_token'))}`;
const tokenPatient = `Bearer ${String(
  localStorage.getItem('api_patientToken')
)}`;

const configManagement = {
  headers: {
    Authorization: tokenPatient
  }
};

const config = {
  headers: {
    'x-access-token': token,
    'x-access-patient-token': tokenPatient
  }
};

const AuthUser: React.FC = ({ children }) => {
  const [user, setUser] = useState<any>(
    JSON.parse(localStorage.getItem('user') ?? '[]')
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const baseUrl = process.env.REACT_APP_BASE_API;
  const baseUrManagement = process.env.REACT_APP_MC_MANAGEMENT_URL;

  const nationality = (person: IPersonPatientPortal): string => {
    switch (person?.nationality) {
      case 'BR':
      case 'BRA':
        return 'brasileiro';
    }

    return person?.cpf ? 'brasileiro' : 'estrangeiro';
  };

  const getUserInfo = async (): Promise<any> => {
    const personRequest = await axios
      .get(`${baseUrManagement}/patient-portal/v1/person`, configManagement)
      .then((response) => {
        return response?.data || {};
      })
      .catch((error) => {
        console.log(error);
        return error?.error || error?.code;
      });

    const person: IPersonPatientPortal = personRequest || {};
    const fullName = person.name.split(' ');
    const firstName = fullName.shift();
    const lastName = fullName.join(' ');
    const [firstMedicalRecord] = person?.medicalRecords || [];
    const medicalRecord = firstMedicalRecord ? firstMedicalRecord.value : '';

    const ret: any = await axios
      .get(`${baseUrl}/user/detailed`, config)
      .then((response) => {
        return response.data.response;
      })
      .catch((_) => {
        history.replace('/contratar');
      });

    if (ret?.eligibility === false) {
      history.replace('/contratar');
    }

    if (ret) {
      dispatch({
        type: PURCHASE_PRONTUARIO,
        name: firstName,
        lastName: lastName,
        fullName: person.name,
        param: person.cpf,
        prontuario: medicalRecord || ret.medicalRecord,
        email: person.email,
        birthDate: person.birthDate,
        nationality: nationality(person)
      });

      dispatch({
        type: USER_PRODUCT,
        name: firstName,
        fullName: person.name,
        param: ret.planDescription,
        lastName: lastName,
        prontuario: medicalRecord || ret.medicalRecord,
        state: {
          user: {
            eligibility: ret?.eligibility || false,
            phone: person.phone,
            email: person.email,
            birthDate: person.birthDate,
            sex: String(person.biologicalSex).substring(0, 1) || '',
            document: person.cpf || person.foreignDocument,
            nationality: nationality(person),
            questionnaire: ret.sendQuestionnaire,
            singleSchedule: ret.singleSchedule,
            plan: ret.planId,
            step: ret.stepStatusId,
            stepStatus: ret.stepStatus,
            stepDescription: ret.stepStatusDescription
          }
        }
      });

      const dataUser = {
        document: person.cpf || person.foreignDocument,
        documentType: person.cpf ? 'CPF' : 'PASSAPORTE',
        elegibility: ret.elegibility,
        name: person.name,
        fullName: person.name,
        firstName: firstName,
        lastName: lastName,
        email: person.email,
        product: ret.planId,
        plan: ret.planDescription,
        nationality: nationality(person),
        prontuario: medicalRecord || ret.medicalRecord,
        step: ret.stepStatusId ?? 0,
        stepStatus: ret.stepStatus,
        stepDescription: ret.stepStatusDescription,
        birthDate: person.birthDate,
        phone: person.phone,
        sex: String(person.biologicalSex).substring(0, 1) || '',
        questionnaire: ret.sendQuestionnaire,
        singleSchedule: ret.singleSchedule
      };
      setUser(dataUser);

      dispatch({
        type: IS_USER_DATA_STALE,
        state: {
          isUserDataStale: true
        }
      });

      dispatch({
        type: USER_DATA_LOADED
      });

      // localStorage.setItem('user', JSON.stringify(dataUser));
      dispatch({ type: SET_LOAD, load: false });
    }
  };
  useEffect(() => {
    setLoading(true);
    getUserInfo().finally(() => {
      setLoading(false);
    });
    // return () = {dispatch(load: true);}
  }, []);

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
  }, [user]);

  return (
    <>
      {loading ? (
        <Container>
          <Load left="45%" top="35%" />
        </Container>
      ) : (
        children
      )}
    </>
  );
};

export default AuthUser;
