import creditCardType from 'credit-card-type';
import * as yup from 'yup';
import isCardNumberValid from '../utils/validateCreditCard';

yup.addMethod(yup.string, 'validateDocument', function (errorMessage) {
  return this.test('test-valid-cpf', errorMessage, function (value) {
    const { path, createError } = this;

    if (!value) {
      return createError({ path, message: 'CPF não definido!' });
    }

    if (this.parent.nationality === 'estrangeiro') {
      const passport = value.replace(/[^0-9a-z]+/gi, '');
      if (passport.length > 0 && passport.length <= 10) {
        return true;
      }
    }

    if (
      value.length === 0 ||
      (value.length > 10 && this.parent.nationality === 'estrangeiro')
    ) {
      return createError({ path, message: 'Este documento não é válido!' });
    }

    const cpf = value.replace(/[^0-9]+/g, '');

    if (
      cpf.length !== 11 ||
      !Array.from(cpf).filter(e => e !== cpf[0]).length
    ) {
      return createError({ path, message: 'Este documento não é válido!' });
    }

    let soma = 0;
    let resto;

    for (let i = 1; i <= 9; i += 1) {
      soma += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
    }

    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== parseInt(cpf.substring(9, 10), 10)) {
      return createError({ path, message: 'Este CPF não é válido!' });
    }

    soma = 0;

    for (let i = 1; i <= 10; i += 1) {
      soma += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
    }

    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== parseInt(cpf.substring(10, 11), 10)) {
      return createError({ path, message: 'Este CPF não é válido!' });
    }

    return true || createError({ path, message: errorMessage });
  });
});

yup.addMethod(yup.string, 'creditCardType', function (errorMessage) {
  return this.test('test-card-type', errorMessage, function (value) {
    const { path, createError } = this;

    const currentFlags = [
      'visa',
      'mastercard',
      'elo',
      'diners-club',
      'american-express'
    ];

    if (!value) {
      return createError({ path, message: 'Número do cartão não definido!' });
    }

    const newValue = value.replace(/[^0-9]+/g, '');

    let isNumberValid;

    if (isCardNumberValid(Number(newValue))) {
      const cardType = creditCardType(newValue);
      if (cardType.length !== 0) {
        isNumberValid = currentFlags.includes(cardType[0].type);
      }
    }

    if (isNumberValid) return isNumberValid;
    return createError({ path, message: errorMessage });
  });
});

yup.addMethod(yup.string, 'validateExpirationDate', function (errorMessage) {
  return this.test('test-valid-date', errorMessage, function (value) {
    const { path, createError } = this;

    if (!value) {
      return createError({ path, message: 'Data não definida!' });
    }

    const [expirationMonth, expirationYear] = value.split('/');

    const currentYear = new Date().getFullYear().toString().substr(2);

    return (
      (expirationYear >= currentYear &&
        Number(expirationMonth) <= 12 &&
        Number(expirationMonth) > 0) ||
      createError({ path, message: errorMessage })
    );
  });
});

yup.addMethod(yup.string, 'validateBirthDate', function (errorMessage) {
  return this.test('test-valid-birthDate', errorMessage, function (value) {
    const { path, createError } = this;

    if (!value) {
      return createError({ path, message: 'Data não definida!' });
    }

    const [birthDay, birthMonth, birthYear] = value.split('/');

    const currentYear = new Date().getFullYear();

    if (Number(birthMonth) > 12 || Number(birthMonth) <= 0) {
      return createError({ path, message: 'Mês inválido!' });
    }

    if (Number(birthDay) > 31 || Number(birthDay) <= 0) {
      return createError({ path, message: 'Dia inválido!' });
    }

    if (currentYear - Number(birthYear) >= 120) {
      return createError({ path, message: 'Ano inválido!' });
    }

    return (
      currentYear - Number(birthYear) >= 18 ||
      createError({ path, message: errorMessage })
    );
  });
});

yup.addMethod(yup.string, 'validateInstallments', function (errorMessage) {
  return this.test('test-valid-date', errorMessage, function (value) {
    const { path, createError } = this;

    if (this.parent.installments !== '') {
      return true;
    }

    if (!value) {
      return createError({ path, message: errorMessage });
    }

    return true || createError({ path, message: errorMessage });
  });
});

export const formSchemaSingle = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[a-zÀ-ü '-]+$/gi, 'Digite um nome válido')
    .required('O nome é obrigatório'),
  lastName: yup
    .string()
    .matches(/^[a-zÀ-ü '-]+$/gi, 'Digite um nome válido')
    .required('O sobrenome é obrigatório'),
  gender: yup.string().required('Como você se identifica?'),
  birthDate: yup
    .string()
    .validateBirthDate('O titular precisa ser maior de 18 anos')
    .required('A data de nascimento é obrigatória'),
  phoneNumber: yup.string().required('O número do telefone é obrigatório'),
  nationality: yup.string().required('Qual a sua nacionalidade?'),
  document: yup
    .string()
    .validateDocument('CPF inválido')
    .required('O Documento é obrigatório'),
  rg: yup.string().when('nationality', {
    is: 'brasileiro',
    then: yup
      .string()
      .matches(/^\d+x?$/, 'Digite um RG válido')
      .required('O RG é obrigatório')
  }),
  email: yup
    .string()
    .email('Você precisa digitar um email válido')
    .required('O e-mail é obrigatório'),
  installments: yup
    .string()
    .validateInstallments('A quantidade de parcelas é obrigatória'),
  creditCard: yup.object().shape({
    cardNumber: yup
      .string()
      .min(16, 'No mínimo 13 caracteres')
      .max(19, 'No máximo 16 caracteres')
      .creditCardType('Este cartão é inválido')
      .required('O número do cartão é obrigatório'),
    expirationDate: yup
      .string()
      .validateExpirationDate('A data é inválida')
      .required('A data de expiração é obrigatória'),
    securityCode: yup
      .string()
      .min(3, 'No mínimo 3 caracteres')
      .max(4, 'No máximo 4 caracteres')
      .required('O código de segurança é obrigatório')
  }),
  address: yup.object().shape({
    zipCode: yup.string().required('O CEP é obrigatório'),
    street: yup.string().required('O endereço é obrigatório'),
    number: yup
      .string()
      .matches(/^\d+$/, 'Digite apenas números')
      .required('O número é obrigatório'),
    complement: yup.string().optional(),
    city: yup.string().required('A cidade é obrigatória'),
    state: yup.string().required('O estado é obrigatório')
  })
});
