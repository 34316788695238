import styled from 'styled-components';
type props = {
  open: boolean
};
export const ModalBkg = styled.div<props>`
    ${props => !props.open ? 'display: none' : 'display:flex'};    
    background: rgba(0,0,0, 0.8);
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 60px;
    bottom:0;
    z-index: 30;
    align-items:center;
    justify-content:center;
    overflow-y: hidden;
    @media(max-width: 1024px){
      top: 50px;
    }
`;
export const Modal = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    h1{
      text-align: center;
      font-size: 1.5rem;
      font-weight: 600;
    }
    button{
      font-size: 1.15 rem;
      font-weight: 500;
      border-radius: 20px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media(max-width: 1024px){
      flex-direction: column-reverse;
    }
`;
export const Register = styled.div`
    background: #004F92;
    border-radius: 10px 0 0 10px;
    height: 500px;
    width: 35vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    p{
      font-size: 1.15rem;
      font-weight: 500;
    }
    .button{
      font-size: 1.15rem;
      font-weight: 500;
      border-radius: 20px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px white;
      color: white;
      width: 100%;
      max-width: 260px;
      margin-top: 1.85rem;
    }
    @media(max-width: 1024px){
      padding: 10px;
      height: 30vh;
      width: 90vw;
      border-radius: 0 0 10px 10px;
      .button{
        width: 80vw;
      }
    }
    @media(min-width: 991px){
      max-width: 500px;
    }
`;
export const Login = styled.div`
    background: white;
    border-radius: 0 10px 10px 0;
    height: 500px;
    width: 55vw;
    .login{
      background:#004F92;
      color: white;
      width: 100%;
      max-width: 500px;
      margin-bottom: 1.25rem;
      margin-top: 1.25rem;
    }
    .link{
      color: #004F92;
      font-size: 1.15rem;
      font-weight: 600;
    }
    .flex{
      display:flex;
      margin-bottom: .65rem;
      button{
        margin-left:auto;
        svg{
          color: #707070;
          height: 20px;
        }
      }
    }
    .space{
      font-size: 0.75rem;
      text-align: left;
      justify-content: space-between;
      p{
        margin-bottom: .25rem;
      }
    }
    .wrapper{
      display:flex;   
      flex-direction: column; 
      align-items: center;
      justify-content:center;
      text-align:center;
      height: 80%;
      .height{
        min-height: 200px;
      }
    }
    .forgot{
      margin-top: .65rem;
      font-size: .95rem;
      font-weight: 600;
    }
    .mb-20{
      margin-bottom: 1.25rem;
    }
    .mt-40{
      margin-top: 2.5rem;
    }
    @media(max-width: 1024px){
      height: 55vh;
      width: 90vw;
      border-radius: 10px 10px 0px 0px;

      .login{
        width: 80vw;
        margin-bottom: 10px;
      }

      .flex{
        margin-bottom: 0px;
      }
    }
    @media(min-width: 991px){
      max-width: 620px;
    }
    @media(max-width: 440px){
      .forgot{
        width: 80vw;
      }
`;
export const FormWrapper = styled.div`
    .error{
      text-align: center;
      color: #F53748;
      margin-bottom: .65rem;
      font-size: 1rem;
    }  
    .error-list{
      text-align: center;
      color: #F53748;
      font-size: .85rem;
      margin-bottom: .6rem;
    }  
    margin-top: 3.17rem;
    display: flex;
    flex-direction: column;
    input{
      width: 500px;
      height: 50px;
      padding: 10px;
      border-radius: 5px;
      margin-bottom: 15px;
      border: solid 1px #999999ba;
      ::placeholder {
        color: #999999ba;
        font-size: 14px;
      }
    }
    .small{
      width: 40px;
      text-align: center;
      font-size: 22px;
    }
    @media(max-width: 1024px){
      input{
        width: 80vw;
        max-width: 500px;
      }
    }
`;
