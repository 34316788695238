import React from 'react';

import { Container } from './styles';

export interface UserInfoProps {
  src?: string
  size?: string
  margin?: string
}
const UserIconWrapper: React.FC<UserInfoProps> = ({ src, size, margin }) => {
  return (
    <Container className="user-icon" src={src} size={size} margin={margin} />
  );
};

export { UserIconWrapper as UserIcon };
