import styled, { keyframes } from 'styled-components';

export interface StyleProps {
  action: 'success' | 'error' | 'submit' | 'loading'
}

const GetColor = ({ action }: StyleProps) => {
  if (action === 'success') return '#004f92';
  if (action === 'error') return '#FF5353';
  return '#707070';
};

export const InputButton = styled.input<StyleProps>`
    background-color: transparent;
    border: none;
    padding: 0 0.7rem;
    cursor: pointer;
    font-weight: 400;
    color: ${(props: StyleProps) => GetColor(props)};
    padding-bottom: 0.2rem;
`;

export const ButtonError = styled.button<StyleProps>`
    background-color: transparent;
    border: none;
    padding: 0 0.7rem;
    color: ${(props: StyleProps) => GetColor(props)};
    cursor: pointer;
    transition: 300ms;

    &:hover {
      color: #d41414;
    }
    
`;

export const Image = styled.img`
    padding: 0.1rem 0.4rem;
    border-radius: 50%;
    display: flex;
    background-color: #E5F9F7;
    object-fit: cover;
    margin: 10px 14px;
    width: 24px;
`;

const circleKeyframe = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
`;

export const Loading = styled.div`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 0.15rem solid #004f92;
  animation: ${circleKeyframe} .6s infinite linear;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  align-self: center;
  margin: 0 0.9rem;

  ::after{
    content: '*';
    color: white;
    font-weight: bold;
    margin: 0.9rem;
  }
`;
