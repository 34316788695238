import React, { ReactElement } from 'react';
import { Load } from './styles';
type props = {
  top?: string
  left?: string
  color?: string
  isFixed?: boolean
  size?: string
};
export default function Simpleload(props: props): ReactElement {
  return (
    <Load top={props.top} left={props.left} color={props.color} isFixed={props.isFixed} size={props.size}/>
  );
}
