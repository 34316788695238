/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable comma-dangle  */
import { api } from "../api";

export const getUnitsList = async () => {
  try {
    const token = `Bearer ${String(localStorage.getItem('api_token'))}`;
    const tokenPatient = `Bearer ${String(localStorage.getItem('api_patientToken'))}`;
    const headers = {
      'x-access-token': token,
      'x-access-patient-token': tokenPatient
    };
    const response = await api.get("/schedule/units/list", {
      headers: headers,
    });
    return response.data.response;
  } catch (err) {
    return err;
  }
};
