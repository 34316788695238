import styled from 'styled-components';
type props = {
  isDarkMode?: number
  bkColor?: string
  color?: string
  border?: string
  fontWeight?: string
  fontSize?: string
  width?: number | string
  height?: number | string
  icon?: boolean
  noMargin?: boolean
};
export const StyledButton = styled.button<props>`
  border-radius: 25px;
  ${props => !props.noMargin && 'margin-right: auto;'}
  padding: 8px 20px 8px 20px;
  width: auto;
  cursor: pointer;
  border: ${props => props.border && props.border};
  color: ${props => props.color ? props.color : 'white'};
  ${props => props.width && `width:${props.width}px;`}
  ${props => props.height && `height:${props.height}px;`}

  font-size: ${props => props.fontSize ? props.fontSize : '12px'};
  background: ${props => props.bkColor ? `${props.bkColor}` : '#40BDA6'};

  ${props => props.fontWeight && `
    font-weight: ${props.fontWeight};
  `}

  ${props => props.icon &&
    `
     display:flex;
     align-items:center;
     justify-content-center;
    `
 }
  svg{
    margin-right: 5px;
  }
   
  @media(max-width: 1024px){
    padding: 5px 15px 5px 15px;
    width: 100%;
  }  
  &:disabled{
    background: gray;
    cursor: block;
    opacity: .3;
  }
`;
