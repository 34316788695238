import React, { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ModalLogin from '../../components/ModalLogin';

import { HeaderSite, HeaderContainer, MenuDesktop, BtnWrapper, GradientLine } from './styles';

import LogoEinstein from '../../assets/logo.png';

import {
  ScheduleRootState,
  Purchase
} from '../../store/redux';

import TagManager from 'react-gtm-module';
import { moveToLogin } from '../../utils/moveToLogin';

export default function HeaderDesktop(): ReactElement {
  const purchase = useSelector<ScheduleRootState, Purchase | undefined>(scheduleReducer => scheduleReducer.purchase);

  const [loginOpen, setLoginOpen] = useState(purchase?.registered === 400);

  return (
    <>
      <ModalLogin open={loginOpen} setOpen={setLoginOpen}></ModalLogin>
      <HeaderSite>
        <HeaderContainer>
          <Link to="/">
            <img src={LogoEinstein} width={'200px'} />
          </Link>
          <MenuDesktop>
            <a href="#banner" className="menu-desktop--item">INÍCIO</a>
            <a href="#como-funciona" className="menu-desktop--item">COMO FUNCIONA</a>
            <a href="#diferenciais" className="menu-desktop--item">DIFERENCIAIS</a>
            <a href="#ajuda" className="menu-desktop--item">AJUDA</a>
          </MenuDesktop>
          <BtnWrapper>
            <div onClick={() => {
              setLoginOpen(!loginOpen);
              const tagManagerArgs = {
                gtmId: 'GTM-TDPD3K8',
                dataLayer: {
                  event: 'Einstein-Predicta',
                  categoria: 'Contratar-Agora',
                  acao: 'step-1',
                  rotulo: 'Click-Header'
                }
              };
              TagManager.initialize(tagManagerArgs);
            }}>
              <Link id="btn-001"
                className="btn orange" to={{
                  pathname: 'contratar',
                  state: 1
                }}

              >
                Contratar agora
              </Link>
            </div>
            <button id="btn-002" onClick={() => moveToLogin()} className="btn outline">Entrar</button>
          </BtnWrapper>
        </HeaderContainer>
        <GradientLine />
      </HeaderSite>
    </>
  );
}
