/* eslint-disable */
import styled from "styled-components";

export const Container = styled.div`
  background: #E6F2F0;
  -webkit-box-shadow: 1.5px 1.5px 10px 1px rgba(0,0,0,0.38); 
  box-shadow: 1.5px 1.5px 10px .5px rgba(0,0,0,0.38);
  width: 90%;
  min-height: 500px;
  margin: 40px auto;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  @media(max-width: 768px){
    width: 80%;
    padding: 10px;
    flex-direction: column;
  }
`;

export const Image = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 250px;
  >img{
    display: flex;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    object-position: 0;
  }
  >h3{
    width: 100%;
    flex-wrap: wrap;
    margin-top: 15px;
  }
  @media(max-width: 768px){
    min-width: 100%;
    >img{
      width: 140px;
      height: 140px;
    }
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
  @media(max-width: 768px){
    text-align: left;
  }
  >p{
    padding: 15px 10px;
    font-size: 1.12rem !important;
    font-weight: 500 !important;
    color: #000000 !important;
  }
  @media(max-width: 768px){
    >p{
      font-size: 1.1rem;
    }
  }
  @media(max-width: 420px){
    p{
      padding:15px 5px;
    }
  }
`;
