import React, { ReactElement, useEffect, useState } from 'react';

import { Container } from './styles';
import mainBanner from '../../assets/reference/main-banner.png';
import content from '../../assets/reference/content.svg';
import {
  AccordionWrapper,
  CompromiseSection,
  FaqSection
} from '../Home/styles';
import ButtonWhatsapp from '../../ui/ButtonWhatsapp';
import Footer from '../../ui/Footer';
import { LogoEinstein } from '../../assets/icons';
import FactImg from '../../assets/Site/FACT.png';
import Doutor1 from '../../assets/Site/Imagem1.png';
import Doutor2 from '../../assets/Site/Imagem2.png';
import Doutor3 from '../../assets/Site/Imagem3.png';
import Doutor4 from '../../assets/Site/Imagem4.png';
import JciImg from '../../assets/Site/jci.png';
import SecurityCard from '../../components/SiteSecCard';
import Plan from '../../components/Plan';
import WestchesterImg from '../../assets/Site/Westchester.png';
import Accordion from '../../ui/Accordion';
import Header from '../../ui/Header';
import HeaderMobile from '../../ui/HeaderMobile';

export default function Landing(): ReactElement {
  const [width, setWindowWidth] = useState(0);
  const showDesktopComponent: boolean = width > 768;

  // Responsive
  const updateDimensions = () => {
    const width = window.outerWidth;
    setWindowWidth(width);
  };

  useEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <>
      {showDesktopComponent ? <Header /> : <HeaderMobile />}

      <Container>
        <div className="backgroundsection">
          <img className="main-banner" src={mainBanner} alt="" />
        </div>
        <img className="content" src={content} alt="" />
        <FaqSection id="ajuda">
          <h2>Ficou alguma dúvida?</h2>
          <p>Confira aqui algumas perguntas frequentes: </p>
        </FaqSection>

        <CompromiseSection>
          <LogoEinstein />
          <h4>COMPROMISSO E EXCELÊNCIA!</h4>
          {/* <p>
            Nossos processos seguem severamente a LGPD genética
            <br />
            para proteger e guardar os dados do seu DNA!
          </p> */}
          <div>
            <img src={FactImg} />
            <img src={JciImg} />
            <img src={WestchesterImg} />
          </div>
        </CompromiseSection>

        <ButtonWhatsapp phone="551136202550" />
        <Footer></Footer>
      </Container>
    </>
  );
}
