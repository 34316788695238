import styled from 'styled-components';
type props = {
  isToday?: boolean
  schedule?: boolean
};
export const Div = styled.div`
    display: flex;
    justify-content: space-between;
    align-items:center;
    span{
      font-size: .75rem;
      color: #6B7786;
      font-weight: bold;
    }
    .today{
        color: #009DFF;
    }
`;

export const Card = styled.div`
    background-color: white;
    border-radius: .5rem;
    padding: .8rem;
    padding-left: 1.5rem;
    padding-right: 1rem;
    border-left: solid 6px #15B193; 
    margin-top: 20px;
    margin-bottom: 30px;
`;
export const Hr = styled.div<props>`
  border-bottom: 1px solid #DFE3E8;
  height: 0.2em;
  width: ${props => props.isToday ? '95' : '88'}%;
`;

export const CardInfo = styled.div<props>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    h1{
      font-weight: 600;
      color: #000;
      font-size: .875rem;
      margin-bottom: .5rem;
    }
    h2{
        font-weight: 600;
        color: #707070;
        font-size: .875rem;
        margin-bottom: .5rem;
    }

    h3{
        color: #707070;
        font-weight: 400;
        font-size: .75rem;
        margin-bottom: .5rem;
    }
    h4{
      color: #707070;
      font-weight: 400;
      font-size: 9px;
      margin-bottom: .5rem;
      margin-top: -10px;
    }
    .border{
      border-right: solid 1px #DFE2E6;
      height: 50px;
      width: 1px;
    }
    .mr-20{
      margin-right: 20px;
    }
    div:last-child {
      border-right: none;
    }
    .userInfo{
      display:flex;
      text-align: left;
      flex: 2 1 0%;
      border-right: 1px solid rgb(223, 226, 230);
      height: 100%;
      padding-right: 20px;
      @media(max-width: 1024px){
        display: none;
      }
    }
    .product{
      flex: 1 1 0%;
      border-right: 1px solid rgb(223, 226, 230);
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media(max-width: 1024px){
        padding-right: 1rem;
      }
      @media(min-width: 1024px){
        padding: 0px 1rem;
      }
    }
    .doctor{
      flex: 3 1 0%;
      border-right: 1px solid rgb(223, 226, 230);
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0px 1rem;
      @media(max-width: 1024px){
        display: none;
      }
    }
    .date{
      flex: 2 1 0%;
      border-right: 1px solid rgb(223, 226, 230);
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0px 1rem;
    }
    .files{
      flex: 1 1 0%;
      border-right: 1px solid rgb(223, 226, 230);
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0px 1rem;
    }
    .status{
      flex: 1 1 0%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0px 0 0 1rem;
    }
    .date-schedule{
      @media(min-width: 1024px){
        padding: 0px 1rem;
      }
      @media(max-width: 1024px){
        padding-right: 1rem;
        h1{
          font-size: 11px;
        }
      }
      flex: 1 1 0%;
      border-right: 1px solid rgb(223, 226, 230);
      height: 100%;
    }
    .doctor-schedule{
      flex: 2 1 0%;
      border-right: 1px solid rgb(223, 226, 230);
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media(max-width: 1024px){
        h2{
          font-size: 11px;
        }
        flex: 1 1 0%;
        padding: 0px 1rem;
      }
    }
    .product-schedule{
      flex: 1 1 0%;
      border-right: 1px solid rgb(223, 226, 230);
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media(max-width: 1024px){
        padding-left: 1rem;
      }
      @media(min-width: 1024px){
        padding: 0px 1rem;
      }
    }
    .green{
      color: #4CAF50;
      font-size: .85rem;
      font-weight: 600;
    }
    .upload{
      margin-left: 14px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0px 1rem;
    }

    .disabled{
      opacity: 0.8;
      cursor: auto;
      svg{
        #iconFile_path{
          stroke: #999;
        }
        #iconFile_g{
          fill: #999;
        }
      }
    }
    
    .canceled{
      color: #F53748;
      font-size: .85rem;
      font-weight: 600;
    }
    
    span{
        display: flex;
        align-items:center;
        font-size: .75rem;
        text-align: left;
        color: #7A819A;
        font-weight:bold;
        b{
          font-size: 1.1rem;
          color: #F4F7FA;
          text-align: center;
          line-height: 2rem;
          display: block;
          border-radius: .55rem;
          width: 2rem;
          height: 2rem;
          margin-right: .375rem;
        }

        .predicta{
            background-color: #15B193;
        }
    }
`;

export const CardButton = styled.div`
    text-align: right;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    line-height: 5px;
    font-weight: 600;
`;
