import { ReactElement, useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  ModalButton,
  ModalButtonBox, ModalButtonClose, CardInfo, CardButton, CardScheduleWrapper, Load
} from './styles';
import { deleteSchedule } from '../../utils/services';
import { CONFIRMATION_STEP_INFO, OPEN_MODAL, RESCHEDULE, ScheduleRootState, SET_RELOAD, UserInfo } from '../../store/redux';
import { useDispatch, useSelector } from 'react-redux';

import { unitsPredicta } from '../../utils/units';
// console.log(unitsPredicta);

const CardSchedule = ({ schedule }: any): ReactElement => {
  // const [lastStep, setLastStep] = useState<any>({});
  const useLocalStorage = (key: string, delay?: number) => {
    const [result, setResult] = useState<any>({});
    const startTime = new Date().getTime();
    useEffect(() => {
      const interval = setInterval(() => {
        const now = new Date().getTime();
        if (now - startTime > (delay ?? 1000) * 10) {
          clearInterval(interval);
          return;
        }
        const value = localStorage.getItem(key);
        if (value !== '' && value != null) {
          const data = JSON.parse(value);
          if (Object.keys(data).length === 0) { return; }
          setResult(data);
          clearInterval(interval);
        }
      }, delay ?? 1000);
      return () => clearInterval(interval);
    }, []);
    return result;
  };

  const lastStep = useLocalStorage('lastStep', 100);

  const [clinicFullName, setClinicFullName] = useState<string>('');

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const scheduleDate = format(parseISO(schedule.appointmentDate), 'cccccc, dd/MM', { locale: ptBR });
  const user = useSelector<ScheduleRootState, UserInfo>(scheduleReducer => scheduleReducer.user);
  const reload = useSelector<ScheduleRootState, boolean>(scheduleReducer => scheduleReducer.reload);
  const userDataLoaded = useSelector<ScheduleRootState, boolean>(scheduleReducer => scheduleReducer.userDataLoaded);

  const handleCancel = async (idSchedule: string, stepId: number, document: string): Promise<any> => {
    setLoading(true);
    await deleteSchedule(idSchedule, stepId, document);
    dispatch({ type: SET_RELOAD, load: !reload });
    setLoading(false);
    setModalOpen(!modalOpen);
    location.reload();
  };

  const handleReschedule = async (idSchedule: any): Promise<any> => {
    dispatch({ type: RESCHEDULE, rescheduleId: idSchedule });
    dispatch({ type: OPEN_MODAL });
  };

  const handleScheduleInfo = async (idSchedule: any): Promise<any> => {
    dispatch({ type: CONFIRMATION_STEP_INFO, rescheduleId: idSchedule });
    dispatch({ type: OPEN_MODAL });
  };

  const handleModal = (): any => {
    setModalOpen(!modalOpen);
    setConfirmation(false);
  };

  useEffect(() => {
    if (Object.keys(lastStep).length > 0) {
      return;
    }

    const startTime = new Date().getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();

      if (now - startTime > 5000) {
        clearInterval(interval);
        return;
      }

      const value = localStorage.getItem('lastStep');

      if (value !== '' && value != null) {
        const data = JSON.parse(value);

        if (Object.keys(data).length === 0) {
          return;
        }

        // setLastStep(data);
        clearInterval(interval);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [userDataLoaded]);

  useEffect(() => {
    if (Object.keys(lastStep).length === 0) {
      return;
    }

    const clinic = unitsPredicta.unidades.filter((item: any) => item.id.toString() === lastStep?.unityId);

    // console.log('clinic', clinic);

    if (clinic.length !== 0) {
      setClinicFullName(clinic[0].fullName);
    }
  }, [lastStep]);

  return (
    <CardScheduleWrapper>
      <CardInfo>
        <h2>{scheduleDate.charAt(0).toUpperCase() + scheduleDate.slice(1)} | {schedule.appointmentHour}</h2>
        <h3>{schedule.appointmentStatus}</h3>
        <span>{clinicFullName}</span>

      </CardInfo>
      <CardButton onClick={() => { setModalOpen(!modalOpen); }}>
        .<br />.<br />.<br />
      </CardButton>
      {
        modalOpen &&
        <ModalButtonBox>
          {confirmation
            ? (
              <>
                <h3>Tem certeza que deseja cancelar esse agendamento?</h3>
                {loading
                ? <Load/>
                : <div>
                <div className="flex">
                  <button className="confirmation" onClick={() => { handleCancel(schedule.scheduleId, user.step, user.document); }}>
                    Sim
                  </button>
                  <button onClick={() => { handleModal(); }} className="confirmation close">
                    Não
                  </button>
                </div>
                  </div>}
              </>
            )
            : (
              <>
                <ModalButton onClick={() => { handleScheduleInfo(schedule.scheduleId); }}>
                  Detalhes de agendamento
                </ModalButton>
                <ModalButton onClick={() => { handleReschedule(schedule.scheduleId); }}>
                  Reagendar
                </ModalButton>
                <ModalButton onClick={() => { setConfirmation(true); }}>
                  Cancelar agendamento
                </ModalButton>
              </>
            )}
          <ModalButtonClose onClick={() => { handleModal(); }}>X</ModalButtonClose>
        </ModalButtonBox>
      }
    </CardScheduleWrapper>
  );
};

export default CardSchedule;
