import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OPEN_MODAL, RESCHEDULE, ScheduleRootState } from '../../store/redux';
import Load from '../../ui/SimpleLoad';

import { DateRange } from '@material-ui/icons/';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { IconCheck, IconCircleGene, IconFile, IconSquareClock, IconUpload } from '../../assets/icons.js';
import { getSteps } from '../../utils/services';
import {
  ActualStep,
  BtnHistoric,
  ButtonWrapper,
  Circle,
  CircleIcon,
  ContainerHistory,
  Content,
  Header,
  HeaderItem,
  Li,
  TextWrapper,
  ToolTip,
  Ul
} from './styles';

import Button from '../../ui/Button';
import Label from '../../ui/Label';
import ProgressBarLocal from '../../ui/ProgressBar';
// import { ProgressBar } from '../../components/ProgressBar/ProgressBar';
type props = {
  reverse?: boolean
  buttonSchedule?: boolean
  buttonReschedule?: boolean
  page?: string
  setResultsOpen?: Function | any
};
export default function Historic(props: props): ReactElement {
  const [historicOpen, setHistoricOpen] = useState(false);
  const [steps, setSteps] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [postSchedule, setPostSchedule] = useState(false);
  const [progress, setProgress] = useState(0);
  const [width, setWindowWidth] = useState(0);
  const dispatch = useDispatch();

  // const user = useSelector<ScheduleRootState, UserInfo>(scheduleReducer => scheduleReducer.user);
  // const userDataLoaded = useSelector<ScheduleRootState, boolean>(scheduleReducer => scheduleReducer.userDataLoaded);
  // const totalSteps = user.plan === 2 ? 7 : 1;

  const user = JSON.parse(localStorage.getItem('user') ?? '[]');
  // eslint-disable-next-line no-debugger
  // debugger;
  const userPlanIsOne: boolean = user.product === 1;
  const planInfinityOver: boolean = user.step === 7;
  const totalSteps = user.product === 2 ? 7 : 1;
  const showDesktopComponent: boolean = width > 1024;

  const reload = useSelector<ScheduleRootState, boolean>(scheduleReducer => scheduleReducer.reload);
  const handleHistoric = (isOpen: boolean): void => {
    setHistoricOpen(isOpen);
  };
  const stepsHandle = async (): Promise<void> => {
    setLoading(true);
    try {
      const { data, postSchedule } = await getSteps(user?.document ?? '') || {};
      setPostSchedule(postSchedule?.status ?? false);
      localStorage.setItem('postSchedule', postSchedule.status);
      setSteps(data);
      setProgress(data?.length === 0 ? 1 : data?.length);
    } catch (error) {
      console.log('ERROR_STEP_HANDLE', JSON.stringify(error));
    }
    setLoading(false);
  };

  const filterScheduledStep = (steps: any): any => {
    const scheduled = steps.filter((element: { appointmentStatus: string }) => (element.appointmentStatus === 'Agendada' || element.appointmentStatus === 'Em análise'));
    return scheduled;
  };

  useEffect(() => {
    if (user?.document) {
      stepsHandle();
    }
  }, [reload]);
  // const dataHistory =
  //   {
  //     document: user.document,
  //     currentStep: user.step + 1,
  //     status: 'Agendada'
  //   };

  const handleReschedule = async (idSchedule: any): Promise<any> => {
    dispatch({ type: RESCHEDULE, rescheduleId: idSchedule });
    dispatch({ type: OPEN_MODAL });
  };

  // Responsive
  const updateDimensions = () => {
    const width = window.outerWidth;
    setWindowWidth(width);
  };

  useEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <Content>
      {showDesktopComponent
        ? <>
          <Header>
            <HeaderItem>
              <IconCircleGene />
            </HeaderItem>
            <HeaderItem>
              <p>
                Predicta
              </p>
              <strong>
                {user.products}
              </strong>
            </HeaderItem>
            <HeaderItem lastItem>
              <Label>
               { userPlanIsOne ? 'Predicta One' : 'Predicta Infinity'}
              </Label>
            </HeaderItem>
          </Header>
          <ProgressBarLocal isOne={userPlanIsOne} stepStatus={user.stepStatus} progress={progress} steps={totalSteps} />
          <BtnHistoric open={historicOpen} onClick={() => handleHistoric(!historicOpen)}>
          </BtnHistoric>

          <ContainerHistory open={historicOpen}>
            {loading
              ? (
                <Ul>
                  <Load top='60px' left='48%' color='#15B193' />
                </Ul>
              )
              : (
                <>
                  <Ul>
                    <li>
                      <ActualStep>
                        <Circle />
                        <div>

                          {userPlanIsOne ? 'Kit de Coleta' : steps?.length === 0 ? 'Etapa 1 à agendar ' : `Etapa ${steps.slice(-1)[0].stepStatusId} ${steps.slice(-1)[0].appointmentStatus}`
                          }
                        </div>
                        <ButtonWrapper>
                          {((user.stepStatus === 'Agendada' || user.stepStatus === 'Em análise') && user.step !== 10) &&
                            (
                              <div>
                                <Button
                                  width={150}
                                  bkColor="#E1E6EF"
                                  color="#15B193"
                                  fontWeight="bold"
                                  border="none"
                                  onClick={() => {
                                    const scheduled = filterScheduledStep(steps);
                                    handleReschedule(scheduled[0]?.schedulingId);
                                  }}
                                >
                                  Remarcar
                                </Button>
                              </div>
                            )
                          }
                          {(user.stepStatus !== 'Agendada' && user.stepStatus !== 'Em análise') && !userPlanIsOne
                            ? (<div>
                              <Button
                                onClick={() => dispatch({ type: OPEN_MODAL })}
                                width={150}
                                bkColor="#15B193"
                                fontWeight="bold"
                                disabled={userPlanIsOne || (user.step >= 3 && user.stepStatus === 'Realizada' && !postSchedule)}
                              >
                                Agendar
                              </Button>
                            </div>)
                            : ''
                          }
                          {user.step >= 3 && user.stepStatus === 'Realizada' && !postSchedule && !planInfinityOver && <ToolTip> Disponível após a emissão do laudo anual. </ToolTip>}
                        </ButtonWrapper>
                      </ActualStep>
                    </li>
                  </Ul>
                  <Ul reverse={props.reverse}>
                    {steps?.length === 0
                      ? (
                        <Li>
                          <CircleIcon
                            className='actual'
                            index={1}
                          >
                            <IconSquareClock />
                          </CircleIcon>
                          <TextWrapper>
                            <div>
                              {user.products}
                              <p>
                                <small>Predicta</small>
                              </p>
                            </div>
                            <div className="border" />
                            <div className="border-md">
                              <p>
                                {userPlanIsOne ? 'Laboratório' : 'Consulta pré-exame'}
                              </p>
                              <small>{userPlanIsOne && 'Envio do Kit de Coleta'}</small>
                            </div>
                            <div className="border" />
                            <div>
                              Etapa {user.step + 1}
                            </div>
                            <div className="border" />
                            <div>
                              {userPlanIsOne
                                ? (user.stepStatus === 'Executado' ? 'Realizado' : user.stepStatus === 'analise' ? 'Em análise' : 'Envio de Kit para Coleta')
                                : (
                                  <Button disabled={userPlanIsOne || (user.step >= 3 && user.stepStatus === 'Realizada' && !postSchedule)} onClick={() => dispatch({ type: OPEN_MODAL })} width={130}>Agendar</Button>
                                )
                              }
                            </div>
                          </TextWrapper>
                        </Li>
                      )
                      : (
                        <>
                          {steps?.map((step: any, index: number) => {
                            return (
                              <Li key={index}>
                                <CircleIcon
                                  index={
                                    props.reverse ? index + 1 === 1 ? 1 : 0 : index + 1 === steps?.length ? 1 : 0
                                  }
                                >
                                  {(step.appointmentStatus === 'Agendada' || step.appointmentStatus === 'Em análise') ? <DateRange /> : <IconCheck />}
                                </CircleIcon>
                                <TextWrapper page={props.page}>
                                  <div>
                                    {user.products}
                                    <p>
                                      <small>Predicta</small>
                                    </p>
                                  </div>
                                  <div className="border" />
                                  <div className="border-md">
                                    <p>
                                      {step.doctor.toLowerCase()}
                                    </p>
                                    <p>
                                      <small>{step.stepStatusDescription}</small>
                                    </p>
                                  </div>
                                  <div className="border" />
                                  {props.page === 'historic'
                                    ? (
                                      <>
                                        <div className="date">
                                          {format(parseISO(step.registered), 'dd/MM/yyyy', { locale: ptBR })} | {format(parseISO(step.registered), 'H:mm', { locale: ptBR })}
                                        </div>
                                        <div className="border" />
                                        <div>
                                          Etapa {step.stepStatusId}
                                        </div>
                                        <div className="border" />
                                        <div className="padding-0">
                                          <button onClick={() => props.setResultsOpen(true)}>
                                            <IconFile />
                                            <h4>Arquivos</h4>
                                          </button>
                                        </div>
                                        <div className="border" />
                                        <div className="upload">
                                          <button title="Enviar Arquivos"> <IconUpload /> </button>
                                        </div>
                                      </>
                                    )
                                    : (
                                      <>
                                        <div>
                                          Etapa {step.stepStatusId === 10 ? 1 : step.stepStatusId}
                                        </div>
                                        <div className="border" />
                                        <div className={step.appointmentStatus === 'Realizada' ? 'status green' : 'status'}>
                                          {(!step.url || (step.appointmentStatus !== 'Agendada' && step.appointmentStatus !== 'Em análise'))
                                            ? (
                                              <>
                                                {step.appointmentStatus === 'Agendar' ? <Button onClick={() => dispatch({ type: OPEN_MODAL })} width={130}>{step.appointmentStatus}</Button> : step.appointmentStatus}
                                              </>
                                            )
                                            : (
                                              <Button onClick={() => window.open(step.url)} width={130}>Ingressar</Button>
                                            )
                                          }
                                        </div>
                                      </>
                                    )
                                  }
                                </TextWrapper>
                              </Li>
                            );
                          })}
                        </>
                      )}
                    {((user.stepStatus !== 'Agendada' && user.stepStatus !== 'Em análise' && !userPlanIsOne) && props.page !== 'historic' && user.step !== 0 && !planInfinityOver) && (
                      <Li>
                        <CircleIcon
                          className={props.reverse ? 'actual' : 'actual after'}
                          index={props.reverse ? 0 : 1}
                        >
                          <IconSquareClock />
                        </CircleIcon>
                        <TextWrapper>
                          <div>
                            {user.products}
                            <p>
                              <small>Predicta</small>
                            </p>
                          </div>
                          <div className="border" />
                          <div className="border-md">
                            <p>
                              {user.step === 1 ? 'Coleta' : user.step === 2 ? 'Consulta pós-exame' : 'Consulta anual'}
                            </p>
                          </div>
                          <div className="border" />
                          <div>
                            Etapa {user.step + 1}
                          </div>
                          <div className="border" />
                          <div>
                            <Button disabled={userPlanIsOne || (user.step >= 3 && user.stepStatus === 'Realizada' && !postSchedule)} onClick={() => dispatch({ type: OPEN_MODAL })} width={130}>Agendar</Button>
                          </div>
                        </TextWrapper>
                      </Li>
                    )}
                  </Ul>
                </>
              )}
          </ContainerHistory>
        </>
        : <>
          <Header>
            <HeaderItem>
              <IconCircleGene />
            </HeaderItem>
            <HeaderItem>
              <p>
                Programa
              </p>
              <strong>
                {user.products}
              </strong>
            </HeaderItem>
            <HeaderItem lastItem>
              <Label>
                Predicta
              </Label>
            </HeaderItem>
          </Header>
          {/* <ProgressBar progress={props.progress} steps={props.steps}/> */}
          <ProgressBarLocal isOne={userPlanIsOne} stepStatus={user.stepStatus} progress={progress} steps={totalSteps} />
          <BtnHistoric open={historicOpen} onClick={() => handleHistoric(!historicOpen)}>
          </BtnHistoric>
          <ContainerHistory open={historicOpen}>
            {loading
              ? (
                <Ul>
                  <Load top='60px' left='40%' color='#15B193' />
                </Ul>
              )
              : (
                <>
                  <Ul>
                    <li>
                      <ActualStep>
                        <Circle />
                        <div>
                          {userPlanIsOne ? 'Envio do Kit de Coleta' : steps?.length === 0 ? 'Etapa 1 à agendar ' : `Etapa ${steps.slice(-1)[0].stepStatusId} ${steps.slice(-1)[0].appointmentStatus}`
                          }
                        </div>
                        <ButtonWrapper>
                          {((user.stepStatus === 'Agendada' || user.stepStatus === 'Em análise') && user.step !== 10) &&
                            (
                              <div>
                                <Button
                                  width={150}
                                  bkColor="#E1E6EF"
                                  color="#15B193"
                                  fontWeight="bold"
                                  border="none"
                                  onClick={() => {
                                    const scheduled = filterScheduledStep(steps);
                                    handleReschedule(scheduled[0]?.schedulingId);
                                  }}
                                >
                                  Remarcar
                                </Button>
                              </div>
                            )
                          }
                          {(user.stepStatus !== 'Agendada' && user.stepStatus !== 'Em análise') && !userPlanIsOne
                            ? (<div>
                              <Button
                                onClick={() => dispatch({ type: OPEN_MODAL })}
                                width={150}
                                bkColor="#15B193"
                                fontWeight="bold"
                                disabled={userPlanIsOne || (user.step >= 3 && user.stepStatus === 'Realizada' && !postSchedule)}
                              >
                                Agendar
                              </Button>
                            </div>)
                            : ''
                          }
                          {user.step >= 3 && user.stepStatus === 'Realizada' && !postSchedule && !planInfinityOver && <ToolTip> Disponível após a emissão do laudo anual. </ToolTip>}
                        </ButtonWrapper>
                      </ActualStep>
                    </li>
                  </Ul>
                  <Ul reverse={props.reverse}>
                    {steps?.length === 0
                      ? (
                        <Li>
                          <CircleIcon
                            className='actual'
                            index={1}
                          >
                            <IconSquareClock />
                          </CircleIcon>
                          <TextWrapper>
                            <div className="border-md">
                              <p>
                                {userPlanIsOne ? 'Envio do Kit de Coleta' : 'Consulta pré-exame'}
                              </p>
                            </div>
                            <div className="border" />
                            <div>
                              {userPlanIsOne
                                ? (user.stepStatus === 'Executado' ? 'Realizado' : user.stepStatus === 'analise' ? 'Em análise' : 'Envio de Kit para Coleta')
                                : (
                                  <Button disabled={userPlanIsOne || (user.step >= 3 && user.stepStatus === 'Realizada' && !postSchedule)} onClick={() => dispatch({ type: OPEN_MODAL })} width={130}>Agendar</Button>
                                )
                              }
                            </div>
                          </TextWrapper>
                        </Li>
                      )
                      : (
                        <>
                          {steps?.map((step: any, index: number) => {
                            return (
                              <Li key={index}>
                                <CircleIcon
                                  index={
                                    props.reverse ? index + 1 === 1 ? 1 : 0 : index + 1 === steps?.length ? 1 : 0
                                  }
                                >
                                  {(step.appointmentStatus === 'Agendada' || step.appointmentStatus === 'Em análise') ? <DateRange /> : <IconCheck />}
                                </CircleIcon>
                                <TextWrapper page={props.page}>
                                  <div className="border-md">
                                    <p>
                                      <small>{step.stepStatusDescription}</small>
                                    </p>
                                  </div>
                                  <div className="border" />
                                  {props.page === 'historic'
                                    ? (
                                      <>
                                        <div className="date">
                                          {format(parseISO(step.registered), 'dd/MM/yyyy', { locale: ptBR })} | {format(parseISO(step.registered), 'H:mm', { locale: ptBR })}
                                        </div>
                                        <div className="border" />
                                        <div>
                                          Etapa {step.stepStatusId}
                                        </div>
                                        <div className="border" />
                                        <div className="padding-0">
                                          <button onClick={() => props.setResultsOpen(true)}>
                                            <IconFile />
                                            <h4>Arquivos</h4>
                                          </button>
                                        </div>
                                        <div className="border" />
                                        <div className="upload">
                                          <button title="Enviar Arquivos"> <IconUpload /> </button>
                                        </div>
                                      </>
                                    )
                                    : (
                                      <>
                                        <div className={step.appointmentStatus === 'Realizada' ? 'status green' : 'status'}>
                                          {(!step.url || (step.appointmentStatus !== 'Agendada' && step.appointmentStatus !== 'Em análise'))
                                            ? (
                                              <>
                                                {step.appointmentStatus === 'Agendar' ? <Button onClick={() => dispatch({ type: OPEN_MODAL })} width={90}>{step.appointmentStatus}</Button> : step.appointmentStatus}
                                              </>
                                            )
                                            : (
                                              <Button onClick={() => window.open(step.url)} width={90}>Ingressar</Button>
                                            )
                                          }
                                        </div>
                                      </>
                                    )
                                  }
                                </TextWrapper>
                              </Li>
                            );
                          })}
                        </>
                      )}
                    {(user.stepStatus !== 'Agendada' && user.stepStatus !== 'Em análise') && props.page !== 'historic' && user.step !== 0 && (
                      <Li>
                        <CircleIcon
                          className={props.reverse ? 'actual' : 'actual after'}
                          index={props.reverse ? 0 : 1}
                        >
                          <IconSquareClock />
                        </CircleIcon>
                        <TextWrapper>
                          <div className="border-md">
                            <p>
                              {user.step === 1 ? 'Coleta' : user.step === 2 ? 'Consulta pós-exame' : 'Consulta anual'}
                            </p>
                          </div>
                          <div className="border" />
                          <div>
                            <Button disabled={userPlanIsOne || (user.step >= 3 && user.stepStatus === 'Realizada' && !postSchedule)} onClick={() => dispatch({ type: OPEN_MODAL })} width={90}>Agendar</Button>
                          </div>
                        </TextWrapper>
                      </Li>
                    )}
                  </Ul>
                </>
              )}
          </ContainerHistory>
        </>
      }
    </Content>
  );
}
