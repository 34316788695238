import UniversalCookies from 'universal-cookie';

/* eslint-disable */
class AppCookies {
  static cookies = new UniversalCookies(null, { path: '/' });

  public static get(key: string) {
    return this.cookies.get(key);
  }

  public static set(key: string, value: any) {
    return this.cookies.set(key, JSON.stringify(value));
  } 
}

export default AppCookies;
/* eslint-enable */
