import React, { ReactElement, useEffect } from 'react';
import { Background } from './styles';
type props ={
  noLabel?: boolean
};
export default function Load(props: props): ReactElement {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const spinner = document.querySelector<HTMLElement>('.loading-spinner')!;
    const length = document.querySelector<SVGGeometryElement>('#loopingPath path');
    const totalLength = length?.getTotalLength();
    spinner.style.setProperty('--total-length', String(totalLength
    ));
  }, []);
  return (
    <Background>
      <svg className="display-none">
        <defs>
          <symbol id="loopingPath">
            <title>Loading</title>
            <path d="M94.65,14a27.5,27.5,0,1,1-.17,39.88L52.93,14.06a27.5,27.5,0,1,0,.12,39.77Z" fill="none" strokeMiterlimit="10"/>
          </symbol>
        </defs>
      </svg>
      <svg className="loading-spinner">
        <use id="background" xlinkHref="#loopingPath"/>
        <use id="master" xlinkHref="#loopingPath"/>
      </svg>
        { !props.noLabel && 'Confirmando compra'}
        {/* Confirmando compra */}
    </Background>
  );
}
