import styled from 'styled-components';
export const Background = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #6aabe1;
    color: white;
    font-size: 20px;
    font-weight: 600;
    
    .loading-spinner {
      --looping-percent: 82;
    
      [id="master"],
      [id="background"] {
        strokeWidth: 14;
      }
      
      [id="background"] {
        stroke: #6aabe1;
      }
      
      [id="master"] {
        // Edge don't like calc with css variables, so use Scss variables instead for calculation
        --looping-length: calc((var(--looping-percent) * var(--total-length)) / 100);
        stroke: #fff;
        stroke-dasharray: var(--looping-length), calc(var(--total-length) - var(--looping-length));
        animation: loading-loop 1.8s linear infinite;
      }
    }
    
    @keyframes loading-loop {
      from { stroke-dashoffset: var(--total-length); }
      to   { stroke-dashoffset: 0; }
    }
    .loading-spinner { width: 150px; }
`;
