import styled from 'styled-components';
import { Container } from '../../styles/GlobalStyle';

export const HeaderSite = styled.header`
    position: fixed;
    background-color: #fff;
    height: 52px;
    width: 100%;
    top: 0;
    z-index: 30;

    .logo-header{
        max-width: 40px;

        svg{
            max-width: 100%;
        }
    }

    &:before{
        content: '';
        position: absolute;
        width: 100%;
        background-image: linear-gradient(to right, #F1AD51 0%, #DE656B 30%, #4595C4 60% , #3AD5CA 100%);
        height: 4px;
        bottom: -4px;
    }
`;

export const HeaderContainer = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 600px) {
        padding-top: 10px;
    }
`;

export const MenuMobileWrapper = styled.div`
    position: absolute;
    height: calc(100vh - 52px);
    width: 100%;
    max-width: 320px;
    background: white;
    left: 0px;
    top: 56px;
    display: flex;
    flex-direction: column;
    // padding: 2rem;
`;

export const MenuMobile = styled.nav` 
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-bottom: 1px solid #ddd;

    a{
        padding: 1rem;
    }
`;
export const BtnWrapper = styled.div`

    padding: 2rem;

    .btn{
        border-radius: 20px;
        margin: 0 auto;
        width: 100%;
        max-width: 230px;
        padding: .5rem 1rem;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        display: block;
    }
    .orange{
        background-color: #E0832C;
        color: #fff;
    }
    .outline{
        border: solid 1px #1F4E8D;
        color: #004F92;
    }

    @media(max-width: 768px){

        .btn{
            margin-bottom: 1rem;
        }
    }

    @media(min-width: 769px){  
        display:flex;
        justify-content: center;
        margin-left: auto;
        align-items:center;

        .outline{
            margin-left: 1rem;
        }
    }
`;

export const BtnMenu = styled.button`
    width: 30px;
    height:4px;
    border-radius: 2px;
    background-color: #004F92;
    margin-left: 1rem;
    position: absolute;

    &:before,
    &:after{
        content: '';
        position: absolute;
        width: 30px;
        height:4px;
        border-radius: 2px;
        background-color: #004F92;
        left: 0;
        right: 0;
    }

    &:before{
        top: -8px;
    }

    &:after{
        bottom: -8px;
    }
`;

export const MenuBackground = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,.3);
    z-index: -1;
    top: 56px;
`;

export const LogoWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: auto;
    align-items: center;
    font-size: 1.5rem;
    color: #004F92;
    font-weight: 600;

    .logo-header{
        margin-right: 14rem;
        @media only screen and (max-width: 600px) {
            width: 100%;
            display: contents;
            
            img {
                max-height: 28px;
                width: auto;
            }
        }
    }

    .label{
        margin-left: 1rem;
    }
`;
