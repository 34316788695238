import styled from 'styled-components';

const GetBorder = (isSuccess: boolean, isError: boolean) => {
  if (isSuccess) return '#004f92';
  if (isError) return '#FF5353';
  return '#8E959A';
};

export interface StyleProps {
    isSuccess: boolean
    isError: boolean
}

export const Form = styled.form<StyleProps>`
    display: flex;
    position: relative;
    border: 1px solid ${({ isSuccess, isError }: StyleProps) => GetBorder(isSuccess, isError)};
    border-radius: 5px;
    min-width: 260px;
    margin: 1.2rem 0 1rem 0;
    width: 100%;    
    :hover {
      ${({ isSuccess, isError }: StyleProps) => !isSuccess && !isError && 'border: 1px solid #0a0a0a;'}
     
    }
`;
