/* eslint-disable array-callback-return */
/* eslint-disable multiline-ternary */
/* eslint-disable */
import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import Header from './Header';
import Login from './Login';
import Payment from './Payment';
import Load from '../../ui/Load';
import Warning from '../../ui/ModalWarning';

import {
  searchDocument,
  login,
  checkEligibility,
  register
} from './Payment/utils/purchaseService';
import CloseIcon from '@material-ui/icons/Close';
// import { TermsModule } from '@einstein/terms-module';

import { Cpf, Phone, DateM } from '../../utils/masks';
import { Redirect, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { CONFIG } from '../../config/env';

import {
  ScheduleRootState,
  LOGIN_PURCHASE,
  IS_REGISTERED,
  // PURCHASE_PRONTUARIO,
  Purchase
} from '../../store/redux';

import {
  Content,
  FormWrapper,
  Main,
  Alert,
  Box,
  ErrorBox,
  Title,
  Wrapper,
  OrderDetailTitle,
  OrderIconTitle,
  OrderTitle,
  OrderResume,
  TermsOverlay,
  OrderDetailDiv,
  OrderDetailIcon,
  OrderDetail
} from './styles';

import { BsCheckCircleFill, IconCheck, IconClose } from '../../assets/icons';
import { useWarning } from './Payment/hooks/warning';

import { useInstallments } from '../../hooks/installments';

import Dropdown from './Payment/Layout/Dropdown';
import TagManager from 'react-gtm-module';
import SimpleLoad from '../../ui/SimpleLoad';
import {
  Installments,
  ProductCatalog,
  ServiceProducts,
  Diferenciais,
  ProductsMock,
  ServiceProductsMock,
  Differentials,
  Plan,
  Differential,
  Services,
  DifferentialMocks
} from '../../interfaces';
import { Rules } from '../../enums';
import {
  getCatalogo,
  getCatalogoLambda,
  getCatalogoMock
} from '../../services/catalogo.service';

import { VoucherInput } from '../../components/VoucherInput';
import { useAuth } from '../../contexts/Auth/context';
import { getAuthUser } from '../../services/user/getAuthUser';
// import { getPlatformToken } from '../../services/payment/getPaymentToken';
import { useTotalValues } from '../../hooks/totalValues';
import { Total } from '../../components/Total';
import { diferentialsPlans } from '../../utils/diferentials';
import Simpleload from '../../ui/SimpleLoad';
import axios from 'axios';

export default function Compra(): ReactElement {
  const dispatch = useDispatch();
  const {
    setUser,
    setProductId,
    setServiceId,
    setPlatformToken,
    productId,
    setAuthPatientToken,
    setAuthToken,
    platformToken
  } = useAuth();

  const { setTotalValues, totalValues } = useTotalValues();
  const { handleInstallments } = useInstallments();

  const purchase = useSelector<ScheduleRootState, Purchase | undefined>(
    (scheduleReducer) => scheduleReducer.purchase
  );
  const fullDiscountVoucher = useSelector<ScheduleRootState, boolean>(
    (scheduleReducer) => scheduleReducer.fullDiscountVoucher
  );
  const [step, setStep] = useState('email');
  const { message, removeWarning } = useWarning();
  const predictaOne = process.env.REACT_APP_PREDICTA_PRODUCT_ONE_ID;

  // INPUTS
  const [paymentMethod, setPaymentMethod] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [date, setDate] = useState('');
  const [sex, setSex] = useState('');
  const [phone, setPhone] = useState('');
  const [nationality] = useState('');
  const [cpf, setCpf] = useState('');
  const [passport, setPassport] = useState('');
  const [typeDocument, setDocumentType] = useState('');
  const [erro, setErro] = useState('');

  const [planId, setPlanId] = useState<number>(0);

  const [alert, setAlert] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showTerms, setShowTerms] = useState(true);
  const [infinityLoad, setInfinityLoad] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [terms, setTerms] = useState(false);
  const [contract, setContract] = useState(false);
  const [warning, setWarning] = useState(false);

  const [service, setService] = useState<Services>({} as Services);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [plan, setPlan] = useState<any>();
  const [productsSVC, setProductsSVC] = useState<ProductCatalog[]>([]);
  const [diferenciais, setDiferenciais] = useState<DifferentialMocks[]>([]);


  useEffect(() => {
    handleStep('paymentMethod')
  }, [])

  const filteredProducts = plans || [];

  function closeAlert(): void {
    setAlert(false);
  }

  function handleEmail(e: React.ChangeEvent<HTMLInputElement>): void {
    setEmail(e.target.value);
  }

  function handlePassword(e: React.ChangeEvent<HTMLInputElement>): void {
    setPassword(e.target.value);
  }

  function handleConfirmPassword(e: React.ChangeEvent<HTMLInputElement>): void {
    setConfirmPassword(e.target.value);
  }

  function handleName(e: React.ChangeEvent<HTMLInputElement>): void {
    setName(e.target.value);
  }
  function handleLastName(e: React.ChangeEvent<HTMLInputElement>): void {
    setLastName(e.target.value);
  }
  function handleDate(e: React.ChangeEvent<HTMLInputElement>): void {
    setDate(DateM(e.target.value));
  }
  function handleSex(e: React.ChangeEvent<HTMLSelectElement>): void {
    setSex(e.target.value);
  }
  function handlePhone(e: React.ChangeEvent<HTMLInputElement>): void {
    setPhone(Phone(e.target.value));
  }

  function handleCpf(e: React.ChangeEvent<HTMLInputElement>): void {
    const inputValue = e.target.value;
    if (inputValue.length === 11 || inputValue.length === 14) {
      const formatedCpf = Cpf(inputValue);
      setCpf(formatedCpf);
      setDocumentType('CPF');
    }
    if (inputValue.length < 11) {
      setCpf(inputValue);
      setDocumentType('PASSAPORTE');
      setPassport(inputValue);
    }
  }

  function handleTerms(e: React.ChangeEvent<HTMLInputElement>): void {
    setTerms(e.target.checked);
  }
  function handleContract(e: React.ChangeEvent<HTMLInputElement>): void {
    setContract(e.target.checked);
  }

  async function handleStep(stepName: string): Promise<any> {
    let documentFormated = '';

    if (typeDocument === 'CPF') {
      documentFormated = cpf.replace(/[^\d]+/g, '');
    } else {
      documentFormated = cpf;
    }

    switch (stepName) {
      case 'login':
        setWarning(false);
        if (cpf) {
          setLoading(true);
          const searchResponse = await searchDocument(documentFormated);
          if (searchResponse === 200) {
            dispatch({
              type: IS_REGISTERED,
              search: cpf,
              number: searchResponse
            });
          } else {
            dispatch({ type: IS_REGISTERED, search: cpf, number: 400 });
          }
          setLoading(false);
          setStep('login');
        } else {
          setErro('Digite o CPF ou passaporte');
        }
        break;

      case 'paymentMethod':

        setLoading(true);
        const loginRet = login();

        setAuthPatientToken(loginRet.patientToken);

        setAuthToken(loginRet.token);

        await getAuthUser({
          authToken: loginRet?.token,
          patientToken: loginRet?.patientToken
        })
          .then((user) => {
            setUser(user);
            setServiceId(service.id);
          })
          .catch((err) => {
            console.log(err);
          });

        if ('token' in loginRet) {
          dispatch({
            type: LOGIN_PURCHASE,
            search: loginRet.token,
            param: ''
          });

          // const ResponseEligibility = await checkEligibility(cpf);

          /* if (!('message' in ResponseEligibility.response)) {
            setRedirect(true);
          } */
          setStep('paymentData');
        } else {
          dispatch({ type: LOGIN_PURCHASE, error: ' ' }); // TODO: não existe mensagem de erro data do login, verificar a possibilidade de realizar o tratamento
        }
        setLoading(false);
        break;

      case 'userData':
        if (!name || !lastName || !sex || !nationality || !phone || !cpf) {
          const listErros = [];
          if (!name) {
            listErros.push('Insira o nome do titular do cartão');
          }
          if (!lastName) {
            listErros.push('Insira o sobrenome do titular do cartão');
          }
          if (!sex) {
            listErros.push('Insira o gênero do titular do cartão');
          }
          if (!nationality) {
            listErros.push('Insira a nacionalidade do titular do cartão');
          }
          if (!phone) {
            listErros.push('Insira o telefone do titular do cartão');
          }
          if (!cpf) {
            listErros.push('Insira o cpf do titular do cartão');
          }
          dispatch({
            type: LOGIN_PURCHASE,
            error: 'Preencha as informações abaixo: ',
            errors: listErros
          });
        } else {
          dispatch({ type: LOGIN_PURCHASE, error: '', errors: [] });
          setStep(stepName);
        }
        break;

      case 'finish':
        console.log('finish');
        break;

      default:
        setStep(stepName);
    }
  }

  const handleAcceptTerms = () => {
    setShowTerms(false);
    setStep('paymentMethod');
  };

  const handleInstallmentsData = () => {
    return plans.forEach((product) => {
      if (String(product.id) === String(productId)) {
        const info = {} as Installments;

        handleInstallments({
          product_id: String(product.id),
          price: Number(product.price),
          installmentsMax: product.max_installments ?? 0,
          installmentsNotFees: product.installments_without_fee ?? 0,
          installmentsPercentFees: Number(product.installments_percent) ?? 0,
          hasInstallments: product.allow_installments ?? false
        });
      }
    });
  };

  useEffect(() => {
    handleInstallmentsData();
  }, [productsSVC, productId, handleInstallments, message]);

  async function getProductsInCatalog() {
    try {
      const selectProductLandPage = localStorage.getItem('selectedProduct');

      const service = await getCatalogo();
      setService(service);

      const availablePlans: Plan[] = [];
      service.plans.map((plan) => {
        /** Não exibir consulta avulsa no select */
        if (plan.id !== process.env.REACT_APP_PREDICTA_AVULSA_ID)
          availablePlans.push(plan);
      });

      setPlans(availablePlans);
      setProductId(selectProductLandPage!!);
      setPlan(service.plans.find((item) => item.id === productId));

      const filterDifer = diferentialsPlans.find(
        (dif) => dif.idPlan === availablePlans[0].id
      )!.diferentials;

      const diferes =
        productId !== null ? filterDifer : diferentialsPlans[0].diferentials;
      setDiferenciais(diferes);

      setTotalValues({
        price: Number(availablePlans[0].price),
        percentDiscount: 0
      });

      availablePlans.forEach((plan) => {
        if (plan.id === selectProductLandPage) {
          const diferentials = diferentialsPlans.find((dif) => {
            return dif!.idPlan === plan.id;
          })!.diferentials;

          if (plan.id === process.env.REACT_APP_PREDICTA_PRODUCT_ONE_ID) {
            setPlanId(1);
          } else {
            setPlanId(2);
          }

          setProductId(plan.id);

          setDiferenciais(diferentials);

          setTotalValues({
            price: Number(plan.price),
            percentDiscount: 0
          });

          window.localStorage.setItem('selectedProduct', String(plan.id));
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getProductsInCatalog();
  }, [productId]);

  function getPriceProduct(id: string | number): string | number {
    if (id) {
      const product = plans.find((product) => product.id === id);
      if (product) {
        return product.amount;
      }
    }
    return '';
  }

  const handleDropdown = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const eventValue = event.target.value as string;
      setProductId(eventValue);

      // eslint-disable-next-line array-callback-return
      if (eventValue === process.env.REACT_APP_PREDICTA_PRODUCT_ONE_ID) {
        setPlanId(1);
      } else {
        setPlanId(2);
      }
      plans.find((product) => {
        if (product.id === eventValue) {
          setDiferenciais(
            diferentialsPlans.find((difers) => {
              return difers.idPlan === product.id;
            })!.diferentials
          );
          setTotalValues({
            price: Number(product.price),
            percentDiscount: 0
          });
        }
      });
      window.localStorage.setItem('selectedProduct', String(eventValue));
    },
    [setProductId, plans, setPlanId]
  );

  if (redirect) {
    if (purchase?.registered === 400) {
      return <Redirect to="/" />;
    } else {
      return <Redirect to="/dashboard" />;
    }
  }

  const handleRemoveVoucher = () => {
    handleInstallmentsData();
    setTotalValues({
      voucher: '',
      price: +plan.price,
      percentDiscount: 0,
      amountDiscountApplied: 0,
      amountWithDiscountApplied: undefined
    });
  };

  // Scroll to error
  const scrollToRef = (ref: any) =>
    window.scrollTo(0, ref.current.offsetBottom);
  const myRefError = useRef(null);
  const executeScroll = (ref: any) => scrollToRef(ref);


  return (
    <>
      {infinityLoad ? (
        <Load />
      ) : (
        <>
          <Header step={step} handleStep={handleStep} />
          <Content ref={myRefError}>
            <FormWrapper>
              {step === 'paymentMethod' ? (
                <Main>
                  {purchase!.registered === 400 && (
                    <Alert color="#01B092" open={alert}>
                      <button onClick={closeAlert}>
                        <IconClose />
                      </button>
                      Seu cadastro foi criado com sucesso.
                      <br />
                      Para ativa-lo clique no link que enviamos para {email}
                    </Alert>
                  )}
                  <button
                    className="button"
                    onClick={() => {
                      handleStep('login');
                      const tagManagerArgs = {
                        gtmId: 'GTM-TDPD3K8',
                        dataLayer: {
                          event: 'Einstein-Predicta',
                          categoria: 'Contratar-Agora',
                          acao: 'step-3',
                          rotulo: 'Credito',
                          value: getPriceProduct(
                            String(localStorage.getItem('selectedProduct'))
                          )
                        }
                      };
                      TagManager.initialize(tagManagerArgs);
                    }}
                  >
                    Realize seu Login
                  </button>
                </Main>
              ) : step === 'paymentData' ? (
                <Main>
                  <div>
                    {Object.keys(message).length !== 0 && (
                      <ErrorBox warning={message.signal}>
                        <CloseIcon onClick={removeWarning} />
                        <h3>
                          {message.title
                            ? message.title
                            : 'Erro ao processar o pagamento'}
                        </h3>
                        <p>
                          {message.description ? message.description : ''}

                          {message.code && (
                            <span>{` ${message.description}. Tente novamente mais tarde. Caso o erro persista por favor
                          entrar em contato com o suporte do Einstein informando o
                          código #${message.code}.`}</span>
                          )}
                        </p>
                      </ErrorBox>
                    )}
                    <Box>
                      {fullDiscountVoucher ? (
                        <h1>
                          Você está utilizando um Voucher com 100% de desconto na cobrança
                        </h1>
                      ) : (
                        <h1>
                          Qual a forma de pagamento desejada?
                        </h1>
                      )}

                      {fullDiscountVoucher ? (
                        <p>
                          Finalize o preenchimento dos dados para prosseguir.
                        </p>
                      ) : (
                        <p>
                          Você pode utilizar um{' '}
                          <strong>cartão de crédito.</strong>
                        </p>
                      )}


                      <div>
                        <div className={`${fullDiscountVoucher ? "radio-wrapper hide" : "radio-wrapper"}`}>
                          <div>
                            <input
                              name="payment-method"
                              value="credit-card"
                              onChange={(e) => ''}
                              checked
                              type="radio"
                            />
                            <label>Cartão de crédito</label>
                          </div>
                        </div>
                      </div>
                      <Payment
                        product={productId}
                        numberProductPlan={planId}
                        service_id={service.id}
                      />
                    </Box>
                  </div>
                  <div></div>
                </Main>
              ) : (
                <Main>
                  <Simpleload />
                </Main>
              )}
            </FormWrapper>
            <Wrapper>
              <Title>Seu Pedido</Title>
              <OrderDetailTitle>
                <OrderIconTitle>{'PR'}</OrderIconTitle>
                <OrderTitle>{service?.name}</OrderTitle>
              </OrderDetailTitle>
              <Dropdown
                valueList={plans}
                value={productId}
                defaultValue={productId}
                onChange={handleDropdown}
                disabled={!!totalValues.amountDiscountApplied}
              />
              <OrderResume>
                {step === 'paymentData' ? (
                  <VoucherInput remove={handleRemoveVoucher} />
                ) : (
                  ''
                )}
                <Total />
              </OrderResume>
              {diferenciais.map((diferencial, index) => (
                <OrderDetailDiv key={index}>
                  <OrderDetailIcon>
                    <BsCheckCircleFill />
                  </OrderDetailIcon>
                  <OrderDetail>{diferencial.description}</OrderDetail>
                </OrderDetailDiv>
              ))}
            </Wrapper>
          </Content>
        </>
      )}
    </>
  );
}
