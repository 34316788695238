import styled from 'styled-components';
type props ={
  lastItem?: boolean
  open?: boolean
  reverse?: boolean
  page?: string
  index?: number
};
export const Content = styled.div`
    border-radius: 15px;
    box-shadow: 0px 5px 15px rgba(0,0,0, 0.02);
    padding: 25px;
    padding-bottom: 0;
    width: 100%;
    color: black;
    background: white;
    text-align:center;
    height: fit-content;
    z-index: 1;
    @media(max-width: 1024px) {
      padding: 20px;
      margin-bottom: 20px;
    }
`;
export const Header = styled.div`
    display:flex;
    width: 100%;
    color: black;
    img{
        height: 38px;
    }
`;

export const HeaderItem = styled.div<props>`
    margin-right: 15px;
    font-size: .85rem;
    p{
      text-align: left;
    }
    strong{
        font-size: 1.12rem;
    }
    img{
        height: 38px;
    }
    ${props => props.lastItem && 'margin-left: auto;'}
`;

export const BtnHistoric = styled.div<props>`
  height: 20px;
  position: relative;
  text-align: center;
  margin-top: 20px;
  &:before{
    content: "";
    cursor: pointer;
    width: 6px;
    height: 6px;
    position: absolute;
    margin: auto;
    display: block;
    left: 0;
    right: 0;
    transition: all .21s ease-out;
    bottom: 10px;
    border: 2px solid;
    border-color: #707070 transparent transparent #707070;
    background-color: transparent;
  }

  ${props => props.open
    ? `
      &:before{  
          transform: translateY(45deg);
      }

      &:before{
          transform: rotate(45deg);
      }
    `
    : `   
      &:before{  
        transform: translateY(225deg);
      }

      &:before{
        transform: rotate(225deg);
      }
    `
  }
`;

export const ContainerHistory = styled.div<props>`
  min-height: 150px;
  margin-bottom: 30px;
  ${props => !props.open && 'display:none;'}
  .disabled{
    opacity: 0.5;
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`;
export const SubTitleSchedule = styled.p`
  display: flex;
  font-size: .85rem;
  color: #909090;
`;

export const DivTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleSchedule = styled.h4`
  width: 250px;
  font-weight: bold;
  font-size: 1rem;
  margin: 0 0 5px;
`;

export const Ul = styled.ul<props>`
  list-style: none;
  display:flex;
  flex-direction: ${props => props.reverse ? 'column-reverse;' : 'column;'}
`;
export const Li = styled.li`
  color: #707070;
  display: flex;
  align-items: center;
  .actual{
    background: #F0F2F5;
    color: #707070;
  }
  .after{
    :after{
      content: "";
      width: 0px;
      height: 60px;
      margin: auto;
      display: block;
      left: 0;
      right: 0;
      border-right: dotted 2px #E3E3E3;
      background-color: transparent;
      margin-top: -60px;
    }
  }
  .green{
    color: #15B193;
  }
  .status{
    min-width: 150px;
  }
`;
export const TextWrapper = styled.div<props>`
    border:solid 1px #DFE2E6;
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    font-size:.85rem;
    border-radius: 10px;
    margin-bottom: 10px;
    font-weight: 600;
    color: #707070;
    text-align:left;
    @media(max-width: 1024px) {
      padding: 5px;
    }
    .border{
      border-right: solid 1px #DFE2E6;
      padding: 0;
    }
    .border-md{
      text-align: center;
      width: 30%;
      @media(max-width: 1024px) {
        width: 70px;
      }
    }
    .padding-0{
      padding: 0;
    }
    div{
      padding: 10px;
      h4{
        color: #707070;
        font-weight: 400;
        font-size: .56rem;
        margin-bottom: .5rem;
        margin-top: -10px;
      }
    }
    div:first-letter {
      text-transform:capitalize;
    }
    div:last-child {
      border-right: none;
      text-align:center;
    }
    div:nth-last-child(2) {
      text-align:center;
    }
    ${props => props.page === 'historic' &&
      `
      div:nth-last-child(3) {
        text-align:center;
      }
      div:nth-last-child(4) {
        text-align:center;
      }
      .upload{
        display: flex;
        align-items: center;
      }
      .date{
        color:#000;
        font-weight: 600;
      }
      `
    }
    small{
      color: #6b6b6b;
      font-weight: 100;
      font-size: .75rem;
    }
`;

export const CircleIcon = styled.div<props>`
  min-width: 28px;
  width: 28px;
  height: 28px;
  margin: 5px;
  border-radius: 28px;
  background: #00BE92;
  color: #fff;
  margin-right: 20px;
  text-transform: none;
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
    font-size: 0.98em;
    font-weight:bold;
    position: absolute;
  }
  #check{
    stroke: #fff;
  }
  ${props => !props.index || props.index === 0
  ? `
    :before{
      content: "";
      width: 0px;
      height: 60px;
      margin: auto;
      display: block;
      left: 0;
      right: 0;
      border-right: dotted 2px #E3E3E3;
      background-color: transparent;
      margin-top: 28px;
      z-index: -1;
    }
  `
  : ''}
`;

export const CircleIconActual = styled.button`
  min-width: 35px;
  width: 35px;
  height: 35px;
  margin: 5px;
  border-radius: 35px;
  background: #16AF92;
  color: #fff;
  margin-right: 20px;
  
`;

export const Circle = styled.div`
  min-width: 10px;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin: 6px;
  background: #E3E3E3;
  margin-right: 35px;
  margin-left: 14px;
  &:before{
    content: "";
    width: 0px;
    height: 80px;
    margin: auto;
    display: block;
    left: 0;
    right: 0;
    margin-top: 10px;
    border-right: dotted 2px #E3E3E3;
    //#E3E3E3
    background-color: transparent;
  }
`;
export const ActualStep = styled.div`
  font-size:.75rem;
  margin-bottom: 30px;
  display:flex;
`;

export const ButtonWrapper = styled.div`
    margin-left: auto;
    display:flex;
    div{
      margin-right: 10px;
    }
`;

export const ToolTip = styled.span`
    cursor: pointer;
    background-color: #36a38f;
    font-size: .875rem;
    text-shadow: -1px 0 gray, 0 1px gray, 1px 0 gray, 0 -1px gray;
    padding: .5rem 1.25rem;
    text-align: center;
    color: #fff;
    font-weight: 600;
    border-radius: 1rem;
    justify-content: center;
    width: 100%;
    max-width: 220px;
    position: absolute;
    opacity:0;
    margin: 0px 0px 0px 0px;
    svg{
        margin-right: .5rem;
    }
    &:hover{
      opacity:0.8;
    }
    @media(max-width: 500px) {
      max-width: 120px;
    }
`;
