import UserInfo from '../UserInfo';
import { ReactElement } from 'react';
import { HeaderWrapper } from './styles';
import { LogoEinstein } from '../../assets/icons';
import { Link } from 'react-router-dom';

export default function index(): ReactElement {
  return (
      <HeaderWrapper>
        <Link to="/dashboard">
          <LogoEinstein />
        </Link>
        <UserInfo />
      </HeaderWrapper>
  );
}
