import styled from 'styled-components';
type props = {
  bgColor: string
};
export const ModalBody = styled.div`
    background: #fff;
    position: fixed;
    right: -450px;
    top: 0;
    bottom: 0;
    min-height: 100vh;
    width: 460px;
    z-index: 11;
    transition: all .5s ease;
    transition-delay: 1s;
    @media(max-width: 1024px){
      width: 280px;
    }
`;

export const ModalHeader = styled.div`
    display: flex;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;
    height: 110px;
    border-bottom: 2px solid #DCE1E5;

    .modal--btn-close{
        align-self: flex-start;
    }
`;

export const ModalContent = styled.div`
    padding: 2rem;
    overflow-y: auto;
    height: calc(100% - 110px);
    h4{
      font-weight: 600;
    }
    .step{
      color: #969696;
      font-size: 1rem;
      font-weight: 600;
      margin-top: 20px;
      width: 90%;
    }
    ::-webkit-scrollbar {
    width: .5rem;
    }

    ::-webkit-scrollbar-track {
    background: #f1f1f1; 
    }
    
    ::-webkit-scrollbar-thumb {
    background: #ccc; 
    border-radius: .5rem;
    }

    ::-webkit-scrollbar-thumb:hover {
    background: #555; 
    }
    .nxt-step{
      color: #707070;
      margin-bottom: 1.5rem;
      font-size: .875rem;
    }
    .sample-warning{
      color: #707070;
      font-weight: bold;
      margin-bottom: .5rem;
      font-size: .875rem;
    }
    .sample-top{
      margin-top: 3rem;
    }
    .sample-bottom{
      margin-bottom: 25px;
    }
`;

export const ModalProductText = styled.span<props>`
    display: flex;
    align-items: center;
    color: #707070;
    font-weight: 400;
    font-size: .875rem;
    margin-bottom: 0.8rem;

    b{
        font-size: 1rem;
        color: #F4F7FA;
        text-align: center;
        line-height: 1.875rem;
        background: red;
        display: block;
        border-radius: .5rem;
        width: 1.875rem;
        height: 1.875rem;
        margin-right: .5rem;
        background-color: ${props => props.bgColor}
    }
`;

export const ButtonBack = styled.button`
    font-size: 1.375rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-bottom: 1.75rem;

    svg{
      margin-right: .8rem;
      transform: rotate(-90deg);
    }
    path{
      stroke: #000;
    }
`;

export const UserWrapper = styled.div`
    padding: 1rem;
    display: flex;

    img{
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
    }

    div{
        align-items: center;
        padding: .5rem;

        h2{
            font-size: 1rem;
            font-weight: 600;
            color: #161616;
        }

        span{
            font-size: .75rem;
            font-weight: 400;
            color: #707070;
            display: block;
        }
    }

`;

export const ModalNewSchedule = styled.div`
    span{
      font-size: .875rem;
    }

    p{
      font-size: .875rem;
    }

    .price{
      margin: -10px 0 20px 0;
      @media(max-width: 414px){
        width: 100%;
      }
      select{
        width: 100%;
        padding: 5px;
        font-size: 14px;
        transition: 2s;
        :focus{
          outline: none;
          border: 1px solid #00B193;
        }
      }
    }

    .form-flex{
      display: flex;
      div:first-child{
        margin-right: 10px;
      }
    }

    .flex{
      height: 50vh;
      justify-content:center;
      display: flex;
      flex-direction: column;
      align-items: center;

      h3{
        font-weight: 600;
        font-size: 1.25rem;
        text-align: center;
      }

      span{
        color: #707070;
      }

      hr{
        width: 60%;
        border-color: #ffffff;
      }

      svg{
        height: 60px;
        width: 60px;
        margin-bottom: 20px;
        path{
          stroke: #009DFF;
        }
      }
    }

    .mb-20{
      margin-bottom: 1.25rem;
    }

    input:focus{
      outline: none;  
      box-shadow: none;
    }

    input {
      border: solid 1px #999999ba;
      border-radius: 8px;
      height: 50px;
      width: 100%;
      font-size: 14px;
      padding-left:10px;
      
      ::placeholder {
        color: transparent;
      }
    }

    .form-group{
      transition: all .3s;
      width: 100%;
      height: 60px;
      margin-bottom: 5px;
      margin-top: 5px;
    }

    .form-label{
      color: #999999ba;
      font-size: 13px;
      font-weight: 300;
      padding-right: 4px;
      padding-left: 4px;
      width: fit-content;
      display:block;
      margin-left: 10px;
      transform: translateY(-35px);
      transition: all .3s;
      background: #fff;
      position: relative;
      z-index: 1;
      transform-origin: 0 0;
    }

    .form-control{
      transition: all .5s;
      position: relative;
      z-index: 2;
      background: transparent;
    }

    .form-control:focus{
      color: #707070;
    }

    .form-control:focus + .form-label,
    .form-control:not(:placeholder-shown) + .form-label
    {
      transform: translateY(-55px) scale(.8);
      font-weight: 500;
      z-index: 3;
      color: #707070;
  
    }

    .form-control:focus,
    .form-control:not(:placeholder-shown)
    {
      color: #707070;
    }
`;

export const ScheduleModalButton = styled.button`
    border: 1px solid #DCE1E5;
    border-radius: .5rem;
    margin: .5rem auto;
    padding: 1rem 1.5rem;
    font-size: .875rem;
    font-weight: 600;
    color: #707070;
    display: flex;
    align-items: center;
    width: 100%;
    height: 4rem;
    cursor: pointer;

    svg{
        margin-right: .5rem;
    }
`;

export const ButtonNext = styled.button`
    max-width: 315px;
    width: 100%;
    height: 50px;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    display: block;
    border-radius: 2.5rem;
    cursor: pointer;
    background-color: #009DFF;
    color: #fff;
    transition: all .3s ease;
    font-family: 'Montserrat',sans-serif;
    margin: 1rem auto;

    &:disabled{
        background: gray;
        cursor: block;
        opacity: .3;
    }
`;

export const HoursWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
`;

export const HoursButton = styled.button`
    font-size: .875rem;
    color: #161616;
    border: 1px solid #C8D1D9;
    padding: .75rem 1.25rem;
    border-radius: 2rem;
    cursor: pointer;
    transition: all .3s;

    :hover, &.ativo{
        background-color: #009DFF;
        color: #fff;
        border: 1px solid #009DFF;
    }

`;

export const ScheduleInfoBox = styled.div`
    margin-top: .5rem;
    margin-bottom: 1rem;
    border-radius: .5rem;

    div {
        font-size: .875rem;
        padding: .8rem 0 .8rem 0;
        display: flex;
        align-items: center;

        svg {
            width: 30px;
            margin-right: .8rem;
            
            path, rect, circle{
                stroke: #969696;
                fill: transparent;
            }
        }
    }
`;

export const ClinicList = styled.div` 
`;

export const ClinicItem = styled.button`
    padding: .5rem;
    border: 1px solid rgba(179, 179, 179, .3);
    border-radius: .5rem;
    display: flex;
    align-items: center;
    margin: .5rem auto 1rem;
    cursor: pointer;
    width: -webkit-fill-available;
    min-height: 70px;

    &.active{
        border-color: blue;
    }
`;

export const ClinicItemName = styled.div`
    padding: .5rem;
    font-size: .875rem;
    font-weight: 700;
    color: #15B193;
    width: 40%;
    display: flex;
    align-items: center;

    svg{
        margin-right: .75rem;
        width: 20px;
    }

    span{
        width: calc(100% - 36px);
    }
`;

export const ClinicItemAddress = styled.div`
    padding: .5rem;
    font-size: .875rem;
    width: 60%;
`;

export const Load = styled.div`
  position: relative;
  left: 50%;
  top: 100px;
  width: 50px;
  height: 50px;
  border: 4px solid #80808042;
  border-radius: 50%;
  box-sizing: border-box;
  &:after {
    content: "";
    position: absolute;
    top: -4px;
    left: -4px;
    display: block;
    width: 50px;
    height: 50px;
    border: 4px solid transparent;
    border-top: 4px solid #004F92;
    border-radius: 50%;
    box-sizing: border-box;
    animation: spin 0.55s infinite ease-in-out;
  }
  
 
  @keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
  }
  @media(max-width: 1024px){
    left: 42%;
  }
`;

export const PriceLabel = styled.div`
  padding: 20px;
  border: 2px solid #00B193;
  border-radius: 12px;
  max-width: 100%;
  p{
    color: rgba(0, 0, 0, 0.23);
    font-weight: bolder;
    font-size: .800rem;
  }
`;
