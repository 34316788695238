interface IMoneyFormatProps {
  children: number
}

export function MoneyFormat({ children }: IMoneyFormatProps) {
  return (
    <span>
      {children.toLocaleString('pt-BR', { currency: 'BRL', style: 'currency' })}
    </span>
  );
}
