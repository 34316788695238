import React, { ReactElement } from 'react';
import { Container, Bar, Progress, Pointer } from './styles';
type props = {
  steps: number
  progress: number
  isOne?: boolean
  stepStatus?: string};
export default function ProgressBar(props: props): ReactElement {
  const width = props.progress * (100 / props.steps);
  return (
    <Container>
      {!props.isOne &&
        <Pointer pointerWidth={width - 2}>
          <span>
            {props.progress || ''}
          </span>
        </Pointer>
        }
        <Bar>
            <Progress progressWidth={props.isOne ? (props.stepStatus === 'Realizada' ? 100 : width / 2) : width}></Progress>
        </Bar>
    </Container>
  );
}
