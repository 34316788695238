import styled from 'styled-components';

type props = {
  mobileBottom?: string
};

export const CircleWhatsapp = styled.a<props>`
  position:fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
  bottom: 30px;
  right: 30px;
  z-index: 2;
  min-width: 54px;
  width: 54px;
  height: 54px;
  border-radius: 54px;
  background: #31C227;
  svg{
    position: absolute;
  }
  @media(max-width: 768px){
    bottom: ${props => props.mobileBottom ? `${props.mobileBottom}px` : '30px'};
    right: 10px;
  }
`;
