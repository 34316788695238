import styled from 'styled-components';

const GetBorder = (isSuccess: boolean, isError: boolean) => {
  if (isSuccess) return '#004f92';
  if (isError) return '#FF5353';
  return '#8E959A';
};

interface StyleProps {
    isSuccess: boolean
    isError: boolean
}

export const SubMessage = styled.div<StyleProps>`
  display: flex;
  position: absolute;
  bottom: -16px;
  left: 10px;
  color: #FF5353;
  font-size: 11px;
  background-color: white;
  `;

export const SupMessage = styled.div<StyleProps>`
  display: flex;
  position: absolute;
  top: -7px;
  left: 10px;
  color: ${({ isError, isSuccess }: StyleProps) => GetBorder(isSuccess, isError)};
  font-size: 11px;
  background-color: white;
  padding: 0 0.2rem;
`;
