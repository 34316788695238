import styled from 'styled-components';

interface props {
  isMain: boolean
}

export const Plan = styled.div<props>`
  position: relative;
  max-width: 500px;
  width: 100%; 
  border: ${props => props.isMain ? 'solid 2px #1774DE' : 'solid 2px #49BDCF'};
  border-radius: 10px;
  margin-right: 24px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.isMain ? '0' : '43.5px'};
  margin-bottom: ${props => props.isMain ? '0' : '43.5px'};
  
  div{
    justify-content: space-between;
  }

  .space {
    height: 100%;
  }

  li{
    display:flex;
    align-items: flex-start;
    line-height: 1.5rem;
    font-size: 1rem  !important;
    font-weight: 500 !important;
    color: #000000 !important;
    margin-bottom: 25px;
    text-align: left;

    p{
      margin: 8px auto;
    }

    .blue-check{
      margin-right: 16px;
      min-width: 23px;
      width: 23px;
      height: 23px;
      border-radius: 23px;
      background:  #DCF2F6;
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: #00A2BB;
      border-style: solid;
      border-width: 1.5px;
       
      svg{
        width: 14px;
        height: 12px;
        margin: 0;
        path{
          stroke: #00A2BB;
        }
      }
    }

    .blue-check-default{
      margin-right: 16px;
      min-width: 23px;
      width: 23px;
      height: 23px;
      border-radius: 23px;
      background:  #DCF2F6;
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: #1774DE;
      border-style: solid;
      border-width: 1.5px;
      svg{
        width: 14px;
        height: 12px;
        margin: 0;
        path{
          stroke: #1774DE;
        }
      }
    }
  }
   svg{
    margin-right: 10px;
    height: 22px;
   }
   .btn{
    max-width: 100%;   
    width: 100%;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: .75rem auto;  
    font-size: 1.12rem;
    font-weight: 600;
    border-radius: 40px;
    background-color: ${props => props.isMain ? '#1C7CF9' : '#00A2BB'};
    color: #fff;
    &:hover {
      background-color: #063D8F;
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
  .btn-outline{
    max-width: 100%;
    width: 100%;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: .75rem auto;
    font-size: 1.12rem;
    font-weight: 600;  
    border-radius: 40px;
    border: 1px solid #DCE0E8;
    color: #484B52;   
    &:hover {
      border: 1px solid #484B52;     
    } 
  }
  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
  @media (min-width: 768px) {
    width: 50%;
  }
  @media (max-width: 580px) {
    margin-right: 24px;
    margin-left : 24px;
    width: auto;
  }
`;
export const PlanHeader = styled.div<props>` 
  margin-bottom: 26px;
  text-align: center;
  
  h3{
    font-size: 32px;
    margin: 5px 0 30px 0;
    font-weight: 100;   
    label{
      b{
        color: #000;           
      }
    }
  }
  
  
  .background-description{
    background-color: ${props => props.isMain ? '#DFECFB' : '#EDEFF3'};  
    margin-left: -16px;   
    margin-right: -16px;    
    padding: 24px;  

    h4{
      font-size: 24px;
      font-weight: 400;
      color: #707070;
      text-decoration: line-through;
      text-decoration-color: #1E7DDD;
    }
    
    b{
      color: #484B52; 
      font-size: 16px;
      font-weight: 600;   
    }

    h2{
      color: #484B52;
      font-size: 20px !important;   
      font-weight: 600;    
      margin-bottom: auto;
    }    
    h5{
      color: ${props => props.isMain ? '#1860CC' : '#007A8C'};
      font-weight: 800;
      font-size: 40px;    
      display: inline-block; 
    }
    h6{
      color: ${props => props.isMain ? '#1860CC' : '#007A8C'};
      font-weight: 800;
      font-size: 24px;    
      display: inline-block; 
      margin: 10px;
    }
  }
   
  small{
    font-size: 15px;
    color: #707070;
    font-weight: 600;
  }
`;
