import { ReactElement, useState } from 'react';
import SideBar from './SideBar';
import { QuizContent, SideBarArea, QuizArea, Title, ButtonsWrapper, Button, StyledOption } from './styles';
import { ArrowBack } from '../../assets/icons';
import { Link, useHistory } from 'react-router-dom';
import questsJson from './quests.json';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_ANSWER, Answer, ScheduleRootState, UserInfo } from '../../store/redux';
import { sendQuestionnaire } from '../../utils/services';
import Toast from '../../ui/Toast';

interface IProps {
  type: string
  id: number
  value: string
  index: number
  label: string
  subfield?: string
};

export default function Quiz(): ReactElement {
  const quests = questsJson;

  const dispatch = useDispatch();
  const history = useHistory();

  const [actualQuest, setActualQuest] = useState(0);
  const [selectedOption, setSelectedOption] = useState<string>('-1');
  const [subfield, setSubfield] = useState<string>('');
  const [textAreaAnswer, setTextAreaAnswer] = useState<string>('');
  const [id, setId] = useState('');
  const [message, setMessage] = useState('');
  const [toastActivated, setToastActivated] = useState(false);

  const user = useSelector<ScheduleRootState, UserInfo>(scheduleReducer => scheduleReducer.user);
  const answersList = useSelector<ScheduleRootState, Answer[] | undefined>(scheduleReducer => scheduleReducer.answers);

  const send = async (): Promise<any> => {
    try {
      const responseSend = await sendQuestionnaire(user, answersList);
      if (responseSend.statusCode >= 400) {
        throw new Error('Erro ao enviar o questionário. Por favor, verifique se o questionário já foi respondido ou se as respostas estão corretas.');
      }

      setMessage('Questionário enviado com sucesso!');
      setToastActivated(true);

      setTimeout(() => {
        setToastActivated(false);
        history.push({
          pathname: '/dashboard'
        });
      }, 7000);
    } catch (error: any) {
      setMessage(error.message);
      setToastActivated(true);

      setTimeout(() => {
        setToastActivated(false);
      }, 7500);

      console.log(error);
    }
  };

  const handleNextQuestion = () => {
    const list = answersList ?? [];
    const parsedId = parseInt(id);
    const newAnswer = {
      questionid: parsedId,
      questionAnswer: selectedOption === '1' ? textAreaAnswer : selectedOption
    };
    if (list.filter(item => item.questionid === parsedId).length > 0) {
      // eslint-disable-next-line array-callback-return
      list?.map((item: any) => {
        if (item.questionid === id) {
          item.questionAnswer = selectedOption === '1' ? textAreaAnswer : selectedOption;
        }
      });
    } else {
      list?.push(newAnswer);
    }
    dispatch({ type: ADD_ANSWER, newAnswer: list });
  };

  const onValueChange = (value: any, subfield: any, id: string) => {
    setSelectedOption(value);
    setSubfield(subfield);
    setId(id);
  };

  return (
    <QuizContent>
      {toastActivated &&
      <Toast
        message={message}
      />
    }
      <Title>
        <Link to="/dashboard"><ArrowBack /></Link>
        <h1>Questionário</h1>
      </Title>

      <QuizArea>
        <label className="product">Predicta</label>
        <br />
        <h2>{quests[actualQuest].text}</h2>
        {quests[actualQuest].answers?.map((answer: any, index: number) => (
          <div key={index}>
            <input type="radio" checked={selectedOption === answer.value} id={`${quests[actualQuest].id}`} value={answer.value} onChange={(e) => onValueChange(e.target.value, answer.subField?.label, e.target.id)} /> {answer.label}
          </div>
        )
        )}
        {selectedOption === '1' && (
          <StyledOption>
            <div className="form-group">
              <label>{subfield}</label>
              <br />
              <textarea placeholder="Digite aqui" value={textAreaAnswer} onChange={(e) => setTextAreaAnswer(e.target.value)}></textarea>
            </div>
          </StyledOption>
        )}

        <ButtonsWrapper>
          {actualQuest !== 0 &&
            <Button onClick={() => {
              setActualQuest(actualQuest - 1);
              setSelectedOption('-1');
              setTextAreaAnswer('');
            }
            } bkcolor='#fff' color='#009DFF'>
              Voltar
            </Button>
          }

          {actualQuest !== quests.length - 1 &&
            <Button
              disabled={selectedOption === '-1' ? true : selectedOption === '1' && textAreaAnswer === ''}
              onClick={() => {
                handleNextQuestion();
                setActualQuest(actualQuest + 1);
                setSelectedOption('-1');
                setTextAreaAnswer('');
              }
              } color='#fff' bkcolor='#009DFF'>
              Próximo
            </Button>
          }
          {actualQuest === quests.length - 1 &&
            <Button
              disabled={selectedOption === '-1' ? true : selectedOption === '1' && textAreaAnswer === ''}
              onClick={() => {
                handleNextQuestion();
                send();
              }} color='#fff' bkcolor='#009DFF'>
              Enviar
            </Button>
          }
        </ButtonsWrapper>
      </QuizArea>

      <SideBarArea>
        <SideBar quests={quests} actual={actualQuest} />
      </SideBarArea>
    </QuizContent>
  );
}
