import React, { useEffect } from 'react';

import { Container } from './styles';

const AcceptCookies: React.FC = () => {
  const [cookies, setCookies] = React.useState(true);
  const cookie = localStorage.getItem('pureJavaScriptCookies');

  useEffect(() => {
    if (cookie === 'accept') {
      setCookies(false);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('pureJavaScriptCookies', 'accept');
    document.cookie = 'Aceite_Cookies=Sim';
    setCookies(false);
  };

  const declineCookies = () => {
    localStorage.setItem('pureJavaScriptCookies', 'decline');
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
    document.cookie = 'Aceite_Cookies=Não';
    setCookies(false);
  };

  return (
    <Container visible={cookies}>
      <div className="box-cookies">
        <p className="msg-cookies">
          Nós usamos cookies e outras tecnologias semelhantes para melhorar a
          sua experiência em nossos serviços, personalizar publicidade e
          recomendar conteúdo de seu interesse. Ao utilizar nossos serviços,
          você concorda com tal monitoramento. Para saber mais acesse nossa{' '}
          <a
            target="_blank"
            className="polices"
            href="https://www.einstein.br/sobre-einstein/politicas-site"
            rel="noreferrer"
          >
            <b>
              <u>Política de Privacidade e Uso de Cookies</u>
            </b>
          </a>
        </p>
        <p className="buttons">
          <button
            className="btn-primary"
            onClick={() => {
              acceptCookies();
            }}
          >
            Aceitar
          </button>
          <button
            className="btn-secondary"
            onClick={() => {
              declineCookies();
            }}
          >
            Recusar
          </button>
        </p>
      </div>
    </Container>
  );
};

export { AcceptCookies };
