import styled, { css } from 'styled-components';

interface ErrorBoxProps {
  warning: 'error' | 'warning'
}

type props = {
  color?: string
  open?: boolean
};
export const Content = styled.div`
  background: #fff;
  padding: 100px;
  padding-top: 163px;
  display: flex;
  justify-content: center;
  .space{
    justify-content: space-between;

    svg{
      width: 1rem;
    }
  }
  .mt-10{
    margin-top: 10px;
  }
  .mt-20{
    margin-top: 20px;
  }
  input:focus{
    outline: none;
    box-shadow: none;
  }
  select:focus {
    outline: none;
    box-shadow: none;
  }
  @media(max-width: 1124px){
    flex-direction: column;
    padding: 10px;
    padding-top: 80px;
    align-items: center;
  }
  @media(min-width: 500px){
    .flex{
      display: flex;
      svg{
        path{
          stroke: #3EB49D;
        }
      }
    }
  }
`;
export const FormWrapper = styled.div`
    padding: 50px;
    margin-right: 10px;
    width: 60vw;
    max-width: 675px;
    h1{
      font-size: 1.15rem;
      font-weight: 700;
      line-height: 1.5em;
    }
    .small{
      font-size: .75rem;
      font-weight: 600;
    }
    .flex{
      input:first-child{
        margin-right: 10px;
      }
      div:first-child{
        margin-right: 10px;
      }
      select:first-child{
        margin-right: 10px;
      }
      p{
        font-size: .95rem;
        line-height: 1.5rem;
      }
    }
    p{
      font-size: 1rem;
      color: #464646;
    }
    input[type="text"]{
      width: 100%;
      height: 40px;
      padding: 10px;
      border-radius: 4px;
      margin-top: 15px;
      ::placeholder {
        color: transparent;
      }
    }
    input[type="password"]{
      width: 100%;
      height: 40px;
      padding: 10px;
      border-radius: 4px;
      margin-top: 15px;
      border: solid 1px #999999ba;
      ::placeholder {
        color: transparent;
      }
    }
    select{
      width: 100%;
      height: 40px;
      padding: 10px;
      border-radius: 5px;
      margin-top: 15px;
      border: solid 1px #999999ba;
      font-size: 14px;
      font-weight: 500;
      color: #999999ba;
    }
    .button{
      font-size: 1.15rem;
      font-weight: 500;
      border-radius: 20px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #004F92;
      color: white;
      width: 100%;
      margin-top: 1.85rem;
    }
    .radio-wrapper{
      margin-top: 20px;
      display: flex;
      align-items:center;

      input[type="radio"]{
        margin-right: 5px;
        height: 20px;
        width: 20px;
      }
      input:checked ~ label{
        color:#004F92;
        font-weight: 600;
      }
      div{
        margin-right: 60px;
        display:flex;
        align-items: center;
        color: #B3B3B3;
        input[type="radio"]:checked{
          color: #004F93;
        }
      }
    }

    .hide{
      display: none;
    }

    @media(max-width: 1024px){
      width: 100%;
      padding: 10px;
      margin-bottom: 1.5rem;
      margin: 0;
      select{
        width: 100%;
      }
    }
`;
export const OrderWrapper = styled.div`
  h1{
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.5em;
  }

  h2{
    font-size: 1.15rem;
    font-weight:600;
  }
`;
export const Program = styled.div`
  padding: 20px;

  span{
    display: flex;
    font-weight: 600;
    font-size: 1.35rem;
    align-items: center;
    b{
      font-size: 1rem;
      color: #FFFFFF;
      text-align: center;
      line-height: 1.125rem;
      background: #E17E53;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      width: 2.5rem;
      height: 2.5rem;
      margin-right: .6rem;
    }
    .telepsicologia{
      background-color: #A287C3;
    }

    .predicta{
      background-color: #15B193;
    }
  }

  .blue-check{
    margin-right: 10px;
    min-width: 1.4rem;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 1.4rem;
    background: rgb(30, 125, 221, 0.197);
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
      width: .85rem;
      height: .75rem;
      margin: 0;
      path{
        stroke: rgb(30, 125, 221);
      }
    }
  }

  select{
    width: 40vw;
    max-width: 360px;
    height: 40px;
    padding: 10px;
    border-radius: 6px;
    margin-top: 15px;
    margin-bottom: 30px;
    border: solid 1px #999999ba;
    font-size: 14px;
    font-weight: 500;
  }

  option{
    font-size: .85rem;
    border: solid 1px #999999ba;
  }

  li{
    display:flex;
    font-size: .95rem;
    line-height: 1.5rem;
    color: #707070;
    font-weight: 600;
    margin-bottom: 1.5rem;
    text-align: left;
    max-width: 335px;
  }

   @media(max-width: 1024px){
    width: 100%;
    padding: 10px;
    select{
      width: 100%;
    }
  }
`;
export const PromotionalCode = styled.div`
  margin-top: 50px;
  padding: 10px;

  h2{
    color: #464646;
  }

  .total{
    margin-top: 30px;
    h2{
      font-weight: 700;
    }
  }

  input{
    height: 40px;
    width: 100%;
    max-width:290px;
    padding: 10px;
    border-radius: 5px 0 0 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: solid 1px #999999ba;
    border-right: none;
    ::placeholder {
      color:transparent;
    }
  }

  button{
    height: 40px;
    width: 70px;
    padding: 10px;
    border-radius: 0 5px 5px 0;
    margin-top: 10px;
    margin-bottom: 10px;
    border: solid 1px #999999ba;
    border-left: none;
    color: #999999ba;
  }
`;

export const Alert = styled.div<props>`
  display: ${props => props.open ? 'flex' : 'none'};
  align-items: center;
  background: ${props => props.color};
  color: #fff;
  font-size: .85rem;
  font-weight: 600;
  border-radius: 5px;
  padding: 20px;
  padding-left: 0;
  margin-bottom: 50px;
  button{
    background: transparent;
    width: 50px;
    margin-top: 0;
    padding: 0;
  }
  svg{
    stroke: #fff;
    color: #fff;
  }
  path{
    fill: #fff;
  }
`;
export const Main = styled.div`
  .error{
    color: #F53748;
    margin-bottom: .6rem;
    margin-top: .6rem;
  }
  .error-list{
    color: #F53748;
    font-size:13px;
    margin-bottom: 6px;
    margin-top: 6px;
  }
.form-group{
    transition: all .3s;
    width: 100%;
    height: 60px;
  }
  .form-label{
    color: #999999ba;
    font-size: 14px;
    padding-right: 4px;
    padding-left: 4px;
    width: fit-content;
    display:block;
    margin-left: 10px;
    transform: translateY(-30px);
    transition: all .3s;
    background: #fff;
    position: relative;
    z-index: 1;
  }
  .form-control{
    transition: all .5s;
    position: relative;
    z-index: 2;
    background: transparent;
  }
  .form-control:focus{
    border: solid 2px #004F92 !important;
    color: #004F92;
  }
  .form-control:focus + .form-label,
  .form-control:not(:placeholder-shown) + .form-label
  {
    transform: translateY(-50px) scale(.9);
    color: #004F92;
    font-weight: 500;
    z-index: 3;
  }
  .form-control:focus,
  .form-control:not(:placeholder-shown)
  {
    border: solid 2px #004F92 !important;
    color: #004F92;
  }
`;
export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  padding: 2.5rem 0;

  @media screen and (max-width: 960px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const ErrorBox = styled.div<ErrorBoxProps>`
  padding: 1.5rem 3.5rem;
  border-radius: 10px;
  margin-bottom: 1rem;

  ${props =>
    props.warning === 'error'
      ? css`
          background-color: rgba(247, 40, 78, 0.8);
        `
      : props.warning === 'warning' &&
        css`
          background-color: #f7c800;
        `}

  color: #fff;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  svg {
    position: absolute;
    left: 16px;

    &:hover {
      cursor: pointer;
    }
  }

  h3 {
    font-size: 1rem;
    line-height: 150%;
  }

  p {
    font-size: .85rem;
    line-height: 180%;

    span {
      font-weight: bold;
    }
  }
`;

export const Box = styled.div`
  background-color: #fff;
  border-radius: 10px;

  h1 {
    font-size: 1.12rem;
    font-weight: 600;
  }

  p {
    font-size: 14px;
    padding-top: 4px;

    strong {
      font-weight: 600;
    }
  }

  & > div {
    display: flex;
    flex-direction: row;
  }
`;

export const Button = styled.button`
  height: 50px;
  width: 100%;
  color: #fff;
  background-color: #004f92;
  margin-top: 3.5rem;
  border-radius: 25px;
  border: 0;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  span {
    font-weight: 500;
  }
`;

export const Title = styled.h1`
  color: #000;
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 1rem;

  @media (max-width: 425px) {
    font-size: 1.125rem;
  }
`;

export const Wrapper = styled.div`
  position: sticky;
  align-self: start;
  top: 80px;
  width: 25rem;
  padding: 1.4rem;
  border-radius: 0.5rem;
  background-color: #fff;

  @media (max-width: 960px) {
    width: 42rem;
    padding: 2rem 3rem;
  }

  @media (max-width: 768px) {
    max-width: 32rem;
    align-self: center;
  }

  @media (max-width: 425px) {
    max-width: 20rem;
    align-self: center;
  }
`;

export const OrderIconTitle = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  background-color: #00B193;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 425px) {
    width: 2rem;
    height: 2rem;
    border-radius: 0.5rem;
    font-size: 0.75rem;
  }
`;

export const OrderDetailTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const OrderTitle = styled.h2`
  margin-left: 0.75rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: #000;

  @media (max-width: 425px) {
    font-size: 1rem;
  }
`;

export const OrderDetailDiv = styled.div`
  display: flex;
  margin-top: 1rem;
  padding-bottom: 0.5rem;
  align-items: center;
  width: 100%;
  
`;

export const OrderDetail = styled.span`
  font-size: 0.9rem;
  color: #707070;
  text-align:justify 
`;

export const OrderDetailIcon = styled.div`
  margin-right: 0.5rem;
  svg{
    color: #ccebf8;
    width: 20px;
    height: 20px;
  }

`;

export const OrderResume = styled.div`
  margin-top: 20px;
`;

export const OrderPromoCode = styled.div`
  p {
    color: #464646; 
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 600;
  }
  .promo-area {
    position: relative;
    input {
      width: 100%;
      height: 40px; 
      padding: 10px; 
      border-radius: 5px;
      font-size: 16px;
      :focus {
        border: 2px solid #004f92;
      }
    }
    .loading {
      position: absolute;
      right: 30%;
      top: 68%; 
      color: #ff0000;
      button {
        font-size: 14px;
      }
    }
    .apply {
      position: absolute;
      right: 18px;
      top: 26%; 
      color: #ff0000;
      button {
        font-size: 14px;
      }
    }
  }
`;

export const OrderTotal = styled.div`
      display: flex;
      justify-content: space-between; 
      padding: 0.6rem 1rem; 
      margin-top: 20px; 
      font-size: 1.1rem; 
      font-weight: 600; 
      background-color: #f2f5f4;
`;

export const TermsOverlay = styled.div`
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  a {
    text-decoration: underline;
  }

  h1 {
    font: normal normal 600 24px/29px Montserrat !important;
  }
`;
