import { createContext, useContext } from 'react';

export interface AuthUser {
    documentType: string
    sex: string
    hasAccount: boolean
    _id: string
    firstName: string
    lastName: string
    email: string
    phone: string
    document: string
    birthDate: string
    origin: string
    holder?: any
    fileDocuments: any[]
    externalProviders: any[]
    createdAt: Date
    idB2C: string
    authorizedToken: boolean
}
// @holder_document_token
export interface AuthInterface {
  user: AuthUser
  authToken: string
  paymentTokenBuy: string
  authPatientToken: string
  sourcePlatform: string
  productId: string | number
  serviceId: string | number
  platformToken: string
  successPaymentToken: string
  client: string
  holderDocumentToken: string
  setAuthToken: (token: string) => void
  setPaymentTokenBuy: (token: string) => void
  setAuthPatientToken: (token: string) => void
  setSourcePlatform: (platform: string) => void
  setUser: (user: AuthUser) => void
  setProductId: (productId: string | number) => void
  setServiceId: (productId: string | number) => void
  setPlatformToken: (token: string) => void
  setSuccessPaymentToken: (token: string) => void
  setClient: (client: string) => void
  setHolderDocumentToken: (value: string) => void
}

const AuthContext = createContext<AuthInterface>({
  user: {} as AuthUser,
  authToken: '',
  paymentTokenBuy: '',
  authPatientToken: '',
  sourcePlatform: '',
  productId: '',
  serviceId: '',
  platformToken: '',
  successPaymentToken: '',
  client: '',
  holderDocumentToken: '',
  setAuthToken: (token: string) => {},
  setPaymentTokenBuy: (token: string) => {},
  setAuthPatientToken: (token: string) => {},
  setSourcePlatform: (platform: string) => {},
  setUser: (user: AuthUser) => {},
  setProductId: (productId: string | number) => {},
  setServiceId: (serviceId: string | number) => {},
  setPlatformToken: (token: string) => {},
  setSuccessPaymentToken: (token: string) => {},
  setClient: (client: string) => {},
  setHolderDocumentToken: (value: string) => {}
});

export function useAuth(): AuthInterface {
  return useContext(AuthContext);
}

export default AuthContext;
