import styled from 'styled-components';

export const ScheduleWrapper = styled.div`
    background-color: #fff;
    border-radius: .5rem;
    margin: 0 auto 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media(min-width: 992px){      
      min-height: 50vh;
    }

    @media(min-width: 601px) and (max-width: 991px){
      display: flex;
      width: 50%;
    }
`;

export const ButtonNewSchedule = styled.button`
    background-color: #2196F3;
    font-size: .875rem;
    padding: .5rem 1.25rem;
    text-align: center;
    color: #fff;
    font-weight: 600;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    margin: 1rem auto;
    width: 100%;
    max-width: 240px;

    svg{
        margin-right: .5rem;
    }
    &:disabled{
      background: rgba(128, 128, 128, 0.3);
      cursor: block;
    }
`;

export const ToolTip = styled.span`
    background-color: #36a38f;
    font-size: .875rem;
    text-shadow: -1px 0 gray, 0 1px gray, 1px 0 gray, 0 -1px gray;
    padding: .5rem 1.25rem;
    text-align: center;
    color: #fff;
    font-weight: 600;
    border-radius: 1rem;
    justify-content: center;
    width: 100%;
    max-width: 240px;
    position: absolute;
    opacity:0;
    svg{
        margin-right: .5rem;
    };
    &:hover{
      opacity:0.8;
    }
`;

export const ScheduleTitle = styled.h2`
    font-size: .875rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

export const ScheduleInfos = styled.div`
    padding: 2rem;
    width: 100%;
`;

export const CardScheduleWrapper = styled.div`
    background-color: #F4F7FA;
    border-radius: .5rem;
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    position: relative;
`;

export const CardInfo = styled.div`

    h2{
        font-weight: 600;
        color: #000;
        font-size: .875rem;
        margin-bottom: .5rem;
    }

    h3{
        color: #707070;
        font-weight: 400;
        font-size: .75rem;
        margin-bottom: .5rem;
    }

    span{
        display: flex;
        color: #707070;
        font-weight: 400;
        font-size: .75rem;

        b{
            font-size: .625rem;
            color: #F4F7FA;
            text-align: center;
            line-height: 1.125rem;
            background: red;
            display: block;
            border-radius: .25rem;
            width: 1.125rem;
            height: 1.125rem;
            margin-right: .375rem;
        }

        .telepsicologia{
            background-color: #A287C3;
        }

        .predicta{
            background-color: #15B193;
        }
    }
`;

export const CardButton = styled.div`
    text-align: right;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    line-height: 8px;
    font-size: 18px;
    font-weight: 600;
    @media(max-width: 768px){
      font-size: 24px;
      line-height: 10px;
    }
`;

export const Load = styled.div`
    position: relative;
    margin: 0 auto;
    width: 50px;
    height: 50px;
    border: 4px solid #80808042;
    border-radius: 50%;
    box-sizing: border-box;
    &:after {
        content: '';
        position: absolute;
        top: -4px;
        left: -4px;
        display: block;
        width: 50px;
        height: 50px;
        border: 4px solid transparent;
        border-top: 4px solid #004f92;
        border-radius: 50%;
        box-sizing: border-box;
        animation: spin 0.55s infinite ease-in-out;
    }
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export const ModalButtonBox = styled.div`
    background-color: #fafafa;
    box-shadow: 1px 1px 4px #ccc;
    padding: .5rem;
    position: absolute;
    top: 5px;
    right: 5px;
    h3{
      font-size: .8rem;
      padding: .75rem .5rem 1.25rem .5rem;
    }
    .flex{
      display: flex;
      justify-content: center;
      margin-bottom: 1.25rem;
      button:first-child{
        margin-right: 10px;
      }
    }
    .confirmation{
      border-radius: 25px;
      width: 80px;
      padding: 10px;
      background: #15B193;
      color: #fff;
    }

    .close{
      border: 1px solid #15B193;
      color: #15B193;
      background: transparent;
    }
`;

export const ModalButton = styled.div`
    font-size: .75rem;
    font-weight: 600;
    cursor: pointer;
    padding: .75rem 1.5rem;
`;

export const ModalButtonClose = styled.button`
    font-size: 0;
    font-weight: 600;
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 7px;
    width: 23px;
    height: 23px;
    
    &:before, &:after{
        content: '';
        width: 2px;
        height: 16px;
        background: black;
        display: block;
        position: absolute;
        right: 8px;
        top: 8px;
        border-radius: 5px;
        
      }
      
      &:before{
        transform: rotate(45deg);
      }
      
      &:after{
        transform: rotate(-45deg);
      }
      @media(max-width: 768px){
        top: 1px;
        right: 7px;
        &:before, &:after{
          width: 3px;
          height: 20px;
        }
      }
`;
