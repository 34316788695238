import React, { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ModalLogin from '../../components/ModalLogin';

import { HeaderSite, HeaderContainer, MenuMobile, LogoWrapper, MenuMobileWrapper, MenuBackground, BtnWrapper, BtnMenu } from './styles';

import LogoEinstein from '../../assets/logo.png';

import {
  ScheduleRootState,
  Purchase
} from '../../store/redux';
import { moveToLogin } from '../../utils/moveToLogin';

export default function HeaderMobile(): ReactElement {
  const purchase = useSelector<ScheduleRootState, Purchase | undefined>(scheduleReducer => scheduleReducer.purchase);

  const [loginOpen, setLoginOpen] = useState(purchase?.registered === 400);
  const [menuOpen, setMenuOpen] = useState(false);
  const handleLogin = (): void => {
    setMenuOpen(false);
    setLoginOpen(!loginOpen);
  };
  return (
    <>
      {/* <ModalLogin open={loginOpen} setFileOpen={setLoginOpen}></ModalLogin> */}
      <ModalLogin open={loginOpen} setOpen={setLoginOpen}></ModalLogin>
      <HeaderSite>
          <HeaderContainer>
            <BtnMenu onClick={() => setMenuOpen(!menuOpen)} />
            <LogoWrapper>
              <Link to="/" className="logo-header">
                <img src={LogoEinstein} width={'200px'} />
              </Link>
            </LogoWrapper>
            {menuOpen &&
            <>
            <MenuBackground />
            <MenuMobileWrapper>
              <MenuMobile>
                  <a href="#banner" className="menu-desktop--item">INICIO</a>
                  <a href="#como-funciona" className="menu-desktop--item">COMO FUNCIONA</a>
                  <a href="#diferenciais" className="menu-desktop--item">DIFERENCIAIS</a>
                  <a href="#ajuda" className="menu-desktop--item">AJUDA</a>
              </MenuMobile>
              <BtnWrapper>
                <Link className="btn orange" to={{
                  pathname: 'contratar',
                  state: 1
                }}
                >
                  Contratar agora
                </Link>
                <button onClick={() => moveToLogin() } className="btn outline">Entrar</button>
              </BtnWrapper>
            </MenuMobileWrapper >
            </>
            }
          </HeaderContainer>
      </HeaderSite>
    </>
  );
}
