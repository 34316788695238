import styled, { css } from 'styled-components';

interface Props {
  visible: boolean
}

export const Container = styled.div<Props>`  

  .box-cookies { 
  ${props => props.visible
? css`
    
    `
: css`
    display: none !important;
    `}
  
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  width: 90%;
  z-index: 999;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: 20px;
  right: 90px;
}

.box-cookies .msg-cookies,
.box-cookies .btn-cookies {
  text-align: center;
  padding: 20px;

  color: #f8f5f5;
  font-size: 15px;
}

.box-cookies .btn-cookies {
  background: #1e88e5;
  cursor: pointer;
  align-self: normal;
}

.polices{
  color:#ffffff;
}

@media screen and (max-width: 600px) {
  .box-cookies {
    flex-direction: column;
  }
  .buttons {
    margin-bottom: 20px;
  }
}
@media(min-width: 600px) {
  .buttons {
    margin-right:20px; 
    display: inline-flex;
  }
}
.btn-primary {
  color: #FFF;
  border: 1px solid #007bff;
  border-radius: 6px;
  padding: 10px;
  max-width: 80px;
  background-color: #007bff;
  margin-right: 10px;
  cursor: pointer;
}

.btn-secondary {
  color: #FFF;
  border: 1px solid #4e555b;
  border-radius: 6px;
  padding: 10px;
  max-width: 80px;
  background-color: #545b62;
  cursor: pointer;
}
`;
