import styled from 'styled-components';
import background_dna from '../../assets/Site/analisys-section-bg.jpg';

export const Container = styled.div`
  display: block;
  text-align: center;
  background-color: white;
  
  padding-top: 60px;
  @media (max-width: 768px) {
    padding-top:30px;
  }

  .backgroundsection {
    background-color: white;
    height: 471px;
  }

  .main-banner {
    display: inline-block;
  }

  .content {
    padding-top: 64px;
    display: inline-block;
  }
`;

export const BackSection = styled.div`
  width: 100%;
  height: 35px;
  padding-left: 98px;
  text-align: left;
  background: #3ad6ca;
  a {
    margin: 0;
    padding: 0;
    line-height: 35px;
    text-decoration: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
  }

  @media only screen and (max-width: 768px) {
    padding-left: 28px;
  }
`;

export const ProductDetails = styled.div`
  padding-top: 88px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 1078px) {
    flex-direction: column;
    justify-content: center;
  }
  
  div {
    padding: 0 2%;
  }

  div img {
    width: 50%
    height: auto;
    object-fit: cover;

    @media (max-width: 1078px) {
      margin-bottom: 30px;
    }
    @media (max-width: 768px) {
      margin-bottom: 30px;
      width: 80% !important;
    }
  }

  h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 45px;
    line-height: 55px;
    text-align: justify;
    color: #3ad6ca;
    font-weight: 600;
    }
  
  .text-blue {
    color: #009ddc;
    text-decoration: underline;
  }

  ul {
    padding-top: 32px;
    padding-bottom: 44px;
  }

  li {
    max-width: 557px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
    text-align: left;
    padding-bottom: 18px;
    /* padding-left: 57px; */

    /* background-image: url('https://einstein-ui-conecta-lib.s3.amazonaws.com/icons/predicta/predicta-arrow-li.svg');
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: 4px; */
  }

  a {
    width: 469px;
    height: 67px;
    border: 1px solid #009ddc;
    border-radius: 64px;
    color: #009ddc;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 67px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #247892;
    display: block;

    b {
      color: #3ad6ca;
      font-weight: normal;
      padding-left: 12px;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 0px 27px;
    padding-right: 0px;
    margin: 24px 0px;
    background-size: 82px;
    background-position-x: right;
    background-position-y: top;
    margin-right: 24px;

    div img {
      width: 40%;
    }

    h2 {
      font-size: 24px;
      line-height: 44px;
      margin-bottom: 24px;
    }

    ul {
      padding-top: 0;
    }

    li {
      font-size: 14px;
      line-height: 24px;
      background-size: 28px;
      max-width: initial;
    }

    a {
      width: 100%;
      height: 53px;
      border: 1px solid #009ddc;
      border-radius: 64px;
      color: #009ddc;
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 53px;
      display: block;
      align-items: center;
      text-align: center;
      color: #247892;

      b {
        color: #3ad6ca;
        font-weight: normal;
        padding-left: 12px;
      }
    }
  }
`;

export const TypesFaq = styled.div`
  h3 {
    /* color: #247892; */
    font-weight: normal;
    @media only screen and (max-width: 768px) {
      padding: 0 24px;
    }
  }

  .main {
    height: auto;
    padding: 12px;
  }

  .holder {
    max-width: 1280px;
  }

  .accordion {
    max-height: none;
  }

  .title {
    display: flex;
    justify-content: space-around;
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 24px;
    border-bottom: 1px solid lightgray;

    @media only screen and (max-width: 768px) {
      div {
        width: 45%;
        font-size: 12px;
        text-align: center;
      }
    }
  }

  .row {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #e9e9e9;

    &-section {
      width: 45%;
      padding: 24px 0;
      padding-bottom: 26px;

      b {
        font-weight: 600;
      }
    }

    &:last-child {
      border-bottom: 0px;
    }
  }

  .long {
    .row-section {
      padding-right: 36px;
      &:last-child {
        padding-right: 0px;
      }
    }
  }
`;

export const Steps = styled.div`
  padding: 93px;
  padding-top: 80px;
  padding-bottom: 0px;

  @media only screen and (max-width: 768px) {
    padding: 0 24px;
    padding-top: 42px;
  }

  h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 45px;
    line-height: 55px;
    color: #247892;
    text-align: left;
    margin-bottom: 42px;
    @media only screen and (max-width: 768px) {
      text-align: center;
    }
  }

  .titles {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    font-size: 1.5em;
    font-weight: 600;

    @media only screen and (max-width: 768px) {
      font-size: 18px;
    }
  }

  .second {
    margin-top: 36px;
  }

  .timeline {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 36px;
    margin-bottom: 64px;
  }

  .left,
  .right {
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: initial;
    align-items: center;
    margin-right: 36px;
    width: 20%;

    h4 {
      @media only screen and (max-width: 768px) {
        font-size: 18px;
      }
    }

    p {
      margin-top: 8px;
      font-size: 0.8rem;

      @media only screen and (max-width: 768px) {
        font-size: 14px;
      }
    }

    @media only screen and (max-width: 768px) {
      margin: 0px;
      margin-bottom: 36px;
      width: auto;
    }

    &:last-child {
      margin-right: 0px;
    }

    .image {
      width: 130px;
      height: 130px;
      background-color: #247892;
      border-radius: 50%;
      margin-bottom: 24px;
      align-items: center;
      justify-content: center;
      display: flex;

      img {
        filter: invert(1);
        width: 64px;
      }
    }
  }

  .right {
    justify-content: center;

    .card {
      width: 20%;

      @media only screen and (max-width: 768px) {
        margin: 0px;
        margin-bottom: 36px;
        width: auto;
      }
    }
  }

  @media only screen and (max-width: 768px) {
  }
`;

export const PlansSection = styled.section`
  margin-top: 100px;
  text-align: center;

  .btn-outline {
    display: none;
  }

  .green {
    color: #00b193;
  }

  .text-blue {
    color: #009ddc;
    text-decoration: underline;
  }

  h2 {
    margin-bottom: 50px;
  }
`;

export const SliderContainer = styled.div`
  padding-top: 80px;
  padding-bottom: 0px;
  /* margin: 0 93px; */

  @media only screen and (max-width: 768px) {
    padding: 0 24px;
    padding-top: 42px;

    .slick-next {
      right: 0px;
      &:before {
        color: #3ad6ca;
        font-size: 28px;
      }
    }

    .slick-prev {
      left: -10px;
      &:before {
        color: #3ad6ca;
        font-size: 28px;
      }
    }
  }

  h3 {
    margin-left: 93px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 45px;
    line-height: 55px;
    color: #247892;
    text-align: left;
    margin-bottom: 42px;
    @media only screen and (max-width: 768px) {
      text-align: center;
      margin-left: 0px;
    }
  }
`;

export const MedicCard = styled.div`
  width: 300px !important;
  height: 338px;

  @media only screen and (max-width: 768px) {
    width: 71vw !important;
    margin: 37px;
  }

  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 10px 0px;
  cursor: pointer;

  img {
    display: flex;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    object-position: 0;

    @media only screen and (max-width: 768px) {
      width: 150px;
      height: 150px;
    }
  }

  h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 36px;
    margin-top: 24px;
    padding: 0 24px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #3ad6ca;

    @media only screen and (max-width: 768px) {
      font-size: 16px;
      padding: 0 16px;
    }
  }

  .slick-dots {
    margin-top: 24px;
  }
`;

export const ModalMedic = styled.div`
  position: fixed;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  display: flex;
  height: 500px;
  background-color: red;
  z-index: 9999;
  top: 15%;
  left: 10vw;
  width: 80vw;
`;

export const Analisys = styled.section`
  display: block;
  position: relative;

  .background_dna{
    height: 250px;
  }

  h2 {
    margin-bottom: 30px;
    @media only screen and (max-width: 800px) {
      margin-bottom: 24px;
      padding: 0 12px;
      font-size: 17px;
    }
  }

  p {
    position: absolute;
    font-size: 12px;

    @media only screen and (max-width: 800px) {
      position: unset;
      font-size: 13px;
      padding: 0 32px;
      margin-bottom: 16px;
    }
    /* -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease; */
    /* cursor: pointer; */

    /* &:hover {
      font-size: 17px;
    } */
  }
`;

export const AnalisysImg = styled.section`
  display: flex;
  justify-content: center;
  height: 100%;

  img{
    height: 100%;
    width: 80%;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

export const AnalisysMobile = styled.section`
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
`;

export const AnalisysOne = styled.p`
  max-width: fit-content;
  text-align: left;
`;

export const AnalisysTwo = styled.p`
  max-width: fit-content;
  text-align: left;
`;

export const AnalisysThree = styled.p`
  max-width: fit-content;
  text-align: left;
`;

export const AnalisysFour = styled.p`
  max-width: fit-content;
  text-align: left;
`;

export const AnalisysFive = styled.p`
  max-width: 240px;
  top: 588px;
  right: 11%;
  text-align: left;

  @media only screen and (min-width: 1920px) {
    max-width: 240px;
    top: 526px;
    right: 339px;
  }

  @media only screen and (width: 1280px) {
    max-width: 147px;
    top: 60%;
    right: 14.3%;
    text-align: left;
  }

  @media only screen and (width: 1366px) {
    top: 66%;
    right: 8%;
  }

  @media only screen and (max-width: 738px) {
    max-width: fit-content;
    text-align: center;
  }
`;
