import styled from 'styled-components';

type props={
  progress: number
};

export const Container = styled.div<props>`
  z-index: 99;
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #FFFFFF;
  width: 320px;
  min-height: 55px;
  border-radius: 10px;

  >svg{
    width: 20px;
    height: 20px;
    margin: 15px 0 0 15px;
  }

  >p{
    color: #707070;
    font-weight: bold;
    font-size: .875rem;
    width: 90%;
    text-align: center;
    margin: 20px auto 20px auto;
  }

  div{
    position: absolute;
    bottom: 0;
    width: 320px;
    height: 8px;
    border-radius: ${props => props.progress < 10 ? '10px 0 0 10px' : '10px'};
    >.progressBar{
      width: ${props => `${props.progress * 10}%`};
      height: 100%;
      background-color: #004F92;
      transition: all .5s;
    }
  }
`;
