import styled from 'styled-components';
import { Container } from '../../../styles/GlobalStyle';

export const HeaderSite = styled.header`
    position: fixed;
    width: 100%;
    background-color: white;
    z-index: 10;
`;

export const HeaderContainer = styled(Container)`
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    height: 63px;

    img {
      height: 44px;
      width: auto
    }

    .label{
      font-weight: 600;
      color: #004F92;
      font-size: 17px;
      border-left: solid 1px #004f9269;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 1rem;
      margin-left: 1rem;
    }
    svg{
      height: 23px;
    }
    @media(max-width: 778px){
      justify-content: center;
    }
`;

export const MenuDesktop = styled.nav`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;

    .menu-item{
      display:flex;
      align-items: center;
      font-size: .85rem;
      color: #b2cade;
      font-weight: 600;
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }
    .actual{
      color: #004F92;
      path{
        stroke: #004F92!important;
      }
    }

    .past{
      color:#00BEB1;
      path{
        stroke: #01B092!important;
      }
    }
    .actual-circle{
      background: #E5F9F7;
      color: #00BEB1;
      path{
        stroke: #00BEB1 !important;
      }
    }
    .actual-number{
      color: #004F92
    }
    .arrow{
      transform: rotate(90deg);
      path{
        stroke: #b2cade;
      }
    }
`;

export const Circle = styled.div`
    min-width: 20px;
    width: 20px;
    height: 20px;
    margin: 5px;
    border-radius: 20px;
    background: #F0F2F4;
    color: #b2cade;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
`;

export const GradientLine = styled.div`
    background-image: linear-gradient(to right, #ECB561 0%, #DE656B 30%, #4595C4 60% , #3AD5CA 100%);
    height: 8px;
    @media(max-width: 778px){
      height: 4px;
    }
`;

export const Separator = styled.div`
  border-right: solid 1px #005A9C;
  height: 35px;
  margin-right: 7px;
  margin-left: 17px;
`;
