/* eslint-disable multiline-ternary */
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import Layout from '../Layout';
// import NotFound from '../pages/NotFound';
import AppProvider from '../pages/Purchase/Payment/hooks';
import Dashboard from '../pages/Dashboard';
import Schedule from '../pages/Schedule';
import Historic from '../pages/Historic';
import Help from '../pages/Contact';
import UserData from '../pages/UserData';
import UserDependents from '../pages/UserDependents';
import UserPlans from '../pages/UserPlans';
import Security from '../pages/UserSecurity';
import MedicalRecord from '../pages/UserMedicalRecord';
import UserAuth from '../pages/UserAuth';
import UserUpload from '../pages/UserUpload';
import Quiz from '../pages/Quiz';

import Home from '../pages/Home';
import Compra from '../pages/Purchase';
import Cadastro from '../pages/Register';

import GlobalStyles from '../styles/GlobalStyle';

import { isExpired } from 'react-jwt';
import { Success } from '../pages/Purchase/Success';

import Landing from '../pages/Landing';
import LandingOne from '../pages/LandingOne';
import LandingInfinity from '../pages/LandingInfinity';

const logged = localStorage.getItem('api_token');

const Routes = (): any => {
  const tokenValid = isExpired(String(logged));

  return (
    <Router>
      <GlobalStyles />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/new" exact component={Landing} />
        <Route path="/one" exact component={LandingOne} />
        <Route path="/infinity" exact component={LandingInfinity} />
        <Route path="/cadastro" exact component={Cadastro} />
        <Route path="/contratar" exact>
          <AppProvider>
            <Compra />
          </AppProvider>
        </Route>
        <Route path="/success" exact>
          <Success />
        </Route>
        {tokenValid ? (
          <Redirect to="/" />
        ) : (
          <>
            <Route path="/dashboard" exact>
              <Layout>
                <Dashboard />
              </Layout>
            </Route>
            <Route path="/meus-dados">
              <Layout>
                <UserData />
              </Layout>
            </Route>
            <Route path="/schedule">
              <Layout>
                <Schedule />
              </Layout>
            </Route>
            <Route path="/historic">
              <Layout>
                <Historic />
              </Layout>
            </Route>
            <Route path="/contact">
              <Layout>
                <Help />
              </Layout>
            </Route>
            <Route path="/dependentes">
              {' '}
              {/* <Layout><UserDependents /></Layout> */}{' '}
              <Redirect to="/meus-dados" />
            </Route>
            <Route path="/meus-planos">
              {/* <Layout><UserPlans /></Layout> */}{' '}
              <Redirect to="/meus-dados" />
            </Route>
            <Route path="/seguranca">
              <Layout>
                <Security />
              </Layout>
            </Route>
            <Route path="/prontuario">
              <Layout>
                <MedicalRecord />
              </Layout>
            </Route>
            <Route path="/autenticacao">
              <Layout>
                <UserAuth />
              </Layout>
            </Route>
            <Route path="/upload-documentos">
              <Layout>
                <UserUpload />
              </Layout>
            </Route>
            <Route path="/questionario">
              <Layout>
                <Quiz />
              </Layout>
            </Route>

            {/* <Route path='/*'><Layout><NotFound /></Layout></Route> */}
          </>
        )}
        {/* <Route path='/*' component={NotFound} /> */}
      </Switch>
    </Router>
  );
};

export default Routes;
