import styled from 'styled-components';
type props = {
  open: boolean
};
export const ModalBkg = styled.div<props>`
    ${props => !props.open ? 'display: none' : 'display:flex'};    
    background: rgba(0,0,0, 0.8);
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 60px;
    bottom:0;
    z-index: 11;
    align-items:center;
    justify-content:center;
    overflow-y: hidden;
    @media(max-width: 1024px){
      z-index: 11;
    }
    @media(min-width: 991px){
      padding-left: 72px;
    }
`;

export const Contact = styled.div`
    overflow-y: auto;
    background: white;
    border-radius: .6rem;
    width: 90vw;
    max-width: 500px;
    height: 85vh;
    padding: .6rem;
    .flex{
      display:flex;
      button{
        margin-left:auto;
        svg{
          color: #707070;
          height: 1.25rem;
        }
      }
    }
    p{
      font-size: 1.25rem;
      color: #707070;
      font-weight: 600;  
      margin-bottom: 1.25rem;
    }

    ::-webkit-scrollbar {
      width: .8em;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: .6rem;
    }
    
    ::-webkit-scrollbar-thumb {
      background: #ccc; 
      border-radius: .5rem;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #b5b5b5;
    }
`;
export const FormWrapper = styled.div`
  padding: .3rem 1.25rem;

  .required{
    border: solid 1px #F53748;
  }

  .error {
    color: #F53748;
    font-size: .75rem;
    margin-bottom: .6rem;
    margin-top: .6rem;
  }

  .success{
    color: #5cb85c;
    font-size: .85rem;
    margin-bottom: .6rem;
    margin-top: .6rem;
  }

  input {
    border: solid 1px #999999ba;
    border-radius: 8px;
    height: 50px;
    width: 100%;
    font-size: 14px;
    padding-left:10px;
    ::placeholder {
      color: #999999;
    }
  }

  .form-group{
    transition: all .3s;
    width: 100%;
    height: 60px;
    margin-bottom: 5px;
  }

  .textarea{
    height: auto;
  }

  input {
    ::placeholder {
      color: transparent;
    }
  }

  .form-label{
    color: #999999ba;
    font-size: 13px;
    font-weight: 300;
    padding-right: 4px;
    padding-left: 4px;
    width: fit-content;
    display:block;
    margin-left: 10px;
    transform: translateY(-38px);
    transition: all .3s;
    background: #fff;
    position: relative;
    z-index: 1;
    transform-origin: 0 0;
  }

  .form-control{
    transition: all .5s;
    position: relative;
    z-index: 2;
    background: transparent;
  }

  .form-control:focus{
    color: #707070;
  }

  .form-control:focus + .form-label,
  .form-control:not(:placeholder-shown) + .form-label
  {
    transform: translateY(-60px) scale(.8);
    font-weight: 500;
    z-index: 3;
    color: #707070;

  }

  .form-control:focus,
  .form-control:not(:placeholder-shown)
  {
    color: #707070;
  }

  @media(max-width: 1024px){
    padding: 0;
  }
`;

export const TextArea = styled.textarea`
  border: solid 1px #999999ba;
  border-radius: 5px;
  height: 130px;
  width: 100%;
  font-size: .85rem;
  padding-left:10px;
  padding-top:15px;
  margin-bottom: 10px;
  margin-top: 5px;
  color: #707070;
  ::placeholder {
    color: #999999ba;
    font-size: .85rem;
    font-weight: 300;
  }
`;

export const ContactWrapper = styled.div`
  display:flex;
  align-items:center;
  margin-bottom: 1rem;
  font-size: .85rem;
  color: #707070;
  font-weight: 600;
  line-height: 1.5rem;
`;

export const CircleIcon = styled.div`
  min-width: 32px;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background: #009DFF;
  color: #fff;
  margin-right: .6rem;
  text-transform: none;
  display: flex;
  justify-content: center;
  align-items: center;

`;
