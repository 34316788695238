import styled from 'styled-components';
type props = {
  top?: string
  left?: string
  color?: string
  isFixed?: boolean
  size?: string
};

export const Load = styled.div<props>`
  position: ${props => props.isFixed ? 'fixed' : 'relative'};
  left: ${props => props.left ? props.left : '50%'};
  top: ${props => props.top ? props.top : '100px'};
  width: ${props => props.size ? props.size : '50px'};
  height: ${props => props.size ? props.size : '50px'};
  border: 4px solid #80808042;
  border-radius: 50%;
  box-sizing: border-box;
  
  ${props => props.isFixed && `
      ::before{
        content: "";
        position: fixed;
        transform: translateX(-50%);
        transform: translateY(-300px);
        left: 0;
        height: 100vh;
        width: 100vw;
        background: rgba(141, 145, 150, .2);
      }
    }
    `}
    &:after {
    content: "";
    position: absolute;
    top: -4px;
    left: -4px;
    display: block;
    width: ${props => props.size ? props.size : '50px'};
    height: ${props => props.size ? props.size : '50px'};
    border: 4px solid transparent;
    border-top: 4px solid ${props => props.color ? props.color : '#004F92'};
    border-radius: 50%;
    box-sizing: border-box;
    animation: spin 0.55s infinite ease-in-out;
    filter: brightness(130%);
  }

  @keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
  }
`;
