import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import {
  HeaderSite,
  HeaderContainer,
  MenuDesktop,
  GradientLine,
  Separator,
  Circle
} from './styles';
import {
  // LogoEinstein,
  IconEinsteinConecta,
  ArrowBack,
  IconCheck
} from '../../../assets/icons';

import LogoEinstein from '../../../assets/logo.png';

type props = {
  step: string
  handleStep: Function
};
export default function Header(props: props): ReactElement {
  return (
    <HeaderSite>
      <HeaderContainer>
        <Link to="/">
          <img src={LogoEinstein} width={'200px'} />
        </Link>
          <MenuDesktop>
            <button onClick={() => props.handleStep('email')}>
              <div className={props.step === 'email' || props.step === 'login' ? 'menu-item actual' : 'menu-item past'}>
                <Circle className="actual-circle">
                  <IconCheck />
                </Circle>
                <span className="text">Login ou cadastro</span>
              </div>
            </button>
            <div className={props.step === 'email' || props.step === 'login' ? 'arrow actual' : 'arrow past'}>
              <ArrowBack />
            </div>

            <button onClick={() => props.handleStep('paymentMethod')}>
              <div
                className={
                  props.step === 'paymentMethod' || props.step === 'paymentData' ? 'menu-item actual' : props.step === 'userData' ? 'menu-item past' : 'menu-item'
                }
                >
                <Circle
                  className={props.step === 'paymentMethod' || props.step === 'paymentData' ? 'actual-number' : props.step === 'userData' ? 'actual-circle' : ''}
                  >
                  { props.step === 'userData' ? (<IconCheck />) : '2'}
                </Circle>
                <span className="text">Pagamento</span>
              </div>
            </button>
            <div className={
              props.step === 'email' || props.step === 'login' ? 'arrow' : props.step === 'userData' ? 'arrow past' : 'arrow actual'}>
              <ArrowBack />
            </div>

            <div className={props.step === 'userData' ? 'menu-item actual' : 'menu-item'}>
              <Circle className={props.step === 'userData' ? 'actual-number' : ''}>3</Circle>
              <span className="text">Dados do beneficiário</span>
            </div>
          </MenuDesktop>
      </HeaderContainer>
      <GradientLine />
    </HeaderSite>
  );
}
