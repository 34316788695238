export const unitsPredicta = {
  unidades: [
    {
      id: 7,
      fullName: 'Unidade Morumbi',
      name: 'Morumbi',
      image: '',
      uf: 'São Paulo',
      address: 'Av. Albert Einstein, 627 - Morumbi, São Paulo - SP, 05652-900',
      subaddress: 'Bloco A1 - Intermediário 3 (I3) - Central de Exames',
      description:
        '​Na TESTE Hospital Israelita Albert Einstein você tem à disposição uma equipe formada por médicos de família e enfermeiros, com apoio de nutricionistas, educadores físicos, psicólogos, entre outros, trabalhando juntos para garantir o cuidado da sua saúde e construir um plano de cuidado exclusivo para você. Levamos em consideração o seu histórico de saúde, seus comportamentos de vida e outros fatores para definir com você, suas metas de saúde e programar consultas e orientações que vão te ajudar a melhorar a sua qualidade de vida.\n\nVocê tem ainda um canal de comunicação direto com a sua equipe, para esclarecer dú​vidas e receber orientações, lembrar você da sua programação de cuidado e agendar suas consultas. Tudo de forma rápida e fácil.',
      coordinates: {
        latitude: -23.5984151,
        longitude: -46.7114279
      },
      initialRegion: {
        latitude: -23.5984151,
        longitude: -46.7114279,
        latitudeDelta: 0.002,
        longitudeDelta: 0.002
      },
      telephone: '+55 (11) 2151-7564 - Público geral',
      employeeTelephone: '+55 (11) 2151-0021 - Funcionários Einstein',
      services: [
        {
          type: 'rt-pcr',
          title: 'RT-PCR Covid19'
        },
        {
          type: 'vacinacao',
          title: 'Vacinação'
        },
        {
          type: 'consultas',
          title: 'Consultas'
        },
        {
          type: 'telemedicina',
          title: 'Telemedicina'
        },
        {
          type: 'exames',
          title: 'Exames'
        }
      ]
    },
    {
      id: 70,
      fullName: 'Unidade Ambulatorial Parque da Cidade',
      name: 'Parque da Cidade',
      image:
        'https://aps-digital.s3.amazonaws.com/unidades/parque-da-cidade.jpeg',
      uf: 'São Paulo',
      address:
        'Av. Nações Unidas, 14401 - Chácara Santo Antônio, São Paulo - SP, 04794-000',
      subaddress: 'A1 - 2º Andar',
      description:
        '​Na Clínica Einstein você tem à disposição uma equipe formada por médicos de família e enfermeiros, com apoio de nutricionistas, educadores físicos, psicólogos, entre outros, trabalhando juntos para garantir o cuidado da sua saúde e construir um plano de cuidado exclusivo para você. Levamos em consideração o seu histórico de saúde, seus comportamentos de vida e outros fatores para definir com você, suas metas de saúde e programar consultas e orientações que vão te ajudar a melhorar a sua qualidade de vida.\n\nVocê tem ainda um canal de comunicação direto com a sua equipe, para esclarecer dú​vidas e receber orientações, lembrar você da sua programação de cuidado e agendar suas consultas. Tudo de forma rápida e fácil.',
      coordinates: {
        latitude: -23.625051,
        longitude: -46.7065853
      },
      initialRegion: {
        latitude: -23.625051,
        longitude: -46.7065853,
        latitudeDelta: 0.002,
        longitudeDelta: 0.002
      },
      telephone: '+55 (11) 2151-7564 - Público geral',
      employeeTelephone: '+55 (11) 2151-0021 - Funcionários Einstein',
      services: [
        {
          type: 'rt-pcr',
          title: 'RT-PCR Covid19'
        },
        {
          type: 'vacinacao',
          title: 'Vacinação'
        },
        {
          type: 'consultas',
          title: 'Consultas'
        },
        {
          type: 'telemedicina',
          title: 'Telemedicina'
        },
        {
          type: 'exames',
          title: 'Exames'
        }
      ]
    },
    {
      id: 72,
      fullName: 'Unidade Ambulatorial Parque Ibirapuera',
      name: 'Ibirapuera',
      image: 'https://aps-digital.s3.amazonaws.com/unidades/ibirapuera.jpg',
      uf: 'São Paulo',
      address:
        'Av. República do Líbano, 315 - Vila Mariana, São Paulo - SP, 04502-000',
      subaddress: 'A1 - 2º Andar',
      description:
        '​Na Clínica Einstein você tem à disposição uma equipe formada por médicos de família e enfermeiros, com apoio de nutricionistas, educadores físicos, psicólogos, entre outros, trabalhando juntos para garantir o cuidado da sua saúde e construir um plano de cuidado exclusivo para você. Levamos em consideração o seu histórico de saúde, seus comportamentos de vida e outros fatores para definir com você, suas metas de saúde e programar consultas e orientações que vão te ajudar a melhorar a sua qualidade de vida.\n\nVocê tem ainda um canal de comunicação direto com a sua equipe, para esclarecer dú​vidas e receber orientações, lembrar você da sua programação de cuidado e agendar suas consultas. Tudo de forma rápida e fácil.',
      coordinates: {
        latitude: -23.584537140644446,
        longitude: -46.66349579782675
      },
      initialRegion: {
        latitude: -23.584537140644446,
        longitude: -46.66349579782675,
        latitudeDelta: 0.002,
        longitudeDelta: 0.002
      },
      telephone: '+55 (11) 2151-1233 - Público geral',
      employeeTelephone: '',
      services: [
        {
          type: 'consultas',
          title: 'Consultas'
        },
        {
          type: 'telemedicina',
          title: 'Telemedicina'
        },
        {
          type: 'exames',
          title: 'Exames'
        }
      ]
    },
    {
      id: 73,
      fullName: 'Unidade Ambulatorial  Analia Franco',
      name: 'Anália Franco',
      image: 'https://aps-digital.s3.amazonaws.com/unidades/analia-franco.jpg',
      uf: 'São Paulo',
      address:
        'Av. Reg. Feijó, 1425 - 2º andar - Vila Reg. Feijó, São Paulo - SP, 03342-000',
      subaddress: 'A1 - 2º Andar',
      description:
        '​Na Clínica Einstein você tem à disposição uma equipe formada por médicos de família e enfermeiros, com apoio de nutricionistas, educadores físicos, psicólogos, entre outros, trabalhando juntos para garantir o cuidado da sua saúde e construir um plano de cuidado exclusivo para você. Levamos em consideração o seu histórico de saúde, seus comportamentos de vida e outros fatores para definir com você, suas metas de saúde e programar consultas e orientações que vão te ajudar a melhorar a sua qualidade de vida.\n\nVocê tem ainda um canal de comunicação direto com a sua equipe, para esclarecer dú​vidas e receber orientações, lembrar você da sua programação de cuidado e agendar suas consultas. Tudo de forma rápida e fácil.',
      coordinates: {
        latitude: -23.56077874683661,
        longitude: -46.56306050179369
      },
      initialRegion: {
        latitude: -23.56077874683661,
        longitude: -46.56306050179369,
        latitudeDelta: 0.002,
        longitudeDelta: 0.002
      },
      telephone: '+55 (11) 2151-7564 - Público geral',
      employeeTelephone: '+55 (11) 2151-0021 - Funcionários Einstein',
      services: [
        {
          type: 'rt-pcr',
          title: 'RT-PCR Covid19'
        },
        {
          type: 'vacinacao',
          title: 'Vacinação'
        },
        {
          type: 'consultas',
          title: 'Consultas'
        },
        {
          type: 'telemedicina',
          title: 'Telemedicina'
        },
        {
          type: 'exames',
          title: 'Exames'
        }
      ]
    },
    {
      id: 74,
      fullName: 'Unidade Ambulatorial Santana',
      name: 'Santana',
      image: 'https://aps-digital.s3.amazonaws.com/unidades/santana.jpeg',
      uf: 'São Paulo',
      address: 'Av. Braz Leme, 3265 - Santana, São Paulo - SP, 02022-011',
      subaddress: 'A1 - 2º Andar',
      description:
        '​Na Clínica Einstein você tem à disposição uma equipe formada por médicos de família e enfermeiros, com apoio de nutricionistas, educadores físicos, psicólogos, entre outros, trabalhando juntos para garantir o cuidado da sua saúde e construir um plano de cuidado exclusivo para você. Levamos em consideração o seu histórico de saúde, seus comportamentos de vida e outros fatores para definir com você, suas metas de saúde e programar consultas e orientações que vão te ajudar a melhorar a sua qualidade de vida.\n\nVocê tem ainda um canal de comunicação direto com a sua equipe, para esclarecer dú​vidas e receber orientações, lembrar você da sua programação de cuidado e agendar suas consultas. Tudo de forma rápida e fácil.',
      coordinates: {
        latitude: -23.50381600074172,
        longitude: -46.629115744122664
      },
      initialRegion: {
        latitude: -23.50381600074172,
        longitude: -46.629115744122664,
        latitudeDelta: 0.002,
        longitudeDelta: 0.002
      },
      telephone: '+55 (11) 2151-7564 - Público geral',
      employeeTelephone: '+55 (11) 2151-0021 - Funcionários Einstein',
      services: [
        {
          type: 'rt-pcr',
          title: 'RT-PCR Covid19'
        },
        {
          type: 'vacinacao',
          title: 'Vacinação'
        },
        {
          type: 'consultas',
          title: 'Consultas'
        },
        {
          type: 'telemedicina',
          title: 'Telemedicina'
        },
        {
          type: 'exames',
          title: 'Exames'
        }
      ]
    },
    {
      id: 53,
      fullName: 'Unidade Avancada Ibirapuera',
      name: 'Ibirapuera',
      image: 'https://aps-digital.s3.amazonaws.com/unidades/ibirapuera.jpg',
      uf: 'São Paulo',
      address:
        'Av. República do Líbano, 315 - Vila Mariana, São Paulo - SP, 04502-000',
      subaddress: 'A1 - 2º Andar',
      description:
        '​Na Clínica Einstein você tem à disposição uma equipe formada por médicos de família e enfermeiros, com apoio de nutricionistas, educadores físicos, psicólogos, entre outros, trabalhando juntos para garantir o cuidado da sua saúde e construir um plano de cuidado exclusivo para você. Levamos em consideração o seu histórico de saúde, seus comportamentos de vida e outros fatores para definir com você, suas metas de saúde e programar consultas e orientações que vão te ajudar a melhorar a sua qualidade de vida.\n\nVocê tem ainda um canal de comunicação direto com a sua equipe, para esclarecer dú​vidas e receber orientações, lembrar você da sua programação de cuidado e agendar suas consultas. Tudo de forma rápida e fácil.',
      coordinates: {
        latitude: -23.584537140644446,
        longitude: -46.66349579782675
      },
      initialRegion: {
        latitude: -23.584537140644446,
        longitude: -46.66349579782675,
        latitudeDelta: 0.002,
        longitudeDelta: 0.002
      },
      telephone: '+55 (11) 2151-1233 - Público geral',
      employeeTelephone: '',
      services: [
        {
          type: 'consultas',
          title: 'Consultas'
        },
        {
          type: 'telemedicina',
          title: 'Telemedicina'
        },
        {
          type: 'exames',
          title: 'Exames'
        }
      ]
    },
    {
      id: 69,
      fullName: 'Unidade Ambulatorial Alto de Pinheiros',
      name: 'Alto de Pinheiros',
      image:
        'https://aps-digital.s3.amazonaws.com/unidades/alto-de-pinheiros.jpg',
      uf: 'São Paulo',
      address:
        'Av. São Gualter, 766 - Alto de Pinheiros, São Paulo - SP, 05455-001',
      subaddress: 'A1 - 2º Andar',
      description:
        '​Na Clínica Einstein você tem à disposição uma equipe formada por médicos de família e enfermeiros, com apoio de nutricionistas, educadores físicos, psicólogos, entre outros, trabalhando juntos para garantir o cuidado da sua saúde e construir um plano de cuidado exclusivo para você. Levamos em consideração o seu histórico de saúde, seus comportamentos de vida e outros fatores para definir com você, suas metas de saúde e programar consultas e orientações que vão te ajudar a melhorar a sua qualidade de vida.\n\nVocê tem ainda um canal de comunicação direto com a sua equipe, para esclarecer dú​vidas e receber orientações, lembrar você da sua programação de cuidado e agendar suas consultas. Tudo de forma rápida e fácil.',
      coordinates: {
        latitude: -23.542077692144083,
        longitude: -46.71188835946636
      },
      initialRegion: {
        latitude: -23.542077692144083,
        longitude: -46.71188835946636,
        latitudeDelta: 0.002,
        longitudeDelta: 0.002
      },
      telephone: '+55 (11) 2151-7564 - Público geral',
      employeeTelephone: '+55 (11) 2151-0021 - Funcionários Einstein',
      services: [
        {
          type: 'rt-pcr',
          title: 'RT-PCR Covid19'
        },
        {
          type: 'vacinacao',
          title: 'Vacinação'
        },
        {
          type: 'consultas',
          title: 'Consultas'
        },
        {
          type: 'telemedicina',
          title: 'Telemedicina'
        },
        {
          type: 'exames',
          title: 'Exames'
        }
      ]
    },
    {
      id: 23,
      fullName: 'Unidade Alphaville',
      name: 'Alphaville',
      image: '',
      uf: 'São Paulo',
      address: 'Av. Juruá, 706 - CEP 06455-010 - Alphaville - São Paulo - SP',
      subaddress: '',
      description:
        '​Na Hospital Israelita Albert Einstein você tem à disposição uma equipe formada por médicos de família e enfermeiros, com apoio de nutricionistas, educadores físicos, psicólogos, entre outros, trabalhando juntos para garantir o cuidado da sua saúde e construir um plano de cuidado exclusivo para você. Levamos em consideração o seu histórico de saúde, seus comportamentos de vida e outros fatores para definir com você, suas metas de saúde e programar consultas e orientações que vão te ajudar a melhorar a sua qualidade de vida.\n\nVocê tem ainda um canal de comunicação direto com a sua equipe, para esclarecer dú​vidas e receber orientações, lembrar você da sua programação de cuidado e agendar suas consultas. Tudo de forma rápida e fácil.',
      coordinates: {
        latitude: -23.5984151,
        longitude: -46.7114279
      },
      initialRegion: {
        latitude: -23.5984151,
        longitude: -46.7114279,
        latitudeDelta: 0.002,
        longitudeDelta: 0.002
      },
      telephone: '+55 (11) 2151-7564 - Público geral',
      employeeTelephone: '+55 (11) 2151-0021 - Funcionários Einstein',
      services: [
        {
          type: 'rt-pcr',
          title: 'RT-PCR Covid19'
        },
        {
          type: 'vacinacao',
          title: 'Vacinação'
        },
        {
          type: 'consultas',
          title: 'Consultas'
        },
        {
          type: 'telemedicina',
          title: 'Telemedicina'
        },
        {
          type: 'exames',
          title: 'Exames'
        }
      ]
    },
    {
      id: 62,
      fullName: 'Unidade Perdizes',
      name: 'Perdizes',
      image: '',
      uf: 'São Paulo',
      address: 'Rua Apiacás, 85 - CEP 05017-020 - Perdizes - São Paulo - SP',
      subaddress: '',
      description:
        '​Na Hospital Israelita Albert Einstein você tem à disposição uma equipe formada por médicos de família e enfermeiros, com apoio de nutricionistas, educadores físicos, psicólogos, entre outros, trabalhando juntos para garantir o cuidado da sua saúde e construir um plano de cuidado exclusivo para você. Levamos em consideração o seu histórico de saúde, seus comportamentos de vida e outros fatores para definir com você, suas metas de saúde e programar consultas e orientações que vão te ajudar a melhorar a sua qualidade de vida.\n\nVocê tem ainda um canal de comunicação direto com a sua equipe, para esclarecer dú​vidas e receber orientações, lembrar você da sua programação de cuidado e agendar suas consultas. Tudo de forma rápida e fácil.',
      coordinates: {
        latitude: -23.5984151,
        longitude: -46.7114279
      },
      initialRegion: {
        latitude: -23.5984151,
        longitude: -46.7114279,
        latitudeDelta: 0.002,
        longitudeDelta: 0.002
      },
      telephone: '+55 (11) 2151-7564 - Público geral',
      employeeTelephone: '+55 (11) 2151-0021 - Funcionários Einstein',
      services: [
        {
          type: 'rt-pcr',
          title: 'RT-PCR Covid19'
        },
        {
          type: 'vacinacao',
          title: 'Vacinação'
        },
        {
          type: 'consultas',
          title: 'Consultas'
        },
        {
          type: 'telemedicina',
          title: 'Telemedicina'
        },
        {
          type: 'exames',
          title: 'Exames'
        }
      ]
    },
    {
      id: 25,
      fullName: 'Unidade Jardins',
      name: 'Jardins',
      image: '',
      uf: 'São Paulo',
      address:
        'Av. Brasil, 1085 - CEP 01431- 000 - Jardim América - São Paulo - SP',
      subaddress: '',
      description:
        '​Na Hospital Israelita Albert Einstein você tem à disposição uma equipe formada por médicos de família e enfermeiros, com apoio de nutricionistas, educadores físicos, psicólogos, entre outros, trabalhando juntos para garantir o cuidado da sua saúde e construir um plano de cuidado exclusivo para você. Levamos em consideração o seu histórico de saúde, seus comportamentos de vida e outros fatores para definir com você, suas metas de saúde e programar consultas e orientações que vão te ajudar a melhorar a sua qualidade de vida.\n\nVocê tem ainda um canal de comunicação direto com a sua equipe, para esclarecer dú​vidas e receber orientações, lembrar você da sua programação de cuidado e agendar suas consultas. Tudo de forma rápida e fácil.',
      coordinates: {
        latitude: -23.5984151,
        longitude: -46.7114279
      },
      initialRegion: {
        latitude: -23.5984151,
        longitude: -46.7114279,
        latitudeDelta: 0.002,
        longitudeDelta: 0.002
      },
      telephone: '+55 (11) 2151-7564 - Público geral',
      employeeTelephone: '+55 (11) 2151-0021 - Funcionários Einstein',
      services: [
        {
          type: 'rt-pcr',
          title: 'RT-PCR Covid19'
        },
        {
          type: 'vacinacao',
          title: 'Vacinação'
        },
        {
          type: 'consultas',
          title: 'Consultas'
        },
        {
          type: 'telemedicina',
          title: 'Telemedicina'
        },
        {
          type: 'exames',
          title: 'Exames'
        }
      ]
    },
    {
      id: 88,
      fullName: 'Unidade Brasília',
      name: 'Brasília',
      image: '',
      uf: 'Brasília',
      address:
        'Rua SGAS 915, lote 69/70, bloco A - Empresarial Advance Centro Clinico Sul - Loja 152 - Asa Sul (BRASILIA)',
      subaddress: '',
      description:
        '​Na Hospital Israelita Albert Einstein você tem à disposição uma equipe formada por médicos de família e enfermeiros, com apoio de nutricionistas, educadores físicos, psicólogos, entre outros, trabalhando juntos para garantir o cuidado da sua saúde e construir um plano de cuidado exclusivo para você. Levamos em consideração o seu histórico de saúde, seus comportamentos de vida e outros fatores para definir com você, suas metas de saúde e programar consultas e orientações que vão te ajudar a melhorar a sua qualidade de vida.\n\nVocê tem ainda um canal de comunicação direto com a sua equipe, para esclarecer dú​vidas e receber orientações, lembrar você da sua programação de cuidado e agendar suas consultas. Tudo de forma rápida e fácil.',
      coordinates: {
        latitude: -23.5984151,
        longitude: -46.7114279
      },
      initialRegion: {
        latitude: -23.5984151,
        longitude: -46.7114279,
        latitudeDelta: 0.002,
        longitudeDelta: 0.002
      },
      telephone: '+55 (11) 2151-7564 - Público geral',
      employeeTelephone: '+55 (11) 2151-0021 - Funcionários Einstein',
      services: [
        {
          type: 'rt-pcr',
          title: 'RT-PCR Covid19'
        },
        {
          type: 'vacinacao',
          title: 'Vacinação'
        },
        {
          type: 'consultas',
          title: 'Consultas'
        },
        {
          type: 'telemedicina',
          title: 'Telemedicina'
        },
        {
          type: 'exames',
          title: 'Exames'
        }
      ]
    },
    {
      id: 67,
      fullName: 'Unidade Chacara Klabin',
      name: 'Chacara Klabin',
      image: '',
      uf: 'São Paulo',
      address:
        'Avenida Dr. Ricardo Jafet, 1600 - CEP 04260-000 - Vila Mariana - São Paulo - SP',
      subaddress: '',
      description:
        '​Na Hospital Israelita Albert Einstein você tem à disposição uma equipe formada por médicos de família e enfermeiros, com apoio de nutricionistas, educadores físicos, psicólogos, entre outros, trabalhando juntos para garantir o cuidado da sua saúde e construir um plano de cuidado exclusivo para você. Levamos em consideração o seu histórico de saúde, seus comportamentos de vida e outros fatores para definir com você, suas metas de saúde e programar consultas e orientações que vão te ajudar a melhorar a sua qualidade de vida.\n\nVocê tem ainda um canal de comunicação direto com a sua equipe, para esclarecer dú​vidas e receber orientações, lembrar você da sua programação de cuidado e agendar suas consultas. Tudo de forma rápida e fácil.',
      coordinates: {
        latitude: -23.5984151,
        longitude: -46.7114279
      },
      initialRegion: {
        latitude: -23.5984151,
        longitude: -46.7114279,
        latitudeDelta: 0.002,
        longitudeDelta: 0.002
      },
      telephone: '+55 (11) 2151-7564 - Público geral',
      employeeTelephone: '+55 (11) 2151-0021 - Funcionários Einstein',
      services: [
        {
          type: 'rt-pcr',
          title: 'RT-PCR Covid19'
        },
        {
          type: 'vacinacao',
          title: 'Vacinação'
        },
        {
          type: 'consultas',
          title: 'Consultas'
        },
        {
          type: 'telemedicina',
          title: 'Telemedicina'
        },
        {
          type: 'exames',
          title: 'Exames'
        }
      ]
    },
    {
      id: 86,
      fullName: 'Unidade Higienópolis',
      name: 'Higienópolis',
      image: '',
      uf: 'São Paulo',
      address:
        'Rua Mato Grosso, 306 - CEP 01239-040 - Higienópolis - Higienópolis Medical Center - Loja 05 - São Paulo - SP',
      subaddress: '',
      description:
        '​Na Hospital Israelita Albert Einstein você tem à disposição uma equipe formada por médicos de família e enfermeiros, com apoio de nutricionistas, educadores físicos, psicólogos, entre outros, trabalhando juntos para garantir o cuidado da sua saúde e construir um plano de cuidado exclusivo para você. Levamos em consideração o seu histórico de saúde, seus comportamentos de vida e outros fatores para definir com você, suas metas de saúde e programar consultas e orientações que vão te ajudar a melhorar a sua qualidade de vida.\n\nVocê tem ainda um canal de comunicação direto com a sua equipe, para esclarecer dú​vidas e receber orientações, lembrar você da sua programação de cuidado e agendar suas consultas. Tudo de forma rápida e fácil.',
      coordinates: {
        latitude: -23.5984151,
        longitude: -46.7114279
      },
      initialRegion: {
        latitude: -23.5984151,
        longitude: -46.7114279,
        latitudeDelta: 0.002,
        longitudeDelta: 0.002
      },
      telephone: '+55 (11) 2151-7564 - Público geral',
      employeeTelephone: '+55 (11) 2151-0021 - Funcionários Einstein',
      services: [
        {
          type: 'rt-pcr',
          title: 'RT-PCR Covid19'
        },
        {
          type: 'vacinacao',
          title: 'Vacinação'
        },
        {
          type: 'consultas',
          title: 'Consultas'
        },
        {
          type: 'telemedicina',
          title: 'Telemedicina'
        },
        {
          type: 'exames',
          title: 'Exames'
        }
      ]
    },
    {
      id: 87,
      fullName: 'Unidade Porto Alegre',
      name: 'Porto Alegre',
      image: '',
      uf: 'Rio Grande do Sul',
      address:
        'Rua Gomes Jardim, 201 - CEP 90620-130 - Santana - Medplex Torre Saúde - Torre B - Sala 1205 - Porto Alegre',
      subaddress: '',
      description:
        '​Na Hospital Israelita Albert Einstein você tem à disposição uma equipe formada por médicos de família e enfermeiros, com apoio de nutricionistas, educadores físicos, psicólogos, entre outros, trabalhando juntos para garantir o cuidado da sua saúde e construir um plano de cuidado exclusivo para você. Levamos em consideração o seu histórico de saúde, seus comportamentos de vida e outros fatores para definir com você, suas metas de saúde e programar consultas e orientações que vão te ajudar a melhorar a sua qualidade de vida.\n\nVocê tem ainda um canal de comunicação direto com a sua equipe, para esclarecer dú​vidas e receber orientações, lembrar você da sua programação de cuidado e agendar suas consultas. Tudo de forma rápida e fácil.',
      coordinates: {
        latitude: -23.5984151,
        longitude: -46.7114279
      },
      initialRegion: {
        latitude: -23.5984151,
        longitude: -46.7114279,
        latitudeDelta: 0.002,
        longitudeDelta: 0.002
      },
      telephone: '+55 (11) 2151-7564 - Público geral',
      employeeTelephone: '+55 (11) 2151-0021 - Funcionários Einstein',
      services: [
        {
          type: 'rt-pcr',
          title: 'RT-PCR Covid19'
        },
        {
          type: 'vacinacao',
          title: 'Vacinação'
        },
        {
          type: 'consultas',
          title: 'Consultas'
        },
        {
          type: 'telemedicina',
          title: 'Telemedicina'
        },
        {
          type: 'exames',
          title: 'Exames'
        }
      ]
    },
    {
      id: 85,
      fullName: 'Unidade Recife',
      name: 'Recife',
      image: '',
      uf: 'Pernambuco',
      address:
        'Rua Senador José Henrique, 224- CEP 50070-460 - Ilha do Leite - Empresarial Alfred Nobel - Térreo - Recife',
      subaddress: '',
      description:
        '​Na Hospital Israelita Albert Einstein você tem à disposição uma equipe formada por médicos de família e enfermeiros, com apoio de nutricionistas, educadores físicos, psicólogos, entre outros, trabalhando juntos para garantir o cuidado da sua saúde e construir um plano de cuidado exclusivo para você. Levamos em consideração o seu histórico de saúde, seus comportamentos de vida e outros fatores para definir com você, suas metas de saúde e programar consultas e orientações que vão te ajudar a melhorar a sua qualidade de vida.\n\nVocê tem ainda um canal de comunicação direto com a sua equipe, para esclarecer dú​vidas e receber orientações, lembrar você da sua programação de cuidado e agendar suas consultas. Tudo de forma rápida e fácil.',
      coordinates: {
        latitude: -23.5984151,
        longitude: -46.7114279
      },
      initialRegion: {
        latitude: -23.5984151,
        longitude: -46.7114279,
        latitudeDelta: 0.002,
        longitudeDelta: 0.002
      },
      telephone: '+55 (11) 2151-7564 - Público geral',
      employeeTelephone: '+55 (11) 2151-0021 - Funcionários Einstein',
      services: [
        {
          type: 'rt-pcr',
          title: 'RT-PCR Covid19'
        },
        {
          type: 'vacinacao',
          title: 'Vacinação'
        },
        {
          type: 'consultas',
          title: 'Consultas'
        },
        {
          type: 'telemedicina',
          title: 'Telemedicina'
        },
        {
          type: 'exames',
          title: 'Exames'
        }
      ]
    }
  ]
};
