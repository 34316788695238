import { SubMessage, SupMessage } from './style';

interface Props {
    position: 'top' | 'bottom'
    isSuccess: boolean
    isError: boolean
    message?: string
}

export default function Message({
  position,
  isError,
  isSuccess,
  message = 'Insira um cupom válido'
}: Props) {
  const isTop = position === 'top';
  return (
    isTop
? (
      <SupMessage
        isError={isError}
        isSuccess={isSuccess}
      >
        Cupom de desconto
      </SupMessage>
    )
: (
      <SubMessage
        isError={isError}
        isSuccess={isSuccess}
      >
       {message}
      </SubMessage>
    )
  );
}
