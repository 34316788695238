import React, { ReactElement } from 'react';
import {
  Card,
  CardInfo,
  Div,
  Hr
} from './styles';
type props = {
  date?: String
  isToday?: boolean
  separator?: boolean
  children: JSX.Element|JSX.Element[]
  schedule?: boolean
};
export default function ScheduleCard(props: props): ReactElement {
  return (
    <>
      {props.separator &&
        <Div>
          <span className={ props.isToday ? 'today' : '' }>
            { props.isToday ? 'Hoje' : props.date }
          </span>
          <Hr isToday={props.isToday}/>
        </Div>
      }
      <Card>
        <CardInfo schedule={props.schedule}>
          {props.children}
        </CardInfo>
      </Card>
    </>
  );
}
