/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable comma-dangle  */

import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_API,
});

export { api };

export const apiCatalogo = axios.create({
    baseURL: process.env.REACT_APP_CATALOGO_SERVICE
  });

  export const apiCatalogLambda = axios.create({
    baseURL: process.env.REACT_APP_CATALOGO_LAMBDA_SERVICE
  });

  export const apiProductsMocks = axios.create({
    baseURL: process.env.REACT_APP_PAYMENT_AUTH_SERVICE
  });

  export const apiPayment = axios.create({
    baseURL: process.env.REACT_APP_PAYMENT_SERVER_API
  });
