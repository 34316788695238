import { IconButton } from '@material-ui/core';
import { ChangeEvent } from 'react';
import Button from './Button';
import Wrapper from './Form';
import { InputText } from './style';
import { Close } from '@material-ui/icons';

export interface Props {
  isSuccess: boolean;
  isError: boolean;
  voucher?: string;
  applying: boolean;
  onChange: (voucher: ChangeEvent<HTMLInputElement>) => void;
  submit: () => Promise<void>;
  remove: () => void;
  message?: string;
}

export default function FormVoucher({
  isError,
  isSuccess,
  voucher,
  onChange,
  submit,
  remove,
  applying,
  message
}: Props) {
  const isNotEmpty = typeof voucher === 'string' && voucher.length > 0;
  return (
    <Wrapper
      isError={isError}
      isSuccess={isSuccess}
      isNotEmpty={isNotEmpty}
      message={message}
    >
      <InputText
        type="text"
        name="voucher"
        id="voucher"
        value={voucher}
        placeholder="Insira o cupom de desconto"
        aria-disabled
        isError={isError}
        isSuccess={isSuccess}
        onChange={onChange}
        disabled={isSuccess || applying}
      />
      {!isSuccess && !isError && !applying && (
        <Button value="Aplicar" action="submit" submit={submit} />
      )}
      {isError && <Button value="" action="error" />}
      {isSuccess && (
        <>
          <IconButton onClick={remove}>
            <Close fontSize="medium" />
          </IconButton>
          <Button value="" action="success" />
        </>
      )}
      {applying && <Button value="" action="loading" />}
    </Wrapper>
  );
}
